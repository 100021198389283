<template>
  <div class="container">
    <div class="blog-card-wrap">
      <div class="sort-search-wrapper"></div>
      <div class="found">
        <h1 class="">Gesperrte Benutzer</h1>
        Es wurden <strong>{{ this.allFilteredWerke.length }} Einträge</strong>
        gefunden
      </div>
      <button
        type="button"
        name="Datum"
        value="datum"
        @click="sortByThis('datum')"
      >
        Datum <span v-if="this.asc">&#9650;</span><span v-else>&#9660;</span>
      </button>
      <input type="checkbox" id="hide" v-model="showHidden" @change="resetList()" > <label for="hide">ausgeblendet</label>
   
      <div class="blog-cards">
        <div
      
          v-for="sperrung in this.werkeToShow"
          :key="sperrung.sperrung_id"
        >
        <div class="card" >
    
          <Slot      :admin="true" :sperrung="sperrung" />
          <div class="icons">
            <Entsperren
              :userid="sperrung.benutzer_id"
              @ok="doEntsperren(sperrung)"
            />
            <DeleteBenutzer
              :userid="sperrung.benutzer_id"
              @ok="doHide(sperrung)"
            />
          </div>
        </div></div>
        <div class="scrolling-component"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Slot from "../../components/BenutzerGesperrt.vue";
import userService from "../../services/user.service";

import Entsperren from "../../dialogs/benutzer/EntsperrenBenutzer.vue";
import DeleteBenutzer from "../../dialogs/benutzer/DeleteBenutzer.vue";

//import { werkeFiltered } from "../services/werke.service";
//import userService from "../../services/user.service"
export default {
  name: "admin-werke",
  components: { Slot, Entsperren, DeleteBenutzer },
  data() {
    return {
      search: "",
      amount: 8,
      sperrungList: [],
      allFilteredWerke: [],
      werkeToShow: [],
showHidden: false,
      sortBy: "datum",
      asc: false,
    };
  },
  async mounted() {
    this.werkeToShow = this.getWerkeToShow();
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    async doEntsperren(sperrung) {
      await userService.entsperren(sperrung.sperrung_id);

      await this.$store.dispatch("admin/loadBenutzerGesperrt");

      this.$store.state.admin.showFeedback = true;
      this.resetList();
      setTimeout(() => {
        this.$store.state.admin.showFeedback = false;
      }, 5000);
    },

    async doHide(sperrung) {
      var hide = null;
      if (sperrung.hidden) {
        hide = !sperrung.hidden;
      } else {
        hide = true;
      }
      await userService.hide(sperrung.benutzer_id, hide);

      await this.$store.dispatch("admin/loadBenutzerGesperrt");

      this.$store.state.admin.showFeedback = true;

      setTimeout(() => {
        this.$store.state.admin.showFeedback = false;
      }, 5000);

      this.resetList();

      // this.doEntsperren(sperrung)
    },
    sortRandom() {
      console.log("random");
      this.sortBy = "random";
      this.resetList();
    },
    sortDatum() {
      console.log("datum");
      if (this.sortBy == "date") {
        this.asc = !this.asc;
      }
      this.sortBy = "date";

      this.resetList();
    },
    resetList() {
      console.log("RESET___________________________");
      this.werkeToShow = [];
      // console.log("Reset Werke List!", this.werkeToShow);
      this.werkeToShow = this.getWerkeToShow();
    },
    getWerkeToShow() {
      this.allFilteredWerke = this.werkeFiltered();
      // console.log("all filtered werke", this.allFilteredWerke);
      var ret = [];

      var lastIndex = this.werkeToShow.length;
      var newLength =
        this.allFilteredWerke.length - this.amount < this.werkeToShow.length //if
          ? this.allFilteredWerke.length
          : lastIndex + this.amount;
      console.log("NEW LENGTH", newLength);
      for (let i = lastIndex; i < newLength; i++) {
        ret.push(this.allFilteredWerke[i]);
      }

      return ret;
    },

    handleScroll() {
      if (
        window.scrollY + window.innerHeight >=
          document.body.scrollHeight - window.innerHeight &&
        !(this.werkeToShow.length >= this.allFilteredWerke.length)
      ) {
        console.log("load new werke");
        this.werkeToShow = [...this.werkeToShow, ...this.getWerkeToShow()];
      }
    },

    sortByThis(string) {
      console.log(string);
      if (this.sortBy == string) {
        this.asc = !this.asc;
      }
      this.sortBy = string;

      this.resetList();
    },
    werkeFiltered() {
      console.log("GETTING WERKE");
      var filteredWerke = [];

      // Checkboxes

      for (var w of this.$store.state.admin.benutzerGesperrt) {
       if(w.hidden == this.showHidden||(w.hidden == null && this.showHidden==false)){
        filteredWerke.push(w);}
      }

      if (this.sortBy == "datum" || this.sortBy == "") {
        filteredWerke = filteredWerke.sort((a, b) => {
          var dateA = new Date(a.timestamp);
          var dateB = new Date(b.timestamp);
          return this.asc ? dateA - dateB : dateB - dateA;
        });
      }

      console.log(filteredWerke);

      console.log("FILTERED WERKE:", filteredWerke);
      return filteredWerke;
    },
  },

  computed: {
    editWerk: {
      get() {
        return this.$store.state.editWerk;
      },
      set(payload) {
        this.$store.commit("toggleEditWerk", payload);
      },
    },
    profileAdmin() {
      return true; //this.$store.state.profileAdmin;
    },
  },
  beforeDestroy() {
    this.checkedAnwendungen = [];
    this.checkedThemen = [];
    this.checkedGenres = [];
    this.$store.commit("toggleEditWerk", false);
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.icons {
  display: flex;

  align-content: center;
  z-index: 99;
}
.found {
  text-align: center;
  font-size: 1.1em;
  padding-bottom: 20px;
}
.sort-search-wrapper {
  padding-top: 20px;
  padding-left: 2vw;
  padding-right: 2vw;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-wrapper {
  display: inline-block;
  margin-right: 0;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 50px;
  right: 0;
  input {
    border-radius: 10px;

    max-width: 800px;
    padding: 10px;
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .filterBereich {
    justify-content: center;
  }
}

@media (max-width: 750px) {
  .filterBereich {
    //justify-content: space-between;
    padding-right: 10px;
  }
}

.sort-wrapper {
  vertical-align: middle;
  height: 100px;
  button {
    margin-left: 10px;
    margin-right: 10px;
    height: 30px;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;

    //vertical-align: middle;
  }
}
.blog-cards {
  padding: 50px;
  padding-bottom: 200px;
  position: relative;
}
</style>
