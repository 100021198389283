<template>
  <header>
    <img
      class="titleImage"
      src="..\assets\titleImage\header-reagenzglas.jpg"
      alt=""
      id="title-image"
    />

    <nav class="" id="navbar">
      <div class="">
        <div class="nav-links">
          <div class="branding">
            <router-link class="header" :to="{ name: 'Home' }"
              >Literatur-Apotheke      
            </router-link>
          </div>

          <ul v-show="!mobile">
            <!--router-link class="link" :to="{ name: 'Home' }">Home</router-link-->
            <router-link class="link" :to="{ name: 'Werke' }"
              >Apothekerschrank</router-link
            >
            <router-link class="link" :to="{ name: 'WerkCreate' }"
              >Einreichen</router-link
            >

            <router-link class="link" :to="{ name: 'BestenListe' }"
              >Bestenliste</router-link
            >
            <router-link class="link" :to="{ name: 'about' }"
              >Über uns</router-link
            >
            <router-link class="link" :to="{ name: '2022' }"
              >Wettbewerbe</router-link
            >
            <router-link v-if="!user" class="link user" :to="{ name: 'Login' }"
              >Einloggen
            </router-link>
            <div
              :ref="!mobile ? 'profile' : ''"
              v-if="user"
              @click="toggleProfileMenu"
              class="profile"
            >
              <span>{{ this.$store.state.userInfo.profileInitials }}</span>
              <div
                v-show="this.profileMenu"
                id="profileMenu"
                class="profile-menu"
              >
                <div class="info">
                  <p v-if="true" class="initials">
                    {{ this.$store.state.userInfo.profileInitials }}
                  </p>
                  <div class="right">
                    <p>
                      {{ this.$store.state.userInfo.vorname }}
                      {{ this.$store.state.userInfo.nachname }}
                    </p>
                    <p>
                      {{ this.$store.state.profileUsername }}
                    </p>
                    <p>
                      {{ this.$store.state.profileEmails }}
                    </p>
                  </div>
                </div>
                <div class="options">
                  <div class="option">
                    <router-link
                      class="option"
                      :to="{
                        name: 'BenutzerView',
                        params: {
                          benutzerid: this.$store.state.userInfo.benutzerId,
                        },
                      }"
                    >
                      <userIcon class="icon" />

                      <p>Profil</p>
                    </router-link>
                  </div>
                  <div class="option">
                    <router-link class="option" :to="{ name: 'Profile' }">
                      <userIcon class="icon" />

                      <p>Profil bearbeiten</p>
                    </router-link>
                  </div>
                  <div v-if="$store.getters['auth/admin']" class="option">
                    <router-link
                      class="option"
                      :to="{ name: 'Admin_Dashboard' }"
                    >
                      <adminIcon class="icon" />
                      <p>Admin</p>
                    </router-link>
                  </div>
                  <div @click="signOut" class="option">
                    <signOutIcon class="icon" />
                    <p>Ausloggen</p>
                  </div>
                </div>
              </div>
            </div>
            <router-link class="button" :to="{ name: 'WB_Home' }">
            <button class="wb-button">Wettbewerb 2024</button>
          </router-link>
     
          </ul>
         
          <div class="mobile-flex">
        
            <div class="menu-icon" ref="mobileMenu" v-show="mobile"  @click="toggleMobileNav">
              <menu-icon class="" v-show="true"/>
            
            </div>
           
            <div
              :ref="mobile ? 'profile' : ''"
              v-if="user && mobile"
              @click="toggleProfileMenu"
              class="profile"
            >
              <span v-if="true">{{
                this.$store.state.userInfo.profileInitials
              }}</span>
              <div
                v-show="this.profileMenu"
                id="profileMenu"
                class="profile-menu"
              >
                <div class="info">
                  <p v-if="true" class="initials">
                    {{ this.$store.state.userInfo.profileInitials }}
                  </p>
                  <div class="right">
                    <p>
                      {{ this.$store.state.userInfo.vorname }}
                      {{ this.$store.state.userInfo.nachname }}
                    </p>
                    <p>
                      {{ this.$store.state.profileUsername }}
                    </p>
                    <p>
                      {{ this.$store.state.profileEmails }}
                    </p>
                  </div>
                </div>
                <div class="options">
                  <div class="option">
                    <router-link
                      class="option"
                      :to="{
                        name: 'BenutzerView',
                        params: {
                          benutzerid: this.$store.state.userInfo.benutzerId,
                        },
                      }"
                    >
                      <userIcon class="icon" />

                      <p>Profil</p>
                    </router-link>
                  </div>
                  <div class="option">
                    <router-link class="option" :to="{ name: 'Profile' }">
                      <userIcon class="icon" />

                      <p>Profil bearbeiten</p>
                    </router-link>
                  </div>
                  <div v-if="$store.getters['auth/admin']" class="option">
                    <router-link
                      class="option"
                      :to="{ name: 'Admin_Dashboard' }"
                    >
                      <adminIcon class="icon" />
                      <p>Admin</p>
                    </router-link>
                  </div>
                  <div @click="signOut" class="option">
                    <signOutIcon class="icon" />
                    <p>Ausloggen</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <transition name="mobile-nav">
        <div v-show="mobileNav" id="mobile  Nav" class="mobile-nav">
          <ul>
            <router-link class="link" :to="{ name: 'WB_Home' }">
              Wettbewerb 2024
          </router-link>
            <router-link class="link" :to="{ name: 'Werke' }"
              >Apothekerschrank</router-link
            >
            <router-link class="link" :to="{ name: 'WerkCreate' }"
              >Einreichen</router-link
            >

            <router-link class="link" :to="{ name: 'BestenListe' }"
              >Bestenliste</router-link
            >
            <router-link class="link" :to="{ name: 'Ueberuns' }"
              >Über uns</router-link
            >
            <router-link class="link" :to="{ name: '2022' }">
              Wettbewerbe
            </router-link>

            <router-link v-if="!user" class="link" :to="{ name: 'Login' }"
              >Einloggen</router-link
            >
          </ul>
        </div>
      </transition>
    </nav>

    <div id="placeholder"></div>
  </header>
</template>

<script>
import menuIcon from "../assets/Icons/bars-regular.svg";
import userIcon from "../assets/Icons/user-alt-light.svg";
import adminIcon from "../assets/Icons/user-crown-light.svg";
import signOutIcon from "../assets/Icons/sign-out-alt-regular.svg";

export default {
  name: "navigation",
  components: {
    menuIcon,
    userIcon,
    adminIcon,
    signOutIcon,
  },
  data() {
    return {
      profileMenu: false,
      mobile: false,
      mobileNav: false,
      windowWidth: null,
      stickTo: 0,
      navHeight: 0,
      titleHeight: 0,
      lastScroll: 0,
    };
  },

  mounted() {
    var navbar = document.getElementById("navbar");
    var title = document.getElementById("title-image");

    this.stickTo = navbar.offsetTop;

    //this.stickTo = document.getElementById("title-image").offsetHeight;
    console.log(title.height);

    this.navHeight = navbar.offsetHeight ? navbar.offsetHeight : this.navHeight;

    window.addEventListener("resize", this.checkScreen);

    // When the user scrolls the page, execute myFunction

    window.addEventListener("scroll", this.scrollAndStick);

    window.addEventListener("click", (e) => this.outsideClick(e));
    this.checkScreen();
    // Add the sticky class to the navbar when you reach its scroll position.
    // Remove "sticky" when you leave the scroll position
  },
  methods: {
    scrollAndStick() {
      // Get the navbar
      var title = document.getElementById("title-image");
      var navbar = document.getElementById("navbar");
      var placeholder = document.getElementById("placeholder");
      var currentScroll = window.scrollY;
      this.stickTo = title.offsetHeight;

      if (
        currentScroll >= this.stickTo &&
        (!(this.$route.name == "WerkView") || this.lastScroll > currentScroll)
      ) {
        navbar.classList.add("sticky");
        placeholder.style.height = this.navHeight + "px";
      } else {
        navbar.classList.remove("sticky");
        //  placeholder.classList.remove("placeholder")

        placeholder.style.height = "min-content";
      }
      if (
        this.lastScroll - currentScroll > 100 ||
        this.lastScroll - currentScroll < -1000
      ) {
        this.lastScroll = currentScroll;
      }
      this.checkScreen();
    },

    outsideClick(event) {
      var modal1 = document.getElementById("profileMenu") ? this : [];
      var modal2 = document.getElementById("mobileNav") ? this : [];

      var modals = [modal1, modal2];
      // check outside click for multiple elements
      var clickedOut = true;

      for (let m = 0; m < modals.length; m++) {
        let modal = modals[m];
        for (let i = 0; i < modal.length; i++) {
          if (event.target == modal[i] || modal[i].contains(event.target)) {
            clickedOut = false;
          }
        }
      }

      if (clickedOut) {
        if (this.mobileNav && !(event.target === this.$refs.mobileMenu)) {
          this.mobileNav = false;
        }
        if (this.profileMenu && !(event.target === this.$refs.profile)) {
          this.profileMenu = false;
        }
      }
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;

   

      this.$store.state.headerHeight = document.getElementById(
        "title-image"
      ).offsetHeight;

      if (this.windowWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;

      return;
    },

    toggleMobileNav(e) {
      if (e.target === this.$refs.mobileMenu) {
        this.mobileNav = !this.mobileNav;
      }
    },

    toggleProfileMenu(e) {
      if (e.target === this.$refs.profile) {
        this.profileMenu = !this.profileMenu;
      }
    },

    signOut() {
      console.log("logging out");
      this.$store.dispatch("auth/logout");
      this.$store.commit("userInfo/clear");
      //window.location.reload();
    },
  },

  computed: {
    user() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollAndStick);
    window.removeEventListener("resize", this.checkScreen);
  },
};
</script>

<style lang="scss">
a, .einreich-btn{
  cursor: pointer;
}
.mobile-flex {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.sticky {
  transition: all 0.3s;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

header {
  transition: all 0.3s;
  background-color: #ffffff;
  //padding: 0;

  z-index: 99;

  .titleImage {
    width: 100%;
    //max-height: 11vw;
    //width: 100%;
    overflow: hidden;
  }

  .link {
    font-size: calc(15px + 0.1vw);
    padding: 5px 10px 5px 5px;
    transition: 0.1s color ease;
    transition: 0.2s ease-in all;
    overflow: visible;
    white-space: nowrap;
    vertical-align: middle;
    // margin: 0 auto;
    &:hover {
      box-shadow: 0 1px 0 0 black;
    }
  }

  .user {
    float: right;
  }

  nav {
    position: relative;
    top: 0;

    padding: 5px 5px;

    ul {
      display: flex;
      align-items: center;
      max-width: 1200px;
      margin: 0 auto;

      align-items: center;
    }
    z-index: 99;
    background: white;

    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);

    .nav-links {
      .router-link-exact-active {
        border-bottom: 2px solid rgb(97, 141, 190);
        text-shadow: 0px 0.5px rgba(0, 0, 0, 1);
      }
    }
    .branding {
      padding-left: 0px;
      display: flex;
      align-items: center;
      .header {
        font-weight: 600;
        font-size: calc(15px + 0vw);
        color: #000;
        text-decoration: none;
      }
    }
    .nav-links {
      display: flex;
      justify-content: space-between;

      padding-left: 10px;
      align-items: center;

      padding-right: 5%;
      ul {
        display: flex;
        flex: 1;
        align-items: center;

        justify-content: space-evenly;
        width: 100%;
        font-size: calc(15px + 0vw);
        font-weight: normal;
        .link {
          color: #000000;
        }
        .link:last-child {
          margin-right: 0;
        }
      }
    }
    .profile {
      transition: 0.2s all;
      margin: 10px;

      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: black;
      padding: 5px;
      background-color: white;
      border: 1px solid black;

      &:hover {
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 1);
      }
      span {
        pointer-events: none;
      }
      .profile-menu {
        position: absolute;
        top: 60px;
        display: flex;
        width: 250px;

        // background-color: #303030;
        background-color: whitesmoke;
        color: black;
        flex-direction: column;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06);
        .info {
          display: flex;
          align-items: center;
          padding: 15px;
          border-bottom: 1px solid black;
          .initials {
            position: initial;
            width: 40px;
            height: 40px;
            background-color: white;
            color: #303030;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
          }
          .right {
            flex: 1;
            margin-left: 24px;
            p:nth-child(1) {
              font-size: 14px;
            }
            p:nth-child(2),
            p:nth-child(3) {
              font-size: 12px;
            }
          }
        }
        .options {
          padding: 15px;
          .option {
            text-decoration: none;
            color: black;
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            .icon {
              width: 18px;
              height: auto;
            }
            p {
              font-size: 14px;
              margin-left: 12px;
            }
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }
    }

    .mobile-user-menu {
      margin-right: 40px;
    }
  }

  .menu-icon {
    cursor: pointer;
    position: relative;
    //top: 5px;
    margin-left: 20px;

    width: auto;
    border: 1px solid black;

    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 5px;
    cursor: pointer;
    background: white;

    &:hover{
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 1);
    }
    svg{
      pointer-events: none;
    }
  }

  .mobile-nav {
    position: absolute;
    background-color: rgb(235, 235, 235);

    top: 50px;

width: 100vw;
min-height: 50vh ;
right: 0;
max-width: 350px;
    z-index: 9999;
    border-radius: 10px;
box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, .5);
    ul {
      padding: 50px;
      width: 70%;
      max-width: 250px;
      display: flex;
      flex-direction: column;

      .link {
        padding: 20px 10px;
        color: black;
        font-size: 18px;
      }
    }
  }

  .mobile-nav-enter-active,
  .mobile-nav-leave-active {
    transition: all 0.3s ease;
  }

  .mobile-nav-enter {
    // transform: translateX(0%);
    opacity: 0;
  }

  .mobile-nav-enter-to {
    // transform: translateX(50%);
    opacity: 1;
  }

  .mobile-nav-leave-to {
    // transform: translateX(calc(100% + 550px));
    opacity: 0;
  }
}
#placeholder {
  box-shadow: none !important;
}
/*
@supports (position: sticky) or (position: -webkit-sticky) {
  @media (min-width: 0px) {
    #navbar {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
    }
  }
}*/
</style>
