<template>
  <div
    :class="wettbewerb ? 'wettbewerb' : 'normal'"
    v-if="this.$store.getters['auth/admin']"
  >
    <router-link v-if="!wettbewerb" class="link home" :to="{ name: 'Home' }"
      >zurück zur Apotheke</router-link
    >
    <router-link v-else class="link home" :to="{ name: 'WB_Home' }"
      >zurück zum Wettbewerb</router-link
    >
    <div class="wb-admin-input">
      <input id="wb-admin" type="checkbox" v-model="wettbewerb" /><label
        v-if="wettbewerb"
        for="wb-admin"
        >Wettbewerb</label
      >
      <label v-else for="wb-admin">Normal</label>
    </div>
    <nav class="admin-nav">
      <div class="list-wrap">
        <div>
          <router-link class="link home" :to="{ name: 'Admin_Dashboard' }"
            ><div>Dashboard</div></router-link
          >
        </div>
        <div>
          <router-link class="link" :to="{ name: 'Admin_Werke' }"
            ><div>Werke</div></router-link
          >
        </div>
        <div>
          <router-link class="link" :to="{ name: 'Admin_Werke_Gesperrt' }"
            ><div>gesperrte Werke</div></router-link
          >
        </div>
        <div>
          <router-link class="link" :to="{ name: 'Admin_Werke_Markiert' }"
            ><div>markierte Werke</div></router-link
          >
        </div>
        <div>
          <router-link class="link" :to="{ name: 'Admin_Werke_Nuggets' }"
            ><div>Nuggets</div></router-link
          >
        </div>
        <div>
          <router-link class="link" :to="{ name: 'Admin_Benutzer' }"
            ><div>Benutzer*</div></router-link
          >
        </div>
        <div>
          <router-link class="link" :to="{ name: 'Admin_Benutzer_Gesperrt' }"
            ><div>gesperrte Benutzer*</div></router-link
          >
        </div>
        <div>
          <router-link class="link" :to="{ name: 'Admin_Benutzer_Markiert' }"
            ><div>markierte Benutzer*</div></router-link
          >
        </div>
        <div>
          <router-link class="link" :to="{ name: 'Admin_Kommentare' }"
            ><div>gemeldete Kommentare</div></router-link
          >
        </div>
      </div>
    </nav>
    <div v-if="this.$store.state.admin.showFeedback" class="feedback">
      {{ this.$store.state.admin.feedback }}
    </div>
<div class="admin-bereich">
    <router-view v-slot="{ Component }">
      <component ref="view" :is="Component" />
    </router-view>
    </div>
    <Footer v-if="!navigation" />
  </div>
</template>

<script>
import Footer from "../components/Footer";

export default {
  name: "adminLayout",
  components: { Footer },
  data() {
    return {
      navigation: null,
    };
  },
  async created() {
    this.checkRoute();
  },
  async mounted() {
    this.$store.state.loadingMessage = "Admin-Bereich wird geladen";
    this.$store.state.loading = true;

    if (this.$store.getters["auth/admin"]) {
      await this.$store.dispatch("admin/loadWerkeAll");
      await this.$store.dispatch("admin/loadWerkeGesperrt");
      await this.$store.dispatch("admin/loadWerkeMarkiert");
      await this.$store.dispatch("admin/loadWerkeNuggets");
      await this.$store.dispatch("admin/loadBenutzerAll");
      await this.$store.dispatch("admin/loadBenutzerGesperrt");
      await this.$store.dispatch("admin/loadBenutzerMarkiert");

      await this.$store.dispatch("admin/loadKommentarMeldungen");
    }

    this.$store.state.loading = false;
    this.$store.state.loadingMessage = "";
  },
  methods: {
    toggleWB() {
      this.$store.state.admin.wb = !this.$store.state.admin.wb;
    },

    checkRoute() {
      if (
        this.$route.name === "Login" ||
        this.$route.name === "Register" ||
        this.$route.name === "ForgotPassword"
      ) {
        this.navigation = true;
        return;
      }
      this.navigation = false;
    },
  },
  watch: {
    $route() {
      this.checkRoute();
    },
  },
  computed: {
    wettbewerb: {
      set(payload) {
        this.$store.state.admin.wb = payload;
      },
      get() {
        return this.$store.state.admin.wb;
      },
    },
  },
};
</script>

<style lang="scss">
.wettbewerb {
  background: rgb(224, 207, 209);
}
.normal {
  background: rgb(191, 191, 207);
}

.feedback {
  width: 100%;
  font-weight: bold;
  text-align: center;
  padding-top: 100px;
  color: green;
}

.wb-admin-input {
  width: 100%;
  text-align: center;
  font-size: 36px;
}
.admin-nav {
  width: 100%;
  .list-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 30px;

    div {
      text-align: center;
      margin: 5px;
      border-radius: 10px;
      min-width: 150px;
      width: 10vw;

      padding-top: 10px;

      .link {
        color: black;
        text-decoration: none;
        font-weight: bold;
      }

      div {
        background-color: rgb(255, 255, 255);

        width: 100%;
        height: 100%;
        &:hover {
          background-color: rgb(178, 197, 250);
        }
      }
    }
  }
}
.router-link-active > div {
  border-bottom: 5px solid rgb(97, 141, 190);
}
.home {
  border-bottom: none;
}
.admin-bereich{


.blog-cards{
  padding: 100px;
  border-radius:10px;
  background-color: rgb(255, 255, 255);
    overflow: scroll;
  .card{
  padding-bottom:30px;
  border-bottom: 1px solid black;
}
}}
</style>

