<template>
  <div class="werk-slot-admin">
    <div id="placeholder"></div>
    <div class="info header" id="header" v-if="this.header == true">
      <span class="id bold">werkid</span>
      <span class="title bold">Titel</span>
      <span class="author bold">Autor</span>
      <span class="author bold">Mail</span>
      <span class="date bold">Datum</span>
    </div>

    <div v-else>
      <div class="werk-card-admin">
        <div class="info">
          <span class="id"
            >{{ werk.WerkId }}
            <img
              v-show="werk.Stamm == 1"
              class="stamm-img"
              src="../assets/stamm klein.png"
              alt=""
            />
         
          </span>

          <span class="title">
            <strong> {{ werk.Titel }}</strong></span
          >

          <span class="author"> {{ werk.Autor }}</span>

          <span @click="userBearbeiten(werk.BenutzerID)" class="author">
            {{ email }}</span
          >

          <span class="date">
            {{
              new Date(werk.Timestamp).toLocaleString("de", {
                dateStyle: "short",
              })
            }}
          </span>
        </div>
      </div>

      <div class="icons"></div>
    </div>
  </div>
</template>

<script>
//import Arrow from "../assets/Icons/arrow-right-light.svg";

import werkeService from "../services/werke.service";
import userService from "../services/user.service";

export default {
  name: "WerkSlot",
  //props: ["werk"],
  props: {
    werk: Object,
    admin: Boolean,
    header: Boolean,
  },
  components: {
    // Arrow,
  },
  data() {
    return {
      username: "",
      stickTo: 0,
      stickHeight: 0,
      lastScroll: 0,
      mail: "",
      gesperrt: false,
    };
  },
  async created() {},

  async mounted() {
    if (this.header) {
      var stick = document.getElementById("header");
      console.log(stick);
      this.stickTo = stick.offsetTop;
      this.stickHeight = stick.offsetHeight
        ? stick.offsetHeight
        : this.stickHeight;
      window.addEventListener("scroll", this.scrollAndStickSlot);
      console.log(this.stickTo);
    } else {
      console.log(this.werk);

      this.mail = await userService.getMail(this.werk.BenutzerID);
      var sperrungen = await werkeService.getSperrungen();
      this.geperrt = sperrungen.some((s) => {
        s.werk_id == this.werk.id;
      });
    }
  },
  methods: {
    scrollAndStickSlot() {
      // Get the navbar
      console.log("check scrolling");
      var sticky = document.getElementById("header");
      console.log(sticky);
      var placeholder = document.getElementById("placeholder");
      var currentScroll = window.scrollY;

      console.log(sticky);

      console.log(this.stickHeight);
      // Get the offset position of the navbar
      console.log(this.$route.name);
      if (currentScroll >= this.stickTo) {
        sticky.classList.add("sticky");
        placeholder.style.height = this.stickHeight + "px";
        console.log("show NavBar");
      } else {
        sticky.classList.remove("sticky");
        //  placeholder.classList.remove("placeholder")
        console.log("hide NavBar");
        placeholder.style.height = "min-content";
      }
      if (
        this.lastScroll - currentScroll > 100 ||
        this.lastScroll - currentScroll < -1000
      ) {
        this.lastScroll = currentScroll;
      }
    },

    werkBearbeiten() {
      this.$router.push({
        name: "WerkBearbeiten",
        params: { blogid: this.post.blogID },
      });
    },
  },
  computed: {
    email() {
      return this.mail;
    },
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollAndStickSlot);
  },
};
</script>

<style lang="scss" scoped>
.stamm-img {
  position: absolute;

  filter: grayscale(0.5);
  width: 30px;
  left: 3%;
}
.werk-slot-admin {
  min-width: 800px;

  border: 1px solid gray;
  position: relative;
  white-space: pre-line;
  &:hover {
    background-color: rgb(229, 229, 229);
  }
  .icons {
    display: flex;
    position: relative;
    z-index: 1;
    width: 100%;

    right: 0;
    .icon-- {
      display: flex;
      justify-content: center;
      align-items: center;

      //background-color: #fff;
      transition: 0.5s ease all;
      &:hover {
        background-color: #303030;
        .edit,
        .delete {
          path {
            fill: #fff;
          }
        }
      }
      &:nth-child(1) {
        margin-right: 8px;
      }
      .edit,
      .delete {
        pointer-events: none;
        height: 15px;
        width: auto;
      }
    }
  }

  .card-header {
    width: 100%;
    font-size: 13px;
    // padding: 0px 10px 10px 10px;
  }

  border-radius: 0px;
  //background-color: #fff;

  transition: 0.5s ease all;

  &:hover {
    //transform: rotateZ(-1deg) scale(1.01);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  div {
    .id,
    .title,
    .author,
    .date,
    .username {
      width: 30%;
      font-size: calc(0.5rem + 0.5vw);
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 1; /* fallback */
      max-height: 200px; /* fallback */
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
    .author {
      width: 20%;
    }
    .date {
      width: 8%;
    }

    .username {
      width: 10%;
    }
    .id {
      width: 6%;
    }

    .props {
      display: flex;

      .col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 10px;
      }
      .col1 {
        color: gray;
        float: right;
      }
    }
  }

  .header {
    position: relative;
    top: 0;
    span {
    }
  }

  .info {
    display: flex;

    z-index: 3;
    //padding: 10px 16px;
    color: #000;

    span {
      height: 50px;
      border-right: 1px solid #000000;
      white-space: normal;
      word-break: break-word;
      display: flex;
      align-items: center;
    }

    .link {
      display: inline-flex;
      align-items: center;
      margin-top: auto;
      font-weight: 500;

      font-size: 12px;

      transition: 0.5s ease-in all;
      &:hover {
        color: rgba(48, 48, 48, 0.8);
      }
      .arrow {
        width: 10px;
      }
    }
  }
}
</style>
