<template>
  <div class="icon">
    <button class="nugget" @click="handle">Nugget</button>
    <text-dialog ref="textDialogue"></text-dialog>
  </div>
</template>




<script>
import TextDialog from "../TextDialog.vue";

export default {
  name: "NuggetWerk",

  components: { TextDialog },
  props: {
    werkid: Number,
  },
  data() {
    return {
      werk: null,
    };
  },
  created() {
    console.log("DELETE WERK DIALOG");
  },

  methods: {
    async handle() {
      const text = await this.$refs.textDialogue.show({
          characters: 200,
        title: "Nugget gefunden?",
        message: "Grund:",
        okButton: "Hinzufügen",
         cancelButton: "abbrechen",
        feedback: "Werk nuggiert"
      });
      if (text != "") {
        this.$emit("ok", text);
      }
    },
  },
};
</script>

<style scoped>
.nugget {
  background-color: rgb(0, 70, 60);
}
</style>