<template>
  <div class="user-slot-admin">
    <div id="placeholder"></div>
    <div class="info header" id="header" v-if="this.header">
      <span class="id bold">userid</span>
      <span class="title bold">Name</span>
      <span class="author bold">Geburtsjahr</span>
      <span class="author bold">Email</span>

      <span class="date bold">registriert</span>
    </div>
    <div v-else class="user-slot-admin"> 
      <div class="info">
     
        <span class="id">{{ user.BenutzerId }}</span>
        <span class="title">{{ user.Vorname }} {{ user.Nachname }}</span>
        <span class="author"> {{ user.Geburtsjahr }}</span>
        <span class="author"> {{ user.Email }}</span>

        <span class="date">
          {{
            new Date(user.Timestamp).toLocaleString("de", {
              dateStyle: "short",
            })
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
//import Arrow from "../assets/Icons/arrow-right-light.svg";

export default {
  name: "UserSlot",
  //props: ["werk"],
  props: {
    user: Object,
    admin: Boolean,
    header: Boolean,
  },
  components: {
    // Arrow,
  },
  data() {
    return {
      username: "",
      stickTo: 0,
      stickHeight: 0,
      lastScroll: 0,
      email: "",
    };
  },

  async created() {},

  async mounted() {
    if (this.header) {
      var stick = document.getElementById("header");
      console.log(stick);
      this.stickTo = stick.offsetTop;
      this.stickHeight = stick.offsetHeight
        ? stick.offsetHeight
        : this.stickHeight;
      window.addEventListener("scroll", this.scrollAndStickSlot);
      console.log(this.stickTo);
    }
  },
  methods: {
    scrollAndStickSlot() {
      // Get the navbar
      console.log("check scrolling");
      var sticky = document.getElementById("header");
      var placeholder = document.getElementById("placeholder");
      var currentScroll = window.pageYOffset;

    
      console.log(this.stickHeight);
      // Get the offset position of the navbar
      console.log(this.$route.name);
      if (currentScroll >= this.stickTo) {
        sticky.classList.add("sticky");
        placeholder.style.height = this.stickHeight + "px";
        console.log("show NavBar");
      } else {
        sticky.classList.remove("sticky");
        //  placeholder.classList.remove("placeholder")
        console.log("hide NavBar");
        placeholder.style.height = "min-content";
      }
      if (
        this.lastScroll - currentScroll > 100 ||
        this.lastScroll - currentScroll < -1000
      ) {
        this.lastScroll = currentScroll;
      }
    },
    deletePost() {  
      this.$store.dispatch("deletePost", this.post.blogID);
    },
    werkBearbeiten() {
      this.$router.push({
        name: "WerkBearbeiten",
        params: { blogid: this.post.blogID },
      });
    },
  },

  computed: {
    editWerk() {
      return this.$store.state.editWerk;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-slot-admin {
  min-width: 800px;
  border: 1px solid gray;
  position: relative;
  white-space: pre-line;
  :hover {
    background-color: rgb(233, 233, 233)
  }
  .icons {
    display: flex;
    position: relative;
    align-content: center;
    z-index: 99;
    float: right;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      //background-color: #fff;
      transition: 0.5s ease all;
      &:hover {
        background-color: #303030;
        .edit,
        .delete {
          path {
            fill: #fff;
          }
        }
      }
      &:nth-child(1) {
        margin-right: 8px;
      }
      .edit,
      .delete {
        pointer-events: none;
        height: 15px;
        width: auto;
      }
    }
  }

  .card-header {
    width: 100%;
    font-size: 13px;
    // padding: 0px 10px 10px 10px;
  }

  //border-radius: 8px;
  //background-color: #fff;

  transition: 0.5s ease all;

  &:hover {
    //transform: rotateZ(-1deg) scale(1.01);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  div {
    .id,
    .title,
    .author,
    .date,
    .username {
      width: 30%;
      font-size: calc(0.5rem + 0.5vw);
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 1; /* fallback */
      max-height: 200px; /* fallback */
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
    .author {
      width: 20%;
    }
    .date {
      width: 8%;
    }

    .username {
      width: 10%;
    }
    .id {
      width: 6%;
    }

    .props {
      display: flex;

      .col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 10px;
      }
      .col1 {
        color: gray;
        float: right;
      }
    }
  }

  .header {
    position: relative;
    top: 0;
    span {
    }
  }

  .info {
    display: flex;

    z-index: 3;
    //padding: 10px 16px;
    color: #000;

    span {
      height: 50px;
      border-right: 1px solid #000000;
      white-space: normal;
      word-break: break-word;
      display: flex;
      align-items: center;
    }

    .link {
      display: inline-flex;
      align-items: center;
      margin-top: auto;
      font-weight: 500;

      font-size: 12px;

      transition: 0.5s ease-in all;
      &:hover {
        color: rgba(48, 48, 48, 0.8);
      }
      .arrow {
        width: 10px;
      }
    }
  }
}
</style>
