<template>
  <header class="header wb-header">
    <img
      class="titleImage"
      src="..\assets\titleImage\header-wb.jpg"
      alt=""
      id="title-image"
    />

    <nav class="nav-links wb" id="navbar">
      <div class="">
        <ul class="nav-links">
          <div class="branding">
            <router-link class="header" :to="{ name: 'WB_Home' }"
              ><span class="link">Wettbewerb</span></router-link
            >
          </div>
          <router-link
            class="einreich-btn link"
            :to="{ name: 'WB_WerkCreate' }"
            v-show="!mobile"
          >
            Einreichen
          </router-link>
          <!--router-link class="link" :to="{ name: 'Home' }">Home</router-link-->
          <router-link class="link" :to="{ name: 'WB_Werke' }" v-show="!mobile"
            >Wettbewerbsbeiträge</router-link
          >

          <router-link
            class="link"
            :to="{ name: 'WB_BestenListe' }"
            v-show="!mobile"
            >Voting</router-link
          >
          <!--router-link class="link" :to="{ name: 'WB_Presse' }" v-show="!mobile"
            >Presse</router-link
          -->
          <div
            class="menu-icon"
            ref="mobileMenu"
            v-show="mobile"
            @click="toggleMobileNav"
          >
            <menu-icon class="" v-show="true" />
          </div>

 
          <router-link class="apo-btn" :to="{ name: 'Home' }">
            <span class="arrow link">&rarr;</span>
            <span class="link">zur Apotheke</span>
          </router-link>
        </ul>
      </div>
      <div class="mobile-flex"></div>
      <transition name="mobile-nav">
        <div v-show="mobileNav" id="mobile  Nav" class="mobile-nav">
          <ul>
            <router-link class="link" :to="{ name: 'WB_WerkCreate' }"
              >Einreichen</router-link
            >
            <router-link class="link" :to="{ name: 'WB_Werke' }"
              >Wettbewerbsbeiträge</router-link
            >

            <router-link class="link" :to="{ name: 'WB_BestenListe' }"
              >Bestenliste</router-link
            >
          </ul>
        </div>
      </transition>
    </nav>

    <div id="placeholder"></div>
  </header>
</template>

<script>
//import menuIcon from "../assets/Icons/bars-regular.svg";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import menuIcon from "../assets/Icons/bars-regular.svg";
library.add(fas);
library.add(far);

export default {
  name: "navigation",
  components: {
    menuIcon,

    // FontAwesomeIcon,
  },
  data() {
    return {
      profileMenu: null,
      mobile: null,
      mobileNav: null,
      windowWidth: null,
      stickTo: 0,
      navHeight: 0,
      lastScroll: 0,
    };
  },

  mounted() {
    var navbar = document.getElementById("navbar");

    this.stickTo = navbar.offsetTop;

    //this.stickTo = document.getElementById("title-image").offsetHeight;
    console.log(document.getElementById("title-image").offsetHeight);

    this.navHeight = navbar.offsetHeight ? navbar.offsetHeight : this.navHeight;

    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();

    // When the user scrolls the page, execute myFunction

    window.addEventListener("scroll", this.scrollAndStick);

    window.addEventListener("click", (e) => this.outsideClick(e));

    // Add the sticky class to the navbar when you reach its scroll position.
    // Remove "sticky" when you leave the scroll position
  },
  methods: {
    scrollAndStick() {
      // Get the navbar
      var title = document.getElementById("title-image");
      var navbar = document.getElementById("navbar");
      var placeholder = document.getElementById("placeholder");
      var currentScroll = window.pageYOffset;
      this.stickTo = title.offsetHeight;

      if (
        currentScroll >= this.stickTo &&
        (!(this.$route.name == "WB_WerkView") ||
          this.lastScroll > currentScroll)
      ) {
        navbar.classList.add("sticky");
        placeholder.style.height = this.navHeight + "px";
      } else {
        navbar.classList.remove("sticky");
        //  placeholder.classList.remove("placeholder")

        placeholder.style.height = "min-content";
      }
      if (
        this.lastScroll - currentScroll > 100 ||
        this.lastScroll - currentScroll < -1000
      ) {
        this.lastScroll = currentScroll;
      }
    },

    outsideClick(event) {
      var modal1 = document.getElementById("profileMenu") ? this : [];
      var modal2 = document.getElementById("mobileNav") ? this : [];

      var modals = [modal1, modal2];
      // check outside click for multiple elements
      var clickedOut = true;

      for (let m = 0; m < modals.length; m++) {
        let modal = modals[m];
        for (let i = 0; i < modal.length; i++) {
          if (event.target == modal[i] || modal[i].contains(event.target)) {
            clickedOut = false;
          }
        }
      }

      if (clickedOut) {
        if (this.mobileNav && !(event.target === this.$refs.mobileMenu)) {
          this.mobileNav = false;
        }
        if (this.profileMenu && !(event.target === this.$refs.profile)) {
          this.profileMenu = false;
        }
      }
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },

    toggleMobileNav(e) {
      if (e.target === this.$refs.mobileMenu) {
        this.mobileNav = !this.mobileNav;
      }
    },

    toggleProfileMenu(e) {
      if (e.target === this.$refs.profile) {
        this.profileMenu = !this.profileMenu;
      }
    },

    signOut() {
      console.log("logging out");
      this.$store.dispatch("auth/logout");
      window.location.reload();
    },
  },

  computed: {
    user() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollAndStick);
    window.removeEventListener("resize", this.checkScreen);
  },
};
</script>

<style lang="scss" scoped>
.wb {
  .link:hover {
    box-shadow: 0 1px 0 0 rgb(255, 255, 255);
  }
  .router-link-exact-active {
    border-bottom: 2px solid rgb(255, 255, 255);
    text-shadow: 0px 0.5px rgb(218, 218, 218);
  }
}

header .menu-icon{

  color: white;
  background: none;;
  border: 2px solid white;
}
</style>
