  
<template>
  <div class="edit-werk">
    <Loading v-show="loading" />

    <div class="container">
      <h1>Werk bearbeiten</h1>
      <h4>
        eingereicht von {{ werk.Vorname }} {{ werk.Nachname }} || Werk ID: {{ werk.WerkId }}
      </h4>
   

      <div :class="{ invisible: !error }" class="err-message">
        <p><span>Error:</span>{{ this.errorMsg }}</p>
      </div>
      <div class="blog-info">
        <span class=""
          >Titel:<input
            class="title-input"
            type="text"
            placeholder=""
            v-model="werk.Titel"
          />
        </span>

        <span class="autor"
          >Autor:
          <input
            class="title-input autor"
            type="text"
            placeholder=""
            v-model="werk.Autor"
        /></span>
        <span class="autor"
          >Quelle:
          <input
            class="title-input autor"
            type="text"
            placeholder=""
            v-model="werk.Quelle"
        /></span>
      </div>

      <div class="editor">
        Inhalt:
        <vue-editor :editorOptions="editorSettings" v-model="werk.Inhalt" />
      </div>

      <div class="editor">
        Motivation:
        <vue-editor
          :editorOptions="editorSettings2"
          v-model="werk.Motivation"
        />
      </div>

      <div class="dropdownArea">
        <div class="dropdownRow">
          <p>
            <span>Anwendung:</span>

            <span class="serif">{{ _anwendung }}</span>
          </p>
          <select v-model="werk.Anwendung">
            <option
              v-for="(el, index) in this.$store.state.anwendungen"
              :key="index"
              :value="index + 1"
            >
              {{ el.name }}
            </option>
          </select>
        </div>

        <div class="dropdownRow">
          <p>
            <span>Genre:</span> <span class="serif">{{ _genre }}</span>
          </p>
          <select v-model="werk.Genre">
            <option
              v-for="(el, index) in this.$store.state.genres"
              :key="index"
              :value="index + 1"
            >
              {{ el.name }}
            </option>
          </select>
        </div>
        <div class="dropdownRow">
          <p>
            <span>Thema:</span> <span class="serif">{{ _thema }}</span>
          </p>
          <select v-model="werk.Thema">
            <option
              v-for="(el, index) in this.$store.state.themen"
              :key="index"
              :value="index + 1"
            >
              {{ el.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="blog-actions">
        <button @click="updateWerk">Änderungen speichern</button>
        <button @click="resetWerk">zurücksetzen</button>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/Loading";
import werkService from "../../services/werke.service";
import Quill from "quill";
window.Quill = Quill;

export default {
  name: "AdminEditWerk",
  data() {
    return {
      file: null,
      error: null,
      errorMsg: null,
      loading: null,
      routeID: null,
      currentWerk: null,
      backupWerk: null,
      editorSettings: {
        modules: {
          toolbar: [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
        },
      },
      editorSettings2: {
        modules: {
          toolbar: false,
        },
      },
    };
  },
  components: {
    Loading,
  },

  async created() {
    this.routeID = this.$route.params.werkid;
    console.log("werke", this.$store.state.admin.werkeAll);
    this.currentWerk = await werkService.getWerkById(this.$route.params.werkid)
    

    this.backupWerk = Object.assign({}, this.currentWerk);

    console.log("created");
  },

  methods: {
    fileChange() {
      this.file = this.$refs.blogPhoto.files[0];
      const fileName = this.file.name;
      this.$store.commit("fileNameChange", fileName);
      this.$store.commit("createFileURL", URL.createObjectURL(this.file));
    },
    openPreview() {
      this.$store.commit("openPhotoPreview");
    },
    async updateWerk() {
      werkService.updateWerk(this.currentWerk);
      this.$store.dispatch("admin/loadWerkeAll")
      this.$store.state.showModal=true
      this.$store.state.modalMessage="Änderungen gespeichert"
    },
    resetWerk() {
      console.log("Reset", this.backupWerk);

      this.currentWerk = Object.assign({}, this.backupWerk);
    },
  },
  computed: {
    profileId() {
      return this.$store.state.profileId;
    },
    blogCoverPhotoName() {
      return this.$store.state.blogPhotoName;
    },
    werk: {
      get() {
        return this.currentWerk;
      },
      set(payload) {
        this.currentWerk = payload;
      },
    },
    _anwendung() {
      var a = this.$store.state.anwendungen.filter((anw) => {
        return anw.id == this.currentWerk.Anwendung;
      });

      if (a.length == 0) return "-";
      else return a[0].name;
    },
    _thema() {
      var t = this.$store.state.themen.filter((th) => {
        return th.id == this.currentWerk.Thema;
      });

      if (t.length == 0) return "-";
      else return t[0].name;
    },
    _genre() {
      var g = this.$store.state.genres.filter((gen) => {
        return gen.id == this.currentWerk.Genre;
      });

      if (g.length == 0) return "-";
      else return g[0].name;
    },
  },
};
</script>

<style lang="scss">
.edit-werk {
  position: relative;
  height: 100%;
  background: white;
  button {
    margin-top: 0;
  }
  .router-button {
    text-decoration: none;
    color: #fff;
  }
  label,
  button,
  .router-button {
    transition: 0.5s ease-in-out all;
    align-self: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 20px;
    padding: 12px 24px;
    color: #fff;
    background-color: #303030;
    text-decoration: none;
    &:hover {
      background-color: rgba(48, 48, 48, 0.7);
    }
  }
  .container {
    position: relative;
    height: 100%;
    padding: 10px 25px 60px;
  }
  // error styling
  .invisible {
    opacity: 0 !important;
  }
  .err-message {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    color: #fff;
    margin-bottom: 10px;
    background-color: #303030;
    opacity: 1;
    transition: 0.5s ease all;
    p {
      font-size: 14px;
    }
    span {
      font-weight: 600;
    }
    span {
      font-weight: 600;
    }
  }
  .blog-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    min-width: 100%;

    span {
      font-size: 24px;
      padding: 10px;
    }

    input {
      transition: 0.5s ease-in-out all;
      margin-left: 10px;
      border: none;
      border-bottom: 1px solid #303030;

      &:focus {
        outline: none;
        box-shadow: 0 1px 0 0 #303030;
      }
      min-width: 400px;
    }
    .autor {
      font-size: 16px;
    }
  }

  .dropdownArea {
    display: flex;
    flex-direction: row;
    padding: 3vw;
    flex-wrap: wrap;
    padding-top: 80px;

    .dropdownRow {
      padding-bottom: 50px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: flex-start;
      .serif {
        font-weight: bold;
      }
      span {
        padding-left: 10px;
        padding-bottom: 10px;
        font-size: 18px;
        font-weight: 400;
      }

      width: 400px;

      select {
        color: black;
        border-radius: 20px;
        font-size: 1.1em;
        min-width: 300px;
        padding: 20px;
        border: 1px solid;
      }
      option {
        font-size: 1.1em;
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.356);
        padding: 12px;
      }
      option:hover {
        background-color: cadetblue;
      }

      option:first-child {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
      option:last-child {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      .dropdown-content {
        min-width: 350px;
        min-height: 50px;
        max-height: max-content;
        overflow: visible;
        border-radius: 20px;
        border: 1px solid;
      }
    }
  }
  .editor {
    height: min-content;
    display: flex;
    flex-direction: column;
    .quillWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .ql-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: scroll;
    }
    .ql-editor {
      padding: 20px 16px 30px;
    }
  }
  .blog-actions {
    margin-top: 32px;
    button {
      margin-right: 16px;
    }
  }
}
</style>