<template>
  <div class="profile">
    <Modal
      v-if="modalActive"
      :modalMessage="modalMessage"
      v-on:close-modal="closeModal"
    />

    <div class="container">
      <div class="profile-info">
        <!--div class="initials">{{ this.benutzer.initials }}</div-->
        <h2>{{ this.benutzer.vorname }} {{ this.benutzer.nachname }}</h2>
        <div class="info">
          
          <span v-if=" false&&this.benutzer.geburtsjahr ">Geburtsjahr: {{ this.benutzer.geburtsjahr }} </span>
          <span v-if="false&&this.benutzer.ort">Wohnort: {{ this.benutzer.ort }} </span>


          <span v-if="this.benutzer.beruf">
            Beruf: {{ this.benutzer.beruf }}
          </span>
        </div>
        <div class="vita-wrapper">
          <span class="vita serif" v-html="this.benutzer.vita"></span>
        </div>
        
      </div>
    </div>
    <tabs class="tabs">
      <tab name="Eingereicht">
        <div class="blog-cards eingereicht">
   
          <div v-for="werk in userWerke" :key="werk.WerkId">
     
            <router-link
              class="link"
              :to="{ name: 'WerkView', params: { werkid: werk.WerkId } }"
              :title="werk.Titel"
            >
              <WerkPost :werk="werk"
            /></router-link>
          </div>
          <div class="notFound" v-if="!userWerke || userWerke.length == 0">
            ...noch keine Werke eingereicht.
          </div>
        </div>
      </tab>

      <tab name="Favoriten">
        <div class="blog-cards favoriten">
          <div v-for="fav in userFavoriten" :key="fav.favorit_id">
            <div v-if="benutzer.id == $store.state.userInfo.benutzerId">
                     <EntFav  @ok="deleteFavorit(fav)"/>
                    </div>
                    <br><br>
            <WerkFavorit :fav="fav" />

       
   
          </div>

          <div
            class="notFound"
            v-if="!userFavoriten || userFavoriten.length == 0"
          >
            ...noch keine Favoriten.
          </div>
        </div>
      </tab>
    </tabs>
  </div>
</template>

<script>
import Modal from "../components/Modal";
import WerkPost from "../components/WerkCard";
import WerkFavorit from "../components/WerkFavorit";
import EntFav from "../dialogs/main/Entfavorisieren.vue"
import userService from "../services/user.service";
import { Tabs, Tab } from "../components/tabs";
import werkeService from '../services/werke.service';
export default {
  name: "Profile",
  components: {
    Modal,
    WerkPost,
    WerkFavorit,
    EntFav,
    Tab,
    Tabs,
  },
  data() {
    return {
      modalMessage: "Changes were saved!",
      modalActive: null,
      benutzer: "",
      favoriten: null,
      userWerke:[]
    };
  },
  async created() {
    this.benutzer = await userService.getBenutzerByID(
      this.$route.params.benutzerid
    );
    console.log(this.benutzer);

    await this.setFavoriten();
    console.log(this.favoriten);

    this.userWerke = await werkeService.getWerkeFiltered("benutzerid="+this.benutzer.id+"&") //---------------------- FETCH 
  },
  methods:{
async deleteFavorit(fav){
  await userService.deleteFavorit(fav.favorit_id)
  
    await this.setFavoriten(); 

},

async setFavoriten(){
  this.favoriten = await userService.getFavoriten(this.benutzer.id)

    this.favoriten = this.favoriten.sort((a,b)=>{
       var dateA = new Date(a.timestamp);
          var dateB = new Date(b.timestamp);
          return dateB - dateA;
    });
}
  },
  computed: {
   XXXXuserWerke() {
      console.log(this.benutzer.benutzerid);
      return this.$store.getters.getUserWerke(this.benutzer.id);
    },
    userFavoriten() {
      return this.favoriten;
    },
  },
};
</script>
<style lang="scss" scoped>
.vita-wrapper {
  margin: 20px 0 20px 0;
  //box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  //background-color: #fdfdfd;
  border-radius: 10px;
  .vita {
    padding: 10px;
    color: #303030;
    line-height: 1.5;
  }
}

.profile {
  .container {
    max-width: 800px;
    padding: 6px 5px;
    h2 {
      text-align: center;
      margin-bottom: 16px;
      font-weight: 300;
      font-size: 32px;
    }
    .profile-info {
      border-radius: 8px;

      padding: 32px;
      //background-color: #f1f1f1;
      display: flex;
      flex-direction: column;

      margin: 32px auto;
      .info {
        display:flex;
        justify-content: center;
        flex-wrap: wrap;
        span {
          padding: 10px;
        }
      }

      .initials {
        position: initial;
        width: 80px;
        height: 80px;
        font-size: 32px;
        background-color: #303030;
        color: #fff;
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      .admin-badge {
        display: flex;
        align-self: center;
        color: #fff;
        font-size: 14px;
        padding: 8px 24px;
        border-radius: 8px;
        background-color: #303030;
        margin: 16px 0;
        text-align: center;
        text-transform: capitalize;
        .icon {
          width: 14px;
          height: auto;
          margin-right: 8px;
        }
      }
      .input {
        margin: 16px 0;
        label {
          font-size: 14px;
          display: block;
          padding-bottom: 6px;
        }
        input {
          width: 100%;
          border: none;
          background-color: #f2f7f6;
          padding: 8px;
          height: 50px;
          @media (min-width: 900px) {
          }
          &:focus {
            outline: none;
          }
        }
      }
      button {
        align-self: center;
      }
    }
  }
  .blog-cards {
    display: grid;
    padding-top: 80px;
    padding-bottom: 100px;
    max-width: 1200px;
    margin: 0 auto;
    gap: 32px;
    grid-template-columns: 1fr;
    &.eingereicht {
      @media (min-width: 500px) {
        grid-template-columns: repeat(1, 1fr);
      }
      @media (min-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (min-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

.notFound {
  height: 500px;

  padding-top: 150px;
  margin: 0 auto;
  vertical-align: middle;
}
</style>