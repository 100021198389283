<template>
  <div class="app-wrapper">
    <Loading v-show="this.$store.state.loading">
      <p>{{ this.$store.state.loadingMessage }}</p>
    </Loading>

    <Modal
      v-if="this.$store.state.showModal"
      :modalMessage="this.$store.state.modalMessage"
      v-on:close-modal="
        () => {
          this.$store.state.showModal = false;
        }
      "
    />
    <span v-if="false" class="construction"
      >Diese Website ist momentan nur eingeschränkt nutzbar und wird bearbeitet.
      Wir empfehlen Ihnen, später noch einmal vorbei zu schauen!<br />Vielen
      Dank für Ihr Verständnis.</span
    >

    <div class="app" v-if="this.$store.state.postLoaded">
      <router-view />
    </div>
    <text-dialog ref="textDialogue"></text-dialog>
    <confirm-dialog ref="confirmDialogue"></confirm-dialog>
  </div>
</template>

<script>
import Loading from "./components/Loading";
import ConfirmDialog from "./dialogs/ConfirmDialog.vue";
import TextDialog from "./dialogs/TextDialog.vue";
import Modal from "./components/Modal.vue";
export default {
  name: "app",
  components: { Loading, ConfirmDialog, TextDialog, Modal },
  data() {
    return {};
  },
  async created() {},
  async mounted() {
    this.$store.state.loadingMessage = "Apotheke wird gestartet";

    this.$store.state.loading = true;
    let user = JSON.parse(localStorage.getItem("user"));
    if (user && new Date() < new Date(user.expiration)) {
      await this.$store.dispatch("userInfo/setCurrentUser", user);

      // load Admin stuff...
      console.log(this.$store.getters["auth/admin"]);
    } else {
      this.$store.dispatch("auth/logout");

      this.$store.commit("userInfo/clear");
    }

    if (this.$store.getters["auth/admin"]) {
      // await this.$store.dispatch("admin/loadWerkeAll");
      //await this.$store.dispatch("admin/loadWerkeGesperrt");
      //await this.$store.dispatch("admin/loadWerkeMarkiert");
      //await this.$store.dispatch("admin/loadWerkeNuggets");
      // await this.$store.dispatch("admin/loadBenutzerAll");
      //await this.$store.dispatch("admin/loadBenutzerGesperrt");
      //await this.$store.dispatch("admin/loadBenutzerMarkiert");
    }

    // await this.$store.dispatch("loadWerke");
    await this.$store.dispatch("loadThemen");
    await this.$store.dispatch("loadAnwendungen");
    await this.$store.dispatch("loadGenres");

    this.$store.state.postLoaded = true;

    this.checkRoute();

    this.$store.commit("setLoading", false);

    this.$store.state.loadingMessage = "";
  },
  methods: {
    checkRoute() {
      if (
        this.$route.name === "Login" ||
        this.$route.name === "Register" ||
        this.$route.name === "ForgotPassword"
      ) {
        // this.navigation = true;
        return;
      }
      // this.navigation = false;
    },
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },

    // --- ?? kann gelöscht werden.. ??
    async dialogText(dialog) {
      const text = await this.$refs.textDialogue.show(dialog);
      return text;
    },
    async dialogConfirm(dialog) {
      const ok = await this.$refs.confirmDialogue.show(dialog);
      return ok;
    },
  },

  watch: {
    $route() {
      this.checkRoute();
    },
  },
  computed: {
    loggedIn() {
      return this.isLoggedIn();
    },
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
$mainColor: #195073;

$wbColor: #755d39;

.glass-btn {
  border: 1px solid black;
  background: none;
  color: black;
  cursor: pointer;

  :hover {
    background: none;
  }
}

.construction {
  width: 100%;
  text-align: center;
  position: fixed;
  font-size: 18px;
  background: rgb(254, 215, 255);
  box-shadow: currentColor;
  bottom: 0%;
  padding: 5px;
  opacity: 1;
  z-index: 999;
}

.char-count {
  font-size: 12px;
  color: darkgray;
}

.red {
  color: rgb(196, 79, 79);
  font-weight: bold;
}

span.card-preview p br {
  // --> remove Break-Tags in Preview Cards funktioniert nur hier in App?!
  display: none;
}

span.card-preview p {
  // --> remove Paragraph Styles fom Cards
  display: block !important;
  margin: 0px !important;
  border: 0px !important;
  padding: 0px !important;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url("./assets/fonts/serif/SourceSerifPro-Regular.ttf");
}
@font-face {
  font-family: "DMSans";
  src: url("./assets/fonts/san/DMSans-Regular.ttf");
}
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DMSans", serif;
  background: #fbfaf8;
}

.serif {
  font-family: "Source Serif Pro";
}
.sticky {
  transition: all 0.3s;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.bold {
  font-weight: 900;
}
.san {
  font-family: "DMSans";
}
.shadow {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.paper {
  background: #f8f6f1;
}
.gray {
  background: rgb(240, 240, 240);
}

.center {
  text-align: center;
  margin: 0 auto;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.container {
  max-width: 1440px;
  margin: 0 auto;
  // width: 100%;
}
.link {
  cursor: pointer;
  text-decoration: none;
  text-transform: none;
  color: $mainColor;
}
.link-light {
  color: #fff;
}
.arrow {
  margin-left: 8px;
  width: 12px;
  path {
    fill: #000;
  }
}
.arrow-light {
  path {
    fill: #fff;
  }
}
input.accept-btn {
  transform: scale(2);
  margin: 10px;
}

button,
.router-button {
  transition: 200ms ease all;
  cursor: pointer;
  margin: 10px;
  padding: 12px 24px;
  background-color: $mainColor;
  color: #fff;
  border-radius: 20px;
  border: none;
  text-transform: none;

  &:focus {
    outline: none;
  }
  &:hover {
    background-color: rgba(48, 48, 48, 0.7);
  }
}
.invert-button{
  color:black;
  background: white;
}

.button-ghost {
  color: #000;
  padding: 0;
  border-radius: 0;
  margin-top: 50px;
  font-size: 15px;
  font-weight: 500;
  background-color: transparent;
  @media (min-width: 700px) {
    margin-top: 0;
    margin-left: auto;
  }
  i {
    margin-left: 8px;
  }
}
.button-light {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}
.button-inactive {
  pointer-events: none !important;
  cursor: none !important;
  background-color: rgba(128, 128, 128, 0.5) !important;
}
.error {
  font-size: 12px;
  color: red;
  position: absolute;
  margin-top: 10px;
  width: 100%;

  //text-align: center;
}

.message {
  font-size: 12px;
  color: $mainColor;
  position: absolute;
  margin-top: 10px;
  width: 100%;
  padding: 50px;
  //text-align: center;
}
.blog-card-wrap {
  position: relative;
  //padding-left: 16px;
  padding-right: 16px;
  //background-color: #f2f1f1;
  @media (min-width: 500px) {
  }
  .blog-card {
    &:hover {
    }
  }
}

.ql-align-left {
  text-align: left;
}
.ql-align-right {
  text-align: right;
}
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}

.wb-button {
  // background: #5b1819;
  background-color: var($wbColor);
}
</style>
