import axios from "axios"
export const userInfo = {
  namespaced: true,
  state: {
    users:[],
    vorname: "",
    nachname: "",
    registriert: "",
    profileInitials: "",
    imagePath: "",
    role: null,
    benutzerId: null,
    vita: "",
    favorits: [],
    ort: "",
    beruf: "",
    geburtsjahr: 0,
    newsletter: false


  },

  getters: {

    getUserInfo(state) {

      return state;
    },


  },
  actions: {
    async loadFavorits({state}){
      console.log("loading FAVS");
      await axios
      .get(process.env.VUE_APP_API_URL + "benutzer/favoriten/"+state.benutzerId)
      .then(response => {
        console.log(response.data);
        state.favorits = response.data
      }).catch(err=>{console.log(err);})

      console.log("favoriten:: ",state.favorits);
    },

async loadUsersFull({state}){
  console.log("load users");
  await axios
    .get(process.env.VUE_APP_API_URL + "benutzer")
    .then(response => {
      this.data = response.data;
      this.data.forEach(doc => {

        if (!state.users.some((user) => user.benutzerId === doc.BenutzerId)) {
        
          const user = {
            benutzerId: doc.BenutzerId,
            vorname: doc.Vorname,
            nachname: doc.Nachname,
            registriert: doc.Timestamp,
            imagePath: doc.imagePath,
            role: doc.Role,
            vita: doc.Vita,
            email: doc.Email,
            geburtsjahr:doc.Geburtsjahr,
            ort: doc.Ort,
            beruf: doc.Beruf,
            newsletter: doc.Newsletter

          };

          state.users.push(user);
        }
      })
      state.postLoaded = true;
      //commit('setWerke',state.werke)
    }).catch(error => {
      console.log(error)
    })

    console.log("loaded users:",state.users);
},
    async updateUserInfo({ state, rootState }) {
      console.log(rootState.auth);
      console.log("state", state);
      //var id = rootState.auth.user.id
      var info = {
        BenutzerId: state.benutzerId,
        Vorname: state.vorname,
        Nachname: state.nachname,
      
        Vita: state.vita,
        Ort: state.ort,
        Beruf:state.beruf,
        Geburtsjahr:state.geburtsjahr,
        Newsletter:state.newsletter
      }

      console.log(info);
      await axios
        .put(process.env.VUE_APP_API_URL + "benutzer", info).then(r=>{
          console.log(r);
          
        }).catch(error => { console.log(error); })
    },

   async setCurrentUser({ commit, state }, user) {
      console.log(user);
     await axios
        .get(process.env.VUE_APP_API_URL + "benutzer/mail/" + user.email)
        .then(response => {
          this.data = response.data;
          this.data.forEach(doc => {
            //commit("ALLE_WERKE", data.data)
            console.log("set Current User", doc);
            const currentuserInfo =
            {
              benutzerId: doc.BenutzerId,
              vorname: doc.Vorname,
              nachname: doc.Nachname,
              registriert: doc.Timestamp,
              imagePath: doc.imagePath,
              role: doc.Role,
              vita: doc.Vita,
              beruf: doc.Beruf,
              ort: doc.Ort,
              geburtsjahr: doc.Geburtsjahr,
              newsletter:doc.Newsletter
            };
            commit("updateUserInfo", (state, currentuserInfo))
          })
          commit("setProfileInitials");
        }).catch(error => {
          console.log(error)
        })
          await this.dispatch("userInfo/loadFavorits")
    },
  },
  mutations: {
    updateUserInfo(state, payload) {
      console.log(payload);
      state.vorname = payload.vorname;
      state.nachname = payload.nachname;
      state.registriert = payload.registriert;
      state.imagePath = payload.imagePath;
      state.role= payload.role;
      state.benutzerId = payload.benutzerId;
      state.vita = payload.vita;
      state.ort = payload.ort;
      state.beruf = payload.beruf;
      state.newsletter = payload.newsletter
      
      state.geburtsjahr = payload.geburtsjahr;
    },
    changeVorname(state, payload) {
      state.vorname = payload
    },
    changeNachname(state, payload) {
      state.nachname = payload
    },
    changeVita(state, payload) {
      state.vita = payload
    },
    changeOrt(state, payload) {
      state.ort = payload
    },
    changeBeruf(state, payload) {
      state.beruf = payload
    },
    changeGeburtsjahr(state, payload) {
      state.geburtsjahr = payload
    },
    changeNewsletter(state, payload) {
      state.newsletter = payload
    },
    setProfileInitials(state) {
      console.log("profile initials");
      state.profileInitials =
        state.vorname.match(/(\b\S)?/g).join("") + state.nachname.match(/(\b\S)?/g).join("");
    },

    clear(state){
      console.log("CLEAR USER");
      state.users=[]
      state.vorname= ""
      state.nachname= ""
      state.registriert= ""
      state.profileInitials= ""
      state.imagePath= ""
      state.role= null
      state.benutzerId= null
      state.vita= ""
      state.favorits= []
      state.ort= ""
      state.beruf= ""
      state.geburtsjahr= 0
      state.newsletter= false

    }
  }
};