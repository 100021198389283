<template>
  <div class="container">
    <div class="blog-card-wrap">
      <div class="sort-search-wrapper"></div>
      <div class="found">
        <h1 class="">NUGGETS</h1>
        <strong>{{ nuggetList.length }}</strong> Einträge
      </div>
      <div class="blog-cards">
        <div class="card" v-for="nugget in nuggetList" :key="nugget.nugget_id">
          <NuggetSlot :admin="true" :nugget="nugget" />
          <WerkEntnuggieren
            :werkid="nugget.werk_id"
            @ok="doEntnuggieren(nugget)"
          />
          <StammWerk :werkid="nugget.werk_id" @ok="doStamm(nugget)"></StammWerk>
        </div>
        <div class="scrolling-component"></div>
      </div>
    </div>
  </div>
</template>

<script>
import NuggetSlot from "../../components/WerkNugget.vue";
import werkeService from "../../services/werke.service";
import WerkEntnuggieren from "../../dialogs/werk/EntnuggierenWerk.vue";
import StammWerk from "../../dialogs/werk/StammWerk.vue";
//import { werkeFiltered } from "../services/werke.service";
//import userService from "../../services/user.service"
export default {
  name: "admin-werke-nuggets",
  components: { NuggetSlot, WerkEntnuggieren, StammWerk },
  data() {
    return {
      nuggetList: [],
    };
  },
  async created() {
    await this.resetList();
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    async doEntnuggieren(nugget) {
      await werkeService.werkEntnuggieren(nugget.nugget_id);

      this.$store.state.admin.showFeedback = true;
      await this.$store.dispatch("admin/loadWerkeNuggets");

      this.nuggetList.splice(
        this.nuggetList
          .map(function(e) {
            console.log("splice", e.nugget_id);
            return e.nugget_id;
          })
          .indexOf(nugget.nugget_id),
        1
      );

      await this.resetList();
      setTimeout(() => {
        this.$store.state.admin.showFeedback = false;
      }, 5000);
    },
    async doStamm(nugget){
   
      await werkeService.werkStamm(nugget.werk_id,true)

      await this.doEntnuggieren(nugget);
    },
    async resetList() {
      console.log("reste");
      this.nuggetList = await this.nuggets();
    },
    async nuggets() {
      var nuggetList = [];

      for (var w of this.$store.state.admin.werkeNuggets) {
        console.log(w.werk_id);
        let werk = await werkeService.getWerkById(w.werk_id);

        if (this.wb ? werk.Wettbewerb : !werk.Wettbewerb) {
          nuggetList.push(w);
        }
      }
      nuggetList = nuggetList.sort((a, b) => {
        var dateA = new Date(a.timestamp);
        var dateB = new Date(b.timestamp);
        return this.asc ? dateA - dateB : dateB - dateA;
      });

      return nuggetList; //this.$store.state.profileAdmin;
    },
  },

  computed: {
    wb() {
      return this.$store.state.admin.wb;
    },
  },
  watch: {
    async wb(e) {
      console.log(e);
      // Our fancy notification (2).
      await this.resetList();
    },
  },
};
</script>

<style lang="scss" scoped>
.found {
  text-align: center;
  font-size: 1.1em;
  padding-bottom: 20px;
}
.sort-search-wrapper {
  padding-top: 20px;
  padding-left: 2vw;
  padding-right: 2vw;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-wrapper {
  display: inline-block;
  margin-right: 0;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 50px;
  right: 0;
  input {
    border-radius: 10px;

    max-width: 800px;
    padding: 10px;
    font-size: 18px;
  }
}
.filterBereich {
  padding-top: 20px;
  padding: 20px;
  //max-width: 1200px;
  height: 100%;
  display: flex;

  align-content: center;
  column-gap: 5vw;
  padding-bottom: 20px;
  font-size: calc(0.6em + 0.5vw);
  margin: 0 auto;
  flex-wrap: wrap;

  .filterspalte {
    width: calc(25% + 50px);
    overflow: visible;
    padding-top: 30px;
    span {
      font-size: calc(1em + 1vw);
    }
    label {
      white-space: nowrap;
      vertical-align: middle;
      position: inherit;
    }

    input {
      transition: 750ms ease all;
      appearance: none;
      border: none;
      // background: #fff;
      outline: none;
      background-color: #fafafa;
      border: 1px solid #cacece;
      box-shadow: 0 0px 2px rgba(0, 0, 0, 0.5),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 10px;
      vertical-align: middle;
      // border-radius: 30px;
      display: inline-block;
      position: relative;
      //height: 30px;
      // border-radius: 100px;
    }

    input:checked {
      content: "\2714";
      padding-left: 25px;
      //position:absolute;
      color: #000000;
      background-color: #195073;
    }

    .field {
      white-space: nowrap;
    }
    .field:hover {
      font-weight: bold;
    }
  }
}

@media (min-width: 1200px) {
  .filterBereich {
    justify-content: center;
  }
}

@media (max-width: 750px) {
  .filterBereich {
    //justify-content: space-between;
    padding-right: 10px;
  }
}

.sort-wrapper {
  vertical-align: middle;
  height: 100px;
  button {
    margin-left: 10px;
    margin-right: 10px;
    height: 30px;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;

    //vertical-align: middle;
  }
}
.blog-cards {
  padding: 50px;
  padding-bottom: 200px;
  position: relative;

  .toggle-edit {
    display: flex;
    align-items: center;
    position: absolute;
    top: -70px;
    right: 1;

    span {
      margin-right: 16px;
    }
    input[type="checkbox"] {
      position: relative;
      border: none;
      -webkit-appearance: none;
      background: #fff;
      outline: none;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    input[type="checkbox"]:before {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      top: 0;
      left: 0;
      background: #303030;
      transform: scale(1.1);
      transition: 750ms ease all;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    input:checked[type="checkbox"]:before {
      background: #fff;
      left: 52px;
    }
  }
}
</style>
