<template>
  <div class="">
    <h1>Über uns</h1>
    <div class="redaktion center"><strong>Redaktion:</strong> Rachel Bleiber • Lucie Kling • Jo Köhler • Annette Stock • Malu von Marschall • Anna Volmering</div>
    
    <div class="uberuns-nav">
      <nav class="nav">
        <router-link :to="{ name: 'about' }">Literapedia</router-link>
        <router-link :to="{ name: 'radio' }">Radiobeiträge</router-link>

        <router-link :to="{ name: 'presse' }">Presse</router-link>
        <router-link :to="{ name: 'grussworte' }">Grußworte</router-link>

        <router-link :to="{ name: 'gallerie' }">Galerie</router-link>
      </nav>
      <div class="wrapper">
      <router-view />
    </div>
    
    </div>
  </div>
</template>

<script>
export default {
  name: "Ueberuns",

  data() {
    return {};
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.wrapper{
  max-width: 1200px;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
  
}
.redaktion{
  font-size: 11px;
}
.uberuns-nav nav {
  display: flex;
  justify-content: center;
      flex-wrap: wrap;

  a {
    color: black;
    text-decoration: none;
    font-size: 18px;
    margin: 20px;
  }
}

.router-link-exact-active {
  border-bottom: 2px solid black;
}
h1 {
  //padding: 30px;
  text-align: left;
  
  font-weight: lighter;
  text-align: center;
}


</style>