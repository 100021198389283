<template>
  <div class="welcome">

    <br /><br />

    <h2 class="heading">
    Literapedia Hildesheim
    </h2>
    <br /><br />
    <p>
 

Das bundesweit erste digitale Lexikon der freien Literaturarbeit. Es dokumentiert und inspiriert. Und erzählt auf faszinierende Weise die Geschichte und die Geschichten der freien Literaturarbeit in und um Hildesheim. Unzählige Bild- und Textdokumente, Radio- und TV-Beiträge sind hier zusammengetragen. 
Es wirkt wie ein Echolot der Zeit und nimmt nicht nur Bezug auf große und namhafte Künstler:innen, sondern dokumentiert auch die Arbeit weniger bekannter Kulturschaffender, deren Engagement und künstlerische Beteiligung sonst nirgendwo verzeichnet wäre.
<a href="https://www.literapedia-hildesheim.de" target="_blank"> <h1>Literapedia.de</h1> </a>  
    </p>
    
  </div>
</template>

<script>
export default {
  name: "Ueberuns",

  data() {
    return {};
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.heading{
  text-align: center;
}
ul{
  li{
    padding:10px;
  }
}
</style>