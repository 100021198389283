<template>
  <div class="welcome">
    <p>
  Zum 20-jährigen Jubiläum des Forum Literaturbüros wurde die Literatur-Apotheke im Rahmen
des Magdalenenfestes vom 11.06.-12.06.2016 physisch realisiert. Besucher:innen ließen sich von
unseren „Apotheker:innen“ beraten und anschließend Erste Hilfe, Trostpflaster, Injektionen oder
Balsam in literarischer Form verschreiben. Und das ganz ohne Rezept. 
    </p>
    <div class="text-center gallerie">
      <table>
        <tr>
          <td>
            <a 
              ><img alt="" src="../assets/gallerie/(11).jpg" />
            </a>
          </td>
          <td>
            <a 
              ><img alt="" src="../assets/gallerie/(8).jpg" />
            </a>
          </td>
          <td>
            <a 
              ><img alt="" src="../assets/gallerie/(1).jpg" />
            </a>
          </td>
        </tr>

        <tr>
          <td>
            <a
              ><img alt="" src="../assets/gallerie/(2).jpg" />
            </a>
          </td>
          <td>
            <a
              ><img alt="" src="../assets/gallerie/(3).jpg" />
            </a>
          </td>
          <td>
            <a 
              ><img alt="" src="../assets/gallerie/(4).jpg" />
            </a>
          </td>
        </tr>

        <tr>
          <td>
            <a 
              ><img alt="" src="../assets/gallerie/(5).jpg" />
            </a>
          </td>
          <td>
            <a 
              ><img alt="" src="../assets/gallerie/(6).jpg" />
            </a>
          </td>
          <td>
            <a 
              ><img alt="" src="../assets/gallerie/(7).jpg" />
            </a>
          </td>
        </tr>

        <tr>
          <td>
            <a
              ><img alt="" src="../assets/gallerie/(12).jpg" />
            </a>
          </td>
          <td>
            <a 
              ><img alt="" src="../assets/gallerie/(9).jpg" />
            </a>
          </td>
          <td>
            <a 
              ><img alt="" src="../assets/gallerie/(10).jpg" />
            </a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "Überuns",

  data() {
    return {
    };
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.gallerie {
  width: 100%;

  td {
    width: 30%;

    img {
      margin: 3%;
      width: 95%;
    }
  }
}
h1 {
  //padding: 30px;
  text-align: left;
  font-size: 50px;
  font-weight: lighter;
}

.welcome {
  //padding: 50px;
  max-width: 1200px;
}

</style>