<template>
  <div class="create-post">
    <div class="container">
      <h1 class="">Text einreichen</h1>
      <!-- <div v-if="true">
        <p>
          Während des Wettebwerbs können hier keine Werke eingereicht werden.
          Wenn Sie am Wettebwerb teilnehmen möchten, nutzen Sie folgendes
          Formular:
        </p>
        <router-link :to="{ name: 'WB_WerkCreate' }"
          >Werk im Wettbewerb einreichen</router-link
        >
      </div> -->
      <p v-if="!this.$store.state.auth.user">
        Bitte loggen Sie sich erst mit einem vorhandenen Benutzerkonto ein, oder
        registrieren Sie sich. Sie finden die entsprechenden Buttons dafür oben
        in der Navigationszeile. Die Teilnahme sowie die Registrierung sind
        kostenfrei!
      </p>
      <div v-else class="blog-info">
        <span class="title"
          ><span class="label">Titel:</span
          ><input
            class="title-input serif"
            type="text"
            placeholder=""
            v-model="titel"
        /></span>

        <span class="autor"
          ><span class="label">Autor:in:</span>
          <input
            class="title-input autor"
            type="text"
            placeholder=""
            v-model="autor"
        /></span>

        <span class="autor"
          ><span class="label">Quelle:</span
          ><input
            class="title-input"
            type="text"
            placeholder=""
            v-model="quelle"
          />
        </span>

        <div class="editor motivation">
          <span>Text:</span>

          <vue-editor :editorOptions="editorSettings" v-model="inhalt" />
          <p
            class="char-count"
            :class="[plainText(inhalt).length > this.inhaltMax ? 'red' : '']"
          >
            {{ plainText(inhalt).length }} / {{ this.inhaltMax }}
          </p>
        </div>
        <div class="blog-info"></div>
        <div class="editor motivation">
          <span>Motivation:</span>
          <vue-editor
            class=""
            :editorOptions="editorSettings2"
            v-model="motivation"
          />
          <p
            class="char-count"
            :class="[plainText(motivation).length > this.motiMax ? 'red' : '']"
          >
            {{ plainText(motivation).length }} / {{ this.motiMax }}
          </p>
        </div>

        <div class="dropdownArea">
          <div class="dropdownRow">
            <span>Thema: </span>
            <select v-model="thema">
              <option
                v-for="(el, index) in this.$store.state.themen"
                :key="index"
                :value="index + 1"
              >
                {{ el.name }}
              </option>
            </select>
          </div>
          <div class="dropdownRow">
            <span>Anwendung: </span>
            <select v-model="anwendung">
              <option
                v-for="(el, index) in this.$store.state.anwendungen"
                :key="index"
                :value="index + 1"
              >
                {{ el.name }}
              </option>
            </select>
          </div>

          <div class="dropdownRow">
            <span>Genre: </span>

            <select v-model="genre" required>
              <option
                v-for="(el, index) in this.$store.state.genres"
                :key="index"
                :value="index + 1"
              >
                {{ el.name }}
              </option>
            </select>
          </div>
        </div>
        <div :class="{ invisible: !error }" class="err-message red">
          <p><span></span>{{ this.errorMsg }}</p>
        </div>
        <div class="werk-actions">
          <button
            v-if="this.$store.state.auth.user"
            class="button"
            @click="werkEinreichen"
          >
            Werk einreichen
          </button>
          <router-link
            class="button router-button"
            :to="{ name: 'WerkPreview' }"
          >
            Vorschau</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import BlogCoverPreview from "../components/BlogCoverPreview";

//import Dropdown from "vue-simple-search-dropdown";
import Quill from "quill";
import "quill-paste-smart";
import utils from "../utils";
//import werkeService from '../services/werke.service';
window.Quill = Quill;

export default {
  name: "CreatePost",
  data() {
    return {
      inhaltMax: 5000,
      motiMax: 1000,
      error: null,
      errorMsg: null,
      loading: null,
      editorSettings: {
        modules: {
          toolbar: [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
        },
      },

      editorSettings2: {
        modules: {
          toolbar: false,
        },
      },
    };
  },
  components: {
    // BlogCoverPreview,
    // Dropdown,
  },
  methods: {
    setAnwendung(payload) {
      console.log("Set Anwendung", payload);
      this.$store.commit("updateWerkAnwendung", payload);
      return true;
    },
    setThema(payload) {
      this.$store.commit("updateWerkThema", payload);
      return true;
    },
    setGenre(payload) {
      this.$store.commit("updateWerkGenre", payload);
      return true;
    },
    fileChange() {
      this.file = this.$refs.blogPhoto.files[0];
      const fileName = this.file.name;
      this.$store.commit("fileNameChange", fileName);
      this.$store.commit("createFileURL", URL.createObjectURL(this.file));
    },
    openPreview() {
      this.$store.commit("openPhotoPreview");
    },
    async werkEinreichen() {
      if (
        this.titel.length !== 0 &&
        this.inhalt.length !== 0 &&
        this.motivation.length !== 0 &&
        this.anwendung &&
        this.thema &&
        this.genre &&
        this.quelle.length !== 0
      ) {
        if (
          this.plainText(this.inhalt).length > this.inhaltMax ||
          this.plainText(this.motivation).length > this.motiMax
        ) {
          this.error = true;
          this.errorMsg = "Text oder Motivation hat zu viele Zeichen.";
          setTimeout(() => {
            this.error = false;
          }, 5000);
          return;
        }
        this.loading = true;
        await this.$store.dispatch("werkEinreichen");

        this.loading = false;

        this.$router.push({
          name: "WerkView",
          params: { werkid: this.$store.state.werkId },
        });
        this.$store.state.modalMessage =
          "Sie haben das Werk erfolgreich eingereicht. Vielen Dank!";
        this.$store.state.showModal = true;

        return;
      }
      this.error = true;
      this.errorMsg =
        "Alle Felder müssen ausgefüllt sein, damit Sie das Werk einreichen können.";

      setTimeout(() => {
        this.error = false;
      }, 5000);
      return;
    },
    plainText(html) {
      return utils.plainText(html);
    },
  },

  computed: {
    anwendung: {
      get() {
        return this.$store.state.anwendung;
      },
      set(payload) {
        this.$store.commit("updateWerkAnwendung", payload);
      },
    },
    thema: {
      get() {
        return this.$store.state.thema;
      },
      set(payload) {
        this.$store.commit("updateWerkThema", payload);
      },
    },
    genre: {
      get() {
        return this.$store.state.genre;
      },
      set(payload) {
        this.$store.commit("updateWerkGenre", payload);
      },
    },

    getAnwendungenOptions() {
      var options = [];
      this.$store.state.anwendungen.forEach((e) => {
        var option = {
          id: e.id,
          name: e.name,
        };
        options.push(option);
      });
      return options;
    },
    getGenreOptions() {
      var options = [];
      this.$store.state.genres.forEach((e) => {
        var option = {
          id: e.id,
          name: e.name,
        };
        options.push(option);
      });
      return options;
    },
    getThemaOptions() {
      var options = [];
      this.$store.state.themen.forEach((e) => {
        var option = {
          id: e.id,
          name: e.name,
        };
        options.push(option);
      });
      return options;
    },

    profileId() {
      return this.$store.state.profileId;
    },
    blogCoverPhotoName() {
      return this.$store.state.blogPhotoName;
    },

    titel: {
      get() {
        return this.$store.state.titel;
      },
      set(payload) {
        this.$store.commit("updateWerkTitel", payload);
      },
    },

    autor: {
      get() {
        return this.$store.state.autor;
      },
      set(payload) {
        this.$store.commit("updateWerkAutor", payload);
      },
    },
    quelle: {
      get() {
        return this.$store.state.quelle;
      },
      set(payload) {
        this.$store.state.quelle =
          this.$store.state.quelle == "Literaturwettbewerb 2022"
            ? ""
            : this.$store.state.quelle;

        this.$store.commit("updateWerkQuelle", payload);
      },
    },
    inhalt: {
      get() {
        return this.$store.state.inhalt;
      },
      set(payload) {
        this.$store.commit("updateWerkInhalt", payload);
      },
    },
    motivation: {
      get() {
        return this.$store.state.motivation;
      },
      set(payload) {
        this.$store.commit("updateWerkMotivation", payload);
      },
    },
  },
};
</script>

<style lang="scss">
h1 {
  margin-top: 50px;
  text-align: center;
}
.create-post {
  position: relative;
  height: 100%;

  .werk-actions {
    margin-top: 20px;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
  }
  .router-button {
    text-decoration: none;
    color: #fff;
  }

  button,
  .router-button {
    transition: 0.5s ease-in-out all;
    align-self: center;
    font-size: 18px;
    cursor: pointer;
    border-radius: 20px;
    padding: 12px 24px;
    color: #fff;
    background-color: #303030;
    text-decoration: none;

    &:hover {
      background-color: rgba(48, 48, 48, 0.7);
    }
  }

  .router-button {
    background-color: cadetblue;
  }
  .container {
    position: relative;
    min-height: 50vh;
    padding: 10px 25px 60px;
  }
  // error styling
  .invisible {
    opacity: 0 !important;
  }
  .err-message {
    width: 100%;
    padding: 12px;
    border-radius: 8px;

    margin-bottom: 10px;
    opacity: 1;
    transition: 0.5s ease all;
    text-align: center;

    p {
      font-size: 18px;
    }
    span {
      font-weight: 600;
    }
  }
  .blog-info {
    //margin: 0 auto;
    display: flex;
    margin-bottom: 2px;
    flex-direction: column;
    //align-items: center;
    margin-left: 2vw;

    max-width: 1200px;

    span {
      display: flex;
      width: 80%;
      //text-align: right;
      align-items: flex-end;
      padding-top: 15px;
      .label {
        width: 75px;
      }
    }

    input {
      transition: 0.5s ease-in-out all;

      border: none;
      border-bottom: 1px solid #303030;
      font-size: 32px;
      margin-top: 5px;
      //width: 500px;
      //margin-left: 20px;
      width: 100%;
      &:focus {
        outline: none;
        box-shadow: 0 1px 0 0 #303030;
      }
    }
  }
  .title {
    font-size: 18px;
  }
  .autor {
    font-size: 14px;
    input {
      font-size: 18px !important;
    }
  }
  .motivation {
    padding-top: 50px;
    span {
      padding-left: 10px;
      padding-bottom: 10px;
      font-size: 18px;
      font-weight: bold;
    }
    .quillWrapper {
      height: max-content;
    }
  }
  .dropdownArea {
    display: flex;
    flex-direction: row;
    padding: 2vw;
    padding: 2%;
    flex-wrap: wrap;
    padding-top: 55px;
    justify-content: center;

    .dropdownRow {
      padding-bottom: 50px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: flex-start;

      span {
        padding-left: 2%;
        padding-bottom: 5px;
        font-size: 18px;
        font-weight: bold;
      }

      //width: 400px;

      select {
        color: black;
        border-radius: 20px;
        font-size: 1rem;
        //min-width: 300px;
        padding: 10px;
        border: 1px solid;
        background: #fff;
        margin: 10px;

        width: 300px;
      }
      option {
        font-size: 1.1em;
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.356);
        padding: 1px;
      }
      option:hover {
        background-color: cadetblue;
      }

      option:first-child {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
      option:last-child {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      .dropdown-content {
        //min-width: 350px;
        min-height: 50px;
        max-height: max-content;
        overflow: visible;
        border-radius: 5px;
        border: 1px solid;
      }
    }
  }

  .editor {
    height: 60vh;
    display: flex;
    flex-direction: column;
    .quillWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      border-radius: 20px;
      border: 1px solid;
    }
    .ql-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: scroll;
      border: none;

      border-bottom-right-radius: 20px;

      border-bottom-left-radius: 20px;
    }
    .ql-editor {
      padding: 20px 16px 30px;

      font-family: "Source Serif Pro";
    }
    .ql-editor::-webkit-scrollbar {
      height: 90%;
      width: 10px;
    }
    .ql-editor::-webkit-scrollbar-track {
      background-color: white;
    }

    .ql-editor::-webkit-scrollbar-thumb {
      background: #4e4e4e;
      border-radius: 25px;
    }
    .ql-container::-webkit-scrollbar {
      display: none;
    }

    .ql-toolbar {
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.63);
      border: none;
    }
  }
}
</style>
