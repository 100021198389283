import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Werke from "../views/Werke.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import Profile from "../views/Profile.vue";

import Kontakt from '../views/Kontakt.vue'

import WerkCreate from "../views/WerkCreate.vue";
import WerkPreview from "../views/WerkPreview.vue";
import WerkView from "../views/WerkView.vue";
import BestenListe from "../views/BestenListe.vue";

import WerkBearbeiten from "../views/WerkBearbeiten.vue";
import HandleOldUser from "../views/HandleOldUser.vue";

import ConfirmAccount from "../views/ConfirmAccount.vue";
import ResetPassword from "../views/ResetPassword.vue";
import BenutzerView from "../views/BenutzerView.vue";
import UeberUns from "../views/Ueberuns.vue";
import UeberUnsRadio from "../views/UeberunsRadio.vue";
import UeberUnsGruss from "../views/UeberunsGruss.vue";
import UeberUnsGallerie from "../views/UeberunsGallerie.vue";

import UeberUnsAbout from "../views/UeberunsAbout.vue";

import UeberUnsPresse from "../views/UeberunsPresse.vue";

import Wettbewerbe from "../views/Wettbewerbe.vue";
import Wettberwerb2022 from "../views/Wettbewerb2022.vue";
import Wettberwerb2020 from "../views/Wettbewerb2020.vue";


import Teilnahmebedingungen from "../views/Teilnahmebedingungen.vue";
import Impressum from "../views/Impressum.vue";
import Datenschutz from "../views/Datenschutz.vue";

import NormalLayout from "../layouts/Normal.vue"

import AdminRouter from "./admin.js"

import WbRouter from "./wettbewerb.js"

import store from "../store/index.js"

Vue.use(VueRouter);

const routes = [
  {
    path: "",

    component: NormalLayout,
    children: [
      {

        path: "",
        name: "Home",
        component: Home,
        meta: {
          title: "Home",
        },
      },


      {
        path: "/werke",
        name: "Werke",
        component: Werke,
        meta: {
          title: "Werke",
        },
      },
      {
        path: "/bestenliste",
        name: "BestenListe",
        component: BestenListe,
        meta: {
          title: "Bestenliste",
        },
      },
      {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
          title: "Login",
        },
      },
      {
        path: "/register",
        name: "Register",
        component: Register,
        meta: {
          title: "Register",
        },
      },
      {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
        meta: {
          title: "Forgot Password",
        },
      }, {
        path: "/profile",
        name: "Profile",
        component: Profile,
        meta: {
          title: "Profile",
        },
      },
      {
        path: "/text-einreichen",
        name: "WerkCreate",
        component: WerkCreate,
        meta: {
          title: "Werk einreichen",
        },
      },
      {
        path: "/text-vorschau",
        name: "WerkPreview",
        component: WerkPreview,
        meta: {
          title: "Vorschau",
        },
      },
      {
        path: "/praeparat/:werkid",
        name: "WerkView",
        component: WerkView,
        props: { admin: false },
        meta: {
          title: "Werk Ansicht"
        },
      },
      {
        path: "/profile-ansicht/:benutzerid",
       
        name: "BenutzerView",
        component: BenutzerView,
        meta: {
          title: "Nutzer Ansicht"
        },
      },
      {
        path: "/old-user/",
        name: "old-user",
        component: HandleOldUser,
        meta: {
          title: ""
        },
        props: true
      },
      {
        path: "/edit-blog/:blogid",
        name: "WerkBearbeiten",
        component: WerkBearbeiten,
        meta: {
          title: "Edit Blog"
        },
      },
      {
        path: "/neues-passwort/",
        name: "NeuesPasswort",
        component: ResetPassword,
        props: (route) => ({ t: route.query.t, id: route.query.id }),
      },
      {
        path: "/confirm/",
        name: "ConfirmMail",
        component: ConfirmAccount,
        props: (route) => ({ t: route.query.t, id: route.query.id }),
      },
      {
        path: "/ueber-uns",
        name: "Ueberuns",
        component: UeberUns,
        meta: {
          title: "Über uns"
        },
        children: [
          {
            path: "verein",
            name: "about",
            component: UeberUnsAbout,
            meta: {
              title: "Über uns"
            },
          },
          {
            path: "presse",
            name: "presse",
            component: UeberUnsPresse,
            meta: {
              title: "Über uns"
            },
          },
         
          {
            path: "radio-beitraege",
            name: "radio",
            component: UeberUnsRadio,
            meta: {
              title: "Über uns"
            },
          },
          {
            path: "grussworte",
            name: "grussworte",
            component: UeberUnsGruss,
            meta: {
              title: "Über uns"
            },
          },
          {
            path: "gallerie",
            name: "gallerie",
            component: UeberUnsGallerie,
            meta: {
              title: "Über uns"
            },
          }
        ]
      },
      {
        path: "/wettbewerbe",
        name: "wettbewerbe",
        component: Wettbewerbe,
        meta: {
          title: "Wettbewerbe"
        },
        children: [
          {
            path: "2022",
            name: "2022",
            component: Wettberwerb2022,
            meta: {
              title: "Wettbewerb 2022"
            },
          },
          {
            path: "2020",
            name: "2020",
            component: Wettberwerb2020,
            meta: {
              title: "Wettberwerb 2020"
            },
          },     
         
        ]
      },
      {
        path: "/teilnahmebedingungen/",
        name: "Teilnahmebedingungen",
        component: Teilnahmebedingungen,
        meta: {
          title: "Teilnahmebedingungen"
        },
      },
      {
        path: "/impressum/",
        name: "Impressum",
        component: Impressum,
        meta: {
          title: "Impressum"
        },
      },
      {
        path: "/datenschutz/",
        name: "Datenschutz",
        component: Datenschutz,
        meta: {
          title: "Datenschutz"
        },
      },
      {
        path: "kontakt",
        name: "Kontakt",
        component: Kontakt,
        meta: {
          title: "Kontakt",
        },}
    ],
  },
  ...AdminRouter,
  ...WbRouter

];
const router = new VueRouter({

  mode: "history",
  base: process.env.BASE_URL,
  routes,


  scrollBehavior(to, from, savedPosition) {

   

  var headerHeight=store.state.headerHeight

    if(to.name=="Home" || to.name=="WB_Home"){
      return {
        x:0,y:0,behavior: 'smooth',
      }
    }

    if (1==1|| to.name == "WB_WerkView" || to.name == "WerkView" || to.name == "BenutzerView" || to.name == "Profile" || to.name == "WerkPreview" || to.name == "Login" ) {
      console.log("Route to WerkView");
      console.log(to, from, savedPosition,headerHeight);
      return {
        x: 0, y: store.state.headerHeight,behavior: 'smooth',


      }
    } else if (to.hash) {
      return
    } else {
      return
    }

  }
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title
    } | Literatur-Apotheke`;
  next();
});

export default router;
