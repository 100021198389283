<template>
  <div class="icon">
    <button class="sperren-btn" @click="handle">sperren</button>
    <text-dialog ref="textDialogue"></text-dialog>
  </div>
</template>




<script>
import TextDialog from "../TextDialog.vue";

export default {
  name: "DeleteWerk",

  components: { TextDialog },
  props: {
    werkid: Number,
  },
   data() {
    return {
      werk: null,

    }},
  created() {
    console.log("DELETE WERK DIALOG");
  },


  methods: {
    async handle() {
      const text = await this.$refs.textDialogue.show({
        characters: 200,
        title: "Werk sperren",
        message: "Grund:",
        okButton: "Werk sperren",
         cancelButton: "abbrechen",
        feedback: "Werk gesperrt"
      });
      if (text != "") {
        this.$emit("ok", text);
      }
    },
  },
};
</script>

<style>
.sperren-btn{
  background-color: peru;
}
</style>