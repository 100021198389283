<template>
  <div class="icon">
    <button class="btn" @click="handle">Ausblenden</button>
 
    <confirm-dialog ref="confirmDialogue"></confirm-dialog>
  </div>
</template>




<script>
import ConfirmDialog from "../ConfirmDialog.vue";
import userService from "../../services/user.service";
export default {
  name: "DeleteBenutzer",

  components: { ConfirmDialog },
 props:{
    userid: String
  },
  created() {
    console.log("DELETE WERK DIALOG");
  },

  methods: {
    async handle() {
      var user = await userService.getBenutzerByID(this.userid)
      console.log(this.userid, user);
      const ok = await this.$refs.confirmDialogue.show({
        title: "Benutzer ausblenden",
        message: " <p> "+ user.vorname +" " + user.nachname  +" // "+user.id+"</p>",
       
        okButton: "Ausblenden",
        cancelButton: "abbrechen"
      });
      if (ok) {
        this.$emit("ok");
      }
    },
  },
};
</script>

<style lang="scss" scoped >

</style>