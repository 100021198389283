<template>
  <div class="">
    <div class="title">
    <h1 class="  ">Wettbewerbsbeiträge</h1>
</div>
  <p class="center">  
     Stöbern und lesen Sie in den Wettbewebsbeiträgen!<br><br> Alle Mitglieder der Literatur-Apotheke können Bewertungen abgeben und somit den Publikumspreis vergeben.</p> <br><br>
    <div v-if="false" class="filterBereich">
      <div class="anwendungen filterspalte">
        <span>Anwendungen</span>
        <div v-for="(el, index) in this.$store.state.anwendungen" :key="index">
          <div class="field">
            <input
              type="checkbox"
              :id="el.name"
              :value="el"
              v-model="checkedAnwendungen"
              @change="resetList()"
            />
            <label :for="el.name">{{ el.name }}</label>
          </div>
        </div>
      </div>
      <div class="themen filterspalte">
        <span>Themen</span>
        <div v-for="(el, index) in this.$store.state.themen" :key="index">
          <div class="field">
            <input
              type="checkbox"
              :id="el.name"
              :value="el"
              v-model="checkedThemen"
              @change="resetList()"
            />
            <label :for="el.name">{{ el.name }}</label>
          </div>
        </div>
      </div>
      <div class="genres filterspalte">
        <span>Genres</span>
        <div v-for="(el, index) in this.$store.state.genres" :key="index">
          <div class="field">
            <input
              type="checkbox"
              :id="el.name"
              :value="el"
              v-model="checkedGenres"
              @change="resetList()"
            />
            <label :for="el.name">{{ el.name }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="blog-card-wrap gray">
      <div class="sort-search-wrapper">
        <div class="sort-wrapper">
          <span>Sortieren nach </span>
          <button type="button" name="Datum" value="datum" @click="sortDatum()">
            Datum <span v-if="this.asc">&#9650;</span
            ><span v-else>&#9660;</span>
          </button>
          <span>oder</span>
          <button
            type="button"
            name="Random"
            value="random"
            @click="sortRandom()"
          >
            zufällig <span>⤭</span>
          </button>
        </div>
        <div class="search-wrapper">
          <input
            type="text"
            v-model="search"
            @keyup="resetList()"
            placeholder="Suche.."
            class="search-input"
          />
        </div>
      </div>
      <div class="found">
        Es wurden <strong>{{ totalAmount }}</strong> Beiträge eingereicht
      </div>

      <!--div>
        <span v-for="(el, index) in this.checkedAnwendungen" :key="index">{{
          el.name
        }}</span>
      </div>
      <div>
        <span v-for="(el, index) in this.checkedThemen" :key="index">{{
          el.name
        }}</span>
      </div>
      <div>
        <span v-for="(el, index) in this.checkedGenres" :key="index">{{
          el.name
        }}</span>
      </div-->

      <!--  <WerkCard
          :werk="werk"
          v-for="(werk, index) in werkeFiltered_comp"
          :key="index"
        />
-->

      <div class="blog-cards">


        <div v-for="(werk, index) in this.werkeToShow" :key="index">
          
          <router-link
            class="link"
            :to="{ name: 'WB_WerkView', params: { werkid: werk.WerkId} }"
            :title="werk.Titel"
          >
      
            <WerkCard :werk="werk"
          /></router-link>
        </div>
        <div class="scrolling-component"></div>
      </div>
    </div>
  </div>
</template>


<script>
import WerkCard from "../../components/WerkCard";
import werkeService from "../../services/werke.service";
//import { werkeFiltered } from "../services/werke.service";

export default {
  name: "werke",
  components: { WerkCard },
  data() {
    return {
      search: "",
      amount: 8,
      allFilteredWerke: [],
      werkeToShow: [],
      checkedAnwendungen: [],
      checkedThemen: [],
      checkedGenres: [],
      sortBy: "date",
      asc: false,
      totalAmount:0,
    };
  },
 async mounted() {
    this.werkeToShow = await this.fetchWerkeToShow();
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
  async sortRandom() {
      console.log("random");
      this.sortBy = "random";
      await this.resetList();
    },
    async sortDatum() {
      console.log("datum");
      if (this.sortBy == "date") {
        this.asc = !this.asc;
      }
      this.sortBy = "date";

     await this.resetList();
    },
  async  resetList() {
      console.log("RESET___________________________");
      this.werkeToShow = [];
      // console.log("Reset Werke List!", this.werkeToShow);
      this.werkeToShow = await this.fetchWerkeToShow();
    },
    getWerkeToShow() {
      this.allFilteredWerke = this.werkeFiltered();
      // console.log("all filtered werke", this.allFilteredWerke);
      var ret = [];

      var lastIndex = this.werkeToShow.length;
      var newLength =
        this.allFilteredWerke.length - this.amount < this.werkeToShow.length //if
          ? this.allFilteredWerke.length
          : lastIndex + this.amount;
      console.log("NEW LENGTH", newLength);
      for (let i = lastIndex; i < newLength; i++) {
        ret.push(this.allFilteredWerke[i]);
      }

      return ret;
    },

    async fetchWerkeToShow() {
      //filter
     
      this.query = "";
     

      var searchWords = this.search.split(" ");
      for (var word of searchWords) {
        this.query += "search=" + word.toLowerCase();
        this.query += "&";
      }

  

      this.query+="wb=1&"


      if (
        this.sortBy == "date" ||
        this.sortBy == ""
      ) {
        this.asc
          ? (this.query += "order=1&")
          : (this.query += "order=0&");
     

    

      this.query += "limit=" + this.amount + "&";
      this.query += "offset=" + this.werkeToShow.length + "&";
    } else if (this.sortBy == "random") {
        this.query += "order=2&";

        this.query += "limit=" + this.amount + "&";
        this.query += "offset=" + 0 + "&";
      }



      var result = await werkeService.getWerkeFiltered(this.query);

      
      if (result.length > 0) {
        this.totalAmount = result[0].total_count;
      } else {
        this.totalAmount = 0;
      }

      console.log(result);
      return result;
    },

  async  handleScroll() {
      if (
        window.scrollY + window.innerHeight >=
          document.body.scrollHeight - window.innerHeight &&
        !(this.werkeToShow.length >= this.totalAmount)
      ) {
        console.log("load new werke");
        this.werkeToShow = [...this.werkeToShow, ...(await this.fetchWerkeToShow())];
      }
    },
    werkeFiltered() {
      console.log("GETTING WERKE");
      var filteredWerke = [];

      // Checkboxes

      for (var w of this.$store.state.wb_werke) {
        if (
          (this.checkedAnwendungen.length > 0
            ? this.checkedAnwendungen.some((a) => a.id === w.anwendung)
            : true) &&
          (this.checkedThemen.length > 0
            ? this.checkedThemen.some((a) => a.id === w.thema)
            : true) &&
          (this.checkedGenres.length > 0
            ? this.checkedGenres.some((a) => a.id === w.genre)
            : true)
        ) {
          filteredWerke.push(w);
        }
      }

      filteredWerke = filteredWerke.filter((post) => {
        var includes =
          post.titel.toLowerCase().includes(this.search.toLowerCase()) ||
          post.autor.toLowerCase().includes(this.search.toLowerCase()) ||
          post.inhalt.toLowerCase().includes(this.search.toLowerCase()) ||
          post.vorname.toLowerCase().includes(this.search.toLowerCase()) ||
          post.nachname.toLowerCase().includes(this.search.toLowerCase());
        return includes;
      });

      console.log(filteredWerke);

      if (this.sortBy == "date" || this.sortBy == "") {
        filteredWerke = filteredWerke.sort((a, b) => {
          var dateA = new Date(a.datum);
          var dateB = new Date(b.datum);
          return this.asc ? dateA - dateB : dateB - dateA;
        });
      }

      if (this.sortBy == "random") {
        filteredWerke = filteredWerke.sort(() => {
          return Math.random() - 0.5;
        });
      }
      return filteredWerke;
    },
  },

  computed: {
    foundWerke() {
      return this.allFilteredWerke.length;
    },

    editWerk: {
      get() {
        return this.$store.state.editWerk;
      },
      set(payload) {
        this.$store.commit("toggleEditWerk", payload);
      },
    },
    profileAdmin() {
      return true; //this.$store.state.profileAdmin;
    },
  },
  beforeDestroy() {
    this.checkedAnwendungen = [];
    this.checkedThemen = [];
    this.checkedGenres = [];
    this.$store.commit("toggleEditWerk", false);
    window.removeEventListener("scroll", this.handleScroll);
  },

};
</script>

<style lang="scss" scoped>
.container{
  padding-top: 100px;
}
.found {
  text-align: center;
  font-size: 1.1em;
  padding-bottom: 20px;
}
.sort-search-wrapper {
  padding-top: 2px;
  padding-left: 2vw;
  padding-right: 2vw;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-wrapper {
  display: inline-block;
  margin-right: 0;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 50px;
  right: 0;
  input {
    border-radius: 10px;

    max-width: 800px;
    padding: 10px;
    font-size: 18px;
  }
}
.filterBereich {
  padding-top: 20px;
  padding: 20px;
  //max-width: 1200px;
  height: 100%;
  display: flex;

  align-content: center;
  column-gap: 5vw;
  padding-bottom: 20px;
  font-size: calc(0.6em + 0.5vw);
  margin: 0 auto;
  flex-wrap: wrap;

  .filterspalte {
    width: calc(25% + 50px);
    overflow: visible;
    padding-top: 30px;
    span {
      font-size: calc(1em + 1vw);
    }
    label {
      white-space: nowrap;
      vertical-align: middle;
      position: inherit;
    }

    input {
      transition: 750ms ease all;
      appearance: none;
      border: none;
      // background: #fff;
      outline: none;
      background-color: #fafafa;
      border: 1px solid #cacece;
      box-shadow: 0 0px 2px rgba(0, 0, 0, 0.5),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 10px;
      vertical-align: middle;
      // border-radius: 30px;
      display: inline-block;
      position: relative;
      //height: 30px;
      // border-radius: 100px;
    }

    input:checked {
      content: "\2714";
      padding-left: 25px;
      //position:absolute;
      color: #000000;
      background-color: #195073;
    }

    .field {
      white-space: nowrap;
    }
    .field:hover {
      font-weight: bold;
    }
  }
}

@media (min-width: 1200px) {
  .filterBereich {
    justify-content: center;
  }
}

@media (max-width: 750px) {
  .filterBereich {
    //justify-content: space-between;
    padding-right: 10px;
  }
}

.sort-wrapper {
  vertical-align: middle;
  height: 100px;
  button {
    margin-left: 10px;
    margin-right: 10px;
    height: 30px;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;

    //vertical-align: middle;
  }
}
.blog-cards {
  padding: 5px;
  padding-bottom: 200px;
  position: relative;

  .toggle-edit {
    display: flex;
    align-items: center;
    position: absolute;
    top: -70px;
    right: 1;

    span {
      margin-right: 16px;
    }
    input[type="checkbox"] {
      position: relative;
      border: none;
      -webkit-appearance: none;
      background: #fff;
      outline: none;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    input[type="checkbox"]:before {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      top: 0;
      left: 0;
      background: #303030;
      transform: scale(1.1);
      transition: 750ms ease all;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    input:checked[type="checkbox"]:before {
      background: #fff;
      left: 52px;
    }
  }
}

.blog-cards {
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr;
  @media (min-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>