<template>
  <div class="blog-card">
    <router-link
      class="link"
      :to="{
        name: 'AdminEditBenutzer',
        params: { benutzerid: this.sperrung.benutzer_id },
      }"
      target="_blank"
    >
      <p>Name:</p>
      <span class="titel"
        >{{ this.user.vorname }} {{ this.user.nachname }}
      </span>

      <p class="grund" v-html="'' + sperrung.grund + ''"></p>
    </router-link>
    <div class="info">
      ID: {{ this.user.id }} <br />
      EMAIL: {{ this.usermail }} <br />
      <br />

      gesperrt von:
      {{ this.sperrer.vorname }} {{ this.sperrer.nachname }}
      {{ this.email }}
      <span class="date">
        {{
          new Date(sperrung.timestamp).toLocaleString("de", {
            dateStyle: "short",
          })
        }}
      </span>

      <div class="icons">
        <Delete v-if="false" class="icon" />
      </div>
    </div>
  </div>
</template>

<script>
//import Arrow from "../assets/Icons/arrow-right-light.svg";

import Delete from "../assets/Icons/trash-regular.svg";
import userService from "../services/user.service";

export default {
  name: "BenutzerGesperrt",
  //props: ["werk"],
  props: {
    sperrung: Object,
    admin: Boolean,
    header: Boolean,
  },
  components: {
    // Arrow,

    Delete,
  },
  data() {
    return {
      user: null,
      sperrer: null,
      email: "",
      usermail: "",
    };
  },

  async created() {
    this.user = await userService.getBenutzerByID(this.sperrung.benutzer_id);

    this.sperrer = await userService.getBenutzerByID(this.sperrung.sperrer_id);
    this.email = await userService.getMail(this.sperrung.sperrer_id);

    this.usermail = await userService.getMail(this.user.id);
  },
};
</script>

<style lang="scss" scoped>
.blog-card {
  padding: 30px;

  position: relative;
  white-space: pre-line;
  :hover {
  }
  .icons {
    display: flex;

    align-content: center;
    z-index: 99;
    float: right;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      //background-color: #fff;
      transition: 0.5s ease all;
      &:hover {
        background-color: #303030;
        .edit,
        .delete {
          path {
            fill: #fff;
          }
        }
      }
      &:nth-child(1) {
        margin-right: 8px;
      }
      .edit,
      .delete {
        pointer-events: none;
        height: 15px;
        width: auto;
      }
    }
  }

  .titel {
    color: rgb(92, 0, 0);
    font-size: 18px;
    font-weight: bold;
  }
  .autor {
    font-size: 18px;
  }
  .grund {
    color: #000000;
    padding: 20px;
    font-size: 18px;
    font-weight: bold;
  }

  //background-color: #fff;

  transition: 0.3s ease all;

  &:hover {
    //transform: rotateZ(-1deg) scale(1.01);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background-color: rgb(250, 224, 224);
  }
  div {
    .id,
    .title,
    .author,
    .date,
    .username {
      width: 30%;
      font-size: calc(0.5rem + 0.5vw);
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 1; /* fallback */
      max-height: 200px; /* fallback */
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
    .author {
      width: 20%;
    }
    .date {
      width: 8%;
    }

    .username {
      width: 10%;
    }
    .id {
      width: 6%;
    }

    .props {
      display: flex;

      .col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 10px;
      }
      .col1 {
        color: gray;
        float: right;
      }
    }
  }

  .header {
    position: relative;
    top: 0;
    span {
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    z-index: 3;
    //padding: 10px 16px;
    color: #000;

    span {
      height: 50px;

      white-space: normal;
      word-break: break-word;
      display: flex;
      align-items: center;
    }

    .link {
      display: inline-flex;
      align-items: center;
      margin-top: auto;
      font-weight: 500;

      font-size: 12px;

      transition: 0.5s ease-in all;
      &:hover {
        color: rgba(48, 48, 48, 0.8);
      }
      .arrow {
        width: 10px;
      }
    }
  }
}
</style>
