<template>
  <div class="reset-password">
    <Modal
      v-if="modalActive"
      :modalMessage="modalMessage"
      v-on:close-modal="closeModal"
    />
    <Loading v-if="loading" />
    <div class="form-wrap">
      
      <div class="background"></div>
    </div>
  </div>
</template>

<script>
import Modal from "../components/Modal";
import Loading from "../components/Loading";

import AuthService from "@/services/auth.service.js";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      modalActive: false,
      modalMessage: "",
      loading: null,
    };
  },
  components: {
 
    Modal,
    Loading,
  },
    props: {
  t: {
    type: String,
    default: null,
  },
    id: {
    type: String,
    default: null,
  }
},

async mounted(){
  this.loading =true;
     try {
     const response = await AuthService.confirmAccount( this.id, this.t);

        if(response.status=== 200){
          this.modalActive=true;
          this.modalMessage = "Ihre Email-Adresse wurde erfolgreich bestätigt! Sie können sich nun einloggen.";
          this.loading = false;
          this.retry = false;
        } else {
          this.modalMessage = "Wahrscheinlich ist der Link abgelaufen."
        }
        this.msg = response.msg;

     
      } catch (err) {
          console.log(err);
          this.retry = true;
          this.modalMessage = "Wahrscheinlich ist der Link abgelaufen. Wir senden Ihnen gern eine neue E-Mail!"
          this.loading = false;
          this.modalActive = true;
      }
      console.log(this.id, this.t, this.password);

},
  methods: {
  
    closeModal() {
      this.modalActive = !this.modalActive;
      this.email = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-password {
  position: relative;
  .form-wrap {
    .reset {
      h2 {
        margin-bottom: 8px;
      }
      p {
        text-align: center;
        margin-bottom: 32px;
      }
    }
  }
}
</style>