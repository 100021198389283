<template>
 <div>
      <Navigation  />
      <router-view />
      <Footer />
    </div>

</template>

<script>
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";



export default {
  name: "normalLayout",
  components: { Navigation, Footer },
  data() {
    return {
      navigation: null,
      
    };
  },
  async created() {
    

    this.checkRoute();
  },
  mounted: function () {
    this.loading = false;
  },
  methods: {
    checkRoute() {
      if (
        this.$route.name === "Login" ||
        this.$route.name === "Register" ||
        this.$route.name === "ForgotPassword"
      ) {
        this.navigation = true;
        return;
      }
      this.navigation = false;
    },
  },
  watch: {
    $route() {
      this.checkRoute();
    },
  },
  computed: {},
};
</script>

