<template>
  <div class="modal">
    <div class="modal-content">
      <p>{{ this.modalMessage }}</p>
      <slot />
      <button @click.prevent="closeModal">Okay</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["modalMessage"],
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
  position: fixed;
  width: 100%;
  height:100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  .modal-content {
    display: flex;
    flex-direction: column;
    //justify-content: center;
    border-radius: 8px;
    width: max-content;
    padding: 40px 30px;
    background-color: #fff;
    overflow-x: visible;
    overflow-y: auto;
    max-height:80%;
       max-width:80%;
    p {
      text-align: center;
    }
    button {
      align-self: center;
    }
  }
}
</style>