<template>
  <div class="reset-password">
    <Modal
      v-if="modalActive"
      :modalMessage="modalMessage"
      v-on:close-modal="closeModal"
    />
    <Loading v-if="loading" />
    <div class="form-wrap">
      <form class="reset">
        <p class="login-register">
          Zurück zum
          <router-link class="router-link" :to="{ name: 'Login' }"
            >Login</router-link
          >
        </p>
        <h2>Passwort zurücksetzen</h2>
        <p>
          Haben Sie Ihr Passwort vergessen? Geben Sie hier Ihre Email-Adresse
          an, um das Passwort zurückzusetzen.
        </p>
        <div class="inputs">
          <div class="input">
            <input type="text" placeholder="Email" v-model="email" />
            <email class="icon" />
          </div>
        </div>
        <button @click.prevent="sendMail">Bestätigen</button>
        <div class="angle"></div>
      </form>
      <div class="background"></div>
    </div>
  </div>
</template>

<script>
import email from "../assets/Icons/envelope-regular.svg";
import Modal from "../components/Modal";
import Loading from "../components/Loading";

import AuthService from "@/services/auth.service.js";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      modalActive: false,
      modalMessage: "",
      loading: null,
    };
  },
  components: {
    email,
    Modal,
    Loading,
  },
  methods: {
    async sendMail() {
      this.loading = true;
      try {
        const response = await AuthService.forgotPassword(this.email.replace(/\s/g,''));
        console.log(response.status);
        if (response.status === 200) {
          // CHECK STATUS
          console.log("Status OK");
          this.modalMessage =
            "Email versendet an " + this.email + " (wenn vorhanden)";
        }
      } catch (err) {
        this.modalMessage =
          "Email versendet an " + this.email + " (wenn vorhanden)";
        this.loading = false;
        this.modalActive = true;
      }

      this.modalActive = true;
      this.loading = false;
    },
    closeModal() {
      this.modalActive = !this.modalActive;
      this.email = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-password {
  position: relative;
  .form-wrap {
    .reset {
      h2 {
        margin-bottom: 8px;
      }
      p {
        text-align: center;
        margin-bottom: 32px;
      }
    }
  }
}
</style>