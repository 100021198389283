<template>
  <popup-modal ref="popup">
    <strong> <p style="margin-top: 0" v-html="title"></p></strong>
    <p v-html="message"></p>
    <div class="textarea">
      <textarea type="text" class="serif" v-model="textInput"> </textarea>

      <span
        class="char-count"
        :class="[textInput.length > characters ? 'red' : '']"
      >
        {{ textInput.length }} / {{ characters }}</span
      >
    </div>
    <div class="btns">
      <button
        v-if="
          this.allowEmpty || (textInput != '' && textInput.length <= characters)
        "
        class="ok-btn"
        @click="_confirm"
      >
        {{ okButton }}
      </button>

      <button class="cancel-btn" @click="_cancel">{{ cancelButton }}</button>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "./PopupModal.vue";

export default {
  name: "TextDialog",

  components: { PopupModal },

  data: () => ({
    // Parameters that change depending on the type of dialogue
    title: undefined,
    message: undefined, // Main text content
    okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
    cancelButton: "abbrechen", // text for cancel button
    input: "",
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
    characters: 0,
    allowEmpty: false,
  }),

  computed: {
    textInput: {
      set(payload) {
        this.input = payload;
      },
      get() {
        return this.input;
      },
    },
  },

  methods: {
    show(opts = {}) {
      console.log("SHOW DIALOG");
      this.title = opts.title;
      this.message = opts.message;
      this.okButton = opts.okButton;
      this.characters = opts.characters;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      console.log("CONFIRM DIALOG");
      this.$refs.popup.close();
      this.resolvePromise(this.input);
    },

    _cancel() {
      console.log("CANCEL DIALOG");
      this.$refs.popup.close();
      this.resolvePromise(false);
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea {
  padding: 10px;
  margin: 2%;
  display: flex;
  flex-direction: column;

  textarea {
    padding: 15px;
    width: 100%;
    max-width: 80%;
    height: 100px;
    font-size: 18px;
    border-radius: 10px;
  }
}
.btns {
  transition: 0.2s all;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.ok-btn {
  padding: 0.5em 2em;
  color: white;
  text-decoration: none;

  border-radius: 10px;
  cursor: pointer;
}

.cancel-btn {
  padding: 0.5em 1em;
  background-color: white;
  color: black;
  border: 1px solid rgb(100, 100, 100);
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: rgb(46, 45, 45);
  }
}
</style>