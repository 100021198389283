<template>
  <div class="rating">

    <ul class="list" :class="{ disabled: disabled }">
      <li
        :key="star"
        v-for="star in maxstars"
        @mouseover="hoverStar(star)"
        @mouseleave="mouseLeftStar"
        :class="[{ active: star <= stars }]"
        @click=" star == stars?cancel():rate(star); $emit('rating')"
        
        class="star"
      >
        <font-awesome-icon
         
          :icon="[star <= stars ? 'fas' : 'far', 'heart']"
          size="lg"
        />
      </li>
    
      <span
        v-if="hasdescription && star_desc"
        :class="star_desc.class"
        class="all_desc"
        >{{ star_desc.text }}</span
      >
      <span
        v-else-if="!star_desc && !disabled"
        class="nostar_desc all_desc"
        >Präparat werten</span
      >  </ul><div>
         
        <button class="submit" v-if="this.stars > 0 &&!disabled" @click="submit()">Wertung abgeben</button>
        <br><span class="cancel" v-if="this.stars > 0 &&!disabled" @click="cancel()">Wertung abbrechen</span>
     
</div>
  
    <!--span v-if="hasresults">{{ stars }} of {{ maxstars }}</span-->
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(fas);
library.add(far);
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "vue_star_rating",
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
    //  stars: this.star,
      star_desc: this.getRatingDesc(this.stars),
    };
  },
  props: {
    stars: {
      type: Number,
    },
    maxstars: {
      type: Number,
      default: 5,
    },
    hasresults: {
      type: Boolean,
      default: true,
    },
    hasdescription: {
      type: Boolean,
      default: true,
    },
    ratingdescription: {
      type: Array,
      default: () => {
        return [
          {
            text: "Gut",
            class: "star-poor",
          },
          {
            text: "Sehr gut",
            class: "star-belowAverage",
          },
          {
            text: "Hervorragend",
            class: "star-average",
          },
          {
            text: "Grandios",
            class: "star-good",
          },
          {
            text: "Einfach fantastisch",
            class: "star-excellent",
          },
        ];
      },
      //default: ["Poor", "Below Average", "Average", "Good", "Excellent"]
    },
    starsize: {
      type: String,
      default: "24px",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    rate(star) {
      if (this.disabled) {
        return;
      }
      if (star <= this.maxstars && star >= 0) {
        this.stars = this.stars === star ? star - 1 : star;
      }
      console.log(this.stars);
    },
    cancel() {
      this.stars = 0;
      this.star_desc = "";
    
    },

    submit() {
      this.$emit("rated", this.stars);
    },
    hoverStar(star) {
      if (this.disabled) {
        return;
      }
      if (star <= this.maxstars && star >= 0) {
        this.star_desc = this.ratingdescription[star - 1];
      }
    },
    mouseLeftStar() {
      if (this.disabled) {
        return;
      }
      if (this.stars) {
        this.star_desc = this.ratingdescription[this.stars - 1];
        return this.star_desc;
      } else {
        this.star_desc = "";
      }
    },
    getRatingDesc(star) {
      if (star) {
        this.star_desc = this.ratingdescription[star - 1];
      }
      return this.star_desc;
    },
  },
};
</script>

<style lang="scss">
$active-color: #195073;
$white-color: #fff;
$black-color: black;
$poor-color: rgb(72, 150, 77);
$below-average-color: rgb(72, 150, 77);
$average-color: rgb(72, 150, 77);
$good-color: rgb(72, 150, 77);
$excellent-color: rgb(72, 150, 77);
$no-star-desc-color: rgb(32, 56, 134);


.svg-inline--fa{
  width: 2em;
  height: auto;
}

.nostar_desc {
  transition: 0.2 all;
  opacity: 0;
}
.rating {
   
 // max-width: 200px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  &:hover .nostar_desc {
    opacity: 1;
  }


  //display:flex;
}
.list {
     // display: flex;
  span {
    display: block;
 
  }
}
ul.list li,
span {
  display: inline-block;
  margin: 2px;
}
button {
  display: inline-block;
}
.list {
  margin: 0 0 5px 0;
  padding: 0;
  list-style-type: none;
  &:hover {
    .star {
      color: $active-color;
    }
  }
  span {
    width: fit-content;
    margin-left: 5px;
    padding: 5px;
    color: $black-color;
    border-radius: 2px;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    transition: 0.2s;
    line-height: 25px;
  }
}
.list.disabled {
  &:hover {
    .star {
      color: grey;
      cursor: default;
    }
    .star.active {
      color: $active-color;
    }
  }
}
.star {
transition: all 0.3s;
border-radius: 100%;
  cursor: pointer;
  &:hover {
    color:  $active-color;
   
    & ~ .star {
      &:not(.active) {
        color: white;
      }
    }
  }
}
.active {
  color: $active-color;
}
.star-poor {
  background: $poor-color;
  background: $active-color;
}
.star-belowAverage {
  background: $below-average-color;
  background: $active-color;
}
.star-average {
  background: $average-color;
  background: $active-color;
}
.star-good {
  background: $good-color;
  background: $active-color;
}
.star-excellent {
  background: $excellent-color;
  background: $active-color;
}

.nostar_desc {
  background: $no-star-desc-color;
}
.all_desc {
  background: none;
  color: black;
}
button {
  margin: 10px;
  padding: 10px;
}
.cancel{
  background: none;;
  color:#0d3249;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
    
  background: none;;
  }
}
</style>
