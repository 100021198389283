import axios from 'axios';



class AuthService {
  login(user) {
    console.log(user.email, user.password);
    return axios
      .post(process.env.VUE_APP_API_URL + 'authenticate/login', {

        email: user.email,
        password: user.password

      })
      .then(response => {
        if (response.data.oldUser) {
    //
        } else if(response.data.gesperrt){
          //
            } else if(response.data.confirm){
      //
        }   else
        if (response.data.token) {
          console.log("user stored locally");
          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
          localStorage.setItem('user', JSON.stringify(response.data));
          console.log( response.data.roles)
    
        } else {

          return null;
        }



        return response.data;

      }).catch(err => console.log(err));
  }

  logout() {
    console.log("removing user from local Storage");
    localStorage.removeItem('user');
  }

  register(user) {

    return axios.post(process.env.VUE_APP_API_URL + 'authenticate/register', {
      Email: user.email,
      Password: user.password,
      Vorname: user.vorname,
      Nachname: user.nachname,
      Newsletter: user.newsletter,
      Ort: user.wohnort,
      Beruf: user.beruf,
      Geburtsjahr: user.geburtsjahr,
      Vita: user.vita
    }).then(response => {
      console.log("old user?", response.data.oldUser);
      return response;
    });
  }

  forgotPassword(email) {
    return axios.post(process.env.VUE_APP_API_URL + 'authenticate/forgot-password', {

      Email: email,
      Subject: "Passwort vergessen?",
      Body: "<p>Liebe:r Apotheker:in,</p>"
      +"<p> Um Ihr Passwort in der Literatur-Apotheke zurückzusetzen, klicken Sie auf folgenden Link:</p>"
      + "<p> <a href='%link'> www.literatur-apotheke.de/neues-passwort </a> </p> Er führt Sie zurück zu unserer Website, wo Sie ein neues Passwort vergeben können. <p>Sie wollen Ihr Passwort nicht zurücksetzen? Dann können Sie diese Mail getrost ignorieren.</p> "
     + "<p>Wir freuen uns Sie bald wieder in der Literatur-Apotheke begrüßen zu dürfen.</p>"
     +" <p>Mit freundlichen Grüßen</p>"
     +"<p>Ihr Literatur-Apotheken-Team</p>",

    });
  }

  resetPassword(password, id, token) {
    return axios.post(process.env.VUE_APP_API_URL + 'authenticate/reset-password', {

      Password: password,
      Token: token,
      Id: id
    });
  }
  registerOldUser(user) {
    console.log(user.email);
    return axios.post(process.env.VUE_APP_API_URL + 'authenticate/register-old-user', {
      Email: user.email,
      Password: user.password,
    
    }).then(response => {
      console.log("old user?", response.data.oldUser);
      return response;
    })}

  mailConfirmation(email) {
    console.log(email);
    return axios.post(process.env.VUE_APP_API_URL + 'authenticate/confirm-mail', {

      Email: email,
      Subject: "Bestätigen Sie Ihre Anmeldung",
      Body: "<p>Liebe:r Apotheker:in,</p>"
      + " <p> Vielen Dank, dass Sie Teil der Literatur-Apotheke sein möchten.</p> <p>Um Ihre Email-Adresse zu bestätigen, klicken Sie auf folgenden Link: </p>"
      + "<p><a href='%link'> www.literatur-apotheke.de/email-bestätigen </a></p> "
      +" <p>Sie haben sich nicht registriert? Dann können Sie diese Mail getrost ignorieren. </p> <p> Wir freuen uns, Sie in der Literatur-Apotheke begrüßen zu dürfen.</p>"
      +" <p>Mit freundlichen Grüßen</p>"+
      " <p>Ihr Literatur-Apotheken-Team</p>",

    });
  }

  confirmAccount(id, token) {
    return axios.post(process.env.VUE_APP_API_URL + 'authenticate/confirm-account', {
      Token: token,
      Id: id
    });
  }
}

export default new AuthService();