<template>
  <div class="icon">
    <button class="delete-btn" @click="handle">Werk löschen</button>
    <confirm-dialog ref="confirmDialogue"></confirm-dialog>
  </div>
</template>




<script>
import werkeService from '../../services/werke.service';
import ConfirmDialog from "../ConfirmDialog.vue";

export default {
  name: "DeleteWerk",

  components: { ConfirmDialog },
  props: {
    werkid: Number,
  },

   data() {
    return {
      werk: null,
    }
    },
   async created(){
console.log("delete werk:", this.werkid);
if(this.werkid==null){
  this.werk = {
    titel: "-",
    autor: "-",
    id: "-"
  }
} else {
  this.werk = await werkeService.getWerkById(this.werkid)
}
},
  methods: {
    async handle() {

      const ok = await this.$refs.confirmDialogue.show({
        title: "Werk Löschen",
        message:
          "<p>Soll dieses Werk wirklich aus der Apotheke gelöscht werden? </p> <strong>" +
          this.werk.Titel +
          " // " +
          this.werk.Autor +
          " <br> id: " +
          this.werk.WerkId +
          "</strong>",
        okButton: "Für immer löschen",
        cancelButton: "abbrechen"
      }).catch(err=>{console.log(err)});
      if (ok) {
        this.$emit("ok");
      }
    },
  },
};
</script>

<style>
.delete-btn{
  background-color: maroon;
}
</style>