<template>
  <div class="profile">
    <Modal
      v-if="modalActive"
      :modalMessage="modalMessage"
      v-on:close-modal="closeModal"
    />
    <div v-if="isLoggedIn" class="container">
      <h1>Profil bearbeiten</h1>
      <p class="center">Hier können Sie ihre Profilangaben ändern.  </p>
      
      <div class="center">Hinweis: Wohnort und Geburtsjahr werden nicht öffentlich angezeigt.</div>
      <div class="profile-info">
        <div class="initials">
          <span>{{ this.profileInitials }}</span>
        </div>
        <div v-if="$store.state.userInfo.role == 1" class="admin-badge">
          <adminIcon class="icon" />
          <span>admin</span>
        </div>
        <div class="input">
          <label for="firstName">Vorname: *</label>
          <input type="text" id="firstName" v-model="vorname" required />
        </div>
        <div class="input">
          <label for="lastName">Nachname: *</label>
          <input type="text" id="lastName" v-model="nachname" />
        </div>
        <div class="input">
          <label for="beruf">Wohnort: *</label>
          <input type="text" id="ort" v-model="ort" />
        </div>
        <div class="input">
          <label for="beruf">Geburtsjahr: *</label>
          <input type="number" id="ort" v-model="geburtsjahr" />
        </div>
        <div class="input">
          <label for="beruf">Beruf:</label>
          <input type="text" id="beruf" v-model="beruf" />
        </div>

        <div class="input">
          <label for="username">Vita:</label>
          <textarea type="text" id="username" v-model="vita"></textarea>
          <p
            class="char-count"
            :class="[plainText(vita).length > 1000 ? 'red' : '']"
          >
            {{ plainText(vita).length }} / 1000
          </p>
        </div>
        <div :class="{ invisible: !error }" class="err-message red">
          <p><span></span>{{ this.errorMsg }}</p>
        </div>
        <input type="checkbox" name="" id="nl" v-model="newsletter" /><label
          for="nl" v-if="newsletter"
          >Newsletter abonniert</label
        > <label for="nl" v-else>Newsletter nicht abonniert</label>

        <button @click="updateProfile">Änderungen speichern</button>
      </div>
    </div>
    <div class="container" v-else>Sie sind nicht eingeloggt!</div>
  </div>
</template>

<script>
import Modal from "../components/Modal";
import adminIcon from "../assets/Icons/user-crown-light.svg";
import utils from "../utils";
export default {
  name: "Profile",
  components: {
    Modal,
    adminIcon,
  },
  data() {
    return {
      modalMessage: "Änderungen gespeichert",
      modalActive: null,
      initials: "XX",
      error: null,
      errorMsg: null,
    };
  },
  mounted() {
    this.initials = this.$store.commit("userInfo/setProfileInitials");
  },
  methods: {
    async updateProfile() {

    if (this.vorname=="" || this.nachname=="" ||this.ort=="" || this.geburtsjahr==""
      ) {
        this.error = true;
        this.errorMsg = "Bitte füllen Sie alle Pflichtfelder * aus.";
        setTimeout(() => {
          this.error = false;
        }, 5000);
        
      
        return;
      }

      if (this.geburtsjahr<1900 || this.geburtsjahr>2020
      ) {
        this.error = true;
        this.errorMsg = "Bitte geben Sie Ihr reales Geburtsjahr an.";
        setTimeout(() => {
          this.error = false;
        }, 5000);
        
      
        return;
      }
      if (this.plainText(this.vita).length > 1000) {
         this.error = true;
        this.errorMsg = "Vita hat zu viele Zeichen.";
        setTimeout(() => {
          this.error = false;
        }, 5000);
        return;
      }

         this.error = false;
        await this.$store.dispatch("userInfo/updateUserInfo");

        this.modalActive = !this.modalActive;
        

    },
    closeModal() {
      this.modalActive = !this.modalActive;
    },
    plainText(html) {
      return utils.plainText(html);
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    userWerke() {
      return this.$store.getters.getUserWerke(this.benutzerId);
    },
    role() {
      return this.$store.state.userInfo.vita;
    },

    vorname: {
      get() {
        return this.$store.state.userInfo.vorname;
      },
      set(payload) {
        this.$store.commit("userInfo/changeVorname", payload);
        this.initials = this.$store.commit("userInfo/setProfileInitials");
      },
    },
    nachname: {
      get() {
        return this.$store.state.userInfo.nachname;
      },
      set(payload) {
        this.$store.commit("userInfo/changeNachname", payload);
        this.initials = this.$store.commit("userInfo/setProfileInitials");
      },
    },
    vita: {
      get() {
        return this.$store.state.userInfo.vita;
      },
      set(payload) {
        this.$store.commit("userInfo/changeVita", payload);
      },
    },
    ort: {
      get() {
        return this.$store.state.userInfo.ort;
      },
      set(payload) {
        this.$store.commit("userInfo/changeOrt", payload);
      },
    },
    beruf: {
      get() {
        return this.$store.state.userInfo.beruf;
      },
      set(payload) {
        this.$store.commit("userInfo/changeBeruf", payload);
      },
    },

    geburtsjahr: {
      get() {
        return this.$store.state.userInfo.geburtsjahr;
      },
      set(payload) {
        this.$store.commit("userInfo/changeGeburtsjahr", payload);
      },
    },
    profileInitials() {
      return this.$store.state.userInfo.profileInitials;
    },
    benutzerId() {
      return this.$store.state.userInfo.benutzerId;
    },
    username: {
      get() {
        return this.$store.getters["auth/getUser"].username;
      },
      set(payload) {
        this.$store.commit("auth/changeUsername", payload);
      },
    },
        newsletter: {
      get() {
         return this.$store.state.userInfo.newsletter;
      },
      set(payload) {
    this.$store.commit("userInfo/changeNewsletter", payload);
      },
    },
    email() {
      return this.$store.getters["auth/getUser"].email;
    },
  },
};
</script>
<style lang="scss" scoped>
.profile {
  .container {
    max-width: 1000px;
    padding: 60px 25px;
    h2 {
      text-align: center;
      margin-bottom: 16px;
      font-weight: 300;
      font-size: 32px;
    }
    .profile-info {
      border-radius: 8px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
      padding: 32px;
      background-color: #f1f1f1;
      display: flex;
      flex-direction: column;
      max-width: 600px;
      margin: 32px auto;
      .initials {
        position: initial;
        width: 80px;
        height: 80px;
        font-size: 32px;
        background-color: #303030;
        color: #fff;
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      .admin-badge {
        display: flex;
        align-self: center;
        color: #fff;
        font-size: 14px;
        padding: 8px 24px;
        border-radius: 8px;
        background-color: #303030;
        margin: 16px 0;
        text-align: center;
        text-transform: capitalize;
        .icon {
          width: 14px;
          height: auto;
          margin-right: 8px;
        }
      }
      .input {
        margin: 16px 0;
        label {
          font-size: 16px;
          display: block;
          padding-bottom: 6px;
        }
        input,
        textarea {
          width: 100%;
          border: none;
          background-color: #f2f7f6;
          padding: 8px;
          height: 50px;
          @media (min-width: 900px) {
          }
          &:focus {
            outline: none;
          }
        }
          textarea {
           height: 100px;
       }
      }
     
      button {
        align-self: center;
      }
    }
  }
}
</style>