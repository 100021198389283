<template>
  <div class="icon">
    <button class="btn" @click="handle">Stamm</button>
    <confirm-dialog ref="confirmDialogue"></confirm-dialog>
  </div>
</template>




<script>
import werkeService from "../../services/werke.service";
import ConfirmDialog from "../ConfirmDialog.vue";

export default {
  name: "DeleteWerk",

  components: { ConfirmDialog },
  props: {
    werkid: Number,
  },

   data() {
    return {
      werk: null,
    }
    },
   async created(){
console.log("delete werk:", this.werkid);
if(this.werkid==null){
  this.werk = {
    titel: "-",
    autor: "-",
    id: "-"
  }
} else {

  this.werk = await werkeService.getWerkById(this.werkid)
}
},
  methods: {
    async handle() {

      const ok = await this.$refs.confirmDialogue.show({
        title: "Stamm",
        message:
          "<p>Soll dieses Werk zum Stammpräparat ausgezeichnet werden? </p> <strong>" +
          this.werk.Titel +
          " // " +
          this.werk.Autor +
          " <br> id: " +
          this.werk.WerkId +
          "</strong>",
        okButton: "Ja",
        cancelButton: "Abbrechen"
      }).catch(err=>{console.log(err)});
      if (ok) {
        this.$emit("ok");
      }
    },
  },
};
</script>

<style>
.delete-btn{
  background-color: maroon;
}
</style>