<template>
  <div class="container">
    <h1 class="">BENUTZER</h1>
    <div class="blog-card-wrap gray">
      <div class="sort-search-wrapper">
        <div class="sort-wrapper">
          <span>Sortieren nach </span>
          <button type="button" name="Datum" value="datum" @click="sortId()">
            id <span v-if="this.asc">&#9650;</span><span v-else>&#9660;</span>
          </button>
   
        </div>
        <div class="search-wrapper">
          <input
            type="text"
            v-model="search"
            @keyup="resetList()"
            placeholder="Suche.."
            class="search-input"
          />
        </div>
      </div>
      <div class="found">
        Es wurden <strong>{{ this.totalAmount }}</strong> User gefunden
      </div>

      <div class="blog-cards">
        <Slot :header="true" />
        <div v-for="user in this.userToShow" :key="user.BenutzerId">
          <div
            @click="
              showIcons === user.BenutzerId
                ? (showIcons = -1)
                : (showIcons = user.BenutzerId)
            "
            v-if="
              !gesperrt.some((w) => {
                return w.benutzer_id == user.BenutzerId && w.hidden;
              })
            "
          >
          
            <Slot
              :class="
                gesperrt.some((u) => {
                  return u.benutzer_id == user.BenutzerId;
                })
                  ? 'bg-gesperrt'
                  : markiert.some((u) => {
                      return u.benutzer_id == user.BenutzerId;
                    })
                  ? 'bg-markiert'
                  : ''
              "
              :admin="true"
              :user="user"
            />
            <div class="gesperrt">
              <span
                class="bg-gesperrt p-1"
                v-for="sperrung in gesperrt.filter((s) => {
                  return s.benutzer_id == user.BenutzerId;
                })"
                :key="sperrung.sperrung_id"
              >
                Gesperrt: {{ sperrung.grund }}
              </span>

              <span
                class="bg-markiert p-1"
                v-for="markierung in markiert.filter((s) => {
                  return s.benutzer_id == user.BenutzerId;
                })"
                :key="markierung.markierung_id"
              >
                Markiert: {{ markierung.grund }}
              </span>
            </div>
          </div>
          <div v-show="showIcons === user.BenutzerId">
            <div class="icons">
              <SperrenBenutzer
                :userid="user.BenutzerId"
                @ok="doSperren($event, user)"
              />
              <MarkierenBenutzer
                :userid="user.BenutzerId"
                @ok="doMarkieren($event, user)"
              />

              <button @click="userBearbeiten(user.BenutzerId)">
                Bearbeiten
              </button>
            </div>

            <div><strong>Ort:</strong> {{ user.Ort }}</div>
            <div><strong>Vita:</strong> {{ user.Vita }}</div>
            <div>
              <strong>Newsletter:</strong> {{ user.Newsletter ? "ja" : "nein" }}
            </div>

            <br /><br /><br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slot from "../../components/BenutzerSlot";
//import { werkeFiltered } from "../services/werke.service";
import SperrenBenutzer from "../../dialogs/benutzer/SperrenBenutzer.vue";
import MarkierenBenutzer from "../../dialogs/benutzer/MarkierenBenutzer.vue";
import userService from "../../services/user.service";

export default {
  name: "admin-benutzer",
  components: { Slot, SperrenBenutzer, MarkierenBenutzer },
  data() {
    return {
      search: "",
      amount: 8,
      allFilteredUsers: [],
      userToShow: [],
      totalAmount: 0,
      sortBy: "date",
      asc: false,
      showIcons: false,
    };
  },

  created() {},

  async mounted() {
    this.userToShow = await this.getUserToShow();
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    async doSperren(grund, benutzer) {
      console.log(this.$store.state.userInfo.benutzerId);

console.log(benutzer);
      await userService.sperren(
        benutzer.BenutzerId,
        this.$store.state.userInfo.benutzerId,
        grund
      );
      await this.$store.dispatch("admin/loadBenutzerGesperrt");

      this.$store.state.admin.showFeedback = true;

      setTimeout(() => {
        this.$store.state.admin.showFeedback = false;
      }, 5000);
    },

    async doMarkieren(grund, benutzer) {
      await userService.markieren(
        benutzer.BenutzerId,
        this.$store.state.userInfo.benutzerId,
        grund
      );
      await this.$store.dispatch("admin/loadBenutzerMarkiert");

      this.$store.state.admin.showFeedback = true;

      setTimeout(() => {
        this.$store.state.admin.showFeedback = false;
      }, 5000);
    },

    async sortId() {
      if (this.sortBy == "id") {
        this.asc = !this.asc;
      }
      this.sortBy = "id";

      await this.resetList();
    },
    async resetList() {
      console.log("RESET___________________________");
      this.userToShow = [];
      // console.log("Reset Werke List!", this.werkeToShow);
      this.userToShow = await this.getUserToShow();
    },
    async getUserToShow() {
      this.query = "";

      var searchWords = this.search.split(" ");
      for (var word of searchWords) {
        this.query += "search=" + word.toLowerCase();
        this.query += "&";
      }

      this.query += "limit=" + this.amount + "&";
      this.query += "offset=" + this.userToShow.length + "&";
      this.query += "order="+(this.asc?"1":"0")+"&";

      var result = await userService.getBenutzerAllFiltered(this.query);

      if (result.length > 0) {
        this.totalAmount = result[0].total_count;
      } else {
        this.totalAmount = 0;
      }

      if (result.length > 0) {
        this.totalAmount = result[0].total_count;
      } else {
        this.totalAmount = 0;
      }

      console.log(result);
      return result;
    },

    async handleScroll() {
      if (
        window.scrollY + window.innerHeight >=
          document.body.scrollHeight - window.innerHeight &&
        !(this.userToShow.length >= this.totalAmount)
      ) {
        console.log("load new werke");
        this.userToShow = [...this.userToShow, ...(await this.getUserToShow())];
      }
    },

    userFiltered() {
      var filteredUsers = [];

      for (var u of this.$store.state.admin.benutzerAll) {
        console.log(u);

        filteredUsers.push(u);
      }
      filteredUsers = filteredUsers.filter((post) => {
        console.log(post.benutzerId);
        return (
          post.benutzerId +
          " " +
          post.vorname +
          " " +
          post.nachname +
          " " +
          post.vorname +
          " " +
          post.email +
          " " +
          post.geburtsjahr
        )
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });

      console.log(filteredUsers);

      if (this.sortBy == "date" || this.sortBy == "") {
        filteredUsers = filteredUsers.sort((a, b) => {
          var dateA = new Date(a.registriert);
          var dateB = new Date(b.registriert);
          return this.asc ? dateA - dateB : dateB - dateA;
        });
      }

      if (this.sortBy == "id" || this.sortBy == "") {
        filteredUsers = filteredUsers.sort((a, b) => {
          return this.asc
            ? a.benutzerId - b.benutzerId
            : b.benutzerId - a.benutzerId;
        });
      }

      if (this.sortBy == "random") {
        filteredUsers = filteredUsers.sort(() => {
          return Math.random() - 0.5;
        });
      }
      return filteredUsers;
    },

    userBearbeiten(id) {
      this.$router.push({
        name: "AdminEditBenutzer",
        params: { benutzerid: id },
      });
    },
  },

  computed: {
    editWerk: {
      get() {
        return this.$store.state.editWerk;
      },
      set(payload) {
        this.$store.commit("toggleEditWerk", payload);
      },
    },
    profileAdmin() {
      return true; //this.$store.state.profileAdmin;
    },
    gesperrt() {
      return this.$store.state.admin.benutzerGesperrt;
    },
    markiert() {
      return this.$store.state.admin.benutzerMarkiert;
    },
  },
  beforeDestroy() {
    this.checkedAnwendungen = [];
    this.checkedThemen = [];
    this.checkedGenres = [];
    this.$store.commit("toggleEditWerk", false);
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.icons {
  display: flex;
  position: relative;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  padding-right: 10px;
  padding-bottom: 30px;
  button {
    border-radius: 10px;
  }
}
.found {
  text-align: center;
  font-size: 1.1em;
  padding-bottom: 20px;
}
.sort-search-wrapper {
  padding-top: 20px;
  padding-left: 2vw;
  padding-right: 2vw;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-wrapper {
  display: inline-block;
  margin-right: 0;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 50px;
  right: 0;
  input {
    border-radius: 10px;

    max-width: 800px;
    padding: 10px;
    font-size: 18px;
  }
}
.filterBereich {
  padding-top: 20px;
  padding: 20px;
  //max-width: 1200px;
  height: 100%;
  display: flex;

  align-content: center;
  column-gap: 5vw;
  padding-bottom: 20px;
  font-size: calc(0.6em + 0.5vw);
  margin: 0 auto;
  flex-wrap: wrap;

  .filterspalte {
    width: calc(25% + 50px);
    overflow: visible;
    padding-top: 30px;
    span {
      font-size: calc(1em + 1vw);
    }
    label {
      white-space: nowrap;
      vertical-align: middle;
      position: inherit;
    }

    input {
      transition: 750ms ease all;
      appearance: none;
      border: none;
      // background: #fff;
      outline: none;
      background-color: #fafafa;
      border: 1px solid #cacece;
      box-shadow: 0 0px 2px rgba(0, 0, 0, 0.5),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 10px;
      vertical-align: middle;
      // border-radius: 30px;
      display: inline-block;
      position: relative;
      //height: 30px;
      // border-radius: 100px;
    }

    input:checked {
      content: "\2714";
      padding-left: 25px;
      //position:absolute;
      color: #000000;
      background-color: #195073;
    }

    .field {
      white-space: nowrap;
    }
    .field:hover {
      font-weight: bold;
    }
  }
}

@media (min-width: 1200px) {
  .filterBereich {
    justify-content: center;
  }
}

@media (max-width: 750px) {
  .filterBereich {
    //justify-content: space-between;
    padding-right: 10px;
  }
}

.sort-wrapper {
  vertical-align: middle;
  height: 100px;
  button {
    margin-left: 10px;
    margin-right: 10px;
    height: 30px;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;

    //vertical-align: middle;
  }
}
.blog-cards {
  padding: 50px;
  padding-bottom: 200px;
  position: relative;

  .toggle-edit {
    display: flex;
    align-items: center;
    position: absolute;
    top: -70px;
    right: 1;

    span {
      margin-right: 16px;
    }
    input[type="checkbox"] {
      position: relative;
      border: none;
      -webkit-appearance: none;
      background: #fff;
      outline: none;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    input[type="checkbox"]:before {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      top: 0;
      left: 0;
      background: #303030;
      transform: scale(1.1);
      transition: 750ms ease all;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    input:checked[type="checkbox"]:before {
      background: #fff;
      left: 52px;
    }
  }
}
</style>
