<template>
  <div class="icon">
    <button class="delete-btn" @click="handle">Markierung aufheben</button>
    <confirm-dialog ref="confirmDialogue"></confirm-dialog>
  </div>
</template>




<script>
import ConfirmDialog from "../ConfirmDialog.vue";
import werkeService from "../../services/werke.service";
export default {
  name: "EntmarkierenWerk",

  components: { ConfirmDialog },
  props: {
    werkid: Number,
  },
  async created() {
    if (this.werkid == null) {
      this.werk = {
        titel: "-",
        autor: "-",
        id: "-",
      };
    } else {
 
  this.werk = await werkeService.getWerkById(this.werkid)
    }
  },

  methods: {
    async handle() {
      const ok = await this.$refs.confirmDialogue.show({
        title: "Markierung aufheben",
        message: "Soll für dieses Werk die Markierung aufgehoben werden?",
        okButton: "Markierung aufheben",
        cancelButton: "abbrechen",
      });
      if (ok) {
        this.$emit("ok");
      }
    },
  },
};
</script>

<style>
</style>