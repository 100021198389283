import axios from "axios";
import werkeService from "../services/werke.service";

import userService from "../services/user.service";
export const admin = {
  namespaced: true,
  state: {
    werkeAll: [],
    werkeGesperrt: [],
    werkeMarkiert: [],
    werkeNuggets: [],
    benutzerAll: [],
    benutzerGesperrt: [],
    benutzerMarkiert: [],
    kommentarMeldungen: [],
    kommentareGemeldet: [],
    werkeStack: [],
    userStack: [],
    stammFilter: false,
    oldWerke: false,
    wb: false,
    showFeedback: false,
    feedback: "Aktion erfolgt",
  },
  getters: {
    getBenutzerAll(state) {
      return state.benutzerAll;
    },

    getKommentarMeldungen(state) {
      return state.kommentarMeldungen;
    },
  },

  actions: {
    async loadWerkeAllXX({ state }) {
      console.log("ADMIN load allwerke");
      await axios
        .get(process.env.VUE_APP_API_URL + "werk/all")
        .then((response) => {
          this.data = response.data;
          this.data.forEach((doc) => {
            if (!state.werkeAll.some((werk) => werk.id === doc.WerkId)) {
              const werk = {
                id: doc.WerkId,
                inhalt: doc.Inhalt,
                titel: doc.Titel,
                quelle: doc.Quelle,
                datum: doc.Timestamp,
                autor: doc.Autor,
                benutzerid: doc.BenutzerID,
                motivation: doc.Motivation,
                stamm: doc.Stamm,
                anwendung: doc.Anwendung,
                thema: doc.Thema,
                genre: doc.Genre,
                rating: doc.Rating,
                vorname: doc.Vorname == null ? "-" : doc.Vorname,
                nachname: doc.Nachname == null ? "-" : doc.Nachname,
                wettbewerb: doc.Wettbewerb,
                
              };
              state.werkeAll.push(werk);
            }
          });
          state.postLoaded = true;
          //commit('setWerke',state.werke)
        })
        .catch((error) => {
          console.log(error);
        });

      console.log("loaded allwerke:", state.werkeAll);
    },

    // Werke
    async loadWerkeGesperrt({ state }) {
      state.werkeGesperrt = await werkeService.getSperrungen();
      console.log("Gesperrt", state.werkeGesperrt);
    },
    async loadWerkeMarkiert({ state }) {
      state.werkeMarkiert = await werkeService.getMarkierungen();
      console.log("Markiert", state.werkeMarkiert);
    },

    async loadWerkeNuggets({ state }) {
      state.werkeNuggets = await werkeService.getNuggets();
      console.log(state.werkeGesperrt);
      console.log("Nuggets", state.werkeNuggets);
    },

    async loadKommentarMeldungen({ state }) {
     // state.kommentarMeldungen = await werkeService.getKommentarMeldungen();
console.log("LOAD KOMMENTAR MELDUNGEN");
      state.kommentareGemeldet = await werkeService.getKommentareGemeldet();
    },

    // Benutzer
    async loadBenutzerAllFiltered({ state }) {
      console.log("load users");
      await axios
        .get(process.env.VUE_APP_API_URL + "benutzer")
        .then((response) => {
          this.data = response.data;
          this.data.forEach((doc) => {
            if (
              !state.benutzerAll.some(
                (user) => user.benutzerId === doc.BenutzerId
              )
            ) {
              const user = {
                benutzerId: doc.BenutzerId,
                vorname: doc.Vorname,
                nachname: doc.Nachname,
                registriert: doc.Timestamp,
                imagePath: doc.imagePath,
                role: doc.Role,
                vita: doc.Vita,
                newsletter: doc.Newsletter,
                email: doc.Email,
                geburtsjahr: doc.Geburtsjahr,

                wohnort: doc.Ort,

                beruf: doc.Beruf,
              };
              state.benutzerAll.push(user);
            }
          });
          state.postLoaded = true;
          //commit('setWerke',state.werke)
        })
        .catch((error) => {
          console.log(error);
        });

      console.log("loaded users:", state.users);
    },
    async loadBenutzerGesperrt({ state }) {
      state.benutzerGesperrt = await userService.getSperrungen();
    },
    async loadBenutzerMarkiert({ state }) {
      state.benutzerMarkiert = await userService.getMarkierungen();
    },
  },
  mutations: {
    mutateWerkeStack(state, payload) {
      state.checkedAnwendungen = payload;
    },
  },
};
