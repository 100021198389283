<template>
  <div class="form-wrap register">
    <Modal v-if="showTeilnahme" v-on:close-modal="showTeilnahme = false">
      <Teilnahmebedingungen />
    </Modal>

    <form action="" class="register">
      <p class="login-register">Bereits Mitglied?</p>
      <p>
        <router-link class="router-link" :to="{ name: 'Login' }"
          >Login</router-link
        >
      </p>
      <h2>Werden Sie hier Teil der Apotheke!</h2>
      <div class="inputs">
        <div class="input">
          <input type="text" placeholder="Vorname" v-model="firstName" />
          <user class="icon" />
        </div>
        <div class="input">
          <input type="text" placeholder="Nachname" v-model="lastName" />
          <user class="icon" />
        </div>
        <span class="hint"
          >Hinweis: Wohnort und Geburtsjahr werden nicht öffentlich
          angezeigt.</span
        >
        <div class="input">
          <input
            type="number"
            placeholder="Geburtsjahr"
            v-model="geburtsjahr"
          />
          <user class="icon" />
        </div>

        <div class="input">
          <input type="text" placeholder="Wohnort" v-model="wohnort" />
          <user class="icon" />
        </div>

        <div class="input">
          <input type="email" placeholder="Email" v-model="email" />
          <email class="icon" />
        </div>
        <div class="input">
          <input type="password" placeholder="Passwort" v-model="password" />
          <password class="icon" />
        </div>

        <div v-show="error" class="error">{{ this.errorMsg }}</div>
        <div class="accept">
          <input
            class="accept-btn"
            id="teilnahme"
            type="checkbox"
            v-model="accept"
            required
          />
          <label for=""
            >Hiermit akzeptiere ich die
            <span class="link" @click="showTeilnahme = !showTeilnahme"
              >Teilnahmebedingungen</span
            >
            der Literatur-Apotheke
          </label>
        </div>
        <hr />
        <h3>Wenn Sie möchten, erzählen Sie noch mehr über sich:</h3>
        <div class="input">
          <input type="text" placeholder="Beruf" v-model="beruf" />
        </div>
        <div class="input"></div>
        <div class="input">
          <textarea type="text" placeholder="Vita" v-model="vita"> </textarea>
        </div>
        <div class="accept">
          <input
            class="accept-btn"
            id="nl"
            type="checkbox"
            v-model="newsletter"
          />
          <label for="nl">Ich möchte den Newsletter erhalten. </label>
        </div>

        <span class="error red" v-if="this.error">{{ this.msg }}</span>
      </div>

      <button @click.prevent="signUp">Registrieren</button>
      <div class="angle"></div>
    </form>
    <div class="background"></div>
    <ConfirmDialog
      v-if="this.confirmMessage"
      @ok="$router.push('/')"
      ok="Ok"
      title="Registrierung erfolgreich"
      message="Bitte bestätigen Sie nun ihre Email-Adresse. Wir haben Ihnen eine Email an die angegebene Adresse gesendet. Dort werden Sie gebeten, zur Bestätigung einen Link aufzurufen. Danach können Sie sich einloggen. "
    />
  </div>
</template>

<script>
import email from "../assets/Icons/envelope-regular.svg";
import password from "../assets/Icons/lock-alt-solid.svg";
import user from "../assets/Icons/user-alt-light.svg";
import Modal from "../components/Modal.vue";
import Teilnahmebedingungen from "../views/Teilnahmebedingungen.vue";
import AuthService from "@/services/auth.service.js";
import ConfirmDialog from "../dialogs/main/EmptyConfirm";
export default {
  name: "Register",
  components: {
    email,
    password,
    user,
    Modal,
    Teilnahmebedingungen,
    ConfirmDialog,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      password: "",
      password_repeat: "",
      geburtsjahr: 1985,
      wohnort: "",
      beruf: "",
      vita: "",
      newsletter: false,

      error: null,
      errorMsg: "",
      msg: "",
      showTeilnahme: false,
      accept: false,
      confirmMessage: false,
    };
  },
  methods: {
    async signUp() {
      this.$store.state.loading = true;
      if (
        this.firstname == "" ||
        this.lastName == "" ||
        this.email == "" ||
        this.password == "" ||
        this.wohnort == "" ||
        this.geburtsjahr == ""
      ) {
        this.error = true;
        this.msg = "Alle oberen Felder müssen ausgefüllt sein.";
        setTimeout(() => {
          this.error = false;
        }, 5000);

        this.$store.state.loading = false;
        return;
      }
      if (this.geburtsjahr < 1900 || this.geburtsjahr > 2020) {
        this.error = true;
        this.msg = "Bitte geben Sie Ihr reales Geburtsjahr an.";
        setTimeout(() => {
          this.error = false;
        }, 5000);

        this.$store.state.loading = false;
        return;
      }

      if (!this.accept) {
        this.error = true;
        this.msg = "Bitte akzeptieren Sie die Teilnahmebedingungen.";
        setTimeout(() => {
          this.error = false;
        }, 5000);

        this.$store.state.loading = false;
        return;
      }

      try {
        const credentials = {
          vorname: this.firstName,
          nachname: this.lastName,
          username: this.username,
          email: this.email,
          password: this.password,
          geburtsjahr: this.geburtsjahr,
          wohnort: this.wohnort,
          beruf: this.beruf,
          vita: this.vita,
          newsletter: this.newsletter,
          //password_repeat: this.password_repeat
        };
        console.log(credentials);

        const response = await AuthService.register(credentials);
        console.log(response);
        this.msg = response.msg;
      } catch (error) {
        this.msg = "Registrierung erfolgt";
        this.error = true;

        this.$store.state.loading = false;
        return;
      }

      try {
        await AuthService.mailConfirmation(this.email);
      } catch (e) {
        console.log(e);
      }

      this.confirmMessage = true;

      this.$store.state.loading = false;
    },

    closeModal() {
      this.showTeilnahme = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.hint{
  color: gray;
  font-size: 12px;
}
.register {
  .accept {
    padding-top: 30px;
  }
  button {
    margin: 50px;
  }
  h2 {
    max-width: 350px;
  }
}
</style>