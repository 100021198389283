<template>
  <div class="comment-wrapper">
    <div class="comment-content">
      <div class="">
        <p class="non-serif">
          <router-link
            class="link"
            :to="{
              name: 'BenutzerView',
              params: { benutzerid: kommentarToShow.benutzerid },
            }"
          >
            {{ benutzerName }}
          </router-link>
          schrieb:
        </p>
        "<span
          class="text serif "
          v-html="kommentarToShow.inhalt"
        ></span
        >"

        <span class="date"
          >am
          {{
            new Date(kommentarToShow.timestamp).toLocaleString("de", {
              dateStyle: "long",
            })
          }}
        </span>

        <KommentarMelden
          v-if="
            !this.showMessage &&
              !this.gemeldet &&
              this.$store.getters['auth/isLoggedIn']
          "
          @ok="kommentarMelden($event)"
        />

        <span
          class="delete"
          v-if="this.$store.getters['auth/admin']"
          @click="showConfirm = true"
          >löschen
        </span>

        <Confirm
          v-show="showConfirm && this.$store.getters['auth/admin']"
          ok="ja"
          cancel="nein"
          title="Kommentar löschen?"
          @ok="kommentarLöschen($event)"
          @cancel="showConfirm = false"
        />

        <span
          class="gemeldet"
          v-if="this.showMessage || this.gemeldet"
          v-html="this.message"
        ></span>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
import userService from "../services/user.service";
import KommentarMelden from "../dialogs/main/Kommentar_Melden.vue";

import Confirm from "../dialogs/main/EmptyConfirm.vue";
import werkeService from "../services/werke.service";

export default {
  name: "Comment",
  props: ["kommentar", "kommentarid"],
  data() {
    return {
      benutzerName: "",
      showMessage: false,
      message: "",
      gemeldet: false,
      showConfirm: false,
      kommentarToShow: null,
    };
  },
  components: { KommentarMelden, Confirm },
  computed: {},
  async created() {
    console.log(this.kommentar, this.kommentarid);
    if (!this.kommentar && this.kommentarid) {
      console.log("load Kommentar");
      await werkeService
        .getKommentarById(this.kommentarid)
        .then((res) => {
          console.log(res);
          this.kommentarToShow = res;
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      this.kommentarToShow = this.kommentar;
    }
    console.log(this.kommentar);

    await userService
      .getBenutzerByID(this.kommentarToShow.benutzerid)
      .then((res) => (this.benutzerName = res.vorname + " " + res.nachname));

    await userService
      .isKommentarGemeldet(
        this.kommentarToShow.id,
        this.$store.state.userInfo.benutzerId
      )
      .then((res) => (this.gemeldet = res));
    console.log(this.kommentarToShow.id, this.$store.state.userInfo.benutzerId);
    console.log(this.gemeldet);
  },
  methods: {
    async kommentarMelden(grund) {
      await werkeService.kommentarMelden(
        grund,
        this.kommentarToShow.id,
        this.$store.state.userInfo.benutzerId
      );
      this.showMessage = true;
      this.message = "Kommentar gemeldet.";
    },
    async kommentarLöschen() {
      var alert =
        "Der Kommentar verstieß gegen die Netiquette und wurde deswegen gelöscht.";
      console.log(this.kommentarToShow.benutzerid);
      if (this.kommentarToShow.benutzerid == -1) {
        await werkeService.kommentarLöschen(this.kommentarToShow.id);

        this.$destroy();
        // remove the element from the DOM
        this.$el.parentNode.removeChild(this.$el);
      } else {
        await werkeService.kommentarLöschenTemp(this.kommentarToShow.id);
        this.kommentarToShow.benutzerid = -1;
        this.kommentarToShow.inhalt = alert;
        this.benutzerName = "--";
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.delete {
  color: red;
}
.gemeldet {
  font-style: italic;
  padding: 10px;
  font-size: 11px;
}
.comment-wrapper {
  padding: 10px;

  width: 80%;
  max-width: 800px;
  box-shadow: 0px 1px 0px 0px rgba(10, 10, 10, 0.1);

  @media (min-width: 700px) {
    min-height: 50px;

    flex-direction: row;
  }
  .comment-content {
    display: flex;
    flex-direction: column;

    flex: 1;
    order: 2;
    width: 100%;
    @media (min-width: 700px) {
      // order: 1;
    }
    @media (min-width: 800px) {
      // flex: 1;
    }

    div {
      padding-left: 10px;
      //width: 95%;

      .date {
        font-size: 14px;
        display: block;
        margin-top:10px;
      }
      .link {
        display: inline-flex;
        align-items: center;
        margin-top: 32px;
        padding-bottom: 4px;
        border-bottom: 1px solid transparent;
        transition: 0.5s ease-in all;
        &:hover {
          border-bottom-color: #303030;
        }
      }
      .link-light {
        &:hover {
          border-bottom-color: #ffff;
        }
      }
    }
  }

  &:nth-child(even) {
    .blog-content {
      order: 1;
    }
    .blog-photo {
      order: 1;
    }
  }
}
.no-user:first-child {
  .blog-content {
    background-color: #fff;
    color: #000;
    font-weight: 500;
  }
}
.text {
  padding: 2px;
  display: inline;
  
}
</style>
