<template>
  <div class="home">
    <LiveTicker v-if="showTicker" :text="tickerText" />
    <div class="welcome container">
      <div v-if="!this.$store.getters['auth/isLoggedIn']" class="container">
        <div class="welcome-text">
          <h2>Herzlich willkommen in der Literatur-Apotheke.</h2>

          <p>
            Ein Gedicht kann einen ganzen Tag retten. Literatur tröstet und
            besänftigt, aber macht auch Mut und rüttelt auf. Und eine berührende
            Zeile, grenzt nicht aus oder ab, sondern weitet den Horizont und
            öffnet ein Fenster in eine andere Welt.
          </p>
          <p>
            Schauen Sie rein und werden Sie selbst zum/zur literarischen
            Apotheker:in.
          </p>

          <p>
            Gedichte, Prosa, Romane, Märchen, Mythen, Songtexte - als erste
            Hilfe, Trostpflaster, Injektion oder Balsam! Mit der weltweit ersten
            Literatur-Apotheke geben wir sowohl Autor:innen, als auch den
            Leser:innen die Möglichkeit, sich über die Wirkung von Literatur vor
            dem Hintergrund der eigenen Lese- oder Schreiberfahrung
            auszutauschen.
          </p>
          <p>
            Das Besondere: In der Literatur-Apotheke verwandelt sich jeder
            literarische Beitrag in ein Text-Präparat!
          </p>
          <p>
            Sie können hier kostenlos Ihre eigenen Gedichte oder Prosatexte
            einreichen oder die von anderen Autor:innen abrufen.
          </p>
          <p>Wir wünschen Ihnen viel Freude beim Stöbern und Lesen!</p>
        </div>
        <div class="">
          <router-link class="link wb-link" :to="{ name: 'WB_Home' }">
            <div>
              <button class="wb-button">Hier geht es zum Literaturwettbewerb 2024</button>
              <img
                class="wb-image"
                src="../assets/WB2024.jpg"
                alt="Hier geht es zum Literaturwettbewerb 2024"
              />
            </div>
          </router-link>
        </div>
      </div>

      <div v-else class="container">
        <div class="welcome-text">
          <br /><br />
          <h2 class="">Herzlich willkommen {{ name }}!</h2>
          <br /><br />
          <h3>Stöbern Sie im Apothekerschrank.</h3>

          <p>
            Gedichte, Prosa, Lebenserinnerungen, Songtexte – als erste Hilfe,
            Trostpflaster, Injektion oder Balsam! Im
            <strong>
              <router-link :to="{ name: 'Werke' }"
                >Apothekerschrank</router-link
              >
            </strong>
            befinden sich alle Texte der Literatur-Apotheke. Hier finden Sie
            Werke von bekannten und (noch) unbekannten Autor:innen aus aller
            Welt.
          </p>

          <br />
          <h3>Reichen Sie eigene Texte ein.</h3>
          <p>
            Über unser
            <strong
              ><router-link :to="{ name: 'WerkCreate' }"
                >Online-Formular</router-link
              >
            </strong>
            können Sie Ihre eigenen Texte ganz einfach veröffentlichen und dem
            Publikum vorstellen. Vielleicht wollen Sie lieber einen fremden Text
            mit unserer Community teilen? Auch das ist über unser
            Online-Formular möglich. Jeder Text kann kommentiert und bewertet
            werden.
          </p>

          <br />
          <h3>Küren Sie Ihre Lieblingstexte.</h3>

          <p>
            In der
            <strong
              ><router-link :to="{ name: 'BestenListe' }"
                >Bestenliste</router-link
              >
            </strong>
            finden Sie diejenigen Texte, die von unserer Community am besten
            bewertet wurden. Kuratieren Sie diese Liste mit und geben Sie 1-5
            Herzen als Voting ab. Favorisierte Werke können jederzeit im Profil
            eingesehen und somit abgespeichert werden.
          </p>
          <br />

          <h3>Nehmen Sie am Wettbewerb teil.</h3>
          <p>
            Unsere Hildesheimer Literatur-Wettbewerbe ziehen seit über einem Jahrzehnt
            Autor:innen aus aller Welt und in jedem Alter an. Seit 2017 finden
            diese stets online im Rahmen der Literatur-Apotheke statt. Unter
            wechselnden Themen reichen hunderte Autor:innen ihre Texte ein, aus
            denen eine Jury ihre Favoriten auswählt. Außerdem wählt das Publikum
            seinen Lieblingstext.
          </p>
          <router-link class="link wb-link" :to="{ name: 'WB_Home' }">
            <div>
              <button class="wb-button">Hier geht es zum Literaturwettbewerb 2024</button>
              <img
                class="wb-image"
                src="../assets/WB2024.jpg"
                alt="Hier geht es zum Literaturwettbewerb 2024"
              />
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <!--WerkPost v-if="user" :post="welcomeScreen" /-->
    <div class="stamm container">
      <h3 class="center">Eine Auswahl unserer Stammpräparate:</h3>
      <br />
      <div v-for="(werk, index) in stammFeed" :key="index">
        <router-link
          class="link"
          :to="{ name: 'WerkView', params: { werkid: werk.WerkId } }"
          :title="werk.Titel"
        >
          <WerkPost :werk="werk" v-for="(werk, index) in stammFeed" :key="index"
        /></router-link>
      </div>
    </div>
    <div class="blog-card-wrap container">
      <h3 class="center">Neulich eingereicht:</h3>
      <br />
      <div class="container">
        <div class="blog-cards">
          <div v-for="(werk, index) in recentWerke" :key="index">
            <router-link
              class="link"
              :to="{ name: 'WerkView', params: { werkid: werk.WerkId } }"
              :title="werk.Titel"
            >
              <WerkSlot :werk="werk"
            /></router-link>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!this.$store.getters['auth/isLoggedIn']" class="updates">
      <div class=" center">
        <p>Werden Sie selbst zum/zur literarischen Apotheker:in!</p>
        <router-link class="router-button" :to="{ name: 'Register' }">
          Registrieren
        </router-link>
      </div>
    </div>
    <div class="text-center foerderer">
      <img
        src="../assets/foerderer/las.png"
        class="img-fluid"
        alt="Responsive image"
      />
      <img
        src="../assets/foerderer/sk.jpg"
        class="img-fluid"
        alt="Responsive image"
      />
      <img
        src="../assets/foerderer/lkhi.jpg"
        class="img-fluid"
        alt="Responsive image"
      />
      <img
        src="../assets/foerderer/lv.jpg"
        class="img-fluid"
        alt="Responsive image"
      />
      <img
        src="../assets/foerderer/stadt.jpg"
        class="img-fluid"
        alt="Responsive image"
      />
      <img
        src="../assets/foerderer/wh.jpg"
        class="img-fluid"
        alt="Responsive image"
      />
      <img
        src="../assets/foerderer/svhi.jpg"
        class="img-fluid"
        alt="Responsive image"
      />
      <img
        src="../assets/foerderer/nda.jpg"
        class="img-fluid"
        alt="Responsive image"
      />
      <img
        src="../assets/foerderer/vgh.jpg"
        class="img-fluid"
        alt="Responsive image"
      />
      <img
        src="../assets/foerderer/mwk.jpg"
        class="img-fluid"
        alt="Responsive image"
      />
      <br />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import WerkPost from "../components/WerkPost";
//import InfoPost from "../components/InfoPost";
import WerkSlot from "../components/WerkCard";
//import Arrow from "../assets/Icons/arrow-right-light.svg";
import LiveTicker from "../components/LiveTicker.vue";
import werkeService from "../services/werke.service";

export default {
  name: "Home",
  components: {
    WerkPost,
    WerkSlot,
    // Arrow,
    LiveTicker,
  },
  data() {
    return {
      showTicker: true,
      tickerText: "",
      stammFeed: [],
      recentWerke: [],
    };
  },
  async created() {
    await axios
      .get(process.env.VUE_APP_API_URL + "liveticker/" + 0)
      .then((r) => {
        console.log(r);
        this.tickerText = r.data[0].text;
        this.showTicker = r.data[0].show;
      })
      .catch((err) => console.log(err));

    this.stammFeed = await werkeService.getWerkeFiltered(
      "stamm=1&limit=1&order=2"
    );
    this.recentWerke = await werkeService.getWerkeFiltered(
      "limit=3&order=0&wb=0"
    );
  },
  computed: {
    name() {
      return (
        this.$store.state.userInfo.vorname +
        " " +
        this.$store.state.userInfo.nachname
      );
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  //padding: 30px;
  text-align: left;
  font-size: 25px;
  font-weight: lighter;
}
.wb-link {
  margin: 0 auto;
  color: black;
  width: 300px;
  div {
    text-align: center;
    width: 300px;
    margin: 0 auto;
    &:hover .wb-image {
      opacity: 0.5;
    }
  }
}

.wb-image {
  transition: 0.5s;
  width: 100%;
  border-radius: 10px;
}

.welcome {
  font-size: 14px;
  max-width: 1200px;
  .container {
    margin: 3%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;

    justify-content: space-between;

    .welcome-text {
      text-align: justify;
      padding: 1px;
      @media (min-width: 800px) {
        // MIT WB BUTTON AUF 65%
        // flex-direction: row;
      }
    }
  }
}

.blog-card-wrap {
  //padding: 5%;
  // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.stamm {
  // width: 100%;
  padding: 20px;
  max-width: 600px;
}
.updates {
  .container {
    text-align: center;
    padding: 10px 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    //  flex-direction: column;
    //align-items: center;
    @media (min-width: 800px) {
      padding: 15px 20px;
      // flex-direction: row;
    }

    .router-button {
      display: flex;
      font-size: 14px;
      text-decoration: none;
      @media (min-width: 800px) {
        margin-left: auto;
      }
    }
  }
}
.blog-cards {
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr;
  @media (min-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.foerderer {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;

  .img-fluid {
    width: calc(6% + 50px);
    padding: 20px;
    vertical-align: middle;
  }
}
</style>
