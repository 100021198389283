<template>
  <div class=" favorit" >
    <div v-if="isFavorit">
      <button title="Favorisiert" @click="handleEntFav">
        <font-awesome-icon :class="`fa-10`" :icon="['fas', 'star']" />
      </button>
      <p class="fade-text">favorisiert</p>

    
    </div>
    <div v-else>
      <button title="Nicht favorisiert" @click="handle">
        <font-awesome-icon :class="`fa-10`" :icon="['far', 'star']" />
      </button>
      <p class="fade-text">favorisieren</p>
    </div>
    <text-dialog ref="textDialogue"></text-dialog>
    
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
  </div>
</template>


<script>
import TextDialog from "../TextDialog.vue";

import ConfirmDialog from "../ConfirmDialog.vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
//import vueStarRate from "vue-js-star-rating";
library.add(fas);
library.add(far);
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "Favorisieren",

  components: { TextDialog, ConfirmDialog, FontAwesomeIcon },
  props: {
    isFavorit: Object,
  },
  created() {
  },

  methods: {
    async handle() {
      console.log("HANDLE BUTTON");
      const text = await this.$refs.textDialogue.show({
        characters: 200,
        title: "Ihre Favoriten sind für andere Nutzer*innen in ihrem Profil sichtbar.",
        message: "Warum möchten Sie dieses Text-Präparat favorisieren? <br>(max. 200 Zeichen)",
        okButton: "Ok",
      });
      if (text != "" ) {
        this.$emit("ok", text);
      }
    },
       async handleEntFav() {
      console.log("HANDLE BUTTON");
      const ok = await this.$refs.confirmDialog.show({
     
        title: "Favoritenauswahl und Grund wirklich entfernen?",
        message: "",
        okButton: "Ja",
        cancelButton:"Nein"
      });
      if (ok  ) {
        this.$emit("ok",true);
      }
    },
  },
};
</script>

<style lang="scss">
.fade-text {
  transition: 0.2s all;
  opacity: 0;
  font-size: 12px;
  color: blue($color: #000000);
  overflow: visible;
  width: 80px;
}
.favorit:hover .fade-text {
  opacity: 1;
}
</style>