<template>
  <div class=" favorit" >
      <button title="Favorisiert" class="btn" @click="handleEntFav">
        <font-awesome-icon :class="[`fa-10`,'icon']" :icon="['fas', 'times']" />
        
      </button> 
      <p class="fade-text">Favoritenauswahl aufheben</p>

    
  

    <confirm-dialog ref="confirmDialog"></confirm-dialog>
  </div>
</template>


<script>
import ConfirmDialog from "../ConfirmDialog.vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
//import vueStarRate from "vue-js-star-rating";
library.add(fas);
library.add(far);
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
  name: "Favorisieren",

  components: {  ConfirmDialog, FontAwesomeIcon },
  props: {
    isFavorit: Object,
  },
  created() {
  },

  methods: {
   
       async handleEntFav() {
      console.log("HANDLE BUTTON");
      const ok = await this.$refs.confirmDialog.show({
     
        title: "Favorit wirklich entfernen?",
        message: "",
        okButton: "Ja",
        cancelButton:"Nein"
      });
      if (ok  ) {
        this.$emit("ok",true);
      }
    },
  },
};
</script>

<style lang="scss">
.favorit{
      float: right;;
      z-index: 9999;
  .btn{
    background: none;;
    opacity: 0.15;

    
      font-size: 12px;
    .icon{
      color: black;
      
    }
    &:hover{
      opacity: 0.3;
      font-size: 16px;
    }
  }
}
.fade-text {
  transition: 0.2s all;
  opacity: 0;
  font-size: 12px;
  color: blue($color: #000000);
  overflow: visible;
  width: 80px;
}
.favorit:hover .fade-text {
  opacity: 1;
}
</style>