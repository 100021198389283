<template>
  <div class="reset-password">
    <Modal
      v-if="modalActive"
      :modalMessage="modalMessage"
      v-on:close-modal="closeModal"
    />
    <Loading v-if="loading" />
    <div class="form-wrap">
      <form class="reset">
        <p class="login-register">
          Zurück zum
          <router-link class="router-link" :to="{ name: 'Login' }"
            >Login</router-link
          >
        </p>
        <h2>Neues Passwort eingeben:</h2>
        <p>
       
        </p>
        <div class="inputs">
          <div class="input">
            <input type="text" placeholder="Passwort" v-model="password" />
          
          </div>
        </div>
        <button @click.prevent="resetPassword">Bestätigen</button>
        <div class="angle"></div>
      </form>
      <div class="background"></div>
    </div>
  </div>
</template>

<script>
import Modal from "../components/Modal";
import Loading from "../components/Loading";

import AuthService from "@/services/auth.service.js";

export default {
  name: "ResetPassword",
  data() {
    return {
      password: "",
      modalActive: false,
      modalMessage: "",
      loading: null,
      retry: false,
    };
    
  },
  props: {
  t: {
    type: String,
    default: null,
  },
    id: {
    type: String,
    default: null,
  }
},
  components: {

    Modal,
    Loading,
  },
  methods: {
   async resetPassword() {
      this.loading = true;
     try {
    
        
        const response = await AuthService.resetPassword(this.password, this.id, this.t);

        if(response.status=== 200){
          this.modalActive=true;
          this.modalMessage = "Ihr Passwort wurde erneuert!";
          this.loading = false;
          this.retry = false;
        } else {
          this.modalMessage = "Wahrscheinlich ist der Link abgelaufen"
        }
        this.msg = response.msg;

     
      } catch (err) {
          console.log(err);
          this.retry = true;
          this.modalMessage = "Wahrscheinlich ist der Link abgelaufen. Wir senden Ihnen gern eine neue E-Mail!"
          this.loading = false;
          this.modalActive = true;
      }
      console.log(this.id, this.t, this.password);
   
    },
    closeModal() {
      this.modalActive = !this.modalActive;
      this.email = "";

      if(this.retry){
         this.$router.push( 'forgot-password' );

    }else {
       this.$router.push( 'login' );
    }},
  },
};
</script>

<style lang="scss" scoped>
.reset-password {
  position: relative;
  .form-wrap {
    .reset {
      h2 {
        margin-bottom: 8px;
      }
      p {
        text-align: center;
        margin-bottom: 32px;
      }
    }
  }
}
</style>