<template>
  <div class="ticker-wrap">
    <div class="ticker">
      <div class="ticker__item">{{text}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "live-ticker",

  props:{
    text:String
  }
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  z-index: 9999;
}
$duration: 20s;

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
position: fixed;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  height: 2rem;
  background-color: rgba(#eef, 1);
  padding-left: 100%;
  box-sizing: content-box;
  //border: 1px solid black;
  .ticker {
    
  overflow: hidden;
    display: inline-block;
    height: 2rem;
    line-height: 2rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;

    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: $duration;
    animation-duration: $duration;

    &__item {
      display: inline-block;

  overflow: hidden;
      padding: 0 2rem;
      font-size: 1rem;
      color: color;
    }
  }
}

body {
  padding-bottom: 5rem;
}
h1,
h2,
p {
  padding: 0 5%;
}
</style>
