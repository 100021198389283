<template>
  <div class="reset-password">
    <Modal
      v-if="modalActive"
      :modalMessage="modalMessage"
      v-on:close-modal="closeModal"
    />
    <div class="form-wrap">
      <form class="reset">
        <p class="login-register">
          Zurück zum
          <router-link class="router-link" :to="{ name: 'Login' }"
            >Login</router-link
          >
        </p>

        <h2>Sie sind bereits Mitglied und haben eventuell Präparate?</h2>
        <p>
          Es ist nichts verloren! Aus Sicherheitsgründen müssen Sie auf dieser Seite die Email-Adresse ihres bisherigen  Accounts und ein neues Passwort angeben. Klicken
          Sie danach auf "Absenden" und besuchen Sie den Link, den wir
          Ihnen an Ihre angegebene Email-Adresse schicken. So bestätigen Sie, dass Sie es wirklich sind und können sich dann mit dem neuen Passwort in die neue Apotheke einloggen.
          Vielen Dank für Ihr Verständnis!
        </p>
        <table class="inputs">
          <tr class="">
            <td scope="row">Email:</td>
            <td>
              <input type="email" name="email" id="email" v-model="email" />
            </td>
          </tr>
          <tr  class="">
            <td scope="row" for="pw">Passwort:</td>
            <td>
              <input type="password" name="pw" id="pw" v-model="password" />
            </td>
          </tr>
          <tr  class="">
            <td scope="row" for="pwR">Passwort wiederholen:</td>
            <td><input type="password" name="pwWdh" id="pwWdh" v-model="passwordWdh"/></td>
          </tr>
        </table>
        <span class="red" v-if="this.showMessage">{{this.message}}</span>
        <button @click.prevent="sendMail">Email empfangen</button>
        <div class="angle"></div>
      </form>
      <div class="background"></div>
    </div>
  </div>
</template>

<script>
import Modal from "../components/Modal";
//import Loading from "../components/Loading";

import AuthService from "@/services/auth.service.js";

export default {
  name: "HandleOldUser",
  data() {
    return {
      email: this.$route.params.email,
      password: "",
      passwordWdh:"",
      modalActive: false,
      modalMessage: "",
      loading: null,
      message: "",
      showMessage:false
    };
  },
  components: {
    Modal,
    //  Loading,
  },
  methods: {
    async sendMail() {
    
        // register
this.message=""
if(this.email===""||this.password===""||this.passwordWdh===""){
          this.message= "Alle Felder müssen ausgefüllt sein."
          this.showMessage= true;

}
else if(this.passwordWdh!== this.password){
          this.message= "Passwörter stimmen nicht überein"
          this.showMessage= true;
    

        } else{

  try {

        const credentials = {
          email: this.email,
          password: this.password,
        };
        console.log(credentials);

        var response = await AuthService.registerOldUser(credentials);
        console.log(response);
        this.msg = response.msg;
        console.log("registered");
                 this.modalMessage = "Registrierung erfolgreicht! Überprüfen Sie nun das Postfach ihrer angegebenen Email-Adresse."
                 
        await AuthService.mailConfirmation(this.email);
      } catch (error) {
        console.log("Register failed", error);

        this.showMessage=true
            this.modalMessage = "Fehler bei der Registrierung. Überprüfen Sie eventuell ihr Postfach!"
      }

      this.loading = true;
      

      this.modalActive = true;
      this.loading = false;
      }
            setTimeout(()=>{
this.showMessage=false
          },5000)
    },
    created() {
      this.email = this.$route.params.email;
    },
    mounted() {
      this.$store.state.loading = false;
      console.log("LOADING", this.$store.state.loading);
    },
    closeModal() {
      this.modalActive = !this.modalActive;
      this.email = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.inputs {
  // display:flex;
  input{
    padding: 10px;
  }
}
.reset-password {
  position: relative;
  .form-wrap {
    .reset {
      h2 {
        margin-bottom: 8px;
      }
      p {
        text-align: center;
        margin-bottom: 32px;
      }
    }
  }
}
</style>