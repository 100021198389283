<template>
  <div class="blog-card">
    <router-link
      class="link"
      :to="{        name: $store.state.admin.wb?'WB_WerkView':'WerkView', params: { werkid: nugget.werk_id } }"
      :title="werk.Titel"
      target="_blank"
    >
      <span class="titel"> {{ werk.Titel }} </span>
      <span class="autor">// {{ werk.Autor }}</span>

      <p class="grund" v-html="'' + nugget.grund + ''"></p>
    </router-link>
    <div class="info">
      <span class="titel"> ID: {{ nugget.werk_id}} </span>
      {{ markierer.vorname }} {{ markierer.nachname }}
      {{ email }}
      <span class="date">
        {{
          new Date(nugget.timestamp).toLocaleString("de", {
            dateStyle: "short",
          })
        }}
      </span>

      <div class="icons">
        <Delete v-if="false" class="icon" />
      </div>
    </div>
  </div>
</template>

<script>
//import Arrow from "../assets/Icons/arrow-right-light.svg";

import Delete from "../assets/Icons/trash-regular.svg";
import userService from "../services/user.service";
import werkeService from '../services/werke.service';

export default {
  name: "WerkSlot",
  //props: ["werk"],
  props: {
    nugget: Object,
    admin: Boolean,
    header: Boolean,
  },
  components: {
    // Arrow,

    Delete,
  },
  data() {
    return {
      werk: null,
      markierer: null,
      email: "",
    };
  },

  async mounted() {
   
    this.werk = await werkeService.getWerkById(this.nugget.werk_id)

    this.markierer = await userService.getBenutzerByID(
      this.nugget.markierer_id
    );
    this.email = await userService.getMail(this.nugget.markierer_id);

    console.log("Create Nugget", this.markierer);
  },

  computed:{
    vorname(){
      return this.markierer.vorname
    },
    nachname(){
      return this.markierer.nachname
    }
  }
};
</script>

<style lang="scss" scoped>
.blog-card {
  
  position: relative;
  white-space: pre-line;
  :hover {
  }
  .icons {
    display: flex;

    align-content: center;
    z-index: 99;
    float: right;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      //background-color: #fff;
      transition: 0.5s ease all;
      &:hover {
        background-color: #303030;
        .edit,
        .delete {
          path {
            fill: #fff;
          }
        }
      }
      &:nth-child(1) {
        margin-right: 8px;
      }
      .edit,
      .delete {
        pointer-events: none;
        height: 15px;
        width: auto;
      }
    }
  }

  .titel {
    font-size: 36px;
  }
  .autor {
    font-size: 18px;
  }
  .grund {
    color: #000000;
    padding: 20px;
    font-size: 18px;
    font-weight: bold;
  }

  //background-color: #fff;

  transition: 0.3s ease all;

  &:hover {
    //transform: rotateZ(-1deg) scale(1.01);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background-color: rgb(248, 247, 245);
  }
  div {
    .id,
    .title,
    .author,
    .date,
    .username {
      // width: 30%;
      font-size: calc(0.5rem + 0.5vw);
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 1; /* fallback */
      max-height: 200px; /* fallback */
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;
    }

    .props {
      display: flex;

      .col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //padding-right: 10px;
      }
      .col1 {
        color: gray;
        float: right;
      }
    }
  }

  .header {
    position: relative;
    top: 0;
    span {
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    z-index: 3;
    //padding: 10px 16px;
    color: #000;

    span {
      height: 50px;

      white-space: normal;
      word-break: break-word;
      display: flex;
      align-items: center;
    }

    .link {
      display: inline-flex;
      align-items: center;
      margin-top: auto;
      font-weight: 500;

      font-size: 12px;

      transition: 0.5s ease-in all;
      &:hover {
        color: rgba(48, 48, 48, 0.8);
      }
      .arrow {
        width: 10px;
      }
    }
  }
}
</style>
