<template>
  <div class="container">
    <div class="blog-card-wrap">
      <div class="sort-search-wrapper"></div>
      <div class="found">
        <h1 class="">Gesperrte Werke</h1>
        <div class="sort-search-wrapper">
          <div class="sort-wrapper">
            <span>Sortieren nach </span>
      
            <button
              type="button"
              name="Datum"
              value="datum"
              @click="sortByThis('date')"
            >
              Datum <span v-if="this.asc">&#9650;</span
              ><span v-else>&#9660;</span>
            </button>
          </div>
          <!--div class="search-wrapper">
            <input
              type="text"
              v-model="search"
              @keyup="resetList()"
              placeholder="Suche.."
              class="search-input"
            />
          </div-->
        </div>
        Es wurden <strong>{{ this.sperrungenList.length }} Einträge</strong>
        gefunden
      </div>

      <div class="blog-cards">
        <div
          class="card"
          v-for="sperrung in sperrungenList"
          :key="sperrung.sperrung_id"
        >
          <WerkCard :admin="true" :sperrung="sperrung" />
          <div class="icons">
            <EntsperrenWerk
              :werkid="sperrung.werk_id"
              @ok="doEntsperren(sperrung)"
            />
            <DeleteWerk :werkid="sperrung.werk_id" @ok="doDelete(sperrung)" />
            <button @click="werkBearbeiten(sperrung.werk_id)">
              Bearbeiten
            </button>
          </div>
        </div>
        <div class="scrolling-component"></div>
      </div>
    </div>
  </div>
</template>

<script>
import WerkCard from "../../components/WerkGesperrt.vue";
import werkeService from "../../services/werke.service";

import DeleteWerk from "../../dialogs/werk/DeleteWerk.vue";
import EntsperrenWerk from "../../dialogs/werk/EntsperrenWerk.vue";

//import { werkeFiltered } from "../services/werke.service";
//import userService from "../../services/user.service"
export default {
  name: "admin-werke",
  components: { WerkCard, DeleteWerk, EntsperrenWerk },
  data() {
    return {
      search: "",
      amount: 8,
      sperrungList: [],
      allFilteredWerke: [],
      werkeToShow: [],

      sortBy: "date",
      asc: false,
      sperrungenList: [],
    };
  },
  async created() {
    // this.sperrungenList = await werkeService.getSperrungen();
    //this.werkeToShow = await this.getWerkeToShow();
    // window.addEventListener("scroll", this.handleScroll);

    await this.resetList();
  },
  methods: {
    async doEntsperren(sperrung) {
      await werkeService.werkEntsperren(sperrung.werk_id);
      this.resetList();
      // this.werkeToShow.splice(
      //   this.werkeToShow
      //     .map(function(e) {
      //       return e.sperrung_id;
      //     })
      //     .indexOf(sperrung.sperrung_id),
      //   1
      // );
      this.$store.state.admin.showFeedback = true;
      await this.$store.dispatch("admin/loadWerkeGesperrt");
      setTimeout(() => {
        this.$store.state.admin.showFeedback = false;
      }, 5000);
    },

    async doDelete(sperrung) {
      await werkeService.werkLöschen(sperrung.werk_id);
      console.log("WERK DELETE");

      await this.$store.dispatch("admin/loadWerkeGesperrt");
      this.resetList();
    },

    werkBearbeiten(id) {
      this.$router.push({
        name: "AdminEditWerk",
        params: { werkid: id },
      });
    },

    sortByThis(string) {
      console.log(string);
      if (this.sortBy == string) {
        this.asc = !this.asc;
      }
      this.sortBy = string;

      this.resetList();
    },
    async spliceList(index) {
      console.log("splice", index);
      const filtersList = this.sperrungList.filter(
        (element) => element !== index
      );
      this.sperrungList = filtersList;
      this.werkeToShow = [];
      this.werkeToShow = await this.getWerkeToShow();
    },
    async resetList() {
      console.log("RESET___________________________");
      // this.werkeToShow = [];
      // console.log("Reset Werke List!", this.werkeToShow);
      //  this.werkeToShow = await this.getWerkeToShow();

      this.sperrungenList = await werkeService.getSperrungen();

      if (this.sortBy == "date" || this.sortBy == "") {
        this.sperrungenList =    this.sperrungenList.sort((a, b) => {
          console.log("soort", a, this.asc);

          var dateA = new Date(a.timestamp);
          var dateB = new Date(b.timestamp);
          return this.asc ? dateA - dateB : dateB - dateA;
        });
      }
    },
    async getWerkeToShow() {
      this.allFilteredWerke = await this.werkeFiltered();
      // console.log("all filtered werke", this.allFilteredWerke);
      var ret = [];

      var lastIndex = this.werkeToShow.length;
      var newLength =
        this.allFilteredWerke.length - this.amount < this.werkeToShow.length //if
          ? this.allFilteredWerke.length
          : lastIndex + this.amount;
      console.log("NEW LENGTH", newLength);
      for (let i = lastIndex; i < newLength; i++) {
        ret.push(this.allFilteredWerke[i]);
      }

      return ret;
    },

    async handleScroll() {
      if (
        window.scrollY + window.innerHeight >=
          document.body.scrollHeight - window.innerHeight &&
        !(this.werkeToShow.length >= this.allFilteredWerke.length)
      ) {
        console.log("load new werke");
        this.werkeToShow = [
          ...this.werkeToShow,
          ...(await this.getWerkeToShow()),
        ];
      }
    },
    async werkeFiltered() {
      console.log("GETTING WERKE");
      var filteredWerke = [];

      // Checkboxes

      for (var w of this.$store.state.admin.werkeGesperrt) {
        let werk = await werkeService.getWerkById(w.werk_id);

        if (this.wb ? werk.Wettbewerb : !werk.Wettbewerb) {
          filteredWerke.push(w);
        }
      }

      filteredWerke = filteredWerke.filter((post) => {
        console.log(post, this.search);
        var user = this.$store.state.admin.benutzerAll.filter((b) => {
          return b.benutzerId == post.sperrer_id;
        })[0];

        var werk = this.$store.state.admin.werkeAll.filter((b) => {
          return b.id == post.werk_id;
        })[0];

        console.log(user, werk);
        var includes =
          (post.grund
            ? post.grund.toLowerCase().includes(this.search.toLowerCase())
            : false) ||
          (user
            ? user.nachname.toLowerCase().includes(this.search.toLowerCase())
            : false) ||
          (user
            ? user.vorname.toLowerCase().includes(this.search.toLowerCase())
            : false) ||
          (werk
            ? werk.titel.toLowerCase().includes(this.search.toLowerCase())
            : false) ||
          (werk
            ? werk.autor.toLowerCase().includes(this.search.toLowerCase())
            : false);
        return includes;
      });

      if (this.sortBy == "date" || this.sortBy == "") {
        filteredWerke = filteredWerke.sort((a, b) => {
          console.log("soort", a, this.asc);

          var dateA = new Date(a.timestamp);
          var dateB = new Date(b.timestamp);
          return this.asc ? dateA - dateB : dateB - dateA;
        });
      }

      if (this.sortBy == "id") {
        filteredWerke = filteredWerke.sort((a, b) => {
          return this.asc ? a.werk_id - b.werk_id : b.werk_id - a.werk_id;
        });
      }

      return filteredWerke;
    },
    getWerk(id) {
      return this.$store.state.admin.werkeAll.filter((werk) => {
        return werk.id === id;
      });
    },
  },

  computed: {
    wb() {
      return this.$store.state.admin.wb;
    },
    editWerk: {
      get() {
        return this.$store.state.editWerk;
      },
      set(payload) {
        this.$store.commit("toggleEditWerk", payload);
      },
    },
    profileAdmin() {
      return true; //this.$store.state.profileAdmin;
    },
  },
  beforeDestroy() {
    this.checkedAnwendungen = [];
    this.checkedThemen = [];
    this.checkedGenres = [];
    this.$store.commit("toggleEditWerk", false);
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    wb() {
      // Our fancy notification (2).
      this.resetList();
    },
  },
};
</script>

<style lang="scss" scoped>
.found {
  text-align: center;
  font-size: 1.1em;
  padding-bottom: 20px;
}
.sort-search-wrapper {
  padding-top: 20px;
  padding-left: 2vw;
  padding-right: 2vw;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-wrapper {
  display: inline-block;
  margin-right: 0;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 50px;
  right: 0;
  input {
    border-radius: 10px;

    max-width: 800px;
    padding: 10px;
    font-size: 18px;
  }
}
.filterBereich {
  padding-top: 20px;
  padding: 20px;
  //max-width: 1200px;
  height: 100%;
  display: flex;

  align-content: center;
  column-gap: 5vw;
  padding-bottom: 20px;
  font-size: calc(0.6em + 0.5vw);
  margin: 0 auto;
  flex-wrap: wrap;

  .filterspalte {
    width: calc(25% + 50px);
    overflow: visible;
    padding-top: 30px;
    span {
      font-size: calc(1em + 1vw);
    }
    label {
      white-space: nowrap;
      vertical-align: middle;
      position: inherit;
    }

    input {
      transition: 750ms ease all;
      appearance: none;
      border: none;
      // background: #fff;
      outline: none;
      background-color: #fafafa;
      border: 1px solid #cacece;
      box-shadow: 0 0px 2px rgba(0, 0, 0, 0.5),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 10px;
      vertical-align: middle;
      // border-radius: 30px;
      display: inline-block;
      position: relative;
      //height: 30px;
      // border-radius: 100px;
    }

    input:checked {
      content: "\2714";
      padding-left: 25px;
      //position:absolute;
      color: #000000;
      background-color: #195073;
    }

    .field {
      white-space: nowrap;
    }
    .field:hover {
      font-weight: bold;
    }
  }
}

@media (min-width: 1200px) {
  .filterBereich {
    justify-content: center;
  }
}

@media (max-width: 750px) {
  .filterBereich {
    //justify-content: space-between;
    padding-right: 10px;
  }
}

.sort-wrapper {
  vertical-align: middle;
  height: 100px;
  button {
    margin-left: 10px;
    margin-right: 10px;
    height: 30px;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;

    //vertical-align: middle;
  }
}
.blog-cards {
  padding: 50px;
  padding-bottom: 200px;
  position: relative;
  .icons {
    display: flex;

    align-content: center;
    z-index: 99;

    padding-bottom: 50px;
  }
  .toggle-edit {
    display: flex;
    align-items: center;
    position: absolute;
    top: -70px;
    right: 1;

    span {
      margin-right: 16px;
    }
    input[type="checkbox"] {
      position: relative;
      border: none;
      -webkit-appearance: none;
      background: #fff;
      outline: none;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    input[type="checkbox"]:before {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      top: 0;
      left: 0;
      background: #303030;
      transform: scale(1.1);
      transition: 750ms ease all;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    input:checked[type="checkbox"]:before {
      background: #fff;
      left: 52px;
    }
  }
}
</style>
