<template>
  <div class="icon">
    <button class="delete-btn" @click="handle">Entsperren</button>
    <confirm-dialog ref="confirmDialogue"></confirm-dialog>
  </div>
</template>


<script>
import werkeService from '../../services/werke.service';
import ConfirmDialog from "../ConfirmDialog.vue";

export default {
  name: "EntsperrenWerk",

  components: { ConfirmDialog },
  props: {
    werkid: Number,
  },
  data() {
    return {
      werk: null,
    };
  },
  async created() {
    if (this.werkid == null) {
      this.werk = {
        titel: "-",
        autor: "-",
        id: "-",
      };
    } else {
      this.werk = await werkeService.getWerkById(this.werkid)
    }
  },

  methods: {
    async handle() {
      const ok = await this.$refs.confirmDialogue.show({
        title: "Werk entsperren",
        message:
          "<p>Soll dieses Werk wieder entsperrt werden? </p> <strong>" +
          this.werk.Titel +
          " // " +
          this.werk.Autor +
          " <br> id: " +
          this.werk.WerkId +
          "</strong>",
        okButton: "Wieder entsperren",

        cancelButton: "abbrechen",
        feedback: "Werk entsperrt"
      });
      if (ok) {
        this.$emit("ok");

      }
    },
  },
};
</script>

<style>
</style>