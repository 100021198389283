<template>
  <div class="wrap best-card">


  
    <span class="big-number">{{ nummer }}.</span>
    <div class="">
      <div class="info">
        <span class="serif title">{{ werk.Titel }}</span>
        <span class="author">von {{ werk.Autor }}</span>

        <span class="date">
          hochgeladen von
          <router-link
            class="link"
            :to="{
              name: 'BenutzerView',
              params: { benutzerid: werk.BenutzerID },
            }"
            :title="werk.Titel"
            >{{ werk.Vorname }} {{ werk.Nachname }}</router-link
          >
          am
          {{
            new Date(werk.Timestamp).toLocaleString("de", {
              dateStyle: "long",
            })
          }}
        </span>
        <div v-if="this.$store.getters['auth/admin']">
    {{werk.Rating}} </div>
      </div>
    </div>
  </div>
</template>

<script>
//import Arrow from "../assets/Icons/arrow-right-light.svg";

export default {
  name: "WerkCard",
  //props: ["werk"],
  props: {
    werk: Object,
    list: Array,
    nummer:Number,
  },
  components: {
    // Arrow,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    nummerXXX() {
      var num =
        this.list
          .map(function (e) {
            return e.id;
          })
          .indexOf(this.werk.WerkId) + 1;
      console.log(num);
      return num;
    },

    _anwendung() {
      var a = this.$store.state.anwendungen.filter((anw) => {
        return anw.id === this.werk.anwendung;
      });

      if (a.length == 0) return "-";
      else return a[0].name;
    },
    _thema() {
      var t = this.$store.state.themen.filter((th) => {
        return th.id === this.werk.thema;
      });

      if (t.length == 0) return "-";
      else return t[0].name;
    },
    _genre() {
      var g = this.$store.state.genres.filter((gen) => {
        return gen.id === this.werk.genre;
      });

      if (g.length == 0) return "-";
      else return g[0].name;
    },
    editWerk() {
      return this.$store.state.editWerk;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  flex-direction: row;
  /* align-content: space-between; */
  flex-wrap: none;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.big-number {
  font-size: 2em;
  margin: 10px;
  vertical-align: top;
}
.best-card {
  //border: 1px solid grey;
  margin: 0 auto;
  padding-left: 10px;
  //margin-left:30vw;
  width: 90%;
  //text-align: center;
  position: relative;
  white-space: pre-line;
  max-width: 600px;
  .icons {
    display: flex;
    position: absolute;
    align-content: center;
    z-index: 99;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #fff;
      transition: 0.5s ease all;
      &:hover {
        background-color: #303030;
        .edit,
        .delete {
          path {
            fill: #fff;
          }
        }
      }
      &:nth-child(1) {
        // margin-right: 8px;
      }
      .edit,
      .delete {
        pointer-events: none;
        height: 15px;
        width: auto;
      }
    }
  }

  .card-header {
    //width: 100%;
    font-size: 13px;
    //padding: 0px 10px 10px 10px;
  }

  //box-shadow: 0px 1px 0px 0px rgba(100, 100, 100, 50%);
  border-bottom: 1px solid grey;
  transition: 0.5s ease all;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    //transform: rotateZ(-1deg) scale(1.01);
    background-color: rgb(202, 214, 228);
  }
  div {
    .title {
      font-size: calc(0.5vw + 15px);
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 1.8; /* fallback */
      max-height: 300px; /* fallback */
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;
      text-align:left;
    }
    .author {
      color: #333;
      font-style: bold;
      float: right;
      font-size: calc(0.5vw + 12px);
    }
    .date {
      margin-right: 10px;
      float: right;
      font-size: calc(0.5vw + 8px);
      font-style: bold;
    }
    .preview-container {
      height: 200px;
      min-height: 100px;
      display: inline-flex;
      align-items: center;
      padding-right: 30px;

      .card-preview {
        //white-space: nowrap;

        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 1.8; /* fallback */
        max-height: 100%; /* fallback */
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
        vertical-align: -webkit-baseline-middle;
      }
    }
    .props {
      display: flex;
      font-size: calc(12px);

      .col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 10px;
      }
      .col1 {
        color: gray;
        float: right;
      }
    }
  }

  img {
    display: block;
    border-radius: 8px 8px 0 0;
    z-index: 1;
    width: 100%;
    min-height: 200px;
    object-fit: cover;
  }
  .info {
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 3;
    padding: 10px 16px;
    color: #000;
    h4 {
      padding-bottom: 8px;
      font-size: 20px;
      font-weight: 300;
    }
    h6 {
      font-weight: 400;
      font-size: 12px;
      padding-bottom: 16px;
    }
    .link {
      display: inline-flex;
      align-items: center;

      font-weight: 500;
      padding-top: 20px;
      font-size: 12px;
      padding-bottom: 4px;
      transition: 0.5s ease-in all;
      &:hover {
        color: rgba(48, 48, 48, 0.8);
      }
      .arrow {
        width: 10px;
      }
    }
  }
}
</style>
