<template>
<div>
   
     <popup-modal ref="popup">
        <h2 style="margin-top: 0">{{ title }}</h2>
        <p v-html="message"></p>
        <div class="btns">
            <button v-if="this.cancelButton" class="cancel-btn" @click="_cancel">{{ cancelButton }}</button>
            <span  v-if="this.okButton" class="ok-btn" @click="_confirm">{{ okButton }}</span>
        </div>
        
    </popup-modal>
    </div>
</template>

<script>
import PopupModal from './PopupModal.vue'

export default {
    name: 'ConfirmDialogue',

    components: { PopupModal },

    data: () => ({
        // Parameters that change depending on the type of dialogue
        title: undefined,
        message: undefined, // Main text content
        okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
        cancelButton: undefined, // text for cancel button
        feedback: "Aktion erfolgt",
        showFeedback: false,
        // Private variables
        resolvePromise: undefined,
        rejectPromise: undefined,

    }),

    methods: {
        show(opts = {}) {
            this.title = opts.title
            this.message = opts.message
            this.okButton = opts.okButton
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }
            // Once we set our config, we tell the popup modal to open
            this.$refs.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },

        _confirm() {
            this.$refs.popup.close()
                          this.showFeedback=true
                  setTimeout(() => {
                      this.showFeedback=false;
    
         
                          
      }, 5000);

   this.resolvePromise(true)
        },

        _cancel() {
            this.$refs.popup.close()
            this.resolvePromise(false)
            // Or you can throw an error
            // this.rejectPromise(new Error('User cancelled the dialogue'))
        },
    },
}
</script>

<style lang="scss" scoped>


.btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 999;
    position: relative
}
.ok-btn {
  padding: 0.5em 2em;
  color: black;
  text-decoration: none;

  border-radius: 10px;
  cursor: pointer;
}

.cancel-btn {
  padding: 0.5em 1em;
  background-color: white;
  color: black;
  border: 1px solid rgb(100, 100, 100);
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: rgb(46, 45, 45);
  }
}
</style>