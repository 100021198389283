<template>
  <div class="favorit" >
    
 
    <confirm-dialog ref="confirmDialogue"></confirm-dialog>
  </div>
</template>


<script>
import ConfirmDialog from "../ConfirmDialog.vue";


export default {
  name: "Kommentar_Melden",

  components: { ConfirmDialog },
  props: {
    message: String,
    title:String,
    ok:String,
    cancel:String
  },
  mounted() {

    this.handle();
    console.log("DIALOG OPEN");
  },


  methods: {
    async handle() {
      console.log("HANDLE BUTTON");
      const ok = await this.$refs.confirmDialogue.show({
       
        title: this.title,
        message: this.message,
        okButton: this.ok,
        cancelButton: this.cancel
      });
      if (ok ) {
        this.$emit("ok");
      } else {
        
        this.$emit("cancel");
      }
    },
  },
};
</script>

<style lang="scss">
.fade-text {
  transition: 0.2s all;
  opacity: 0;
  font-size: 12px;
  color: blue($color: #000000);
  overflow: visible;
  width: 80px;
}
.favorit:hover .fade-text {
  opacity: 1;
}
</style>