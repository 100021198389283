<template>
  <div class="welcome">
    <h1 class="center">Presse</h1>
    <p class="center">
      Hier haben Sie die Möglichkeit, Zeitungsartikel zum Wettbererb 2022 herunter zu laden und nachzulesen:
    </p>


      <p class=" center">
        <a
        :href="`${publicPath}print/HAZ 13.07.22 Bekanngabe Preisträger.pdf`" download="HAZ 13.07.22 Bekanngabe Preisträger.pdf"><h2> 
          HAZ 13.07.22 Bekanngabe Preisträger.pdf (810 KB)</h2></a
        >
      
        <a
        :href="`${publicPath}print/Deister2022.jpg`" download="05.04.2022: LDZ.jpg"><h2>
         05.04.2022: LDZ (1.8 MB)</h2></a
        >
      </p>
<div class="gruss">

<h1>Grusswort des Oberbürgermeisters
</h1>
     <h2> 9. Hildesheimer Literatur-Wettbewerb</h2>
<span> Hildesheim ist zweifellos eine Literaturstadt: In unserer Stadt kann das Schreiben,
genaugenommen das literarische Schreiben, sogar studiert werden und immer wieder
gehen ausgezeichnete Schriftstellerinnen und Schriftsteller aus diesem Studiengang
hervor. Es gibt ein Literaturhaus, ein Literaturfestival, sogar eine Literaturzeitschrift – alles
„made in Hildesheim“.</span> <span>
All dies kann aber auch abschrecken. Denn nicht jeder, der Gedichte verfasst, möchte
gleich ein Studium beginnen und nicht jede, die gerne Texte schreibt, strebt einen
Buchpreis an. Es braucht bei all der Professionalisierung in unserer Stadt somit auch
Formate für kreative Nachwuchs-Autorinnen und Autoren und solche, die einfach nur
Freude am Schreiben oder Lesen haben. Genau dies bietet das Forum-Literaturbüro. Es
entwickelt niedrigschwellige Formate, die dazu anregen, sich mit den vielfältigen Formen
der Literatur zu befassen.</span> <span>
Die Literatur-Apotheke macht die Kreativität von Einzelnen zugänglich und fördert den
Austausch zwischen den Autorinnen und Autoren. Mit dem Literatur-Wettbewerb wird
zudem die Möglichkeit geboten, das Können mit anderen zu messen. Der diesjährige
Hildesheimer Literatur-Wettbewerb steht unter dem Motto „Über Wasser - Über Erde - Über
Uns“. Schreibende aller Generationen wurden eingeladen, sich zu diesem Thema mit
eigenen Texten zu beteiligen. Zahlreiche Beiträge, Gedichte und Prosa sind auf dem
Wettbewerbsportal der Hildesheimer Literatur-Apotheke eingegangen. Sie alle zeigen,
welch großer Beliebtheit sich die Literatur-Apotheke erfreut.</span> <span>
Ich danke Jo Köhler und seinem Team, der Jury und allen, die sich am Wettbewerb beteiligt
haben. Aus der großen Anzahl an Einsendungen eine Wahl zu treffen war mit Sicherheit
keine einfache Aufgabe. Ich bin gespannt auf die Ergebnisse. </span>
<p>
Mit herzlichen Grüßen
</p>
<p>Ihr <br>
Dr. Ingo Meyer <br>
Oberbürgermeister</p>

      <img
        src="../../assets/Portraitbild_OB_2018.jpg"
        width="400px"
        class="img-fluid"
        alt="Responsive image"
      />
   
  </div></div>
</template>

<script>
export default {
  components: {},
  name: "Überuns",

  data() {
    return {
      
  publicPath: process.env.BASE_URL

    };
  },
  computed: {},
  
};
</script>

<style lang="scss" scoped>
.gruss {
  padding-bottom: 100px;
  h2 {
    text-align: center;
  }
  img {
    width: 250px;
    //margin-left:25px;

    margin-top: 25px;
    border-radius: 100% 75% 100% 75%;
  }
}
h1 {
  //padding: 30px;

  font-size: 50px;
  font-weight: lighter;
}

.welcome {
  padding: 50px;
  max-width: 1200px;

  margin: 0 auto;

  p{
    padding: 20px;
  }
}
.blog-card-wrap {
  padding: 50px;
  // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.stamm {
  width: 100%;
  padding: 20px;
  max-width: 600px;
}
.updates {
  .container {
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
    //align-items: center;
    @media (min-width: 800px) {
      padding: 15px 20px;
      flex-direction: row;
    }

    .router-button {
      display: flex;
      font-size: 14px;
      text-decoration: none;
      @media (min-width: 800px) {
        margin-left: auto;
      }
    }
  }
}
.blog-cards {
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr;
  @media (min-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>