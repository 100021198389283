<template>

  <div class="blog-wrapper paper" :class="{ 'no-user': !user }">
    <div class="blog-content">
      <div class="werk-container serif">
        <h2>{{ werk.Titel }}</h2>

        <p class="content-preview" v-html="werk.Inhalt"></p>
      </div>
      <div class="wl-container">
    
          <p>weiterlesen
          <Arrow class="arrow arrow"/></p>
    
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from "../assets/Icons/arrow-right-light.svg";
export default {
  name: "WerkPost",
  props: ["werk"],
  components: {
    Arrow,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
};
</script>

<style lang="scss" scoped>
.blog-wrapper {
  transition: 0.2s all;
  display: flex;
  flex-direction: column;
border-radius: 10px;

  @media (min-width: 700px) {
    min-height: 650px;

    flex-direction: row;
  }

  .blog-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    order: 2;

    @media (min-width: 700px) {
      // order: 1;
    }
    @media (min-width: 800px) {
      // flex: 1;
    }

    .werk-container {

      padding: 10px 50px 0px;
      height: 400px;
      vertical-align: middle;
      //width: 95%;

      @media (min-width: 700px) {
        // padding: 0 24px;
        //width: 50%;
      }

      h2 {

        font-size: 32px;
        font-weight: 300;

        margin-bottom: 24px;
        @media (min-width: 700px) {
          font-size: 40px;
        }
      }

      p {

        font-size: 18px;
        font-weight: 300;
        line-height: 1.7;

      }

      .content-preview {

        padding-left: 10px;
        white-space: pre-line;
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 1.8; /* fallback */
        max-height: 300px; /* fallback */
        -webkit-line-clamp: 5; /* number of lines to show */
        -webkit-box-orient: vertical;
        max-width: 500px;
        padding-top: 50px;
      }
    }

      .wl-container{
        padding-bottom: 30px;
        
              .link {
        display: inline-flex;
        align-items: center;
        margin-top: 32px;
        padding-bottom: 4px;
        border-bottom: 1px solid transparent;
        transition: 0.5s ease-in all;

        &:hover {
          border-bottom-color: #303030;
        }
      }
      .link-light {
        &:hover {
          border-bottom-color: #ffff;
        }
      }
    }

    .welcome-div {
      padding: 72px 24px;
      width: 95%;
      @media (min-width: 700px) {
        padding: 0 24px;
        width: 80%;
      }
    }
  }

  .blog-photo {
    order: 1;
    flex: 3;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    @media (min-width: 700px) {
      order: 2;
    }
    @media (min-width: 800px) {
      flex: 4;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &:nth-child(even) {
    .blog-content {
      order: 1;
    }
    .blog-photo {
      order: 1;
    }
  }
  &:hover{

    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);

  }

}
.no-user:first-child {
  .blog-content {
    //background-color: #fff;
    color: #000;
    font-weight: 500;
  }
}
</style>