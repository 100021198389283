<template>
  <div class="profile">
    <Modal
      v-if="modalActive"
      :modalMessage="modalMessage"
      v-on:close-modal="closeModal"
    />
    <div v-if="isLoggedIn" class="container">
      <h2>Benutzer bearbeiten</h2>
      <div class="profile-info">
       

        <div class="input">
          <label for="firstName">Vorname:</label>
          <input type="text" id="firstName" v-model="currentUser.vorname" />
        </div>
        <div class="input">
          <label for="lastName">Nachname:</label>
          <input type="text" id="lastName" v-model="currentUser.nachname" />
        </div>
         <div class="input">
          <label for="lastName">Geburtsjahr:</label>
          <input type="text" id="lastName" v-model="currentUser.geburtsjahr" />
        </div>
         <div class="input">
          <label for="lastName">Wohnort:</label>
          <input type="text" id="lastName" v-model="currentUser.ort" />
        </div>
         <div class="input">
          <label for="lastName">Beruf:</label>
          <input type="text" id="lastName" v-model="currentUser.beruf" />
        </div>
     
       
        <div class="input">
          <label for="email">Email:</label>
          <input disabled type="text" id="email" v-model="currentUser.email" />
        </div>
             <div class="input">
          <label for="username">Vita:</label>
          <textarea rows="10" cols="70" type="text" id="username" v-model="currentUser.vita" ></textarea>
        </div>
              <div class="input">
          <label for="nl">Newsletter:</label>
          <input type="checkbox" id="nl" v-model="currentUser.newsletter" />
    
        </div>
        <button @click="updateProfile">Änderungen speichern</button>
      </div>
    </div>
    <div class="container" v-else>Sie sind nicht eingeloggt!</div>

    <WerkPost :werk="werk" v-for="(werk, index) in userWerke" :key="index" />
  </div>
</template>

<script>
import axios from "axios";
import Modal from "../../components/Modal";
import WerkPost from "../../components/WerkPost";
import userService from '../../services/user.service';
export default {
  name: "Profile",
  components: {
    Modal,
    WerkPost,
  },
  data() {
    return {
      modalMessage: "Changes were saved!",
      modalActive: null,
      initials: "XX",
      currentUser:null,
      backupUser:null
    }
  },
 async created(){
     this.routeID = this.$route.params.benutzerid;
    this.currentUser = await userService.getBenutzerByIDAdmin(this.routeID)
    //this.currentUser = this.currentUser[0];
console.log(this.currentUser);
    this.backupWerk = Object.assign({}, this.currentUser);

    console.log("created");
  },
  mounted() {
    this.initials = this.profileInitials;
  },
  methods: {

   

    async updateProfile() {

         if(this.currentUser.vorname==""|| this.currentUser.nachname==""||this.currentUser.ort==""|| this.currentUser.geburtsjahr <1900|| this.currentUser.geburtsjahr>2020){

         


       
        this.$store.state.admin.feedback = "unvollständige Angaben";
        this.$store.state.admin.showFeedback = true;
           setTimeout(() => {
        this.$store.state.admin.showFeedback = false;
           this.$store.state.admin.feedback = "Aktion erfolgt";
      }, 5000);

         return
         }
    
            var info = {
        BenutzerId:this.currentUser.benutzerId,
        Vorname: this.currentUser.vorname,
        Nachname: this.currentUser.nachname,
 

        Vita: this.currentUser.vita,
        Ort: this.currentUser.ort,
        Beruf:this.currentUser.beruf,
        Geburtsjahr:this.currentUser.geburtsjahr,
        Newsletter:this.currentUser.newsletter
      }
      await axios
        .put(process.env.VUE_APP_API_URL + "benutzer", info).then(r=>{
          console.log(r);
          
        }).catch(error => { console.log(error); })
      this.modalActive = !this.modalActive;
    },
    closeModal() {
      this.modalActive = !this.modalActive;
    },
  },
  computed: {
   user: {
      get() {
        return this.currentUser;
      },
      set(payload) {
        this.currentUser = payload;
      },
   },

    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    userWerke() {
      return this.$store.getters.getUserWerke(this.user.benutzerId);
    },
        role(){
      return  this.$store.state.userInfo.vita;
    },

    vorname: {
      get() {
        return this.$store.state.userInfo.vorname;
      },
      set(payload) {
        this.$store.commit("userInfo/changeVorname", payload);
        this.initials = this.profileInitials;
      },
    },
    nachname: {
      get() {
        return this.$store.state.userInfo.nachname;
      },
      set(payload) {
        this.$store.commit("userInfo/changeNachname", payload);
        this.initials = this.profileInitials;
      },

 
    },
     vita: {
      get() {
        return this.$store.state.userInfo.vita;
      },
      set(payload) {
        this.$store.commit("userInfo/changeVita", payload);
  
      },

    },
    profileInitials() {
      this.$store.commit("userInfo/setProfileInitials");
      return this.$store.state.userInfo.profileInitials;
    },
    benutzerId() {
      return this.$store.state.userInfo.benutzerId;
    },
    username: {
      get() {
        return this.$store.getters["auth/getUser"].username;
      },
      set(payload) {
        this.$store.commit("auth/changeUsername", payload);
      },
    },
    email() {
      return this.$store.getters["auth/getUser"].email;
    },
   }
  }
</script>
<style lang="scss" scoped>
.profile {
  .container {
    max-width: 1000px;
    padding: 60px 25px;
    h2 {
      text-align: center;
      margin-bottom: 16px;
      font-weight: 300;
      font-size: 32px;
    }
    .profile-info {
      border-radius: 8px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
      padding: 32px;
      background-color: #f1f1f1;
      display: flex;
      flex-direction: column;
      max-width: 600px;
      margin: 32px auto;
      .initials {
        position: initial;
        width: 80px;
        height: 80px;
        font-size: 32px;
        background-color: #303030;
        color: #fff;
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      .admin-badge {
        display: flex;
        align-self: center;
        color: #fff;
        font-size: 14px;
        padding: 8px 24px;
        border-radius: 8px;
        background-color: #303030;
        margin: 16px 0;
        text-align: center;
        text-transform: capitalize;
        .icon {
          width: 14px;
          height: auto;
          margin-right: 8px;
        }
      }
      .input {
        margin: 16px 0;
        label {
          font-size: 14px;
          display: block;
          padding-bottom: 6px;
        }
        input {
          width: 100%;
          border: none;
          background-color: #f2f7f6;
          padding: 8px;
          height: 50px;
          @media (min-width: 900px) {
          }
          &:focus {
            outline: none;
          }
        }
      }
      button {
        align-self: center;
      }
    }
  }
}
</style>