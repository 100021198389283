<template>
  <div class="welcome">
    <p>
      Hier haben Sie die Möglichkeit, verschiedene Zeitungsartikel zur
      Literatur-Apotheke herunter zu laden und nachzulesen:
    </p>
   


    <div class="text-left ml-5 mt-4">
      <h2>Literatur-Apotheke</h2>

      <p class="mt-4">
        <a
         :href="`${publicPath}print/TextArt August 2016 Wortpillen.jpg`" download="TextArt August 2016 Wortpillen.jpg">
     
       August 2016: Wortpillen.jpg</a
        >
      </p>

      <p class="mt-4">
        <a
         :href="`${publicPath}print/LDZ 11.04.16.pdf`" download="LDZ 11.04.16.pdf">
     
       11.04.2016: LDZ.pdf</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "Überuns",

  data() {
    return {
      
  publicPath: process.env.BASE_URL

    };
  },
  computed: {},
  
};
</script>

<style lang="scss" scoped>


</style>