import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
console.log("AUTH SERVICE--- User");
console.log(user);
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,

  getters:{
    isLoggedIn(state){
      console.log(state.status.loggedIn);
      return state.status.loggedIn
    },
    getUser(state){
      return state.user
    },
    admin(state){
      return state.user?state.user.roles.some(role =>{
        return role =="Admin"
      }):false
    }
  },
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {

          

          if(user.token){
          commit('loginSuccess', user);
          return Promise.resolve(user);
          } else if(user.oldUser){
          commit('loginFailure');
          return Promise.resolve(user);
        }else if(user.confirm){
          commit('loginFailure');
          return Promise.resolve(user)
        }else if(user.gesperrt){
          console.log("usergesperrt");
          commit('loginFailure');
          return Promise.resolve(user)
        }},
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    refreshToken({ commit }, accessToken) {
      commit('refreshToken', accessToken);
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
 
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
      
      
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },   
    refreshToken(state, accessToken) {
      state.status.loggedIn = true;
      state.user = { ...state.user, accessToken: accessToken };
    }
  }
};