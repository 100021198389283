<template>
  <div class="welcome">
    <div class="container">
      <h1>Teilnahmebedingungen</h1>
      <h2 align="">
        Hildesheimer Literatur-Wettbewerb 2024<br>
Motto: Über Grenzen
      </h2>
      <h3>Einsendefrist 01.03.2024 – 10.05.2024</h3>
      <ul>        <li>Einsendeschluss ist der 10.05.2024 um 23:59 Uhr.</li>
        <li>Die Teilnahme am Wettbewerb ist kostenlos.</li>
        <li>
          Jede:r Teilnehmer:in kann maximal 3 selbstverfasste
          Texte in deutscher Sprache über unser Online-Wettbewerbsformular
          einsenden (Bilder, Videos oder Audiodateien sind
          <strong>nicht zulässig</strong>). Bei fehlenden oder falschen Angaben
          im Online-Formular wird die Einreichung zurückgewiesen.
        </li>
        <li>Es ist sowohl Lyrik als auch Prosa möglich.</li>
        <li>
          Es sind nur Texte zulässig, die vorher noch nicht in unsere
          Literatur-Apotheke eingereicht wurden.
        </li>
        <li>
          Die Zeichenanzahl der eingereichten Beiträge darf 5000 Zeichen (mit
          Leerzeichen) für den eingereichten Text, 500 Zeichen für die
          Vita-Angaben und 1000 Zeichen für die Motivation zum Schreiben nicht
          überschreiten.
        </li>
        <li>
          Die teilnehmende Person bestätigt durch die Einsendung ihres
          Beitrages, dass sie selbst den eingesandten Text verfasst hat und
          folglich über alle Rechte verfügt.
        </li>
        <li>
          Das Urheberrecht der eingesandten Texte verbleibt bei der
          teilnehmenden Person.
        </li>
        <li>
          Der oder die Teilnehmer:in willigt mit seiner/ihrer Teilnahme ein,
          dass seine/ihre Beiträge im Rahmen unseres Wettbewerbes auch
          publiziert werden können.
        </li>
   
        <li>
          Die teilnehmende Person willigt mit seiner/ihrer Teilnahme ein, dass
          seine/ihre eingereichten Texte nach dem Wettbewerb in die
          LiteraturApotheke überführt werden und dort für ein Jahr verbleiben.
        </li>
        <li>
          Die Nichtbeachtung der Teilnahmebedingungen kann zum Ausschluss vom
          Wettbewerb führen.
        </li>
        <li>
          Ansonsten gilt das

          <a href="teilnahmebedingungen" target="_blank"
            >Reglement der Literatur-Apotheke</a
          >. Die Nichtbeachtung der Teilnahmebedingungen kann zum Ausschluss vom
          Wettbewerb führen.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Überuns",

  data() {
    return {};
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
h1 {
  //padding: 30px;
  text-align: left;

  font-weight: lighter;
  font-size: calc(20px + 1vw);
}

.welcome {
  padding-left: 3vw;
  padding-right: 3vw;
  max-width: 1200px;
}

.container {
  padding: 5%;

  font-size: calc(5px + 1vw);
  text-align: initial;

  li {
    padding-bottom: 5%;
  }
}

button {
  padding: 10px;
}
.stamm {
  width: 100%;
  padding: 20px;
  max-width: 600px;
}
</style>