<template>
  <div class="post-view">
    <div class="background paper">
      <div class="werk-container">
        <Werk :currentWerk="currentWerk" :notloggedin="true" />
        <div class="center p-10">
          <button class="button" @click="$router.go(-1)">zurück</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import Arrow from "../assets/Icons/arrow-right-light.svg";
import Werk from "../components/Werk.vue";
export default {
  name: "WerkPreview",

  data() {
    return {};
  },
  components: {
    // Arrow,
    Werk,
  },

  created() {},
  computed: {
    currentWerk() {
      console.log(this.titel);
      return {
        Titel: this.titel,
        Inhalt: this.inhalt,
        Motivation: this.motivation,
        Benutzerid: this.benutzerid,
        Anwendung: this.anwendung,
        Genre: this.genre,
        Thema: this.thema,
        Stamm: false,
        Timestamp: Date.now(),
        Autor: this.autor,
        Quelle: this.quelle,
      };
    },
    titel() {
      return this.$store.state.titel;
    },
    inhalt() {
      return this.$store.state.inhalt;
    },
    motivation() {
      return this.$store.state.motivation;
    },
    anwendung() {
      return this.$store.state.anwendung;
    },
    thema() {
      return this.$store.state.thema;
    },
    genre() {
      return this.$store.state.genre;
    },
    benutzerid() {
      return this.$store.state.userInfo.benutzerId;
    },
    autor() {
      return this.$store.state.autor;
    },
    quelle() {
      return this.$store.state.quelle;
    },
  },
};
</script>

<style lang="scss">
.header-area {
  width: 100%;
  height: 50px;
  display: inline-block;
  max-width: 100px;
  padding: 60px 25px;
}
.arrow-left {
  width: 10px;
  transform: rotate(180deg);
}
.post-view {
  margin: 0;
  display: flex;
  margin-top: 50px;
  //width: 80%;
  overflow: hidden;
  flex-direction: column;
  align-items: center;

  .background {
    //background: #fbfaf8;
    //background: #fbfaf8d5;
    width: min-content;
    margin-bottom: 50px;
    //min-width: 500px;
  }
  .container {
    min-width: 400px;
    padding: 60px 90px;
  }
  .ql-editor {
    padding: 0;
  }
  h2 {
    margin-bottom: 16px;
    font-weight: 300;
    font-size: 32px;
  }
  img {
    width: 100%;
    margin-bottom: 32px;
  }
}
</style>
