
import AdminLayout from "../layouts/Admin.vue"

import Admin from "../views/admin/Admin.vue";
import AdminWerke from "../views/admin/Admin_Werke.vue";
import AdminWerkeGesperrt from "../views/admin/Admin_Werke_Gesperrt.vue";
import AdminWerkeMarkiert from "../views/admin/Admin_Werke_Markiert.vue";
import AdminWerkeNuggets from "../views/admin/Admin_Werke_Nuggets.vue";
import AdminBenutzer from "../views/admin/Admin_Benutzer.vue";
import AdminBenutzerGesperrt from "../views/admin/Admin_Benutzer_Gesperrt.vue";
import AdminBenutzerMarkiert from "../views/admin/Admin_Benutzer_Markiert.vue";

import AdminKommentare from "../views/admin/Admin_Kommentare.vue";
import AdminEditBenutzer from "../views/admin/Admin_EditBenutzer.vue";
import AdminEditWerk from "../views/admin/Admin_EditWerk.vue";

import { Role }  from "../utils/helpers"

export default [
  
  {
    path: "/admin",
    name: "Admin",
    component: AdminLayout,
    meta: { authorize: [Role.Admin] } ,
    children: [
      {
        path: "/dash",
        name: "Admin_Dashboard",
        component: Admin,
        meta: {
          title: "admin",
        },

      }, 
      {
        path: "werke",
        name: "Admin_Werke",
        component: AdminWerke,
        meta: {
          title: "werke",
        },
      },
      
        {
          path: "werk-bearbeiten/:werkid",
          name: "AdminEditWerk",
          component: AdminEditWerk,
          meta: {
            title: "WerkBearbeiten"
          },
        },
        {
          path: "werke-gesperrt",
          name: "Admin_Werke_Gesperrt",
          component: AdminWerkeGesperrt,
          meta: {
            title: "werke-gesperrt",
          },
        },
        {
          path: "werke-markiert",
          name: "Admin_Werke_Markiert",
          component: AdminWerkeMarkiert,
          meta: {
            title: "werke-markiert",
          },
        },
        {
          path: "nuggets",
          name: "Admin_Werke_Nuggets",
          component: AdminWerkeNuggets,
          meta: {
            title: "werke-nuggets",
          },
        },
      {
        path: "benutzer",
        name: "Admin_Benutzer",
        component: AdminBenutzer,
        meta: {
          title: "benutzer",
        },
      },
      {
        path: "user-bearbeiten/:benutzerid",
        name: "AdminEditBenutzer",
        component: AdminEditBenutzer,
        meta: {
          title: "User Bearbeiten"
        },
      },
      {
        path: "benutzer_gesperrt",
        name: "Admin_Benutzer_Gesperrt",
        component: AdminBenutzerGesperrt,
        meta: {
          title: "benutzer-gesperrt",
        },
      },
      {
        path: "benutzer_markiert",
        name: "Admin_Benutzer_Markiert",
        component: AdminBenutzerMarkiert,
        meta: {
          title: "benutzer-markiert",
        },
      },
      {
        path: "kommentare_meldungen",
        name: "Admin_Kommentare",
        component: AdminKommentare,
        meta: {
          title: "benutzer-markiert",
        },
      },
    ]
  },

];



