<template>
  <div class="welcome">
    <h2 class="heading">
      IN DEN WIND GESCHRIEBEN
    </h2>

    <h3 class="heading">Literaturwettebwerb 2020</h3>

    <div class="container">
      <p>
        Unter dem Motto „In den Wind geschrieben" fand der Wettbewerb 2020 im
        Rahmen der Literatur-Apotheke statt.
      </p>
      <p>Die sieben Preisträger:innen 2020 in fünf Kategorien waren:</p>

      <ul>
        <li>
          Hauptpreis Lyrik:
          <router-link
            class="link"
            :to="{ name: 'WerkView', params: { werkid: 1624 } }"
            target="_blank"
            >Sigune Schnabel - Wenn der Sommer leiser wird:
          </router-link>
        </li>
        <li>
          Hauptpreis Lyrik:
          <router-link
            class="link"
            :to="{ name: 'WerkView', params: { werkid: 1784 } }"
            target="_blank"
            >Hans Jörg Stahlschmidt - Jetzt
          </router-link>
        </li>
        <li>
          Hauptpreis Lyrik:
          <router-link
            class="link"
            :to="{ name: 'WerkView', params: { werkid: 1584 } }"
            target="_blank"
            >Lieselotte Degenhardt - Träumer</router-link
          >
        </li>
        <li>
          Hauptpreis Junge Literatur:
          <router-link
            class="link"
            :to="{ name: 'WerkView', params: { werkid: 2360 } }"
            target="_blank"
            >Anna Glashagen - flüssighoffnung</router-link
          >
        </li>

        <li>
          Hauptpreis Prosa:
          <router-link
            class="link"
            :to="{ name: 'WerkView', params: { werkid: 2321 } }"
            target="_blank"
            >Marita Riedlinger - Endzeitlos</router-link
          >
        </li>
        <li>
          Hauptpreis International:
          <router-link
            class="link"
            :to="{ name: 'WerkView', params: { werkid: 2216 } }"
            target="_blank"
            >Isabel Folie - Zerfall</router-link
          >
        </li>

        <li>
          Publikumspreis:
          <router-link
            class="link"
            :to="{ name: 'WerkView', params: { werkid: 2262 } }"
            target="_blank"
            >Slavica Klimkowsky - Mein Sommer</router-link
          >
        </li>
      </ul>
      <p>
        Wir gratulieren von ganzem Herzen!
      </p>
      <br />
      <br />

      <div class="center">
    <h3 class="mt-4">Beitrag zum Anhören von Radio Tonkuhle:</h3>
			
	<audio controls>

  <source src="../audio/200420_1500_WSUV_Litbuero_IndenWind_fertig.mp3" type="audio/mpeg">
Your browser does not support the audio element.
</audio>
</div>

      <img
        class="title-image"
        src="..\assets\wettbewerbe\2020\Preistraeger2020.jpg"
        target="_blank"
        alt=""
      />
      <span>Bildunterschrift</span>
  
  
      <img
      class="title-image"
      src="..\assets\wettbewerbe\2020\artikel2020.jpg"
      alt=""
    />
    <span>Bildunterschrift</span>
    </div>

    <div class="heading">
      <a
        class="literapedia-link"
        href="https://literapedia-hildesheim.de/wettbewerb2020/"
        target="_blank"
      >
        <span> ...mehr in unserer Literapedia </span></a
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.heading {
  text-align: center;
}
ul {
  li {
    padding: 10px;
  }
}
</style>
