<template>
  <div class="welcome center">
    <p>
      Hier haben Sie die Möglichkeit, verschiedene Radiobeiträge über die
      Entwicklung und Fertigstellung der Literatur-Apotheke anzuhören:
    </p>

    <br /><br /><br /><br />

    <h4>Literatur-Apotheke Kulturium</h4>
    <h5 class=""></h5>
    <audio controls>
      <source
        src="../audio/Literatur- Apotheke_Kulturium.mp3"
        type="audio/mpeg"
      />
      Your browser does not support the audio element.
    </audio>
    <br /><br /><br /><br />

    <h4>Literaturapotheke im NDR</h4>
    <h5 class=""></h5>
    <audio controls>
      <source src="../audio/Literaturapotheke im NDR.mp3" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>

    <br /><br /><br /><br />

    <h4>Radio Tonkuhle 11.04.2016</h4>
    <h5 class=""></h5>
    <audio controls>
      <source src="../audio/Radio Tonkuhle 11.04.2016.mp3" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>

    <!--div class="text-left mt-5">
			<h2>Allgemein</h2>
			<div>

				<h4 class="mt-4">Die Literatur-Apotheke im NDR</h4>
				<iframe width="100%" height="166" scrolling="no" frameborder="no"
					allow="autoplay"
					src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/442734801&color=%235e7189&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
			</div>

			<div>
				<h4 class="mt-4">Die Literatur-Apotheke geht online
					(11.04.2016)</h4>
				<iframe width="100%" height="166" scrolling="no" frameborder="no"
					allow="autoplay"
					src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/442734795&color=%235e7189&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>

			</div>


		</div>




		<div class="text-left mt-5">
			<h2>Wettbewerbe</h2>


			<div>
				<h4 class="mt-4">"In den Wind geschrieben": Hildesheimer
					Literaturwettbewerb 2020</h4>
				<h5 class=""></h5>
	<audio controls>

  <source src="200420_1500_WSUV_Litbuero_IndenWind_fertig.mp3" type="audio/mpeg">
Your browser does not support the audio element.
</audio>

				<iframe width="100%" height="166" scrolling="no" frameborder="no"
					allow="autoplay"
					src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/803158960&color=%235e7189&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
		
			</div-->
    <!--div>
				<h4 class="mt-4">"Zwischen den Zeilen": Hildesheimer
					Literaturwettbewerb 2017</h4>
				<h5 class="">Bericht von ffn und Antenne Niedersachsen vom
					07.06.2017</h5>
				<iframe width="100%" height="166" scrolling="no" frameborder="no"
					allow="autoplay"
					src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/802352770&color=%236e7c86&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
			</div>


			<div>
				<h4 class="mt-4">"Was mir heilig ist": Hildesheimer
					Lyrikwettbewerb 2014</h4>
				<h5 class="">Bericht von ffn und Antenne Niedersachsen</h5>
				<iframe width="100%" height="166" scrolling="no" frameborder="no"
					allow="autoplay"
					src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/802358377&color=%236e7c86&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
			</div>


			<div>
				<h4 class="mt-4">"Was mir heilig ist": Hildesheimer
					Lyrikwettbewerb 2014</h4>
				<h5 class="">Bericht von Radio Tonkuhle</h5>
				<iframe width="100%" height="166" scrolling="no" frameborder="no"
					allow="autoplay"
					src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/802356097&color=%236e7c86&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>

			</div>




			<div>

				<h4 class="mt-4">"Es ist, wie es ist": Hildesheimer
					Lyrikwettbewerb 2010</h4>
				<h5 class="">Bericht von Radio Tonkuhle</h5>
				<iframe width="100%" height="166" scrolling="no" frameborder="no"
					allow="autoplay"
					src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/802349617&color=%236e7c86&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
			</div>


		</div-->
  </div>
</template>

<script>
export default {
  name: "Überuns",

  data() {
    return {};
  },
  computed: {},
};
</script>

<style lang="scss" scoped></style>
