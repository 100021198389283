import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }
  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }
  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }
  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
  //----------------------------------------------------------------- Favoriten

  async getFavoriten(id) {
    var favoriten = ""

    await axios.get(API_URL + 'benutzer/favoriten/' + id).then(res => {

      favoriten = res.data
    }).catch(() => favoriten = null)
    console.log(favoriten);

    return favoriten
  }
  async deleteFavorit(id) {
    await axios.delete(API_URL + 'benutzer/favoriten/' + id).then(res => {
      console.log(res);
    }).catch((err) => console.log(err))
  }

  async postFavorit(benutzerid, werkid, grund) {
    var fav = {
      benutzer_id: benutzerid,
      werk_id: werkid,
      grund: grund
    }
    console.log("Post", fav);

    await axios.post(API_URL + 'favoriten/', fav).then(res => {
      console.log(res);
    }).catch((err) => console.log(err))
  }

  //------------------------------------------------- Benutzer

  async getBenutzerAllFiltered(query) {
    var ret = [];
    await axios
      .get(process.env.VUE_APP_API_URL + "benutzer?" + query)
      .then((response) => {
        var data = response.data;

        console.log(data);

        ret = data;

        //commit('setWerke',state.werke)
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  }

  async getBenutzerNameByID(id) {
    var name = ""
    await axios.get(API_URL + 'benutzer/' + id).then(res => {

      name = res.data[0].Vorname + " " + res.data[0].Nachname
    }).catch(() => name = null)


    return name
  }

  async getBenutzerByID(id) {
    var benutzer = ""
    await axios.get(API_URL + 'benutzer/' + id).then(res => {


      var data = res.data[0]

      var inits = this.getProfileInitials(data.Vorname, data.Nachname)
      benutzer = {
        id: data.BenutzerId,
        vorname: data.Vorname,
        nachname: data.Nachname,
        imagePath: data.imagePath,
        initials: inits,
        vita: data.Vita,
        ort: data.Ort,
        geburtsjahr: data.Geburtsjahr,
        role: data.Role,
        beruf: data.Beruf

      }
    }).catch(() => benutzer = {
      id: "-1",
      vorname: "-",
      nachname: "-",
      imagePath: "-",
      initials: "-",
      vita: "-",
      ort: "-",
      geburtsjahr: "-",
      role: 0
    })


    return benutzer
  }

  async getBenutzerByIDAdmin(id) {
    var benutzer = ""
    await axios.get(API_URL + 'benutzer/admin/' + id).then(res => {


      var data = res.data[0]

      console.log(data);

      var inits = this.getProfileInitials(data.Vorname, data.Nachname)
      benutzer = {
        benutzerId: data.BenutzerId,
        vorname: data.Vorname,
        nachname: data.Nachname,
        imagePath: data.imagePath,
        initials: inits,
        vita: data.Vita,
        ort: data.Ort,
        geburtsjahr: data.Geburtsjahr,
        role: data.Role,
        beruf: data.Beruf,
        newsletter: data.Newsletter,
        email: data.Email

      }
    }).catch(() => benutzer = {
      id: "-1",
      vorname: "-",
      nachname: "-",
      imagePath: "-",
      initials: "-",
      vita: "-",
      ort: "-",
      geburtsjahr: "-",
      role: 0
    })


    return benutzer
  }

  async getRole(id) {
    var role = 0

    await axios.get(API_URL + 'benutzer/' + id).then(res => {

      var data = res.data[0]
      role = data.Role


    }).catch(() => role = null)


    return role
  }

  async getMail(id) {
    var mail = ""

    await axios.get(API_URL + 'benutzer/email/' + id).then(res => {

      var data = res.data[0]

      mail = data.email


    }).catch(() => mail = "nicht gefunden")


    return mail
  }

  getProfileInitials(vorname, nachname) {

    return vorname.match(/(\b\S)?/g).join("") + nachname.match(/(\b\S)?/g).join("");
  }

  //----------------------------------------------- Sperrungen
  async sperren(userid, benutzerid, grund) {
    console.log(userid,benutzerid, grund);
    var sperrung = {
      Benutzer_id: userid,
      Sperrer_id: benutzerid,
      Grund: grund,
    }
    await axios.post(API_URL + 'benutzergesperrt/', sperrung).then(doc => {
      console.log(doc);
    }).catch(() => sperrung = null)
  }

  async getSperrungen() {
    var sperrungen = ""
    await axios.get(API_URL + 'benutzergesperrt/').then(doc => {
      sperrungen = doc.data
    }).catch(() => sperrungen = null)
    return sperrungen
  }
  async entsperren(id) {

    await axios.delete(API_URL + 'benutzergesperrt/' + id).then(doc => {
      console.log(doc)
    }).catch((err) => console.log(err))

  }

  async entsperrenByBenutzer(id) {

    await axios.delete(API_URL + 'benutzergesperrt/benutzer/' + id).then(doc => {
      console.log(doc)
    }).catch((err) => console.log(err))

  }

  //-----------------------------------------------------------------------Markierungen

  async markieren(userid, benutzerid, grund) {
    var markierung = {
      Benutzer_id: userid,
      Markierer_id: benutzerid,
      Grund: grund,
    }
    await axios.post(API_URL + 'benutzermarkiert/', markierung).then(doc => {
      console.log(doc);
    }).catch(() => markierung = null)

  }

  async getMarkierungen() {
    var markierungen = ""
    await axios.get(API_URL + 'benutzermarkiert/').then(doc => {
      markierungen = doc.data
    }).catch(() => markierungen = [])
    return markierungen
  }
  async entmarkieren(id) {

    await axios.delete(API_URL + 'benutzermarkiert/' + id).then(doc => {
      console.log(doc)
    }).catch((err) => console.log(err))

  }

  async entmarkierenByBenutzer(id) {

    await axios.delete(API_URL + 'benutzermarkiert/benutzer/' + id).then(doc => {
      console.log(doc)
    }).catch((err) => console.log(err))

  }

  async isKommentarGemeldet(kId, bId) {
    var markierungen = ""
    await axios.get(API_URL + 'kommentarmelden/kommentare/user/'+ bId+ "/"+ kId).then(doc => {
      markierungen = doc.data
    }).catch((e) =>  {console.log(e);markierungen = []})

    console.log(markierungen);
    return markierungen.length>0?true:false
  }


  //______________________________________


  async hide(id,bool) {


    await axios.put(API_URL + 'benutzergesperrt/hide/' + id+'/'+bool).then(doc => {
      console.log(doc)
    }).catch((err) => console.log(err))

  }
  async delete(id) {

    await this.entmarkierenByBenutzer(id)
    await this.entsperrenByBenutzer(id)

    await axios.delete(API_URL + 'benutzer/' + id).then(doc => {
      console.log(doc)
    }).catch((err) => console.log(err))

  }
}

export default new UserService();