<template>

    <div class="werk-card">
        <img
      v-if="werk.Stamm == 1"
      class="stammstempel "
      src="../assets/stamm groß.png"
      alt="Stammpräparat"
    />

    <div class="info">
        <div class="card-header">
          <span class="date">
            {{
              new Date(werk.Timestamp).toLocaleString("de", {
                dateStyle: "long",
              })
            }}
          </span>
        </div>

        <span class="serif title">{{ werk.Titel }}</span>
        <span class="author">von {{ werk.Autor }}</span>
        <div class="preview-container">
          <div class="card-preview serif" v-html="werk.Inhalt"></div>
        </div>
        <div class="props">
          <div class="col col1">
            <span>Anwendung:</span>

            <span>Thema:</span>

            <span>Genre:</span>
          </div>
          <div class="col col2">
            <span>{{ _anwendung }}</span>
            <span>{{ _thema }}</span>
            <span>{{ _genre }}</span>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
//import Arrow from "../assets/Icons/arrow-right-light.svg";

export default {
  name: "WerkCard",
  //props: ["werk"],
  props: {
    werk: Object,
  },
  components: {
    // Arrow,

  },
  data() {
    return {};
  },

  computed: {
    _anwendung() {
      var a = this.$store.state.anwendungen.filter((anw) => {
        return anw.id === this.werk.Anwendung;
      });

      if (a.length == 0) return "-";
      else return a[0].name;
    },
    _thema() {
      var t = this.$store.state.themen.filter((th) => {
        return th.id === this.werk.Thema;
      });

      if (t.length == 0) return "-";
      else return t[0].name;
    },
    _genre() {
      var g = this.$store.state.genres.filter((gen) => {
        return gen.id === this.werk.Genre;
      });

      if (g.length == 0) return "-";
      else return g[0].name;
    },

  },
};
</script>

<style lang="scss" scoped>
.werk-card {
  border: 1px solid grey;
  position: relative;
  white-space: pre-line;




  .stammstempel {
    width: 60%;
    position: absolute;
    filter: grayscale(0.8);
    opacity: 0.4;
    /* float: inherit; */
    //margin-left: 75%;
    top:0;
    padding:10px;
    padding-left:45%;
    /* z-index: 0; */
    mix-blend-mode: multiply;
  }
  
  .icons {
    display: flex;
    position: absolute;
    align-content: center;
    z-index: 99;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #fff;
      transition: 0.5s ease all;

      &:hover {
        background-color: #303030;
        .edit,
        .delete {
          path {
            fill: #fff;
          }
        }
      }
      &:nth-child(1) {
        margin-right: 8px;
      }
      .edit,
      .delete {
        pointer-events: none;
        height: 15px;
        width: auto;
      }
    }
  }

  .card-header {
    width: 100%;
    font-size: 13px;
    padding: 0px 10px 10px 10px;

    .date {
      margin-right: 10px;
      float: right;
      font-size: 12px;
      font-style: bold;
    }
  }

  border-radius: 8px;
  background-color: #fff;
  transition: 0.5s ease all;

  &:hover {
    //transform: rotateZ(-1deg) scale(1.01);
     box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
 
  }
  div {
    .title {
      font-size: 24px;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 1.8; /* fallback */
      max-height: 300px; /* fallback */
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;
      text-align: left;
    }
    .author {
      color: #333;
      font-style: bold;
      float: right;
      font-size: 18px;
    }

    .preview-container {
      height: 150px;
      min-height: 100px;
      display: inline-flex;
      align-items: center;
      padding-right: 30px;
      
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 15px;
   

      .card-preview {
        //white-space: nowrap;
        width: 100%;
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.8; /* fallback */
        max-height: 100%; /* fallback */
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
        vertical-align: -webkit-baseline-middle;
      }
    }
    .props {
      display: flex;
      font-size: calc(12px);

      .col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 10px;
        text-align: left;;
      }
      .col1 {
        text-align: right;;
        color: gray;
        float: right;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 3;
    padding: 10px 16px;
    color: #000;
    h4 {
      padding-bottom: 8px;
      font-size: 20px;
      font-weight: 300;
    }
    h6 {
      font-weight: 400;
      font-size: 12px;
      padding-bottom: 16px;
    }
    .link {
      display: inline-flex;
      align-items: center;
      margin-top: auto;
      font-weight: 500;
      padding-top: 20px;
      font-size: 12px;
      padding-bottom: 4px;
      transition: 0.5s ease-in all;
      &:hover {
        color: rgba(48, 48, 48, 0.8);
      }
      .arrow {
        width: 10px;
      }
    }
  }
}
</style>
