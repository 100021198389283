<template>
  <div class="welcome">
    <div class="container">
      <h1 align="">Reglement für die Nutzung der Literatur-Apotheke</h1>
      <p
        align="LEFT"
        style="
          margin-bottom: 0cm;
          border: none;
          padding: 0cm;
          widows: 2;
          orphans: 2;
        "
      >
        <br />
      </p>
      <p
        align="LEFT"
        style="
          margin-bottom: 0cm;
          border: none;
          padding: 0cm;
          widows: 2;
          orphans: 2;
        "
      >
        <span style="font-style: normal"
          ><strong>&sect; 1 Nutzung der Literatur-Apotheke</strong></span
        ><br />
        <br />
        <span style="font-style: normal"
          ><span style="font-weight: normal"
            >Die Nutzung bzw. Teilnahme an der LITERATUR-APOTHEKE ist
            kostenfrei. Wer mitmachen will, um Texte einzureichen oder
            Beitr&auml;ge zu kommentieren und zu bewerten, muss sich daf&uuml;r
            registrieren.</span
          ></span
        ><br />
        <br />
        <span style="font-style: normal"
          ><strong>&sect; 2 Texte einreichen</strong></span
        ><br />
        <br />
        <span style="font-style: normal"
          ><span style="font-weight: normal"
            >Wer literarische Textbeiträge einreicht, akzeptiert das dafür vorgesehene Formular und füllt dieses
vollständig und ordnungsgemäß aus. Für die Richtigkeit aller dort gemachten Angaben, haftet allein
der oder die Teilnehmer:in, der oder die den Text einreicht. Bei Verstößen oder falschen Angaben
kann jeder Beitrag sofort gelöscht werden. </span
          ></span
        ><br />
        <br />
        <span style="font-style: normal"
          ><strong>&sect; 3 Eigenverantwortung der Teilnehmenden</strong></span
        ><br />
        <br />
        <span style="font-style: normal"
          ><span style="font-weight: normal"
            >Alle Teilnehmenden haften selbst für die Richtigkeit und die Ausführung ihrer Beiträge (siehe
unseren Haftungsausschluss / Impressum). Die Teilnehmenden sind gehalten, sich fair und
ehrenhaft und der Intention unseres Portals angemessen zu verhalten. </span
          ></span
        ><br />
        <br />
        <span style="font-style: normal"
          ><strong>&sect; 4 Zulassung von Beitr&auml;gen</strong></span
        ><br />
        <br />
      </p>
      <p
        align="LEFT"
        style="
          margin-bottom: 0cm;
          border: none;
          padding: 0cm;
          widows: 2;
          orphans: 2;
        "
      >
        a. Bei der Zulassung eines Beitrages (literarischen Präparates) zur Literatur-Apotheke findet von
den Veranstaltenden grundsätzlich keine literarische Bewertung statt.

      </p>
      <p
        align="LEFT"
        style="
          margin-bottom: 0cm;
          border: none;
          padding: 0cm;
          widows: 2;
          orphans: 2;
        "
      >
        b. Bei Verst&ouml;&szlig;en gegen das Reglement (beispielsweise
        gewaltverherrlichende Texte, fremdenfeindliche Texte, &hellip;) hat die
        Redaktion das Recht Texte und/oder Teilnehmer sofort zu l&ouml;schen.<br />
        <br />
      </p>
      <p
        align="LEFT"
        style="
          margin-bottom: 0cm;
          border: none;
          padding: 0cm;
          widows: 2;
          orphans: 2;
        "
      >
      <strong>  &sect; 5 Verbleib von Beitr&auml;gen
      </strong></p>
      <p
        align="LEFT"
        style="
          margin-bottom: 0cm;
          border: none;
          padding: 0cm;
          widows: 2;
          orphans: 2;
        "
      >
        <br />
      </p>
      <p
        align="LEFT"
        style="
          margin-bottom: 0cm;
          border: none;
          padding: 0cm;
          widows: 2;
          orphans: 2;
        "
      >
        a. Die eingereichten Beitr&auml;ge verbleiben in jedem Fall f&uuml;r ein
        Jahr in der Literatur-Apotheke. &Uuml;ber eine l&auml;ngere Verweildauer
        entscheidet die Redaktion.
      </p>
      <p
        align="LEFT"
        style="
          margin-bottom: 0cm;
          border: none;
          padding: 0cm;
          widows: 2;
          orphans: 2;
        "
      >
        b. Besonders wertvolle Beitr&auml;ge k&ouml;nnen von der Redaktion zu
        Stammpr&auml;paraten ernannt werden und damit dauerhaft in der
        Literatur-Apotheke verbleiben.
      </p>
      <p
        align="LEFT"
        style="
          margin-bottom: 0cm;
          border: none;
          padding: 0cm;
          widows: 2;
          orphans: 2;
        "
      >
        <br />
        <span style="font-style: normal"
          ><strong>&sect; 6 Kommentare</strong></span
        ><br />
        <br />
        <span style="font-style: normal"
          ><span style="font-weight: normal"
            >Kommentare sind sachlich und ohne H&auml;me oder verbale Angriffe
            zu verfassen. Wer sich in seinen Kommentaren ehrabschneidend,
            diskriminierend oder fremdenfeindlich &auml;u&szlig;ert, kann nach
            Bekanntwerden die weitere Zulassung zur Teilnahme unmittelbar
            verlieren.</span
          ></span
        ><br />
        <br />
        <span style="font-style: normal"
          ><strong>&sect; 7 Sperrung von Teilnehmenden</strong></span
        ><br />
        <br />
        <span style="font-style: normal"
          ><span style="font-weight: normal"
            >Bei Verst&ouml;&szlig;en gegen Regeln der LITERATUR-APOTHEKE, können Teilnehmende
            ohne weitere Angabe von Gr&uuml;nden gesperrt
            werden.</span
          ></span
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Überuns",

  data() {
    return {};
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
h1 {
  //padding: 30px;
  text-align: left;

  font-weight: lighter;
}

.welcome {
  padding-left: 3vw;
  padding-right: 3vw;
  max-width: 1200px;
}
.container {
  padding: 5%;
  //align-items: center;

  font-size: calc(8px + 1vw);
}
.stamm {
  width: 100%;
  padding: 20px;
  max-width: 600px;
}
.updates {
  .container {
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
    //align-items: center;
    @media (min-width: 800px) {
      padding: 15px 20px;
      flex-direction: row;
    }

    .router-button {
      display: flex;
      font-size: 14px;
      text-decoration: none;
      @media (min-width: 800px) {
        margin-left: auto;
      }
    }
  }
}
.blog-cards {
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr;
  @media (min-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>