<template>
  <div class="container">
    <div class="blog-card-wrap">
      <div class="sort-search-wrapper"></div>
      <div class="found">
        <h1 class="">Markierte Benutzer</h1>
        Es wurden <strong>{{ this.allFilteredWerke.length }} Einträge</strong>
        gefunden
      </div>

      <div class="blog-cards">
        <div
          class="card"
          v-for="mark in this.werkeToShow"
          :key="mark.markierung_id"
        >
          <Slot :admin="true" :mark="mark" />
          <div class="icons">
            <Entmarkieren
              :userid="mark.benutzer_id"
              @ok="doEntmarkieren(mark)"
            />
            <SperrenBenutzer           :userid="mark.benutzer_id"
            @ok="doSperren($event, mark)"

         />
          </div>
        </div>
        <div class="scrolling-component"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Slot from "../../components/BenutzerMarkiert.vue";
import userService from "../../services/user.service";
import SperrenBenutzer from "../../dialogs/benutzer/SperrenBenutzer.vue";
import Entmarkieren from "../../dialogs/benutzer/EntmarkierenBenutzer.vue";

export default {
  name: "admin-werke",
  components: { Slot, Entmarkieren, SperrenBenutzer },
  data() {
    return {
      search: "",
      amount: 8,
      sperrungList: [],
      allFilteredWerke: [],
      werkeToShow: [],

      sortBy: "date",
      asc: false,
    };
  },
  async mounted() {
    this.sperrungList = await userService.getMarkierungen();

    this.werkeToShow = this.getWerkeToShow();
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    async doEntmarkieren(mark) {
      await userService.entmarkieren(mark.markierung_id);

      await this.$store.dispatch("admin/loadBenutzerMarkiert");

      this.$store.state.admin.showFeedback = true;
      this.resetList();
      setTimeout(() => {
        this.$store.state.admin.showFeedback = false;
      }, 5000);
    },

    async doSperren(grund, mark) {
      await userService.sperren(
        mark.benutzer_id,
        this.$store.state.userInfo.benutzerId,
        grund
      );
      await this.$store.dispatch("admin/loadBenutzerGesperrt");

      this.$store.state.admin.showFeedback = true;

      setTimeout(() => {
        this.$store.state.admin.showFeedback = false;
      }, 5000);
      await this.doEntmarkieren(mark)
    },

    sortRandom() {
      console.log("random");
      this.sortBy = "random";
      this.resetList();
    },
    sortDatum() {
      console.log("datum");
      if (this.sortBy == "date") {
        this.asc = !this.asc;
      }
      this.sortBy = "date";

      this.resetList();
    },
    resetList() {
      console.log("RESET___________________________");
      this.werkeToShow = [];
      // console.log("Reset Werke List!", this.werkeToShow);
      this.werkeToShow = this.getWerkeToShow();
    },
    getWerkeToShow() {
      this.allFilteredWerke = this.werkeFiltered();
      // console.log("all filtered werke", this.allFilteredWerke);
      var ret = [];

      var lastIndex = this.werkeToShow.length;
      var newLength =
        this.allFilteredWerke.length - this.amount < this.werkeToShow.length //if
          ? this.allFilteredWerke.length
          : lastIndex + this.amount;
      console.log("NEW LENGTH", newLength);
      for (let i = lastIndex; i < newLength; i++) {
        ret.push(this.allFilteredWerke[i]);
      }

      return ret;
    },

    handleScroll() {
      if (
        window.scrollY + window.innerHeight >=
          document.body.scrollHeight - window.innerHeight &&
        !(this.werkeToShow.length >= this.allFilteredWerke.length)
      ) {
        console.log("load new werke");
        this.werkeToShow = [...this.werkeToShow, ...this.getWerkeToShow()];
      }
    },
    werkeFiltered() {
      console.log("GETTING WERKE");
      var filteredWerke = [];

      // Checkboxes

      for (var w of this.$store.state.admin.benutzerMarkiert) {
        filteredWerke.push(w);
      }

      console.log(filteredWerke);
      filteredWerke = filteredWerke.sort((a, b) => {
        var dateA = new Date(a.timestamp);
        var dateB = new Date(b.timestamp);
        return dateB - dateA;
      });

      console.log("FILTERED WERKE:", filteredWerke);
      return filteredWerke;
    },
  },

  computed: {
    editWerk: {
      get() {
        return this.$store.state.editWerk;
      },
      set(payload) {
        this.$store.commit("toggleEditWerk", payload);
      },
    },
    profileAdmin() {
      return true; //this.$store.state.profileAdmin;
    },
  },
  beforeDestroy() {
    this.checkedAnwendungen = [];
    this.checkedThemen = [];
    this.checkedGenres = [];
    this.$store.commit("toggleEditWerk", false);
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.icons {
  display: flex;
  position: relative;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 30px;
  button {
    border-radius: 10px;
  }
}
.found {
  text-align: center;
  font-size: 1.1em;
  padding-bottom: 20px;
}
.sort-search-wrapper {
  padding-top: 20px;
  padding-left: 2vw;
  padding-right: 2vw;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-wrapper {
  display: inline-block;
  margin-right: 0;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 50px;
  right: 0;
  input {
    border-radius: 10px;

    max-width: 800px;
    padding: 10px;
    font-size: 18px;
  }
}
.filterBereich {
  padding-top: 20px;
  padding: 20px;
  //max-width: 1200px;
  height: 100%;
  display: flex;

  align-content: center;
  column-gap: 5vw;
  padding-bottom: 20px;
  font-size: calc(0.6em + 0.5vw);
  margin: 0 auto;
  flex-wrap: wrap;

  .filterspalte {
    width: calc(25% + 50px);
    overflow: visible;
    padding-top: 30px;
    span {
      font-size: calc(1em + 1vw);
    }
    label {
      white-space: nowrap;
      vertical-align: middle;
      position: inherit;
    }

    input {
      transition: 750ms ease all;
      appearance: none;
      border: none;
      // background: #fff;
      outline: none;
      background-color: #fafafa;
      border: 1px solid #cacece;
      box-shadow: 0 0px 2px rgba(0, 0, 0, 0.5),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 10px;
      vertical-align: middle;
      // border-radius: 30px;
      display: inline-block;
      position: relative;
      //height: 30px;
      // border-radius: 100px;
    }

    input:checked {
      content: "\2714";
      padding-left: 25px;
      //position:absolute;
      color: #000000;
      background-color: #195073;
    }

    .field {
      white-space: nowrap;
    }
    .field:hover {
      font-weight: bold;
    }
  }
}

@media (min-width: 1200px) {
  .filterBereich {
    justify-content: center;
  }
}

@media (max-width: 750px) {
  .filterBereich {
    //justify-content: space-between;
    padding-right: 10px;
  }
}

.sort-wrapper {
  vertical-align: middle;
  height: 100px;
  button {
    margin-left: 10px;
    margin-right: 10px;
    height: 30px;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;

    //vertical-align: middle;
  }
}
.blog-cards {
  padding: 50px;
  padding-bottom: 200px;
  position: relative;

  .toggle-edit {
    display: flex;
    align-items: center;
    position: absolute;
    top: -70px;
    right: 1;

    span {
      margin-right: 16px;
    }
    input[type="checkbox"] {
      position: relative;
      border: none;
      -webkit-appearance: none;
      background: #fff;
      outline: none;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    input[type="checkbox"]:before {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      top: 0;
      left: 0;
      background: #303030;
      transform: scale(1.1);
      transition: 750ms ease all;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    input:checked[type="checkbox"]:before {
      background: #fff;
      left: 52px;
    }
  }
}
</style>
