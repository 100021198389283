<template>
  <div class="welcome">
    <h2 class="heading">
      ÜBER WASSER – ÜBER ERDE – ÜBER UNS
    </h2>
    <h3 class="heading">
      Literaturwettebwerb 2022
    </h3>
    <div class="container">
      <p>
        Unsere Jury hat entschieden. Die Preisträger:innen des Hildesheimer
        Literaturwettbewerbs 2022 in fünf Kategorien sind:
      </p>

      <ul>
        <li>
          Hauptpreis Lyrik:
          <router-link
            class="link"
            :to="{ name: 'WerkView', params: { werkid: 3021 } }"
            target="_blank"
            >Philipp Létranger - brache
          </router-link>
        </li>
        <li>
          Hauptpreis Lyrik:
          <router-link
            class="link"
            :to="{ name: 'WerkView', params: { werkid: 2790 } }"
            target="_blank"
            >Sigune Schnabel - Dialog
          </router-link>
        </li>
        <li>
          Hauptpreis Prosa:
          <router-link
            class="link"
            :to="{ name: 'WerkView', params: { werkid: 3332 } }"
            target="_blank"
            >Sarah Roguschke - Das Gegenteil von Verschwinden</router-link
          >
        </li>
        <li>
          Hauptpreis International:
          <router-link
            class="link"
            :to="{ name: 'WerkView', params: { werkid: 2933 } }"
            target="_blank"
            >Christa Issinger - erbarmen</router-link
          >
        </li>

        <li>
          Hauptpreis Junge Literatur:
          <router-link
            class="link"
            :to="{ name: 'WerkView', params: { werkid: 2862 } }"
            target="_blank"
            >Alexandra Huth - Stadtfrühling</router-link
          >
        </li>
        <li>
          Publikumspreis:
          <router-link
            class="link"
            :to="{ name: 'WerkView', params: { werkid: 2886 } }"
            target="_blank"
            >Dr. Andreas Lukas - Orange am Himmel...?</router-link
          >
        </li>
      </ul>
      <p>
        Wir gratulieren von ganzem Herzen!
      </p>
      <br />
      <br />


      <div class="center">
    <h3 class="mt-4">Beitrag zum Anhören von Radio Tonkuhle:</h3>
			
	<audio controls>

  <source src="../audio/radio wettbewerb 2022.mp3" type="audio/mpeg">
Your browser does not support the audio element.
</audio>
</div>
      <img
        class="title-image"
        src="..\assets\wettbewerbe\2022\2022-Literatur-Preis-ZAG_4675_-scaled.jpg"
        alt=""
      />
      <span>Bildunterschrift</span>
    </div>

    <img
      class="title-image"
      src="..\assets\wettbewerbe\2022\2022-04-05-00_00_00_LDZ_Seite_6-scaled.jpg"
      alt=""
    />
    <span>Bildunterschrift</span>



    <div class="heading">
      <a
        class="literapedia-link"
        href="https://literapedia-hildesheim.de/wettbewerb-2022/"
        target="_blank"
      >
        <span> ...mehr in unserer Literapedia </span></a
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style lang="scss">
.heading {
  text-align: center;
}
ul {
  li {
    padding: 10px;
  }
}
</style>
