<template>
  <div class="post-view" v-if="currentWerk">

    <div class="background paper">
      <div class="werk-container">
        <span v-if="this.$route.params.gesperrt">gesperrt</span>

        <Werk :currentWerk="currentWerk" :wettbewerb="this.wb" />
        <div v-if="loggedIn">
          <div class="rating-wrapper">
            <div
              class="rating-container"
              v-if="
                this.$store.state.userInfo.benutzerId != currentWerk.Benutzerid && (!this.wb || zeitraum)
              "
            >
            <span class="bold">Ihre Wertung:</span>
              <StarRating
                v-on:rated="rate"
                v-model="rating"
                :disabled="isBewertet"
                :stars="this.getUserRating"
                @rating="atRating = true"
              ></StarRating>

              <p
                class="hinweis"
                v-if="this.wb && !this.getUserRating && !atRating"
              >
                Geben Sie 1-5 Herzen als Voting für diesen Text ab. Für jedes
                Werk können Sie einmal abstimmen. Mit Ihrer Wertung bestimmen
                Sie den Votinggewinner.
              </p>
              <p
                class="hinweis"
                v-if="!this.wb && !this.getUserRating && !atRating"
              >
                Geben Sie 1-5 Herzen als Voting für diesen Text ab. Für jedes
                Werk können Sie einmal abstimmen.
              </p>

              <p v-if="this.getUserRating">Vielen Dank für Ihre Wertung</p>
            </div>
            <Favorisieren
              v-if="
                !this.wb &&
                this.$store.state.userInfo.benutzerId != currentWerk.Benutzerid
              "
              :isFavorit="isFavorit"
              @ok="toggleFavorit($event)"
            />
            <div v-if="user && (!this.wb || zeitraum)" class="favorit" @click="toggleFavorit('') ">
              <!--div v-if="isFavorit">
                
                <button title="Favorisiert">
                  <font-awesome-icon :class="`fa-10`" :icon="['fas', 'star']" />
                </button>
                <p class="fade-text">favorisiert</p>
              </div>
              <div v-else>
                <button title="Nicht favorisiert">
                  <font-awesome-icon :class="`fa-10`" :icon="['far', 'star']" />
                </button>
                <p class="fade-text">favorisieren</p>
              </div-->
            </div>
          </div>

          <div class="write-comment">
            <textarea
              class="serif"
              type="text"
              name="comment"
              id=""
              v-model="newComment"
            />
            <button @click="kommentieren">Kommentieren</button>
          </div>
        </div>
        <p class="center" v-show="msgShow">{{ msg }}</p>

        <div
          class="k-container"
          v-for="kommentar in kommentare"
          :key="kommentar.id"
        >
          <Comment :kommentarid="kommentar.id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import userService from "../services/user.service";
import Comment from "../components/Comment.vue";
import Werk from "../components/Werk.vue";
import Favorisieren from "../dialogs/main/Favorisieren.vue";
import Quill from "quill";

//import werkeService from '../services/werke.service';
window.Quill = Quill;

//import vueStarRate from "vue-js-star-rating";
import StarRating from "../components/Rating.vue";
import werkeService from '../services/werke.service';

export default {
  name: "WerkView",
  props: {
    admin: Boolean,
  },
  data() {
    return {
      currentWerk: null,
      benutzerName: "",
      kommentare: [],
      newComment: "",
      rating: 0,
      atRating: false,
      ratings: [],
      points: 0,
      favorit: false,
      favoritObj: null,
      wb: false,
      msg: "",
      msgShow: false,
      zeitraum:false,
    };
  },

  components: {
    Comment,
    Werk,
    // vueStarRate,
    StarRating,
    Favorisieren,
  },
  async created() {
    var werke = [];
    console.log(this.$route.params.werkid);

    console.log(this.$store.getters["auth/admin"]);
    var admin = this.$store.getters["auth/admin"];
    if (admin) {
     // werke = this.$store.state.admin.werkeAll;
      console.log(this.$store.state.admin.werkeAll);
    } else if (this.$route.name == "WB_WerkView") {
    //  werke = this.$store.state.wb_werke;

    
    } else {
     //werke = this.$store.state.werke;
    }
 if (this.$route.name == "WB_WerkView") {
      this.wb = true;
 }

    console.log("WERK VIEW", werke);


    this.currentWerk = await werkeService.getWerkById(this.$route.params.werkid)

   // this.currentWerk = this.currentWerk[0];

    console.log("Current Werk:",this.currentWerk);
    if (this.currentWerk !== null) {
      this.benutzerName = await userService.getBenutzerNameByID(
        this.benutzerid
      );
    }

    this.getKommentare();
    this.getRating();
    this.updateFavorit();
  },

  mounted(){
     // Create date from input value
    var ende = new Date("05/31/2024");
    // Get today's date
    var todaysDate = new Date();
    // call setHours to take the time out of the comparison
    if ( ende.setHours(0, 0, 0, 0) >= todaysDate.setHours(0, 0, 0, 0)) {

      this.zeitraum = true
    }
  },

  methods: {
    async toggleFavorit(grund) {
      if (!grund) grund = "-";
      console.log("GRUND", grund);
      if (!this.isFavorit) {
        console.log("make Favorit");

        await userService.postFavorit(
          this.$store.state.userInfo.benutzerId,
          this.currentWerk.WerkId,
          grund
        );

        await this.$store.dispatch("userInfo/loadFavorits");
        this.updateFavorit();
      } else {
        console.log("unmake Favorit");
        await userService.deleteFavorit(this.favoritObj.favorit_id);

        await this.$store.dispatch("userInfo/loadFavorits");
        this.updateFavorit();
      }
    },

    setRate(payload) {
      console.log(payload);
      this.rating = payload;
    },

    async rate(payload) {
      var rating = {
        User_id: this.$store.state.userInfo.benutzerId,
        Werk_id: this.currentWerk.WerkId,
        Stars: payload,
        Points: payload * payload,
      };
      console.log(rating);
      await axios
        .post(process.env.VUE_APP_API_URL + "werk/rating", rating)
        .catch((err) => console.log(err));

      await this.getRating();
      console.log(
        (this.$store.state.werke.find(
          (werk) => werk.id === this.currentWerk.WerkId
        ).rating = this.points)
      );
    },
    async kommentieren() {
      var kommentar = {
        Benutzer_id: this.$store.state.userInfo.benutzerId,
        Werk_id: this.currentWerk.WerkId,
        Inhalt: this.newComment,
      };

      if (this.newComment != "") {
        await axios
          .post(process.env.VUE_APP_API_URL + "werk/kommentare", kommentar)
          .catch((err) => console.log(err));
        this.newComment = "";
        this.msg = "Kommentar abgegeben";
        this.msgShow = true;

        setTimeout(() => {
          this.msgShow = false;
        }, 5000);
       await this.getKommentare();
      }
    },

    async getKommentare() {
      await axios
        .get(
          process.env.VUE_APP_API_URL + "werk/kommentare/" + this.currentWerk.WerkId
        )
        .then((res) => {
          res.data.forEach((doc) => {
            if (
              !this.kommentare.some(
                (kommentar) => kommentar.id === doc.KommentarId
              )
            ) {
              var kommentar = {
                id: doc.KommentarId,
                benutzerid: doc.Benutzer_id,
                werkid: doc.Werk_id,
                inhalt: doc.Inhalt,
                timestamp: doc.Timestamp,
              };
              this.kommentare.push(kommentar);
            }
          });
          this.kommentare.sort(function (a, b) {
            return b.id - a.id;
          });
        })
        .catch((err) => console.log(err));
    },

    async getRating() {
      var points = 0;
      await axios
        .get(process.env.VUE_APP_API_URL + "werk/rating/" + this.currentWerk.WerkId)
        .then((res) => {
          console.log(res);
          res.data.forEach((doc) => {
            points += doc.Points;
            if (!this.ratings.some((rating) => rating.id === doc.Rating_id)) {
              var rating = {
                id: doc.Rating_id,
                benutzerid: doc.User_id,
                werkid: doc.Werk_id,
                stars: doc.Stars,
                points: doc.Points,
                timestamp: doc.Datum,
              };
              this.ratings.push(rating);
            }
          });
          this.points = points;
          this.ratings.sort(function (a, b) {
            return b.id - a.id;
          });
        })
        .catch((err) => console.log(err));
    },

    updateFavorit() {
      this.favoritObj = this.$store.state.userInfo.favorits.filter((fav) => {
        return this.currentWerk.WerkId == fav.werk_id;
      })[0];
    },
  },

  computed: {
    loggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },

    getUserRating() {
      let stars = 0;
      let rated = this.ratings.filter((rating) => {
        return rating.benutzerid == this.$store.state.userInfo.benutzerId;
      })[0];
      if (this.isBewertet) {
        console.log("is Bewertet", rated.stars);
        stars = rated.stars;
      }
      return stars;
    },
    isBewertet() {
      let bewertet = false;
      bewertet = this.ratings.some((rating) => {
        return rating.benutzerid == this.$store.state.userInfo.benutzerId;
      });
      return bewertet;
    },
    isFavorit() {
      console.log(this.$store.state.userInfo.favorits);

      console.log("Favorisiert:", this.favoritObj);

      return this.favoritObj;
    },

    user() {
      return this.$store.getters["auth/isLoggedIn"];
    },
 
  },
};
</script>

<style lang="scss">
.rating-wrapper {
  .rating-container {
    //width: calc(80%-30vw);
    width: 80%;
    height: 200px;
    overflow: hidden;
    //position: absolute;
  }

  // width: calc(100% - 30vw);
  display: flex;
  justify-content: space-between;
  padding-left: 8vw;
  max-width: 1200px;
  .hinweis {
    position: absolute;
    width: inherit;
    max-width: 50%;
    font-size: calc(8px + 0.5vw);
  }
}
.post-view {
  margin: 0;
  display: flex;
  margin-top: 50px;
  //width: 100%;
  //overflow: hidden;
  //flex-direction: column;
  //align-items: center;

  .background {
    //background: #fbfaf8;
    //background: #fbfaf8d5;
    width: fit-content;
    margin-bottom: 50px;
    min-width: 60%;
    max-width: 1200px;
    .werk-container {
      .write-comment {
        min-width: 60%;
        max-width: 80%;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        flex-direction: row;
        //align-items: stretch;
        padding-top: 50px;
        padding-bottom: 50px;
        margin: 0 auto;
        textarea {
          width: 100%;
          height: 100px;
          border-radius: 10px;
          padding: 10px;
        }
      }
    }
    .k-container {
      width: calc(100% - 5vw);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 50px;
    }
  }
  .editor {
    height: 60vh;
    display: flex;
    flex-direction: column;
    .quillWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .ql-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: scroll;
    }
    .ql-editor {
      padding: 20px 16px 30px;
    }
  }
}
</style>