<template>
  <div class="welcome">
    <div class="gruss">
      <h2 class="">
        "Die Literatur-Apotheke macht die Kreativität von Einzelnen zugänglich"
      </h2>
      <br />
      „Seit 2016 bringt die Literatur-Apotheke Menschen in Hildesheim dazu,
      eigens verfasste Texte zu veröffentlichen und auf diese Weise ihre Ideen
      und Gedanken mit anderen Schreibbegeisterten zu teilen. Ganz gleich, ob
      Lyrik, Roman, Märchen oder Songtext: Die Literatur-Apotheke macht die
      Kreativität von Einzelnen zugänglich und fördert den Austausch zwischen
      Autoren und Lesern über die Wirkung von Literatur und der eigenen Schreib-
      oder Leseerfahrung. <br />
      <br />
      In Kooperation mit dem Fachbereich Informatik und Mathematik der
      Universität Hildesheim hat das Literaturforum nun eine aktualisierte und
      damit noch ansprechendere und nutzerfreundlichere Online-Plattform
      entwickelt. Ich bin mir sicher, dass das neue Aushängeschild der
      Literatur-Apotheke auch zukünftig ein breites Publikum begeistern wird.<br />
      <br />
      Ich danke allen Beteiligten, die an diesem Projekt mitgewirkt haben und
      wünsche viel Freude am literarischen Austausch."

      <br />
      <br />
      Mit herzlichen Grüßen<br />
      <br />
      Dr. Ingo Meyer<br />
      Oberbürgermeister <br />

      <img
        src="../assets/Portraitbild_OB_2018.jpg"
        width="400px"
        class="img-fluid"
        alt="Responsive image"
      />
    </div>
    <div class="gruss">
      <h2>"Texte können ein wundervolles Werkzeug sein"</h2>
      <br />
      „Das Motto des diesjährigen Literaturwettbewerbs berührt uns in bis vor
      kurzem noch unvorstellbarer Weise. Denn jeder und jede von uns hatte
      größere oder kleinere Pläne für dieses Jahr, diesen Frühling, diese Woche
      oder jeden einzelnen dieser Tage, die nun „in den Wind geschrieben"
      scheinen. Gerade deshalb sollten wir uns daran erinnern, dass uns das
      Schreiben ein unendliches Reservoir an Möglichkeiten gibt. <br />
      <br />
      Texte können ein wundervolles Werkzeug sein, um unserer selbst und unserer
      Umwelt bewusst zu werden. Dabei ist Schreiben eine Möglichkeit, sich auf
      die wirklich wichtigen Dinge zu besinnen – auf unsere Beziehungen, die
      kleinen Alltäglichkeiten, die Natur und vieles mehr. Und eigene Texte
      nicht nur für sich zu behalten, sondern in diesen Wettbewerb einzubringen,
      unsere Erfahrungen, Wünsche, Hoffnungen oder Perspektiven mit anderen zu
      teilen."
      <br />
      <br />
      Mit herzlichen Grüßen<br />
      <br />
      Dr. Monika Suchan<br />
      Leiterin der Dombibliothek
      <br />

      <img
        src="../assets//Monika-Suchan-302.jpg"
        width="400px"
        class="img-fluid"
        alt="Responsive image"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "Überuns",

  data() {
    return {};
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.gruss {
 
  padding-bottom: 100px;
  h2 {
    text-align: center;
  }
  img {
    width: 250px;
	//margin-left:25px;
	
	margin-top:25px;
    border-radius: 100% 75% 100% 75%;
  }
}
h1 {
  //padding: 30px;
  text-align: left;
  font-size: 50px;
  font-weight: lighter;
}
</style>