<template>
 
       <div class="icon">
       
          <button class="delete-btn" @click="handle">Markierung aufheben</button>
          <confirm-dialog ref="confirmDialogue"></confirm-dialog>

        </div>
</template>




<script>
import ConfirmDialog from "../ConfirmDialog.vue"

export default {
  name: "EntmarkierenBenutzer",

  components:{ConfirmDialog},
 props:{
    benutzer: Object
  },
created(){

console.log("DELETE WERK DIALOG");
},

methods:{
      
      async handle() {
      const ok = await this.$refs.confirmDialogue.show({          
        
        title: "Markierung aufheben",
            message:
                "Soll für diesen Benutzer die Markierung aufgehoben werden?",
            okButton: "Markierung aufheben",
            cancelButton: "abbrechen"});
      if (ok) {
        this.$emit("ok");
      }
    },
}
  
}
</script>

<style>

</style>