<template>
  <div class="welcome">
    <h1>Wettbewerbe im Überblick</h1>
    <!--div class="redaktion center"><strong>Redaktion:</strong> Rachel Bleiber • Lucie Kling • Jo Köhler • Annette Stock • Malu von Marschall • Anna Volmering</div-->
    
    <div class="uberuns-nav">
      <nav class="nav"> <p>Wettbewerbe:</p>
               <router-link :to="{ name: '2022' }">2022</router-link>
        <router-link :to="{ name: '2020' }">2020</router-link>
        <router-link ></router-link>
        <a
        class="nav"
        href="https://literapedia-hildesheim.de/wettbewerbe/"
        target="_blank"
      >
        <span> 1997 - 2017 (Literapedia.de) </span></a
      >


 


      </nav>
      <div class="wrapper">
      <router-view />
    </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.wrapper{
  max-width: 1200px;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
  
}
.redaktion{
  font-size: 11px;
}
.uberuns-nav nav {
  display: flex;
  justify-content: center;
      flex-wrap: wrap;
      font-size: 18px;
  a {
    color: black;
    text-decoration: none;
    
    margin: 20px;
  }
}

.router-link-exact-active {
  border-bottom: 2px solid black;
}
h1 {
  //padding: 30px;
  text-align: left;
  font-size: 50px;
  font-weight: lighter;
  text-align: center;
}

.welcome {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

.title-image{
  width: 100%;
  margin-top: 25px;
}

.literapedia-link 

{
  
  span{
  background: black;
  color: white;
  padding: 20px;
  margin: 50px;
  border-radius: 10px;;

  text-align: center;

}}
</style>