<template>
 <div>
      <Navigation  />
      <div class="wb-body">
      <router-view />
      </div>
      <Footer  />
    </div>

</template>

<script>
import Navigation from "../components/Navigation_WB";
import Footer from "../components/Footer";



export default {
  name: "normalLayout",
  components: { Navigation, Footer },
  data() {
    return {
      navigation: null,
      
    };
  },
  async created() {
    

    this.checkRoute();
  },
  mounted: function () {
    this.loading = false;
  },
  methods: {
    checkRoute() {
      if (
        this.$route.name === "Login" ||
        this.$route.name === "Register" ||
        this.$route.name === "ForgotPassword"
      ) {
        this.navigation = true;
        return;
      }
      this.navigation = false;
    },
  },
  watch: {
    $route() {
      this.checkRoute();
    },
  },
  computed: {},
};
</script>


<style lang="scss">


$mainColor: #755d39;
$bgColor: white;
.wb-header{
  background-color: $mainColor;
  color: white;


nav{
  background-color: $mainColor;
  color: white;
}

span{
  color: white;
}
a{
  color: white;
}
}

.wb-body{
background-color: $bgColor;;
button{
  
  background-color: $mainColor;
}
}


</style>
