<template>
  <footer>
    <div class="container">
      <div class="left" v-if="!$route.path.includes('wettbewerb')">
        <div class="col-1">
          <router-link class="header" :to="{ name: 'Home' }"
            >Literatur-Apotheke</router-link
          >
        </div>
        <div class="col-2"  >

          <ul>

               <router-link v-if="!user" class="link" :to="{ name: 'Kontakt' }"
              >Kontakt</router-link
            >
            <router-link class="link" :to="{ name: 'Home' }">Startseite</router-link>
            <router-link class="link" :to="{ name: 'Werke' }"
              >Apothekerschrank</router-link
            >
            <router-link v-if="admin" class="link" :to="{ name: 'WerkCreate' }"
              >Werk einreichen</router-link
            >
               <router-link class="link" :to="{ name: 'WB_Home' }"
              >Zum Wettbewerb</router-link
            >
            <router-link  v-if="!this.$store.state.auth.user" class="link" :to="{ name: 'Login' }"
              >Einloggen / Registrieren</router-link
            >
             <router-link  class="link" :to="{ name: 'Teilnahmebedingungen' }"
              >Teilnahmebedingungen</router-link
            >
              <router-link  class="link" :to="{ name: 'Datenschutz' }"
              >Datenschutz</router-link
            >
              <router-link class="link" :to="{ name: 'Impressum' }"
              >Impressum</router-link
            >

            
          </ul>
        </div>
        
      </div>
      <div class="left"  v-else-if="$route.path.includes('wettbewerb')" >

           <div class="col-1">
          <router-link class="header" :to="{ name: 'WB_Home' }"
            > <h3>Wettbewerb</h3> Literatur-Apotheke</router-link
          >
        </div>
          <div class="col-2">

          <ul>

               <router-link v-if="!user" class="link" :to="{ name: 'Kontakt' }"
              >Kontakt</router-link
            >
            <router-link class="link" :to="{ name: 'WB_Home' }">Startseite</router-link>
            <router-link class="link" :to="{ name: 'WB_Werke' }"
              >Einreichungen</router-link
            >
            <router-link v-if="admin" class="link" :to="{ name: 'WB_WerkCreate' }"
              >Werk einreichen</router-link
            >
            <router-link v-if="!this.$store.state.auth.user" class="link" :to="{ name: 'Login' }"
              >Einloggen / Registrieren</router-link
            >
             <router-link v-if="!user" class="link" :to="{ name: 'TeilnahmebedingungenWB' }"
              >Teilnahmebedingungen</router-link
            >
               <router-link v-if="!user" class="link" :to="{ name: 'Home' }"
              >Zur Apotheke</router-link
            >
                    <router-link  class="link" :to="{ name: 'Datenschutz' }"
              >Datenschutz</router-link
            >
              <router-link class="link" :to="{ name: 'Impressum' }"
              >Impressum</router-link
            >
          </ul>
        </div>
      </div>
      <div class="right">
  
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer-vue",
  components: {},
  computed: {
    user() {
      return this.$store.state.user;
    },
    admin() {
      return this.$store.state.profileAdmin;
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  margin-top: auto;
  padding: 100px 25px;
  background-color: #dddddd;
  .container {
    display: flex;
    flex-direction: column;

    @media (min-width: 800px) {
      flex-direction: row;
      padding: 0px;
    }
    > div {
      display: flex;
      flex: 1;
    }
    .left {
      padding: 32px;
      color: black;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (min-width: 800px) {
        flex-direction: row;
        align-items: initial;
        padding: 0;
      }
      .header {
        text-align: center;
        font-size: 24px;
        color: black;
        margin-bottom: 16px;
        text-decoration: none;
        font-weight: 600;
        @media (min-width: 800px) {
          text-align: initial;
        }
      }
      ul {
        padding: 5px;
        list-style: none;
        display: flex;
      }
      .col-1,
      .col-2 {
        padding: 32px;
        display: flex;
        flex: 1;
        @media (min-width: 800px) {
          padding: 0;
        }
      }
      .col-1 {
        flex-direction: column;
        h2 {
          text-align: center;
          @media (min-width: 800px) {
            text-align: initial;
          }
        }
        ul {
          margin-top: auto;
          li {
            display: flex;
            align-items: center;
           
            .svg-icon {
              width: 24px;
              height: auto;
              color: black;
            }
          }
        }
      }
      .col-2 {
        ul {
          height: 100%;
          justify-content: center;
          flex-direction: row;
          flex-wrap: wrap;
          @media (min-width: 800px) {
            flex-direction: column;
          }
          .link {
            font-size: 16px;
            font-weight: 500;
            color: black;
            text-decoration: none;
             padding:10px;
             white-space: nowrap;
          }
        }
      }
    }
    .right {
      padding: 32px;
      color: black;
      align-items: center;
      flex-direction: column;
      @media (min-width: 800px) {
        align-items: flex-end;
        padding: 0;
      }
    }
    p {
      margin-top: auto;
    }
  }
}
</style>