import Vue from 'vue'
import Vuex from 'vuex'


import axios from 'axios';
import { auth } from "./auth.module";
import { userInfo } from "./userInfo.module";

import { admin } from "./admin.module";
//import userService from '../services/user.service';
//import werkeService from '../services/werke.service';

Vue.use(Vuex)

export default new Vuex.Store({
  //strict: true,
  //plugins: [createPersistedState()],
  state: {
    headerHeight:0,
    wb_werke: [],
    werke: [],
    werkSate: null,
    inhalt: "",
    titel: "",
    motivation: "",
    postLoaded: false,
    editWerk: false,
    werkId: null,
    anwendung: 0,
    thema: 0,
    genre: 0,
    teilnahme: false,
    selbstv: false,
    quelle: "",
    autor: "",
    anwendungen: [],
    genres: [],
    themen: [],
    users: [],
    loading: false,
    loadingMessage:"",
    checkedAnwendungen: [],
    checkedThemen: [],
    checkedGenres: [],
    stammAuswahl: false,
    sortBy: "date",
    asc: false,
    showModal:false,
    modalMessage:"",
    wb_login: false
  },
  modules: {
    auth,
    userInfo,
    admin
  },
  getters: {
    werke_stamm(state) {
      var amount = 1;
      var stammWerke = state.werke.filter(werk => {
        return werk.stamm === 1
      })
      // Randomise
      for (var i = stammWerke.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = stammWerke[i];
        stammWerke[i] = stammWerke[j];
        stammWerke[j] = temp
      }
      return stammWerke.slice(0, amount)
    },

    werke_recent(state) {
      var recent_werke = state.werke.slice().sort((a, b) => {

        return b.id - a.id;

      })
      return recent_werke.slice(0, 3);
    },
    werke_recent_wb(state) {
      console.log();

      var recent_werke = state.wb_werke.slice().sort((a, b) => {

        return b.id - a.id;

      })
      console.log("recent werkte", recent_werke);
      return recent_werke.slice(0, 3);
    },
    getUserWerke(state) {
      return benutzerId => state.werke.filter(werk => {
        return werk.benutzerid === benutzerId
      })
    },

  },
  mutations: {

    deleteAnwendung(state, payload) {

      state.checkedAnwendungen.splice(payload, 1)
    },
    deleteThema(state, payload) {

      state.checkedThemen.splice(payload, 1)
    },
    deleteGenre(state, payload) {

      state.checkedGenres.splice(payload, 1)
    },



    checkAnwendungen(state, payload) {
      state.checkedAnwendungen = payload
    },

    checkThemen(state, payload) {
      state.checkedThemen = payload
    },
    checkGenres(state, payload) {
      state.checkedGenres = payload
    },
    stammAuswahl(state, payload) {
      state.stammAuswahl = payload
    },
    asc(state, payload) {
      state.asc = payload
    },
    sortBy(state, payload) {
      state.sortBy = payload
    },

    setLoading(state, payload) {
      state.loading = payload
    },

    setWerke(state, werke) {
      state.werke = werke;
    },
    toggleEditWerk(state, payload) {
      state.editWerk = payload;
    },
    setWerkState(state, payload) {
      state.werkState = payload;
    },
    updateWerkTitel(state, payload) {
      state.titel = payload
    },
    updateWerkInhalt(state, payload) {
      state.inhalt = payload
    },
    updateWerkMotivation(state, payload) {
      state.motivation = payload
    },
    updateWerkAnwendung(state, payload) {
      state.anwendung = payload
    },
    updateWerkThema(state, payload) {
      state.thema = payload
    },
    updateWerkGenre(state, payload) {
      state.genre = payload
    },
    updateWerkQuelle(state, payload) {
      state.quelle = payload
    },
    updateWerkAutor(state, payload) {
      state.autor = payload
    }
  },

  actions: {

    async getBenutzerNameById({ state }, id) {
      state
      console.log(id);
      var name = ""
      await axios.get(process.env.VUE_APP_API_URL + "benutzer/" + id).then(res => {
        console.log(res);
        name += res.data[0].Vorname + " " + res.data[0].Nachname
      })
      console.log(name);
      return name
    },

    async werkEinreichen({ state, rootState }, wettbewerb) {

      console.log(wettbewerb);
      if (rootState.userInfo.benutzerId) {
        var werk = {
          Titel: state.titel,
          Inhalt: state.inhalt,
          Autor: state.autor,
          Motivation: state.motivation,
          BenutzerID: rootState.userInfo.benutzerId,
          Stamm: 0,
          Quelle: state.quelle,
          Anwendung: state.anwendung,
          Thema: state.thema,
          Genre: state.genre,
          Wettbewerb: wettbewerb
        }

        console.log("Werk einereichen", werk);

        await axios.post(process.env.VUE_APP_API_URL + "werk", werk).then(r => {
          console.log(r.data[0])
          //set store.werkId to latest = current werk

          state.werkId = r.data[0].werk_id + 1

        }).catch(error => {
          console.log(error);

        })

        await this.dispatch("loadWerke")
        state.titel = "";
        state.anwendung = null;
        state.genre = null;
        state.thema = null;
        state.autor = "";
        state.quelle = "";
        state.motivation = "";
        state.inhalt = "";
      }
    },


    async updateWerk(payload) {

      var werk = payload

      await axios.put(process.env.VUE_APP_API_URL + "werk/" + werk.id, werk).then(r => {
        console.log(r.data[0].max)
      }
      ).catch(error => {
        console.log(error);
      })

    },

    async loadWerkew({ state }) {

      console.log("load werke");
      await axios
        .get(process.env.VUE_APP_API_URL + "werk")
        .then(response => {
          this.data = response.data;
          this.data.forEach(doc => {
            //commit("ALLE_WERKE", data.data)

            if (doc.Wettbewerb) {

              if (!state.wb_werke.some((werk) => werk.id === doc.WerkId)) {

                const werk = {
                  id: doc.WerkId,
                  inhalt: doc.Inhalt,
                  titel: doc.Titel,
                  quelle: doc.Quelle,
                  datum: doc.Timestamp,
                  autor: doc.Autor,
                  benutzerid: doc.BenutzerID,
                  motivation: doc.Motivation,
                  stamm: doc.Stamm,
                  anwendung: doc.Anwendung,
                  thema: doc.Thema,
                  genre: doc.Genre,
                  rating: doc.Rating,
                  vorname: doc.Vorname == null ? "-" : doc.Vorname,
                  nachname: doc.Nachname == null ? "-" : doc.Nachname,
                  wettbewerb: doc.Wettbewerb

                };

                state.wb_werke.push(werk);
              }
            } else {

              if (!state.werke.some((werk) => werk.id === doc.WerkId)) {

                const werk = {
                  id: doc.WerkId,
                  inhalt: doc.Inhalt,
                  titel: doc.Titel,
                  quelle: doc.Quelle,
                  datum: doc.Timestamp,
                  autor: doc.Autor,
                  benutzerid: doc.BenutzerID,
                  motivation: doc.Motivation,
                  stamm: doc.Stamm,
                  anwendung: doc.Anwendung,
                  thema: doc.Thema,
                  genre: doc.Genre,
                  rating: doc.Rating,
                  vorname: doc.Vorname == null ? "-" : doc.Vorname,
                  nachname: doc.Nachname == null ? "-" : doc.Nachname,
                  wettbewerb: doc.Wettbewerb

                };

                state.werke.push(werk);
              }
            }

          })

          //commit('setWerke',state.werke)
        }).catch(error => {
          console.log(error)
        })
    },
    async loadAnwendungen({ state }) {

      await axios
        .get(process.env.VUE_APP_API_URL + "werk/anwendungen")
        .then(response => {
          this.data = response.data;
          this.data.forEach(doc => {
            //commit("ALLE_WERKE", data.data)

            if (!state.anwendungen.some((anw) => anw.id === doc.AnwendungId)) {
              const werk = {
                id: doc.AnwendungId,
                name: doc.Anwendung,
              };
              state.anwendungen.push(werk);
            }
          })
          console.log(state.anwendungen);

          //commit('setWerke',state.werke)
        }).catch(error => {
          console.log(error)
        })
    },
    async loadThemen({ state }) {

      await axios
        .get(process.env.VUE_APP_API_URL + "werk/themen")
        .then(response => {
          this.data = response.data;
          this.data.forEach(doc => {
            //commit("ALLE_WERKE", data.data)

            if (!state.themen.some((anw) => anw.id === doc.ThemaId)) {
              const werk = {
                id: doc.ThemaId,
                name: doc.Thema,
              };
              state.themen.push(werk);
            }
          })
          console.log("Themen", state.themen);

          //commit('setWerke',state.werke)
        }).catch(error => {
          console.log(error)
        })
    },
    async loadGenres({ state }) {

      await axios
        .get(process.env.VUE_APP_API_URL + "werk/genres")
        .then(response => {
          this.data = response.data;
          this.data.forEach(doc => {
            //commit("ALLE_WERKE", data.data)

            if (!state.genres.some((gen) => gen.id === doc.GenreId)) {
              const werk = {
                id: doc.GenreId,
                name: doc.Genre,
              };
              state.genres.push(werk);
            }
          })

          //commit('setWerke',state.werke)
        }).catch(error => {
          console.log(error)
        })
    }



  },

});

