<template>
  <div class="create-post">
    <!--BlogCoverPreview v-show="this.$store.state.blogPhotoPreview" /-->
    <Modal v-if="showTeilnahme" v-on:close-modal="showTeilnahme = false">
      <TeilnahmebedingungenWB />
    </Modal>
    <Loading v-show="loading" />

    <div class="container title">
      <h1>Wettbewerbsbeitrag einreichen</h1>

      <h3 class="center">
        Einsendungen sind vom 01.03.2024 - 10.05.2024 möglich.
      </h3>

      <div class="center title container" v-if="eingereicht.length >= 3">
        Sie können maximal 3 Wettbewerbsbeiträge einreichen.
      </div>
      <div v-else>
        <p class="title center" v-if="!this.$store.state.auth.user && zeitraum">
          Bitte loggen Sie sich erst mit einem vorhandenen Benutzerkonto ein,
          oder registrieren Sie sich: <br />
          <button @click="toLogin()">Login / Registrieren</button>

          <!--BlogCoverPreview v-show="this.$store.state.blogPhotoPreview" /-->
        </p>
        <div v-else-if="zeitraum">
          <div class="center">
            Sie haben {{ eingereicht.length }} von 3 Werken eingereicht.
          </div>

          <div :class="{ invisible: !error }" class="err-message">
            <p><span>Error:</span>{{ this.errorMsg }}</p>
          </div>
          <div class="blog-info">
            <span class=""
              >Titel:<input
                class="title-input"
                type="text"
                placeholder=""
                v-model="titel"
            /></span>

            <span class="autor"
              >Autor:
              <input class="title-input autor" type="text" v-model="autor"
            /></span>
          </div>
          <div class="editor">
            <vue-editor :editorOptions="editorSettings" v-model="inhalt" />
            <p
              class="char-count"
              :class="[plainText(inhalt).length > this.inhaltMax ? 'red' : '']"
            >
              {{ plainText(inhalt).length }} / {{ this.inhaltMax }}
            </p>
          </div>
          <div class="blog-info"></div>
          <div class="editor motivation">
            <span>Motivation:</span>
            <vue-editor
              class="serif"
              :editorOptions="editorSettings2"
              v-model="motivation"
            />
            <p
              class="char-count"
              :class="[
                plainText(motivation).length > this.motiMax ? 'red' : '',
              ]"
            >
              {{ plainText(motivation).length }} / {{ this.motiMax }}
            </p>
          </div>

          <div class="dropdownArea">
            <div class="dropdownRow">
              <p><span>Anwendung: </span></p>
              <select v-model="anwendung">
                <option
                  v-for="(el, index) in this.$store.state.anwendungen"
                  :key="index"
                  :value="index + 1"
                >
                  {{ el.name }}
                </option>
              </select>
            </div>

            <div class="dropdownRow">
              <p><span>Genre: </span></p>

              <select v-model="genre" required>
                <option
                  v-for="(el, index) in this.$store.state.genres"
                  :key="index"
                  :value="index + 1"
                >
                  {{ el.name }}
                </option>
              </select>
            </div>
            <div class="dropdownRow">
              <p><span>Thema: </span></p>
              <select v-model="thema">
                <option
                  v-for="(el, index) in this.$store.state.themen"
                  :key="index"
                  :value="index + 1"
                >
                  {{ el.name }}
                </option>
              </select>
            </div>
          </div>
          <div  class="bedingungen">
          <div>
            <input
              class="accept"
              type="checkbox"
              id="sv"
              v-model="sv"
              required
            />

            <label for="sv"
              >Ich versichere, dass ich den Text selbst verfasst habe.</label
            >
          </div>
          <div>
            <input
              class="accept"
              type="checkbox"
              id="t"
              v-model="tb"
              required
            />

            <label for="t"
              >Ich akzeptiere die
              <span class="link" @click="showTeilnahme = !showTeilnahme"
                >Teilnahmebedingungen</span
              ></label
            >
          </div>
        </div>
          <div :class="{ invisible: !error }" class="err-message red">
            <p><span></span>{{ this.errorMsg }}</p>
          </div>

          <div class="werk-actions">
            <button class=" router-button" @click="werkEinreichen">
              Werk einreichen
            </button>
            <router-link
              class="  button router-button invert-button"
              :to="{ name: 'WB_Preview' }"
            >
              Vorschau</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import BlogCoverPreview from "../components/BlogCoverPreview";
import Loading from "../../components/Loading";
//import Dropdown from "vue-simple-search-dropdown";
import Quill from "quill";
import "quill-paste-smart";
import Modal from "../../components/Modal.vue";

import TeilnahmebedingungenWB from "../../views/TeilnahmebedingungenWB.vue";

import utils from "../../utils";
import werkeService from "../../services/werke.service";
//import werkeService from '../services/werke.service';
window.Quill = Quill;

export default {
  name: "CreatePost",
  data() {
    return {
      eingereicht: [],
      showTeilnahme: false,
      inhaltMax: 5000,
      motiMax: 1000,
      error: null,
      errorMsg: null,
      loading: null,

      zeitraum: false,
      editorSettings: {
        modules: {
          toolbar: [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
        },
      },

      editorSettings2: {
        modules: {
          toolbar: false,
        },
      },
    };
  },
  components: {
    // BlogCoverPreview,
    Loading,
    Modal,
    TeilnahmebedingungenWB,
    // Dropdown,
  },

  async mounted() {
    // Create date from input value
    var start = new Date("03/01/2024");

    var ende = new Date("05/10/2024");

    // Get today's date
    var todaysDate = new Date();
    console.log(start);
    // call setHours to take the time out of the comparison
    if (
      start.setHours(0, 0, 0, 0) <= todaysDate.setHours(0, 0, 0, 0) &&
      ende.setHours(0, 0, 0, 0) >= todaysDate.setHours(0, 0, 0, 0)
    ) {
      this.zeitraum = true;
    }
    let id = this.$store.state.userInfo.benutzerId;
    console.log(id);
    this.eingereicht = await werkeService.wettbewerbWerkeByBenutzerID(id);
    console.log(this.eingereicht);
    this.$store.state.quelle = "Literaturwettbewerb 2024";
    this.autor =
      this.$store.state.userInfo.vorname +
      " " +
      this.$store.state.userInfo.nachname;
  },
  methods: {
    toLogin() {
      this.$store.state.wb_login = true;
      this.$router.push({ name: "Login" });
    },
    setAnwendung(payload) {
      console.log("Set Anwendung", payload);
      this.$store.commit("updateWerkAnwendung", payload);
      return true;
    },
    setThema(payload) {
      this.$store.commit("updateWerkThema", payload);
      return true;
    },
    setGenre(payload) {
      this.$store.commit("updateWerkGenre", payload);
      return true;
    },
    fileChange() {
      this.file = this.$refs.blogPhoto.files[0];
      const fileName = this.file.name;
      this.$store.commit("fileNameChange", fileName);
      this.$store.commit("createFileURL", URL.createObjectURL(this.file));
    },
    openPreview() {
      this.$store.commit("openPhotoPreview");
    },
    async werkEinreichen() {
      if (
        this.titel.length !== 0 &&
        this.inhalt.length !== 0 &&
        this.motivation.length !== 0 &&
        this.anwendung &&
        this.thema &&
        this.genre &&
        this.quelle !== 0 &&
        this.tb &&
        this.sv
      ) {
        if (
          this.plainText(this.inhalt).length > this.inhaltMax ||
          this.plainText(this.motivation).length > this.motiMax
        ) {
          this.error = true;
          this.errorMsg = "Text oder Motivation hat zu viele Zeichen.";
          setTimeout(() => {
            this.error = false;
          }, 5000);
          return;
        }
        this.loading = true;
        await this.$store.dispatch("werkEinreichen", true);

        this.loading = false;

        this.$router.push({
          name: "WB_WerkView",
          params: { werkid: this.$store.state.werkId },
        });
        this.$store.state.modalMessage =
          "Vielen Dank! Sie haben Ihr Werk erfolgreich im Wettbewerb eingereicht.";
        this.$store.state.showModal = true;
        return;
      }
      this.error = true;
      this.errorMsg =
        "Alle Felder müssen ausgefüllt sein, damit Sie das Werk einreichen können.";
      setTimeout(() => {
        this.error = false;
      }, 5000);
      return;
    },
    plainText(html) {
      return utils.plainText(html);
    },
    closeModal() {
      this.showTeilnahme = false;
    },
  },
  beforeDestroy() {
    this.autor = "";
    this.$store.state.quelle = "";
  },
  computed: {
    sv: {
      get() {
        return this.$store.state.selbstv;
      },
      set(payload) {
        this.$store.state.selbstv = payload;
      },
    },
    tb: {
      get() {
        return this.$store.state.teilnahme;
      },
      set(payload) {
        this.$store.state.teilnahme = payload;
      },
    },

    anwendung: {
      get() {
        return this.$store.state.anwendung;
      },
      set(payload) {
        this.$store.commit("updateWerkAnwendung", payload);
      },
    },
    thema: {
      get() {
        return this.$store.state.thema;
      },
      set(payload) {
        this.$store.commit("updateWerkThema", payload);
      },
    },
    genre: {
      get() {
        return this.$store.state.genre;
      },
      set(payload) {
        this.$store.commit("updateWerkGenre", payload);
      },
    },

    getAnwendungenOptions() {
      var options = [];
      this.$store.state.anwendungen.forEach((e) => {
        var option = {
          id: e.id,
          name: e.name,
        };
        options.push(option);
      });
      return options;
    },
    getGenreOptions() {
      var options = [];
      this.$store.state.genres.forEach((e) => {
        var option = {
          id: e.id,
          name: e.name,
        };
        options.push(option);
      });
      return options;
    },
    getThemaOptions() {
      var options = [];
      this.$store.state.themen.forEach((e) => {
        var option = {
          id: e.id,
          name: e.name,
        };
        options.push(option);
      });
      return options;
    },

    profileId() {
      return this.$store.state.profileId;
    },
    blogCoverPhotoName() {
      return this.$store.state.blogPhotoName;
    },

    titel: {
      get() {
        return this.$store.state.titel;
      },
      set(payload) {
        this.$store.commit("updateWerkTitel", payload);
      },
    },
    autor: {
      get() {
        return this.$store.state.autor;
      },
      set(payload) {
        this.$store.commit("updateWerkAutor", payload);
      },
    },
    quelle: {
      get() {
        return this.$store.state.quelle;
      },
      set() {
        this.$store.commit("updateWerkQuelle", "Literaturwettbewerb 2022");
      },
    },
    inhalt: {
      get() {
        return this.$store.state.inhalt;
      },
      set(payload) {
        this.$store.commit("updateWerkInhalt", payload);
      },
    },
    motivation: {
      get() {
        return this.$store.state.motivation;
      },
      set(payload) {
        this.$store.commit("updateWerkMotivation", payload);
      },
    },
  },
};
</script>

<style lang="scss" scoped>

.bedingungen{
text-align: left;
margin:50px;

}
h1 {
  //padding: 30px;

  font-size: calc(14px + 1.5vw);
  font-weight: lighter;
}

.link {
  text-decoration: underline;
}

h1 {
  margin-top: 50px;
  text-align: center;
}

.accept {
  margin-top: 15px;
}

.create-post {
  position: relative;
  height: 100%;

  .werk-actions {
    margin-top: 200px;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
  }

  .router-button {
    text-decoration: none;
    color: #fff;
  }

  button,
  .router-button {
    transition: 0.5s ease-in-out all;
    align-self: center;
    font-size: 18px;
    cursor: pointer;
    border-radius: 20px;
    padding: 12px 24px;
    color: #fff;
    background-color: #303030;
    text-decoration: none;

    margin-top: 100px;
    &:hover {
      background-color: rgba(48, 48, 48, 0.7);
    }
  }

  .router-button:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
  }

  .container {
    position: relative;
    height: 100%;
    padding: 10px 25px 60px;
  }

  // error styling
  .invisible {
    opacity: 0 !important;
  }

  .err-message {
    width: 100%;
    padding: 12px;
    border-radius: 8px;

    margin-bottom: 10px;
    opacity: 1;
    transition: 0.5s ease all;
    text-align: center;

    p {
      font-size: 14px;
    }

    span {
      font-weight: 600;
    }
  }

  .blog-info {
    
    display: flex;
    margin-bottom: 32px;
    justify-content: center;

    input:nth-child(1) {
      //min-width: 300px;
    }

    input {
      transition: 0.5s ease-in-out all;
      padding: 10px 4px;
      border: none;
      border-bottom: 1px solid #303030;
      font-size: 32px;

      &:focus {
        outline: none;
        box-shadow: 0 1px 0 0 #303030;
      }
    }

    .upload-file {
      flex: 1;
      margin-left: 16px;
      position: relative;
      display: flex;

      input {
        display: none;
      }

      .preview {
        margin-left: 16px;
        text-transform: initial;
      }

      span {
        font-size: 12px;
        margin-left: 16px;
        align-self: center;
      }
    }
  }

  .motivation {
    padding-top: 50px;

    span {
      padding-left: 10px;
      padding-bottom: 10px;
      font-size: 18px;
      font-weight: bold;
    }

    .quillWrapper {
      height: max-content;
    }
  }

  .dropdownArea {
    display: flex;
    flex-direction: row;
    padding: 3vw;
    flex-wrap: wrap;
    padding-top: 80px;

    .dropdownRow {
      padding-bottom: 50px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: flex-start;

      span {
        padding-left: 10px;
        padding-bottom: 10px;
        font-size: 18px;
        font-weight: bold;
      }

      width: 400px;

      select {
        color: black;
        border-radius: 20px;
        font-size: 1.1em;
        min-width: 300px;
        padding: 20px;
        border: 1px solid;
      }

      option {
        font-size: 1.1em;
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.356);
        padding: 12px;
      }

      option:hover {
        background-color: cadetblue;
      }

      option:first-child {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }

      option:last-child {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }

      .dropdown-content {
        min-width: 350px;
        min-height: 50px;
        max-height: max-content;
        overflow: visible;
        border-radius: 20px;
        border: 1px solid;
      }
    }
  }

  .editor {
    height: 60vh;
    display: flex;
    flex-direction: column;

    .quillWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      border-radius: 20px;
      border: 1px solid;
    }

    .ql-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: scroll;
      border: none;

      border-bottom-right-radius: 20px;

      border-bottom-left-radius: 20px;
    }

    .ql-editor {
      padding: 20px 16px 30px;

      font-family: "Source Serif Pro";
    }

    .ql-editor::-webkit-scrollbar {
      height: 90%;
      width: 10px;
    }

    .ql-editor::-webkit-scrollbar-track {
      background-color: white;
    }

    .ql-editor::-webkit-scrollbar-thumb {
      background: #4e4e4e;
      border-radius: 25px;
    }

    .ql-container::-webkit-scrollbar {
      display: none;
    }

    .ql-toolbar {
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.63);
      border: none;
    }
  }
}
</style>
