<template>
  <div class="fav-card">
    <router-link
      class="link"
      :to="{ name: 'WerkView', params: { werkid: fav.werk_id } }"
      :title="werk.Titel"
    >      <span  v-if="false" class="date">
        {{
          new Date(fav.timestamp).toLocaleString("de", {
            dateStyle: "short",
          })
        }}
      </span>
      <span class="titel"> {{ werk.Titel }} </span>
      <span class="autor">von {{ werk.Autor }}</span>
      <div v-if="this.fav.grund" class="grund">
        <p class="serif">
          <span>" {{ this.fav.grund }} "</span>
        </p>
      </div></router-link
    >
    <div class="info">

      <div class="icons">
       
      </div>
    </div>
     <Delete v-if="false" class="icon" />
  </div>
</template>

<script>
//import Arrow from "../assets/Icons/arrow-right-light.svg";

import Delete from "../assets/Icons/trash-regular.svg";
import werkeService from '../services/werke.service';

export default {
  name: "WerkFavorit",
  //props: ["werk"],

  props: {
    fav: Object,
    admin: Boolean,
    header: Boolean,
  },
  components: {
    // Arrow,

    Delete,
  },
  data() {
    return {
      werk: {null: null},
    };
  },
async created(){
this.werk= await werkeService.getWerkById(this.fav.werk_id)
}
};
</script>

<style lang="scss" scoped>
.grund {
  padding: 10px;
}
ul.list li,
span {
  display: block;
}
.fav-card {
  padding: 30px;

  position: relative;
  white-space: pre-line;
  border-bottom: 1px solid;
  :hover {
  }
  .icons {
    display: flex;

    align-content: center;
    z-index: 99;
    float: right;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      //background-color: #fff;
      transition: 0.5s ease all;
      &:hover {
        background-color: #303030;
        .edit,
        .delete {
          path {
            fill: #fff;
          }
        }
      }
      &:nth-child(1) {
        margin-right: 8px;
      }
      .edit,
      .delete {
        pointer-events: none;
        height: 15px;
        width: auto;
      }
    }
  }

  .titel {
    font-size: 24px;
    color: black;
  }
  .autor {
    font-size: 18px;
  }
  .grund {
    color: #000000;
    // padding: 20px;
    font-size: 18px;
    font-weight: bold;
  }

  //background-color: #fff;

  transition: 0.3s ease all;

  &:hover {
    //transform: rotateZ(-1deg) scale(1.01);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background-color: rgb(248, 247, 245);
  }
  div {
    .id,
    .title,
    .author,
    .date,
    .username {
      // width: 30%;
      font-size: calc(0.5rem + 0.5vw);
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 1; /* fallback */
      max-height: 200px; /* fallback */
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
    .author {
      // width: 20%;
    }
    .date {
      //width: 8%;
      float:right;
      left:100%;
    }

    .username {
      // width: 10%;
    }
    .id {
      width: 6%;
    }

    .props {
      display: flex;

      .col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 10px;
      }
      .col1 {
        color: gray;
        float: right;
      }
    }
  }

  .header {
    position: relative;
    top: 0;
    span {
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    z-index: 3;
    //padding: 10px 16px;
    color: #000;

    span {
      height: 50px;

      white-space: normal;
      word-break: break-word;
      display: flex;
      align-items: center;
    }

    .link {
      display: inline-flex;
      align-items: center;
      margin-top: auto;
      font-weight: 500;

      font-size: 12px;

      transition: 0.5s ease-in all;
      &:hover {
        color: rgba(48, 48, 48, 0.8);
      }
      .arrow {
        width: 10px;
      }
    }
  }
}
</style>
