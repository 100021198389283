<template>
  <div class="container">
    <h1 v-if="wb" class="">Werke Wettbewerb</h1>
    <h1 v-if="!wb" class="">Werke</h1>

    <div class="blog-card-wrap">
      <div class="sort-search-wrapper">
        <div class="sort-wrapper">
          <span>Sortieren nach </span>

          <button
            type="button"
            name="Datum"
            value="datum"
            @click="sortByThis('datum')"
          >
            Datum <span v-if="this.asc">&#9650;</span
            ><span v-else>&#9660;</span>
          </button>
          <br />
          <input
            type="checkbox"
            id="stamm"
            v-model="stammFilter"
            @change="resetList()"
          />
          <label for="stamm">Stammpräparate</label>
          <br />
          <input
            type="checkbox"
            id="old"
            v-model="oldWerke"
            @change="resetList()"
          />
          <label for="old">Älter als ein Jahr</label>
        </div>
        <div class="search-wrapper">
          <input
            type="text"
            v-model="search"
            @keyup="resetList()"
            placeholder="Suche.."
            class="search-input"
          />
        </div>
      </div>
      <div class="found">
        Es wurden <strong>{{ this.totalAmount }}</strong> Werke gefunden
        <br />
      
      </div>

      <div class="blog-cards">
        <div class="row-wrapper">
          <!--input type="checkbox" style="opacity: 0" /-->
          <WerkCard :header="true" />
        </div>
        <div v-for="werk in this.werkeToShow" :key="werk.WerkId">
          <div class="row-wrapper" >
      
            <!--input
              class="select"
              :value="werk.id"
              @change="toggleStack(werk.id)"
              type="checkbox"
              name="bearbeiten"
              :id="'bearbeiten' + werk.id"
            /-->
            <div
              @click="
                showIcons === werk.WerkId
                  ? (showIcons = -1)
                  : (showIcons = werk.WerkId)
              "
            >

              <WerkCard
                :class="
                  gesperrt.some((w) => {
                    return w.werk_id == werk.WerkId;
                  })
                    ? 'bg-gesperrt'
                    : nugget.some((w) => {
                        return w.werk_id == werk.WerkId;
                      })
                    ? 'bg-nugget'
                    : markiert.some((w) => {
                        return w.werk_id == werk.WerkId;
                      })
                    ? 'bg-markiert'
                    : ''
                "
                :werk="werk"
              />

              <div class="gesperrt">
                <span
                  class="bg-gesperrt p-1"
                  v-for="sperrung in gesperrt.filter((s) => {
                    return s.werk_id == werk.WerkId;
                  })"
                  :key="sperrung.sperrung_id"
                >
                  Gesperrt: {{ sperrung.grund }}
                </span>
                <span
                  class="bg-nugget p-1"
                  v-for="nugget in nugget.filter((s) => {
                    return s.werk_id == werk.WerkId;
                  })"
                  :key="nugget.nugget_id"
                >
                  Nugget: {{ nugget.grund }}
                </span>
                <span
                  class="bg-markiert p-1"
                  v-for="markierung in markiert.filter((s) => {
                    return s.werk_id == werk.WerkId;
                  })"
                  :key="markierung.markierung_id"
                >
                  Markiert: {{ markierung.grund }}
                </span>
              </div>
            </div>
          </div>
          <div v-show="showIcons === werk.WerkId">
            <div class="icons">
              <span>Stamm:</span>

              <input
                type="checkbox"
                name=""
                id=""
                v-model="werk.Stamm"
                true-value="1"
                false-value="0"
                @change="werkUpdate(werk)"
              />

             
              <button class="glass-btn" @click="werkAnsehen(werk.WerkId)">
                Ansehen
              </button>

              <button
                class="glass-btn"
                @click="userBearbeiten(werk.BenutzerID)"
              >
                Benutzer bearbeiten
              </button>

              <SperrenWerk
                :werkid="werk.WerkId"
                @ok="doSperren($event, werk)"
              />
              <MarkierenWerk
                :werkid="werk.WerkId"
                @ok="doMarkieren($event, werk)"
              />
              <NuggierenWerk
                :werkid="werk.WerkId"
                @ok="doNuggieren($event, werk)"
              />

              <DeleteWerk
                :werkid="werk.WerkId"
                @ok="doDelete(werk, werk.WerkId)"
              />

              <button class="btn" @click="werkBearbeiten(werk.WerkId)">
                Bearbeiten
              </button>
            </div>
       
            <Werk class="paper" :currentWerk="werk" :wettbewerb="wb" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WerkCard from "../../components/WerkSlot";
//import { werkeFiltered } from "../services/werke.service";
//import userService from "../../services/user.service"
import DeleteWerk from "../../dialogs/werk/DeleteWerk.vue";
import NuggierenWerk from "../../dialogs/werk/NuggierenWerk.vue";
import SperrenWerk from "../../dialogs/werk/SperrenWerk.vue";
import MarkierenWerk from "../../dialogs/werk/MarkierenWerk.vue";
import werkeService from "../../services/werke.service";
import Werk from "../../components/Werk.vue";
export default {
  name: "admin-werke",
  components: {
    WerkCard,
    DeleteWerk,
    SperrenWerk,
    MarkierenWerk,
    NuggierenWerk,
    Werk,
  },
  data() {
    return {
      search: "",
      amount: 8,
      allFilteredWerke: [],
      werkeToShow: [],
      checkedAnwendungen: [],
      checkedThemen: [],
      checkedGenres: [],
      totalAmount: 0,
      sortBy: "datum",
      asc: false,
      showIcons: -1,
      showSperrung: -1,
      showMarkierung: -1,
      showNugget: -1,
    };
  },
  async mounted() {
    console.log("Mount werke Admin");
    this.werkeToShow = await this.fetchWerkeToShow();
    window.addEventListener("scroll", this.handleScroll);
  },

  created() {
    console.log("Created werke Admin");
  },
  methods: {
    toggleStack(werkid) {
      var index = this.$store.state.admin.werkeStack.indexOf(werkid);

      if (index === -1) {
        this.$store.state.admin.werkeStack.push(werkid);
      } else {
        this.$store.state.admin.werkeStack.splice(index, 1);
      }
    },
    async doDelete(werk) {
      await werkeService.werkLöschen(werk.WerkId);

      this.werkeToShow.splice(
        this.werkeToShow
          .map(function(e) {
            return e.WerkId;
          })
          .indexOf(werk.WerkId),
        1
      );

    },

    async werkUpdate(werk) {
      await werkeService.updateWerk(werk);
    },

    async doSperren(text, werk) {
      console.log(text, werk);
      await werkeService.werkSperren(
        werk.WerkId,
        this.$store.state.userInfo.benutzerId,
        text
      );

      this.gesperrt = true;
      //this.$router.go();

      await this.$store.dispatch("admin/loadWerkeGesperrt");
    },
    async doMarkieren(text, werk) {
      await werkeService.werkMarkieren(
        werk.WerkId,
        this.$store.state.userInfo.benutzerId,
        text
      );
      this.markiert = true;

      await this.$store.dispatch("admin/loadWerkeMarkiert");
    },

    async doNuggieren(text, werk) {
      await werkeService.werkNuggieren(
        werk.WerkId,
        this.$store.state.userInfo.benutzerId,
        text
      );
      this.nuggiert = true;

      await this.$store.dispatch("admin/loadWerkeNuggets");
    },
    async doStamm(werk) {
      await werkeService.werkStamm(werk, true);

      await this.$store.dispatch("admin/loadWerkeNuggets");
    },

    async sortByThis(string) {
      console.log(string);
      if (this.sortBy == string) {
        this.asc = !this.asc;
      }
      this.sortBy = string;

      await this.resetList();
    },

    async spliceList(index) {
      console.log("splice", index);
      var werke = this.$store.state.admin.werkeAll;
      const filtersList = werke.filter((element) => element !== index);
      this.$store.state.admin.werkeAll = filtersList;
      await this.resetList();
    },
    async resetList() {
      console.log("RESET___________________________");
      this.werkeToShow = [];
      // console.log("Reset Werke List!", this.werkeToShow);
      this.werkeToShow = await this.fetchWerkeToShow();
    },

    async fetchWerkeToShow() {
      //filter

      this.query = "";

      var searchWords = this.search.split(" ");
      for (var word of searchWords) {
        this.query += "search=" + word.toLowerCase();
        this.query += "&";
      }

      if (this.stammFilter) {
        this.query += "stamm=1&";
      }

      if (this.oldWerke) {
        this.query += "old=1&";
      }

      this.query += "wb=" + (this.wb ? "1&" : "0&");

      if (this.sortBy == "datum" || this.sortBy == "") {
        this.asc ? (this.query += "order=1&") : (this.query += "order=0&");
      }

      this.query += "limit=" + this.amount + "&";
      this.query += "offset=" + this.werkeToShow.length + "&";

      var result = await werkeService.getWerkeAll(this.query);

      if (result.length > 0) {
        this.totalAmount = result[0].total_count;
      } else {
        this.totalAmount = 0;
      }

      console.log(result);
      return result;
    },

    /*
    getWerkeToShow() {
      this.allFilteredWerke = this.werkeFiltered();
      // console.log("all filtered werke", this.allFilteredWerke);
      var ret = [];

      var lastIndex = this.werkeToShow.length;
      var newLength =
        this.allFilteredWerke.length - this.amount < this.werkeToShow.length //if
          ? this.allFilteredWerke.length
          : lastIndex + this.amount;
      console.log("NEW LENGTH", newLength);
      for (let i = lastIndex; i < newLength; i++) {
        ret.push(this.allFilteredWerke[i]);
      }

      return ret;
    },
    */

    async handleScroll() {
      if (
        window.scrollY + window.innerHeight >=
          document.body.scrollHeight - window.innerHeight &&
        !(this.werkeToShow.length >= this.totalAmount)
      ) {
        console.log("load new werke");
        this.werkeToShow = [
          ...this.werkeToShow,
          ...(await this.fetchWerkeToShow()),
        ];
      }
    },

    /*
    werkeFiltered() {
      console.log("GETTING WERKE");
      var filteredWerke = [];

      // Checkboxes

      for (var w of this.$store.state.admin.werkeAll) {
        if (
          (this.checkedAnwendungen.length > 0
            ? this.checkedAnwendungen.some((a) => a.id === w.anwendung)
            : true) &&
          (this.checkedThemen.length > 0
            ? this.checkedThemen.some((a) => a.id === w.thema)
            : true) &&
          (this.checkedGenres.length > 0
            ? this.checkedGenres.some((a) => a.id === w.genre)
            : true) &&
          (this.stammFilter ? w.stamm : true) &&
          (this.wb ? w.wettbewerb : !w.wettbewerb)
        ) {
          filteredWerke.push(w);
        }
      }

      filteredWerke = filteredWerke.filter((post) => {
        console.log();
        var includes =
          post.id.toString().includes(this.search.toLowerCase()) ||
          post.titel.toLowerCase().includes(this.search.toLowerCase()) ||
          post.autor.toLowerCase().includes(this.search.toLowerCase()) ||
          post.inhalt.toLowerCase().includes(this.search.toLowerCase()) ||
          post.vorname.toLowerCase().includes(this.search.toLowerCase()) ||
          post.nachname.toLowerCase().includes(this.search.toLowerCase());
        return includes;
      });

      console.log(filteredWerke);

      if (this.sortBy == "datum" || this.sortBy == "") {
        filteredWerke = filteredWerke.sort((a, b) => {
          var dateA = new Date(a.datum);
          var dateB = new Date(b.datum);
          return this.asc ? dateA - dateB : dateB - dateA;
        });
      }

      if (this.sortBy == "id") {
        filteredWerke = filteredWerke.sort((a, b) => {
          return this.asc ? a.id - b.id : b.id - a.id;
        });
      }

      console.log("FILTERED WERKE:", filteredWerke);
      return filteredWerke;
    },


    */
    werkBearbeiten(id) {
      this.$router.push({
        name: "AdminEditWerk",
        params: { werkid: id },
      });
    },
    werkAnsehen(id) {
      if (this.wb) {
        let routeData = this.$router.resolve({
          name: "WB_WerkView",
          params: { werkid: id },
        });
        window.open(routeData.href, "_blank");
      } else {
        let routeData = this.$router.resolve({
          name: "WerkView",
          params: { werkid: id },
        });
        window.open(routeData.href, "_blank");
      }
    },

    userBearbeiten(id) {
      this.$router.push({
        name: "AdminEditBenutzer",
        params: { benutzerid: id },
      });
    },
  },

  computed: {
    werke() {
      return this.getWerkeToShow();
    },

    gesperrt() {
      return this.$store.state.admin.werkeGesperrt;
    },
    markiert() {
      return this.$store.state.admin.werkeMarkiert;
    },
    nugget() {
      return this.$store.state.admin.werkeNuggets;
    },

    wb() {
      return this.$store.state.admin.wb;
    },
    stammFilter: {
      get() {
        return this.$store.state.admin.stammFilter;
      },
      set(payload) {
        this.$store.state.admin.stammFilter = payload;
      },
    },
    oldWerke: {
      get() {
        return this.$store.state.admin.oldWerke;
      },
      set(payload) {
        this.$store.state.admin.oldWerke = payload;
      },
    },
    stack: {
      get() {
        console.log("get werkesrack");
        return this.$store.state.admin.werkeStack;
      },
      set(payload) {
        this.$store.commit("admin/mutateWerkeStack", payload);
      },
    },
    editWerk: {
      get() {
        return this.$store.state.editWerk;
      },
      set(payload) {
        this.$store.commit("toggleEditWerk", payload);
      },
    },
    profileAdmin() {
      return true; //this.$store.state.profileAdmin;
    },
  },
  beforeDestroy() {
    this.checkedAnwendungen = [];
    this.checkedThemen = [];
    this.checkedGenres = [];
    this.$store.commit("toggleEditWerk", false);
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    async wb() {
      await this.resetList();
    },
  },
};
</script>

<style lang="scss" scoped>
.row-wrapper {
  display: flex;
  width: 100%;
  align-items: center;

  div {
    width: 100%;
  }
  input {
    transform: scale(1.5);
  }
}
.icons {
  display: flex;
  position: relative;
  width: 100%;
  gap: 1px;
  padding-left: 30px;
  padding-bottom: 30px;
  button {
    border-radius: 10px;
  }

  align-items: baseline;
}
.found {
  text-align: center;
  font-size: 1.1em;
  padding-bottom: 20px;
}
.sort-search-wrapper {
  padding-top: 20px;
  padding-left: 2vw;
  padding-right: 2vw;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-wrapper {
  display: inline-block;
  margin-right: 0;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 50px;
  right: 0;
  input {
    border-radius: 10px;

    max-width: 800px;
    padding: 10px;
    font-size: 18px;
  }
}
.filterBereich {
  padding-top: 20px;
  padding: 20px;
  //max-width: 1200px;
  height: 100%;
  display: flex;

  align-content: center;
  column-gap: 5vw;
  padding-bottom: 20px;
  font-size: calc(0.6em + 0.5vw);
  margin: 0 auto;
  flex-wrap: wrap;

  .filterspalte {
    width: calc(25% + 50px);
    overflow: visible;
    padding-top: 30px;
    span {
      font-size: calc(1em + 1vw);
    }
    label {
      white-space: nowrap;
      vertical-align: middle;
      position: inherit;
    }

    input {
      transition: 750ms ease all;
      appearance: none;
      border: none;
      // background: #fff;
      outline: none;
      background-color: #fafafa;
      border: 1px solid #cacece;
      box-shadow: 0 0px 2px rgba(0, 0, 0, 0.5),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 10px;
      vertical-align: middle;
      // border-radius: 30px;
      display: inline-block;
      position: relative;
      //height: 30px;
      // border-radius: 100px;
    }

    input:checked {
      content: "\2714";
      padding-left: 25px;
      //position:absolute;
      color: #000000;
      background-color: #195073;
    }

    .field {
      white-space: nowrap;
    }
    .field:hover {
      font-weight: bold;
    }
  }
}

@media (min-width: 1200px) {
  .filterBereich {
    justify-content: center;
  }
}

@media (max-width: 750px) {
  .filterBereich {
    //justify-content: space-between;
    padding-right: 10px;
  }
}

.sort-wrapper {
  vertical-align: middle;
  height: 100px;
  button {
    margin-left: 10px;
    margin-right: 10px;
    height: 30px;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;

    //vertical-align: middle;
  }
}
.blog-cards {
  padding: 50px;
  padding-bottom: 200px;
  position: relative;

  .toggle-edit {
    display: flex;
    align-items: center;
    position: absolute;
    top: -70px;
    right: 1;

    span {
      margin-right: 16px;
    }
    input[type="checkbox"] {
      position: relative;
      border: none;
      -webkit-appearance: none;
      background: #fff;
      outline: none;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    input[type="checkbox"]:before {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      top: 0;
      left: 0;
      background: #303030;
      transform: scale(1.1);
      transition: 750ms ease all;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    input:checked[type="checkbox"]:before {
      background: #fff;
      left: 52px;
    }
  }
}
</style>
