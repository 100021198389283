<template>
  <div class="form-wrap">
    <form action="" class="login">
      <p class="login-register">
        Sie haben noch keinen Account?
        <router-link class="router-link" :to="{ name: 'Register' }"
          >Jetzt registrieren</router-link
        >
      </p>
      <h2>Einloggen</h2>
      <div class="inputs">
        <div class="input">
          <input type="text" placeholder="Email" v-model="email" />
          <email class="icon" />
        </div>
        <div class="input">
          <input type="password" placeholder="Passwort" v-model="password" />
          <password class="icon" />
        </div>
        <div v-show="error" class="error">{{ this.errorMsg }}</div>
      </div>
      <router-link class="forgot-password" :to="{ name: 'ForgotPassword' }"
        >Passwort vergessen?</router-link
      >
      <button @click.prevent="handleLogin">Einloggen</button>
      <div class="angle"></div>
    </form>
    <div class="background"></div>
    <ConfirmDialog
      v-if="this.confirmMessage"
      @ok="$router.push('/')"
      ok="Ok"
      title="Email bestätigen"
      message="Bitte überprüfen Sie Ihr Postfach und bestätigen Ihre Email-Adresse. Wir haben Ihnen hiermit nochmals eine Bestätigungsmail geschickt.  "
    />
  </div>
</template>

<script>
import email from "../assets/Icons/envelope-regular.svg";
import password from "../assets/Icons/lock-alt-solid.svg";
import AuthService from "../services/auth.service";

import ConfirmDialog from "../dialogs/main/EmptyConfirm";
export default {
  name: "Login",
  components: {
    email,
    password,
    ConfirmDialog,
  },
  data() {
    return {
      email: "",
      password: "",
      username: "",
      error: null,
      errorMsg: "",
      confirmMessage: false,
    };
  },
  methods: {
    async handleLogin() {
      var unconfirmed = false;

      var gesperrt = false;
      var user = {
        email: this.email.replace(/\s/g, ""),
        password: this.password,
      };
      this.$store.state.loading = true;

      await this.$store.dispatch("auth/login", user).then(
        (response) => {
          if (response.oldUser) {
            console.log("Handle Old User" + response.email);

            this.$store.state.loading = false;
            this.$router.push({
              name: "old-user",
              params: { email: response.email },
            });
          } else if (response.gesperrt) {
            gesperrt = true;
          } else if (response.confirm) {
            unconfirmed = true;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.errorMsg = "Email-Adresse oder Passwort falsch";
          this.error = true;
          this.$store.state.loading = false;
          setTimeout(() => {
            this.error = false;
          }, 5000);
          return;
        }
      );

      if (gesperrt) {
        try {
          this.errorMsg = "Konto gesperrt";
          this.error = true;
          this.$store.state.loading = false;
          setTimeout(() => {
            this.error = false;
          }, 5000);
        } catch (e) {
          console.log(e);
        }
      } else if (unconfirmed) {
        try {
          await AuthService.mailConfirmation(this.email);
        } catch (e) {
          console.log(e);
        }
        this.$store.state.loading = false;
        this.confirmMessage = true;
      }

      await this.$store.dispatch(
        "userInfo/setCurrentUser",
        this.$store.state.auth.user
      );

      // load Admin stuff...
      var admin = this.$store.getters["auth/admin"];
      if (admin) {
        /* await this.$store.dispatch("admin/loadWerkeAll");
        await this.$store.dispatch("admin/loadWerkeGesperrt");
        await this.$store.dispatch("admin/loadWerkeMarkiert");
        await this.$store.dispatch("admin/loadWerkeNuggets");
        await this.$store.dispatch("admin/loadBenutzerAll");
        await this.$store.dispatch("admin/loadBenutzerGesperrt");
        await this.$store.dispatch("admin/loadBenutzerMarkiert");
*/
        //await this.$store.dispatch("admin/loadKommentarMeldungen");
      }
      this.$store.state.loading = false;

      if (this.$store.state.wb_login) {
        this.$store.state.wb_login = false;
        this.$router.push({ name: "WB_WerkCreate" });
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="scss">
.form-wrap {
  background-color: white;;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
  width: 100%;
  min-height: 800px;

  @media (min-width: 900px) {
    width: 100%;
  }
  .router-link {
    color: #000;
  }

  form {
    padding: 0 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    background-color: white;;
    @media (min-width: 900px) {
      padding: 0 50px;
    }
    h2 {
      text-align: center;
      font-size: 32px;
      color: #303030;
      margin-bottom: 40px;
      margin-top: 40px;
      @media (min-width: 900px) {
        font-size: 40px;
      }
    }
    .inputs {
      width: 100%;
      max-width: 350px;
      .input {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        input,
        textarea {
          width: 100%;
          border: none;
          background-color: #f2f7f6;
          padding: 4px 4px 4px 30px;
          height: 50px;
          &:focus {
            outline: none;
          }
        }
        .icon {
          width: 12px;
          position: absolute;
          left: 6px;
        }
      }
    }
    .forgot-password {
      text-decoration: none;
      color: #000;
      cursor: pointer;
      font-size: 14px;
      margin: 16px 0 32px;
      border-bottom: 1px solid transparent;
      transition: 0.5s ease all;
      &:hover {
        border-color: #303030;
      }
    }
    .angle {
      display: none;
      position: absolute;
      background-color: #fff;
      transform: rotateZ(3deg);
      width: 80px;
      right: -40px;
      height: 101%;
      @media (min-width: 900px) {
        display: initial;
      }
    }
  }
  .background {
    display: none;
    flex: 2;
    background-size: cover;
    background-image: url("../assets/Aposchrank.jpg");
    background-attachment: fixed;

    background-position-y: top;
    //width: 100%;
    //height: 100%;
    @media (min-width: 900px) {
      display: initial;
    }
  }
}
</style>
