<template>
  <div class="container">
    <div class="blog-card-wrap">
      <div class="sort-search-wrapper"></div>
      <h1 class="">Markierte Werke</h1>
      <div class="sort-wrapper">
            <span>Sortieren nach </span>
      
            <button
              type="button"
              name="Datum"
              value="datum"
              @click="sortByThis('date')"
            >
              Datum <span v-if="this.asc">&#9650;</span
              ><span v-else>&#9660;</span>
            </button>
          </div>
      <div class="found">

    



        
        Es wurden <strong>{{ this.markierungenList.length }}</strong> Werke
        gefunden
      </div>

      <div class="blog-cards">
        <WerkCard :header="true" />
        <div
          class="card"
          v-for="mark in this.markierungenList"
          :key="mark.markierung_id"
        >
          <WerkCard :admin="true" :mark="mark" />

          <div class="icons">
            <EntmarkierenWerk
              :werkid="mark.werk_id"
              @ok="doEntmarkieren(mark)"
            />
            <DeleteWerk :werkid="mark.werk_id" @ok="doDelete(mark)" />

            <StammWerk :werkid="mark.werk_id" @ok="doStamm(mark)" />

            <SperrenWerk :werkid="mark.werk_id" @ok="doSperren($event,mark)" />

            <NuggierenWerk :werkid="mark.werk_id" @ok="doNugget($event,mark)" />
          </div>
        </div>
        <div class="scrolling-component"></div>
      </div>
    </div>
  </div>
</template>

<script>
import WerkCard from "../../components/WerkMarkiert.vue";
import werkeService from "../../services/werke.service";
import DeleteWerk from "../../dialogs/werk/DeleteWerk.vue";
import NuggierenWerk from "../../dialogs/werk/NuggierenWerk.vue";
import StammWerk from "../../dialogs/werk/StammWerk.vue";
import SperrenWerk from "../../dialogs/werk/SperrenWerk.vue";
import EntmarkierenWerk from "../../dialogs/werk/EntmarkierenWerk.vue";

export default {
  name: "admin-werke",
  components: {
    WerkCard,
    DeleteWerk,
    EntmarkierenWerk,
    NuggierenWerk,
    StammWerk,
    SperrenWerk,
  },
  data() {
    return {
      search: "",
      amount: 8,
      allFilteredWerke: [],
      werkeToShow: [],
      checkedAnwendungen: [],
      checkedThemen: [],
      checkedGenres: [],
      sortBy: "date",
      asc: false,
      markierungenList: "",
    };
  },

  async created() {
    this.markierungenList = await werkeService.getMarkierungen();
    //this.werkeToShow = await this.getWerkeToShow();
   // window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    async doEntmarkieren(mark) {
      await werkeService.werkEntmarkieren(mark.markierung_id);
      await this.$store.dispatch("admin/loadWerkeMarkiert");

      this.$store.state.admin.showFeedback = true;
      this.resetList();
      setTimeout(() => {
        this.$store.state.admin.showFeedback = false;
      }, 5000);
    },

    async doDelete(mark) {
      await werkeService.werkLöschen(mark.werk_id);
      await this.doEntmarkieren(mark);
      console.log("WERK DELETE");

    },
    async doNugget(text,mark) {
      await werkeService.werkNuggieren(mark.werk_id, this.$store.state.userInfo.benutzerId,
        text );
      await this.doEntmarkieren(mark);
      console.log("WERK DELETE");

     await this.$store.dispatch("admin/loadWerkeNuggets");
    },

    async doStamm(mark) {
      await werkeService.werkStamm(mark.werk_id, 1);

      await this.doEntmarkieren(mark);
      console.log("WERK DELETE");
   
    },

    async doSperren(text,mark) {
      await werkeService.werkSperren(mark.werk_id, this.$store.state.userInfo.benutzerId,
        text );
        console.log(text);

      await this.doEntmarkieren(mark);
      console.log("WERK DELETE");

      await this.$store.dispatch("admin/loadWerkeGesperrt");

    },

    sortByThis(string) {
      console.log(string);
      if (this.sortBy == string) {
        this.asc = !this.asc;
      }
      this.sortBy = string;

      this.resetList();
    },
    async sortRandom() {
      console.log("random");
      this.sortBy = "random";
      this.resetList();
    },
    async sortDatum() {
      console.log("datum");
      if (this.sortBy == "date") {
        this.asc = !this.asc;
      }
      this.sortBy = "date";

      await this.resetList();
    },
    async resetList() {
      console.log("RESET___________________________");
      this.werkeToShow = [];
      // console.log("Reset Werke List!", this.werkeToShow);
     // this.werkeToShow = await this.getWerkeToShow();

      this.markierungenList = await werkeService.getMarkierungen();


if (this.sortBy == "date" || this.sortBy == "") {
  this.markierungenList =    this.markierungenList.sort((a, b) => {
    console.log("soort", a, this.asc);

    var dateA = new Date(a.timestamp);
    var dateB = new Date(b.timestamp);
    return this.asc ? dateA - dateB : dateB - dateA;
  });
}

    },
    async getWerkeToShow() {
      this.allFilteredWerke = await this.werkeFiltered();
      // console.log("all filtered werke", this.allFilteredWerke);
      var ret = [];

      var lastIndex = this.werkeToShow.length;
      var newLength =
        this.allFilteredWerke.length - this.amount < this.werkeToShow.length //if
          ? this.allFilteredWerke.length
          : lastIndex + this.amount;
      console.log("NEW LENGTH", newLength);
      for (let i = lastIndex; i < newLength; i++) {
        ret.push(this.allFilteredWerke[i]);
      }

      return ret;
    },

    async handleScroll() {
      if (
        window.scrollY + window.innerHeight >=
          document.body.scrollHeight - window.innerHeight &&
        !(this.werkeToShow.length >= this.allFilteredWerke.length)
      ) {
  
        this.werkeToShow = [
          ...this.werkeToShow,
          ...(await this.getWerkeToShow()),
        ];
      }
    },
    async werkeFiltered() {
      console.log("GETTING WERKE");
      var filteredWerke = [];



      for (var w of this.$store.state.admin.werkeMarkiert) {
        let werk = await werkeService.getWerkById(w.werk_id);

        if (this.wb ? werk.Wettbewerb : !werk.Wettbewerb) {
          filteredWerke.push(w);
        }
      }
      console.log(filteredWerke);

      if (this.sortBy == "date" || this.sortBy == "") {
        filteredWerke = filteredWerke.sort((a, b) => {
          var dateA = new Date(a.timestamp);
          var dateB = new Date(b.timestamp);
          return this.asc ? dateA - dateB : dateB - dateA;
        });
      }

      if (this.sortBy == "random") {
        filteredWerke = filteredWerke.sort(() => {
          return Math.random() - 0.5;
        });
      }

      return filteredWerke;
    },
  },

  computed: {
    wb() {
      return this.$store.state.admin.wb;
    },
    editWerk: {
      get() {
        return this.$store.state.editWerk;
      },
      set(payload) {
        this.$store.commit("toggleEditWerk", payload);
      },
    },
    profileAdmin() {
      return true; //this.$store.state.profileAdmin;
    },
  },
  beforeDestroy() {
    this.$store.commit("toggleEditWerk", false);
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    wb() {
      // Our fancy notification (2).
      this.resetList();
    },
  },
};
</script>

<style lang="scss" scoped>
.found {
  text-align: center;
  font-size: 1.1em;
  padding-bottom: 20px;
}
.sort-search-wrapper {
  padding-top: 20px;
  padding-left: 2vw;
  padding-right: 2vw;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-wrapper {
  display: inline-block;
  margin-right: 0;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 50px;
  right: 0;
  input {
    border-radius: 10px;

    max-width: 800px;
    padding: 10px;
    font-size: 18px;
  }
}
.filterBereich {
  padding-top: 20px;
  padding: 20px;
  //max-width: 1200px;
  height: 100%;
  display: flex;

  align-content: center;
  column-gap: 5vw;
  padding-bottom: 20px;
  font-size: calc(0.6em + 0.5vw);
  margin: 0 auto;
  flex-wrap: wrap;

  .filterspalte {
    width: calc(25% + 50px);
    overflow: visible;
    padding-top: 30px;
    span {
      font-size: calc(1em + 1vw);
    }
    label {
      white-space: nowrap;
      vertical-align: middle;
      position: inherit;
    }

    input {
      transition: 750ms ease all;
      appearance: none;
      border: none;
      // background: #fff;
      outline: none;
      background-color: #fafafa;
      border: 1px solid #cacece;
      box-shadow: 0 0px 2px rgba(0, 0, 0, 0.5),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 10px;
      vertical-align: middle;
      // border-radius: 30px;
      display: inline-block;
      position: relative;
      //height: 30px;
      // border-radius: 100px;
    }

    input:checked {
      content: "\2714";
      padding-left: 25px;
      //position:absolute;
      color: #000000;
      background-color: #195073;
    }

    .field {
      white-space: nowrap;
    }
    .field:hover {
      font-weight: bold;
    }
  }
}

@media (min-width: 1200px) {
  .filterBereich {
    justify-content: center;
  }
}

@media (max-width: 750px) {
  .filterBereich {
    //justify-content: space-between;
    padding-right: 10px;
  }
}

.sort-wrapper {
  vertical-align: middle;
  height: 100px;
  button {
    margin-left: 10px;
    margin-right: 10px;
    height: 30px;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;

    //vertical-align: middle;
  }
}
.blog-cards {
  padding: 50px;
  padding-bottom: 200px;
  position: relative;

  .toggle-edit {
    display: flex;
    align-items: center;
    position: absolute;
    top: -70px;
    right: 1;

    span {
      margin-right: 16px;
    }
    input[type="checkbox"] {
      position: relative;
      border: none;
      -webkit-appearance: none;
      background: #fff;
      outline: none;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    input[type="checkbox"]:before {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      top: 0;
      left: 0;
      background: #303030;
      transform: scale(1.1);
      transition: 750ms ease all;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    input:checked[type="checkbox"]:before {
      background: #fff;
      left: 52px;
    }
  }
}
</style>
