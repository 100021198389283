<template>
       <div class="icon">
          <button class="btn" @click="handle">Aufheben</button>
          <confirm-dialog ref="confirmDialogue"></confirm-dialog>

        </div>
</template>

<script>
import ConfirmDialog from "../ConfirmDialog.vue"

export default {
  name: "EntnuggierenWerk",

  components:{ConfirmDialog},
 props:{
    werkid: Number
  },
created(){

console.log("DELETE WERK DIALOG");
},

methods:{
           async handle() {
      const ok = await this.$refs.confirmDialogue.show({          
        
        title: "Nugget aufheben",
            message:
                   "Werk ist kein Nugget mehr?",
            okButton: "Nugget aufheben",
            cancelButton: "abbrechen",
        feedback: "Werk entnuggiert"
        });
      if (ok) {
        this.$emit("ok");
      }
    },
}
  
}
</script>

<style>

</style>