<template>
  <div class="blog-card">
    <router-link
      class="link"
      :to="{
        name: $store.state.admin.wb?'WB_WerkView':'WerkView',
        params: { werkid: this.mark.werk_id },
      }"
      :title="werk.titel"
      target="_blank"
    >
      <span class="titel"> {{ this.werk.Titel }} </span>
      <span class="autor">// {{ this.werk.Autor }}</span>

      <p class="grund" v-html="'' + mark.grund + ''"></p>
    </router-link>
    <div class="info">
      <span class="titel"> ID: {{ this.werk.WerkId }} </span>
      {{ this.markierer.vorname }} {{ this.markierer.nachname }}
      {{ this.email }}
      <span class="date">
        {{
          new Date(mark.timestamp).toLocaleString("de", {
            dateStyle: "short",
          })
        }}
      </span>

      <div class="icons">
        <Delete v-if="false" class="icon" />
      </div>
    </div>
  </div>
</template>

<script>
//import Arrow from "../assets/Icons/arrow-right-light.svg";

import Delete from "../assets/Icons/trash-regular.svg";
import userService from "../services/user.service";
import werkeService from '../services/werke.service';

export default {
  name: "WerkMarkiert",
  //props: ["werk"],
  props: {
    mark: Object,
    admin: Boolean,
    header: Boolean,
  },
  components: {
    // Arrow,

    Delete,
  },
  data() {
    return {
      werk: null,
      markierer: null,
      email: "",
    };
  },

  async created() {
    this.werk = await werkeService.getWerkById(this.mark.werk_id)

    this.markierer = await userService.getBenutzerByID(this.mark.markierer_id);
    this.email = await userService.getMail(this.mark.markierer_id);
  },
};
</script>

<style lang="scss" >
.icons {
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-bottom: 10px;

  // float: right;
  .icon {
    min-width: 100px;
    height: 35px;
    border-radius: 50%;
    //background-color: #fff;
    transition: 0.5s ease all;
    &:hover {
      // background-color: #303030;
      .edit,
      .delete {
        path {
          fill: #fff;
        }
      }
    }
    &:nth-child(1) {
      margin-right: 8px;
    }
    .edit,
    .delete {
      pointer-events: none;
      height: 15px;
      width: auto;
    }
  }
}

.blog-card {
  //margin: 10px;
 // padding: 30px;

  position: relative;
  white-space: pre-line;

  &:hover {
    background-color: aliceblue;
  }

  .titel {
    color: rgb(141, 96, 0);
    font-size: 18px;
    font-weight: bold;
  }
  .autor {
    font-size: 18px;
  }
  .grund {
    color: #000000;
    padding: 20px;
    font-size: 18px;
    font-weight: bold;
  }

  //background-color: #fff;

  transition: 0.3s ease all;

  &:hover {
    //transform: rotateZ(-1deg) scale(1.01);
  }
  div {
    .id,
    .title,
    .author,
    .date,
    .username {
      width: 30%;
      font-size: calc(0.5rem + 0.5vw);
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 1; /* fallback */
      max-height: 200px; /* fallback */
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
    .author {
      width: 20%;
    }
    .date {
      width: 8%;
    }

    .username {
      width: 10%;
    }
    .id {
      width: 6%;
    }

    .props {
      display: flex;

      .col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 10px;
      }
      .col1 {
        color: gray;
        float: right;
      }
    }
  }

  .header {
    position: relative;
    top: 0;
    span {
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    z-index: 3;
    //padding: 10px 16px;
    color: #000;

    span {
      height: 50px;

      white-space: normal;
      word-break: break-word;
      display: flex;
      align-items: center;
    }

    .link {
      display: inline-flex;
      align-items: center;
      margin-top: auto;
      font-weight: 500;

      font-size: 12px;

      transition: 0.5s ease-in all;
      &:hover {
        color: rgba(48, 48, 48, 0.8);
      }
      .arrow {
        width: 10px;
      }
    }
  }
}
</style>
