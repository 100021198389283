<template>
  <div class="welcome">
    <div class="container">
      <h1 align="">Bei Fragen und Anregungen:</h1>
      <p>Kontaktieren Sie uns gern per E-Mail unter folgender Adresse:</p>

      <h3>info@literatur-apotheke.de</h3>

      <p>Wir werden Ihre Nachricht schnellstmöglich beantworten.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Kontakt",

  data() {
    return {};
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  min-height: 100vh;
  padding: 100px;
}
</style>