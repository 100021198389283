<template>
  <div class="container">
    <div class="blog-card-wrap">
      <div class="sort-search-wrapper"></div>
      <div class="found">
        <h1 class="">Gemeldete Kommentare</h1>
        <div class="sort-search-wrapper"></div>
        Es wurden
        <strong
          >{{ $store.state.admin.kommentareGemeldet.length }} Meldungen</strong
        >
        gefunden
      </div>
      <div class="blog-cards">
        <div
          class="card"
          v-for="(meldung, index) in meldungenToShow"
          :key="meldung.meldung_id"
        >
   
          {{ index + 1 }}. // ID: {{ meldung.meldung_id }}
          <div class="comment">
            <Comment :kommentarid="meldung.kommentar_id" />
          </div>
          Gemeldet von {{ meldung.name }} <br />

          <p class="bold">"{{ meldung.grund }}"<br /></p>

          am
          {{
            new Date(meldung.timestamp1).toLocaleString("de", {
              dateStyle: "long",
            })
          }}
          <br />
          <br />
          Zum Werk:
          <router-link
            v-if="meldung.werk!={}"
            target="_blank"
            :to="{ name: 'WerkView', params: { werkid: meldung.werk_id } }"
            class="bold"
            >{{ meldung.werk.Titel }}</router-link
          >
          <span v-else>Werk nicht gefunden</span>
          <div class="icons">
            <button @click="showConfirm = meldung.meldung_id">
              Meldung aufheben
            </button>

            <Entmelden
              v-if="showConfirm == meldung.meldung_id"
              message="Meldung aufheben?"
              ok="ok"
              cancel="abbrechen"
              @ok="doEntmelden(meldung)"
            />
          </div>
        </div>
        <div class="scrolling-component"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Comment from "../../components/Comment.vue";
import werkeService from "../../services/werke.service";

import Entmelden from "../../dialogs/main/EmptyConfirm.vue";
import userService from "../../services/user.service";

//import { werkeFiltered } from "../services/werke.service";
//import userService from "../../services/user.service"
export default {
  name: "admin-werke",
  components: { Comment, Entmelden },
  data() {
    return {
      search: "",
      amount: 8,
      meldungenList: [],
      allFilteredWerke: [],
      meldungenToShow: [],
      showConfirm: -1,
      sortBy: "date",
      asc: false,
    };
  },
  async created() {
    this.meldungenToShow = await this.getMeldungenToShow();
    this.meldungenToShow = this.sortedMeldungen(false);
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    sortedMeldungen(asc) {
      var meldungen = this.meldungenToShow.sort(function(a, b) {
        var dateA = new Date(a.timestamp1);
        var dateB = new Date(b.timestamp1);
        return asc ? dateA - dateB : dateB - dateA;
      });
      console.log("MELDUNGEN SORTIERT ", meldungen);

      return meldungen;
    },

    async doEntmelden(meldung) {
      console.log("ENTMELDEN");
      console.log(meldung);
      await werkeService.kommentarEntmelden(meldung.meldung_id);
   
      // this.meldungenToShow.splice(
      //   this.meldungenToShow
      //     .map(function(e) {
      //       console.log(e);
      //       return e.meldung_id;
      //     })
      //     .indexOf(meldung.meldung_id),
      //   1
      // );
      console.log(this.meldungenToShow);
      this.$store.state.admin.showFeedback = true;
      this.showConfirm = -1;
       await this.$store.dispatch("admin/loadKommentarMeldungen");
       setTimeout(() => {
         this.$store.state.admin.showFeedback = false;
       }, 5000);
       await this.resetList();
    },

    async doDelete(meldung) {
      await werkeService.kommentarLöschen(meldung.kommentar_id);
      this.meldungenToShow.splice(
        this.meldungenToShow
          .map(function(e) {
            return e.kommentar_id;
          })
          .indexOf(meldung.kommentar_id),
        1
      );
      this.$store.state.admin.showFeedback = true;
      await this.$store.dispatch("admin/loadKommentarMeldungen");
      setTimeout(() => {
        this.$store.state.admin.showFeedback = false;
      }, 5000);
      this.resetList();
    },

    async getBenutzerName(id) {
      var name = "";
      await userService.getBenutzerByID(id).then((user) => {
        name = user.vorname + " " + user.nachname;
      });

      console.log(name);
      return name;
    },

    sortByThis(string) {
      console.log(string);
      if (this.sortBy == string) {
        this.asc = !this.asc;
      }
      this.sortBy = string;

      this.resetList();
    },
    spliceList(index) {
      console.log("splice", index);
      const filtersList = this.sperrungList.filter(
        (element) => element !== index
      );
      this.sperrungList = filtersList;
      this.meldungenToShow = [];
      this.meldungenToShow = this.getMeldungenToShow();
    },
   async resetList() {
      console.log("RESET___________________________");
      this.meldungenToShow = [];
      // console.log("Reset Werke List!", this.werkeToShow);
      this.meldungenToShow = await this.getMeldungenToShow();
      this.meldungenToShow = this.sortedMeldungen(false)
    },
    async getMeldungenToShow() {
      //this.allFilteredWerke = this.werkeFiltered();
      // console.log("all filtered werke", this.allFilteredWerke);
      var ret = [];

      var lastIndex = this.meldungenToShow.length;
      var newLength =
        this.$store.state.admin.kommentareGemeldet.length - this.amount <
        this.meldungenToShow.length //if
          ? this.allFilteredWerke.length
          : lastIndex + this.amount;
      console.log("NEW LENGTH", newLength);
      for (
        let i = 0;
        i < this.$store.state.admin.kommentareGemeldet.length;
        i++
      ) {
        var meldung = this.$store.state.admin.kommentareGemeldet[i];
        meldung.name = await this.getBenutzerName(meldung.melder_id);
        meldung.werk = await werkeService.getWerkById(meldung.werk_id);


        ret.push(meldung);
      }

      return ret;
    },

   
  },

  computed: {
    kommentareGemeldet() {
      return []; //this.$store.getters["admin/getKommentarMeldungen"];
    },
    wb() {
      return this.$store.state.admin.wb;
    },
    editWerk: {
      get() {
        return this.$store.state.editWerk;
      },
      set(payload) {
        this.$store.commit("toggleEditWerk", payload);
      },
    },
    profileAdmin() {
      return true; //this.$store.state.profileAdmin;
    },
  },
  beforeDestroy() {
    this.checkedAnwendungen = [];
    this.checkedThemen = [];
    this.checkedGenres = [];
    this.$store.commit("toggleEditWerk", false);
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    wb() {
      // Our fancy notification (2).
      this.resetList();
    },
  },
};
</script>

<style lang="scss" scoped>
.found {
  text-align: center;
  font-size: 1.1em;
  padding-bottom: 20px;
}
.sort-search-wrapper {
  padding-top: 20px;
  padding-left: 2vw;
  padding-right: 2vw;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-wrapper {
  display: inline-block;
  margin-right: 0;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 50px;
  right: 0;
  input {
    border-radius: 10px;

    max-width: 800px;
    padding: 10px;
    font-size: 18px;
  }
}
.filterBereich {
  padding-top: 20px;
  padding: 20px;
  //max-width: 1200px;
  height: 100%;
  display: flex;

  align-content: center;
  column-gap: 5vw;
  padding-bottom: 20px;
  font-size: calc(0.6em + 0.5vw);
  margin: 0 auto;
  flex-wrap: wrap;

  .filterspalte {
    width: calc(25% + 50px);
    overflow: visible;
    padding-top: 30px;
    span {
      font-size: calc(1em + 1vw);
    }
    label {
      white-space: nowrap;
      vertical-align: middle;
      position: inherit;
    }

    input {
      transition: 750ms ease all;
      appearance: none;
      border: none;
      // background: #fff;
      outline: none;
      background-color: #fafafa;
      border: 1px solid #cacece;
      box-shadow: 0 0px 2px rgba(0, 0, 0, 0.5),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 10px;
      vertical-align: middle;
      // border-radius: 30px;
      display: inline-block;
      position: relative;
      //height: 30px;
      // border-radius: 100px;
    }

    input:checked {
      content: "\2714";
      padding-left: 25px;
      //position:absolute;
      color: #000000;
      background-color: #195073;
    }

    .field {
      white-space: nowrap;
    }
    .field:hover {
      font-weight: bold;
    }
  }
}

@media (min-width: 1200px) {
  .filterBereich {
    justify-content: center;
  }
}

@media (max-width: 750px) {
  .filterBereich {
    //justify-content: space-between;
    padding-right: 10px;
  }
}

.sort-wrapper {
  vertical-align: middle;
  height: 100px;
  button {
    margin-left: 10px;
    margin-right: 10px;
    height: 30px;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;

    //vertical-align: middle;
  }
}
.comment {
  background-color: rgb(242, 242, 242);
}
</style>
