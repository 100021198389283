<template>
  <div class="admin">
    <div class="container">
      <h2>Newsletter Email-Liste</h2>

      <div>
        <div class="tooltip">
          <button @click="copyToClipboard()" @mouseout="outFunc()">
            <span class="tooltiptext" id="myTooltip"></span>
            Email Liste kopieren
          </button>
        </div>
        <textarea
          class="emailList"
          name="emailList"
          id="emailList"
          cols="100"
          rows="10"
          v-model="newsletterEmailList"
        >
        </textarea>
      </div>

      <div>
        <h2>live Ticker</h2>
        <label for="show">Anzeigen</label>
        <input type="checkbox" id="show" v-model="showTicker" />
        <button @click="updateTicker()">update!</button>

        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          v-model="tickerText"
        ></textarea>
      </div>
      <!--div class="admin-info">
        <h2>Add Admin</h2>
        <div class="input">
          <input
            placeholder="Enter user email to make them an admin"
            type="text"
            id="addAdmins"
            v-model="adminEmail"
          />
        </div>
        <span>{{ this.functionMsg }}</span>
        <button @click="addAdmin" class="button">Submit</button>
      </div-->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import userService from "../../services/user.service";

export default {
  name: "Admin",
  data() {
    return {
      adminEmail: "",
      functionMsg: null,
      newsletterEmailList: "",
      tickerText: "",
      showTicker: false,
    };
  },
  async created() {
    await axios
      .get(process.env.VUE_APP_API_URL + "liveticker/" + 0)
      .then((r) => {
        console.log(r);
        this.tickerText = r.data[0].text;
        this.showTicker = r.data[0].show;

        console.log(this.showTicker);
      })
      .catch((err) => console.log(err));

    await this.generateNewsletterEmailList();
  },
  methods: {
    copyToClipboard() {
      /* Get the text field */
      var copyText = document.getElementById("emailList");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value);

      /* Alert the copied text */
      var tooltip = document.getElementById("myTooltip");
      tooltip.innerHTML = "Liste kopiert";
    },

    async generateNewsletterEmailList() {
      var benutzerAll = await userService.getBenutzerAllFiltered("newsletter=true");

      console.log(benutzerAll);

      for (var b of benutzerAll)
        if (this.validateEmail(b.Email))
          this.newsletterEmailList += b.Email + ", ";
    },

    async updateTicker() {
      var ticker = {
        text: this.tickerText,
        show: this.showTicker,
      };
      await axios
        .put(process.env.VUE_APP_API_URL + "liveticker/", ticker)
        .then()
        .catch((err) => console.log(err));
    },
    async addAdmin() {},

    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    outFunc() {
      var tooltip = document.getElementById("myTooltip");
      tooltip.innerHTML = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.emailList {
  width: 100%;
  height: 500px;
}

.admin {
  .container {
    max-width: 1000px;
    padding: 60px 25px;
    h2 {
      text-align: center;
      margin-bottom: 16px;
      font-weight: 300;
      font-size: 32px;
    }
    .admin-info {
      border-radius: 8px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
      padding: 32px;
      background-color: #f1f1f1;
      display: flex;
      flex-direction: column;
      max-width: 600px;
      margin: 32px auto;
      span {
        font-size: 14px;
      }

      .input {
        margin: 16px 0;
        label {
          font-size: 14px;
          display: block;
          padding-bottom: 6px;
        }
        input {
          width: 100%;
          border: none;
          background-color: #f2f7f6;
          padding: 8px;
          height: 50px;
          @media (min-width: 900px) {
          }
          &:focus {
            outline: none;
          }
        }
      }
      button {
        align-self: center;
      }
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
