<template>
  <div class="werk-bereich quillWrapper">

    <img
      v-if="currentWerk.Stamm == 1"
      class="stammstempel"
      src="../assets/stamm groß.png"
      alt="Stammpräparat"
    />
    <div class="werk">
      <span class="title serif">{{ currentWerk.Titel }}</span>

      <div
        class="post-content ql-editor serif"
        v-html="currentWerk.Inhalt"
      ></div>
    </div>
    <table class="credits cols">

      <tr class="cols">
        <td  class="col1">Titel:</td>
        <td class="col2">{{ currentWerk.Titel }}</td>
      </tr>

      <tr class="cols">
        <td scope="row" class="col1">Autor:in:</td>
        <td class="col2">{{ currentWerk.Autor }}</td>
      </tr>
      <tr class="cols">
        <td scope="row" class="col1">Quelle:</td>
        <td class="col2">{{ currentWerk.Quelle }}</td>
      </tr>

      <tr class="cols">
        <td scope="row" class="col1">Thema:</td> <td class="col2">{{ _thema }}</td>
      </tr>
      <tr class="cols">
        <td scope="row" class="col1">Anwendung:</td>
        <td class="col2">{{ _anwendung }}</td>
      </tr>

      <tr class="cols">
        <td scope="row" class="col1">Genre:</td> <td class="col2">{{ _genre }}</td>
      </tr>

      <tr class="cols">
        <td scope="row" class="col1">Eingestellt am:</td>
        <td class="col2">
          {{
            new Date(currentWerk.Timestamp).toLocaleString("de", {
              dateStyle: "long",
            })
          }}</td
        >
      </tr>
      <tr v-if="!wettbewerb"  class="cols">
        <td scope="row" class="col1">von:</td>
        <td  class="col2" 
          v-if="benutzerName">
        <router-link
          class=" text-link"
          :to="{
            name: 'BenutzerView',
            params: { benutzerid: currentWerk.BenutzerID },
          }"
        >
          {{ benutzerName }}
        </router-link></td>
        <td class="col2" v-else-if="notloggedin"
          >Sie sind nicht eingeloggt!</td
        >
        <td class="col2 no-user" v-else>Benutzer:in nicht gefunden</td>
      </tr>

      <tr class="cols">
        <td scope="row" class="col1">Motivation:</td>
        <td class="col2" v-html="currentWerk.Motivation"></td>
      </tr>

     
    </table>
    <!--div class="credits">
      <div class="cols">
        <div class="col col1">
          <span>Titel:</span>
          <span>Autor/in:</span>
          <span>Quelle:</span>
          <br />
          <span>Thema:</span>
          <span>Anwendung:</span>
          <span>Genre:</span>
          <br />
          <span>Eingestellt am:</span>
          <span v-if="!this.wettbewerb">von:</span>
          <br />
          <span>Motivation:</span>
        </div>
        <div class="col col2">
          <span>{{ this.currentWerk.titel }}</span>
          <span>{{ this.currentWerk.autor }}</span>
          <span
            >{{
              !this.currentWerk.quelle || this.currentWerk.quelle == " "
                ? "-"
                : this.currentWerk.quelle
            }}
          </span>
          <br />
          <span>{{ _thema }}</span>
          <span>{{ _anwendung }}</span>
          <span>{{ _genre }}</span>
          <br />
          <span>
            {{
              new Date(this.currentWerk.datum).toLocaleString("de", {
                dateStyle: "long",
              })
            }}</span
          >
          <div v-if="!this.wettbewerb">
            <router-link
              v-if="benutzerName"
              class="text-link"
              :to="{
                name: 'BenutzerView',
                params: { benutzerid: this.currentWerk.benutzerid },
              }"
            >
              {{ benutzerName }}
            </router-link>
            <span v-else-if="notloggedin">Sie sind nicht eingeloggt!</span>

            <span class="no-user" v-else>Benutzer/in nicht gefunden</span>
          </div>
          <br />
          <span class="serif" v-html="this.currentWerk.motivation"></span>
        </div>
      </div>
    </div-->
  </div>
</template>

<script>
import userService from "../services/user.service";

export default {
  name: "Werk",
  // props:["currentWerk"],
  data() {
    return {
      // currentWerk: null,
      benutzerName: "",
   
    };
  },
  props: {
    currentWerk: Object,
    notloggedin: Boolean,
    wettbewerb: Boolean,
  },
  components: {},

  methods: {},

  computed: {
    _anwendung() {
      var a = this.$store.state.anwendungen.filter((anw) => {
        return anw.id === this.currentWerk.Anwendung;
      });

      if (a.length == 0) return "-";
      else return a[0].name;
    },
    _thema() {  
      var t = this.$store.state.themen.filter((th) => {
        return th.id === this.currentWerk.Thema;
      });

      if (t.length == 0) return "-";
      else return t[0].name;
    },
    _genre() {
      var g = this.$store.state.genres.filter((gen) => {
        return gen.id === this.currentWerk.Genre;
      });
      if (g.length == 0) return "-";
      else return g[0].name;
    },
  },

  async created() {

    console.log("WERK", this.currentWerk);
    if (this.currentWerk !== null) {
      this.benutzerName = await userService.getBenutzerNameByID(
        this.currentWerk.BenutzerID + ""
      );
    }
  },
};
</script>

<style lang="scss">
.werk-bereich {
  // background: #fbfaf8;
  padding: calc(5%);

  //width: calc(100% - 20vw);
  color: #333;
  font-size: calc(14px + 0.5vw);
  padding-left: 8vw;
  // padding-right: 10vw;
 max-width: 100%;
  min-width: 60%;
  height: max-content;

  .stammstempel {
    width: calc(50px + 5vw);
    position: relative;
    filter: grayscale(0.8);
    opacity: 0.5;
    //float: right;

    // right:0;
    margin-left: 70%;
   // margin-top: 1%;
    image {
      vertical-align: middle;
    }
  }
  .werk {
    min-height: 600px;
    margin-top: 100px;
    max-width: 100%;
   
    .title {
      font-weight: 400;
      font-size: calc(18px + 0.8vw);
      margin-bottom: 0;
      padding-left: calc(0%);
    }

    .post-content {
      //display: flex;
      white-space: pre-line;
      padding-left: 0px;
      margin-top: 50px;
      min-height: 300px;
      padding-bottom: 300px;
      max-width: 100%;
      //word-break: keep-all;
      line-height: 1.5;
         font-size: calc(8px + 0.8vw);
    }
  }

  .credits {
    padding: 0px;
    font-size: calc(12px + 0.2vw);
padding-right: 50px;
    .cols {
      
      flex-direction: row;
      .col {
        padding: 10px;
       
      }
      .col1 {
        color: gray;
        white-space: nowrap;
        text-align: right;
        padding-right:5px;
        vertical-align: text-top;
      }
      .col2 {
        font-weight: bold;
        max-width: 600px;
            vertical-align: text-top;

        .no-user {
          font-weight: 100;
          font-style: italic;
          color: gray;
        }
      }
    }
  }

  align-content: center;

  .write-comment {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;
    padding-top: 50px;
    padding-bottom: 50px;

    textarea {
      width: 100%;
      height: 100px;
      border-radius: 10px;
      padding: 10px;
    }
  }
}

.editor {
  height: 60vh;
  display: flex;
  flex-direction: column;

  .quillWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .ql-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: scroll;
  }
  .ql-editor {
    padding: 20px 16px 30px;
  }

}

</style>