import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;

class WerkeService {
  async getWerkeFiltered(query) {
    var ret = [];
    await axios
      .get(process.env.VUE_APP_API_URL + "werk/filtered?" + query)
      .then((response) => {
        var data = response.data;

        console.log(data);

        ret = data;

        //commit('setWerke',state.werke)
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  }

  async getWerkeAll(query) {
    var ret = [];
    await axios
      .get(process.env.VUE_APP_API_URL + "werk/all?" + query)
      .then((response) => {
        var data = response.data;

        console.log(data);

        ret = data;

        //commit('setWerke',state.werke)
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  }

  /*
  werkeFiltered() {
    console.log("GETTING WERKE");
    var filteredWerke = [];

    // Checkboxes

    for (var w of this.$store.state.werke) {
      if (
        (this.checkedAnwendungen.length > 0
          ? this.checkedAnwendungen.some((a) => a.id === w.anwendung)
          : true) &&
        (this.checkedThemen.length > 0
          ? this.checkedThemen.some((a) => a.id === w.thema)
          : true) &&
        (this.checkedGenres.length > 0
          ? this.checkedGenres.some((a) => a.id === w.genre)
          : true)
      ) {
        filteredWerke.push(w);
      }
    }
    console.log(filteredWerke);
    return filteredWerke;
  }
  */

  async updateWerk(payload) {
    console.log(payload);

    var werk = {
      WerkId: payload.WerkId,
      Inhalt: payload.Inhalt,
      Titel: payload.Titel,
      Quelle: payload.Quelle == null ? "" : payload.quelle,
      Timestamp: payload.Timestamp,
      Autor: payload.Autor,
      BenutzerID: payload.BenutzerID,
      Motivation: payload.Motivation,
      Stamm: payload.Stamm == null ? 0 : payload.Stamm,
      Anwendung: payload.Anwendung,
      Thema: payload.Thema,
      Genre: payload.Genre,
    };
    console.log("PUT THIS:", werk);

    await axios
      .put(API_URL + "werk", werk)
      .then((r) => {
        console.log("Erfolgreich geupdatet", r);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getWerkById(id) {
    var werk = "";
    console.log(id);
    await axios
      .get(API_URL + "werk/" + id)
      .then((doc) => {
        doc = doc.data[0];

        werk = {
          WerkId: doc.WerkId,
          Inhalt: doc.Inhalt,
          Titel: doc.Titel,
          Quelle: doc.Quelle,
          Timestamp: doc.Timestamp,
          Autor: doc.Autor,
          BenutzerID: doc.BenutzerID,
          Motivation: doc.Motivation,
          Stamm: doc.Stamm,
          Anwendung: doc.Anwendung,
          Thema: doc.Thema,
          Genre: doc.Genre,
          Rating: doc.Rating,
          Vorname: doc.Vorname == null ? "-" : doc.Vorname,
          Nachname: doc.Nachname == null ? "-" : doc.Nachname,
          Wettbewerb: doc.Wettbewerb,
        };
      })
      .catch((err) => {
        console.log("Errrrorrr", err);
        let setAll = (obj, val) =>
          Object.keys(obj).forEach((k) => (obj[k] = val));
        werk = (obj) => setAll(obj, "-");
      });

    return werk;
  }

  // ____________________________________________ Sperren

  async werkSperren(werkid, benutzerid, grund) {
    var sperrung = {
      Werk_id: werkid,
      Sperrer_id: benutzerid,
      Grund: grund,
    };
    console.log(sperrung);

    await axios
      .post(API_URL + "werkegesperrt/", sperrung)
      .then((doc) => {
        console.log(doc);
      })
      .catch(() => (sperrung = null));

    this.werkEntmarkierenByWerk(werkid);
    return sperrung;
  }
  async werkEntsperren(id) {
    await axios
      .delete(API_URL + "werkegesperrt/" + id)
      .then((doc) => {
        console.log(doc);
      })
      .catch((err) => console.log(err));
  }
  async getSperrungen() {
    var sperrungen = "";
    await axios
      .get(API_URL + "werkegesperrt/")
      .then((doc) => {
        sperrungen = doc.data;

        sperrungen = sperrungen.filter((s) => {
          return s.werk_id != null;
        });
      })
      .catch(() => (sperrungen = null));
    return sperrungen;
  }

  // ____________________________________________ Markierungen
  async werkMarkieren(werkid, benutzerid, grund) {
    var markierung = {
      Werk_id: werkid,
      Markierer_id: benutzerid,
      Grund: grund,
    };
    await axios
      .post(API_URL + "werkemarkiert/", markierung)
      .then((doc) => {
        console.log(doc);
      })
      .catch(() => (markierung = null));
  }

  async getMarkierungen() {
    var markierungen = "";
    await axios
      .get(API_URL + "werkemarkiert/")
      .then((doc) => {
        markierungen = doc.data;
      })
      .catch(() => (markierungen = null));
    return markierungen;
  }

  async werkEntmarkieren(id) {
    await axios
      .delete(API_URL + "werkemarkiert/" + id)
      .then((doc) => {
        console.log(doc);
      })
      .catch((err) => console.log(err));
  }

  async werkEntmarkierenByWerk(id) {
    await axios
      .delete(API_URL + "werkemarkiert/werk/" + id)
      .then((doc) => {
        console.log(doc);
      })
      .catch((err) => console.log(err));
  }

  // ____________________________________________ Nuggets
  async werkNuggieren(werkid, benutzerid, grund) {
    var nugget = {
      Werk_id: werkid,
      Markierer_id: benutzerid,
      Grund: grund,
    };
    await axios
      .post(API_URL + "werkenuggets/", nugget)
      .then((doc) => {
        console.log(doc);
      })
      .catch(() => (nugget = null));

    this.werkEntmarkierenByWerk(werkid);
  }

  async getNuggets() {
    var nuggets = "";
    await axios
      .get(API_URL + "werkenuggets/")
      .then((doc) => {
        nuggets = doc.data;
      })
      .catch(() => (nuggets = null));
    return nuggets;
  }

  async werkEntnuggieren(id) {
    await axios
      .delete(API_URL + "werkenuggets/" + id)
      .then((doc) => {
        console.log(doc);
      })
      .catch((err) => console.log(err));
  }

  async werkEntnuggierenByWerk(id) {
    await axios
      .delete(API_URL + "werkenuggets/werk/" + id)
      .then((doc) => {
        console.log(doc);
      })
      .catch((err) => console.log(err));
  }
  // ____________________________________________ Löschen
  async werkLöschen(id) {
    console.log("entsperren", id);
    await this.werkEntsperren(id);
    await this.werkEntmarkierenByWerk(id);
    await this.werkEntnuggierenByWerk(id);

    await this.deleteFavoritByWerk(id);

    //var kommentare = await axios.get(API_URL +)
    //await this.kommentarLöschen

    await axios
      .delete(API_URL + "werk/" + id)
      .then((r) => {
        console.log(r);
      })
      .catch((err) => console.log(err));
  }

  async wettbewerbWerkeByBenutzerID(id) {
    console.log(id);
    const response = await axios
      .get(API_URL + "werk/werke_wb/" + id)
      .then((r) => {
        console.log(r);
        return r.data;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });

    return response;
  }

  //______________________________________________ Stamm

  async werkStamm(werkid, bool) {

    var stamm = bool?1:0
    
    console.log("PUT STAMM");

    await axios
      .put(API_URL + "werk/stamm/" + werkid +"/"+ stamm).then(r=>
        console.log(r))

      .catch((e) => {
        console.log(e);
      });
  }

  async getKommentarById(kId) {
    var kommentar = {};
    await axios
      .get(API_URL + "werk/kommentar/" + kId)
      .then((doc) => {
        kommentar = {
          id: doc.data[0].Kommentar_id,
          benutzerid: doc.data[0].Benutzer_id,
          werkid: doc.data[0].Werk_id,
          inhalt: doc.data[0].Inhalt,
          timestamp: doc.data[0].Timestamp,
        };
      })
      .catch((e) => {
        console.log(kId, e);
      });

    console.log("GET KOMMENTAR", kommentar);

    return kommentar;
  }

  // ____________________________________________ Kommentar Meldungen
  async kommentarMelden(grund, kommentarid, benutzerid) {
    var meldung = {
      Kommentar_id: kommentarid,
      Melder_id: benutzerid,
      Grund: grund,
    };
    console.log(meldung);
    await axios
      .post(API_URL + "kommentarmelden/", meldung)
      .then((doc) => {
        console.log(doc);
      })
      .catch(() => (meldung = null));
  }

  async getKommentarMeldungen() {
    console.log("GetKoemmentare MELDUNGEN");
    var meldungen = [];
    meldungen = await axios
      .get(API_URL + "kommentarmelden/")
      .catch(() => (meldungen = []));

    console.log("meldungen gemeldet", meldungen.data);
    return meldungen.data;
  }

  async getKommentareGemeldet() {
    var meldungen = [];
    meldungen = await axios
      .get(API_URL + "kommentarmelden/kommentare")
      .catch(() => (meldungen = []));
    console.log("kommentar gemeldet", meldungen.data);
    return meldungen.data;
  }

  async kommentarEntmelden(id) {
    await axios
      .delete(API_URL + "kommentarmelden/" + id)
      .then((doc) => {
        console.log(doc);
      })
      .catch((err) => console.log(err));
  }

  async kommentarLöschenTemp(id) {
    await axios
      .put(API_URL + "werk/kommentarTemp/" + id)
      .then((doc) => {
        console.log(doc);
      })
      .catch((err) => console.log(err));
  }

  async kommentarLöschen(id) {
    await this.kommentarEntmelden(id);
    await axios
      .delete(API_URL + "werk/kommentar/" + id)
      .then((doc) => {
        console.log(doc);
      })
      .catch((err) => console.log(err));
  }
  async deleteFavoritByWerk(werkid) {
    await axios
      .delete(API_URL + "favoriten/werk/" + werkid)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  }
}

export default new WerkeService();
