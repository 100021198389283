<template>
<div>
      <button class="meldebutton" title="Kommentar melden" @click="handle">
        <font-awesome-icon :class="[`fa-10` ,'flag']" :icon="['fas', 'flag']" />
     
      </button>
   
    
  
 
    <text-dialog ref="textDialogue"></text-dialog>
 </div>
</template>


<script>
import TextDialog from "../TextDialog.vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
//import vueStarRate from "vue-js-star-rating";
library.add(fas);
library.add(far);
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
  name: "Kommentar_Melden",

  components: { TextDialog, FontAwesomeIcon },
  props: {
    isFavorit: Object,
  },
  created() {
  },

  methods: {
    async handle() {
      console.log("HANDLE BUTTON");
      const text = await this.$refs.textDialogue.show({
        characters: 200,
        title: "Warum möchten Sie den Kommentar melden?",
        message: "(max. 200 Zeichen)",
        okButton: "Ok",
      });
      if (text != "" ) {
        this.$emit("ok", text);
      }
    },
  },
};
</script>

<style lang="scss">
.meldebutton{
 
 
     float:right;
 
    font-size: 8px;
    background:none;
       opacity: 0.15;
    .flag{
   
      color:#000000;}
      &:hover{
 //font-size: 12px;
      opacity: 0.3;
      color:#ffffff;
      }
    
  
}
.fade-text {
  transition: 0.2s all;
  opacity: 0;
  font-size: 12px;
  color: blue($color: #000000);
  overflow: visible;
  width: 80px;
}
.favorit:hover .fade-text {
  opacity: 1;
}
</style>