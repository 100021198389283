<template>
  <div>
    <div class="title">
      <h1>Apothekerschrank</h1>
    </div>
    <div class="found" v-if="totalAmount == 0 && loading">
      Werke werden geladen...
    </div>
    <div v-else class="found">
      <span v-if="totalAmount == 1">Es wurde</span>
      <span v-else>Es wurden</span>
      <span
        ><strong>{{ totalAmount }}</strong></span
      >
      <span v-if="totalAmount == 1">Präparat gefunden</span>
      <span v-else>Präparate gefunden</span>
    </div>
 

    <div class="container intro">
      Hier finden Sie Texte für alle Lebenslagen. Wählen Sie eine oder mehrere
      Anwendungen, Themen und Genres aus, in denen Sie nach Texten suchen
      wollen. Mit dem Prädikat „Stammpräparat“ zeichnen wir besonders wertvolle
      Texte aus. Lassen Sie sich Werke chronologisch anzeigen, sodass Sie alle
      neuen Einreichungen immer im Blick haben, oder entdecken Sie ganz zufällig
      längst vergessene Schätze.
    </div>
    <div class="filterBereich">
      <div class="anwendungen filterspalte">
        <span>Anwendungen</span>
        <div v-for="(el, index) in this.$store.state.anwendungen" :key="index">
          <div class="field">
            <input
              type="checkbox"
              :id="el.name"
              :value="el"
              v-model="checkedAnwendungen"
              @change="resetList()"
            />
            <label :for="el.name">{{ el.name }}</label>
          </div>
        </div>
      </div>
      <div class="themen filterspalte">
        <span>Themen</span>
        <div v-for="(el, index) in this.$store.state.themen" :key="index">
          <div class="field">
            <input
              type="checkbox"
              :id="el.name"
              :value="el"
              v-model="checkedThemen"
              @change="resetList()"
            />
            <label :for="el.name">{{ el.name }}</label>
          </div>
        </div>
      </div>
      <div class="genres filterspalte">
        <span>Genres</span>
        <div v-for="(el, index) in this.$store.state.genres" :key="index">
          <div class="field">
            <input
              type="checkbox"
              :id="el.name"
              :value="el"
              v-model="checkedGenres"
              @change="resetList()"
            />
            <label :for="el.name">{{ el.name }}</label>
          </div>
        </div>
      </div>
      <div class="genres filterspalte">
        <div class="field" title="Besonders ausgezeichnete Präparate">
          <input
            type="checkbox"
            id="stammChoice"
            v-model="stammAuswahl"
            @change="resetList()"
          />
          <label for="stammChoice"> <span> Stammpräparate </span></label>
        </div>
      </div>
    </div>
    <div class="blog-card-wrap gray">
      <div class="sort-search-wrapper">
        <div class="sort-wrapper">
          <span>Sortieren nach </span>
          <button type="button" name="Datum" value="datum" @click="sortDatum()">
            Datum <span v-if="asc">&#9650;</span><span v-else>&#9660;</span>
          </button>
          <span>oder</span>
          <button
            type="button"
            name="Random"
            value="random"
            @click="sortRandom()"
          >
            zufällig <span>⤭</span>
          </button>
        </div>

        <div class="search-wrapper">
          <input
            type="text"
            v-model="search"
            @keyup="resetList()"
            placeholder="Suche.."
            class="search-input"
          />
        </div>
      </div>
      <div class="found" v-if="totalAmount == 0 && loading">
        Werke werden geladen...
      </div>
      <div v-else class="found">
        <span v-if="totalAmount == 1">Es wurde</span>
        <span v-else>Es wurden</span>
        <span
          ><strong>{{ totalAmount }}</strong></span
        >
        <span v-if="totalAmount == 1">Präparat gefunden</span>
        <span v-else>Präparate gefunden</span>
      </div>
      <div>
        <!--
        <div>
          <div v-if="checkedAnwendungen.length > 0" class="checked">
            Anwendungen:
            <div
              v-for="(ch, index) in this.$store.state.checkedAnwendungen"
              :key="index"
            >
              <span @click="deleteAnwendung(index)">{{ ch.name }}</span>
            </div>
          </div>
        </div>

        <div>
          <div v-if="checkedThemen.length > 0" class="checked">
            Themen:
            <div
              v-for="(ch, index) in this.$store.state.checkedThemen"
              :key="index"
            >
              <span @click="deleteThema(index)">{{ ch.name }}</span>
            </div>
          </div>
        </div>
        <div>
          <div v-if="checkedGenres.length > 0" class="checked">
            Genres:
            <div
              v-for="(ch, index) in this.$store.state.checkedGenres"
              :key="index"
            >
              <span @click="deleteGenre(index)">{{ ch.name }}</span>
            </div>
          </div>
        </div>
        -->
      </div>
      <div class="blog-cards">
        <div v-for="werk in this.werkeToShow" :key="werk.id">
          <router-link
            class="link"
            :to="{ name: 'WerkView', params: { werkid: werk.WerkId } }"
            :title="werk.Titel"
          >
            <WerkCard :werk="werk" />
          </router-link>
        </div>
        <div class="scrolling-component"></div>
        <div v-if="loading" class="loading-werke"></div>
      </div>
    </div>
  </div>
</template>

<script>
import WerkCard from "../components/WerkCard";
import werkeService from "../services/werke.service";

//import { werkeFiltered } from "../services/werke.service";

export default {
  name: "werke",
  components: { WerkCard },
  data() {
    return {
      search: "",
      amount: 8,
      allFilteredWerke: [],
      werkeToShow: [],
      showAnwendungen: false,
      showThemen: false,
      showGenres: false,
      query: "",
      totalAmount: 0,
      loading: false,
    };
  },
  async mounted() {
    //this.werkeToShow = this.getWerkeToShow();
    this.loading = true;
    this.werkeToShow = await this.fetchWerkeToShow();
    window.addEventListener("scroll", this.handleScroll);

    var modal1 = document.getElementById("anwendungen");

    console.log("mount", modal1);

    // window.addEventListener("click", (e) => this.outsideClick(e, modal1));
    this.loading = false;
  },
  methods: {
    outsideClick(event, modal1) {
      //var modal2 = document.getElementById("themen") ? document.getElementById("themen") : [];
      //var modal3 = document.getElementById("genres") ? document.getElementById("genres")  : [];

      //var modals = [modal1, modal2, modal3];
      // check outside click for multiple elements
      var clickedOut = true;
      console.log(modal1);
      var i = 0;

      for (i = 0; i < modal1.length; i++) {
        console.log(modal1[i]);
        if (event.target === modal1[i] || modal1[i].contains(event.target)) {
          clickedOut = false;
        }
      }

      if (clickedOut) {
        if (
          this.showAnwendungen &&
          !(event.target === this.$refs.anwendungen)
        ) {
          this.showAnwendungen = false;
        }
        if (this.showThemen && !(event.target === this.$refs.themen)) {
          this.showThemen = false;
        }
        if (this.showGenres && !(event.target === this.$refs.genres)) {
          this.showGenres = false;
        }
      }
    },

    toggleShowAnwendungen() {
      console.log(this.showAnwendungen, "anw");
      this.showAnwendungen = !this.showAnwendungen;
    },
    toggleShowThemen() {
      this.showThemen = !this.showThemen;
    },
    toggleShowGenres() {
      this.showGenres = !this.showGenres;
    },

    deleteAnwendung(payload) {
      this.$store.commit("deleteAnwendung", payload);
      this.resetList();
    },
    deleteThema(payload) {
      this.$store.commit("deleteThema", payload);
      this.resetList();
    },

    deleteGenre(payload) {
      this.$store.commit("deleteGenre", payload);
      this.resetList();
    },

    sortRandom() {
      console.log("random");
      this.$store.state.sortBy = "random";
      this.resetList();
    },

    sortDatum() {
      console.log("datum");
      if (this.sortBy == "date") {
        this.$store.state.asc = !this.$store.state.asc;
      }
      this.$store.state.sortBy = "date";

      this.resetList();
    },
    async resetList() {
      console.log("RESET___________________________");
      this.werkeToShow = [];
      // console.log("Reset Werke List!", this.werkeToShow);
      //this.werkeToShow = this.getWerkeToShow();
      this.werkeToShow = await this.fetchWerkeToShow();
    },
    getWerkeToShow() {
      this.allFilteredWerke = this.werkeFiltered();
      // console.log("all filtered werke", this.allFilteredWerke);
      var ret = [];

      var lastIndex = this.werkeToShow.length;
      var newLength =
        this.allFilteredWerke.length - this.amount < this.werkeToShow.length //if
          ? this.allFilteredWerke.length
          : lastIndex + this.amount;
      console.log("NEW LENGTH", newLength);
      for (let i = lastIndex; i < newLength; i++) {
        ret.push(this.allFilteredWerke[i]);
      }

      return ret;
    },

    async fetchWerkeToShow() {
      //filter
      var anwendungen = this.$store.state.checkedAnwendungen;
      var themen = this.$store.state.checkedThemen;
      var genres = this.$store.state.checkedGenres;

      console.log(anwendungen, themen, genres);

      this.query = "";
      for (var anw of anwendungen) {
        this.query += "anwendung=" + anw.id;
        this.query += "&";
      }
      for (var th of themen) {
        this.query += "thema=" + th.id;
        this.query += "&";
      }
      for (var gen of genres) {
        this.query += "genre=" + gen.id;
        this.query += "&";
      }

      var searchWords = this.search.split(" ");
      for (var word of searchWords) {
        this.query += "search=" + word.toLowerCase();
        this.query += "&";
      }

      if (this.$store.state.stammAuswahl) {
        this.query += "stamm=1&";
      }

      this.query += "wb=0&";

      if (
        this.$store.state.sortBy == "date" ||
        this.$store.state.sortBy == ""
      ) {
        this.$store.state.asc
          ? (this.query += "order=1&")
          : (this.query += "order=0&");

        this.query += "limit=" + this.amount + "&";
        this.query += "offset=" + this.werkeToShow.length + "&";
      } else if (this.$store.state.sortBy == "random") {
        this.query += "order=2&";

        this.query += "limit=" + this.amount + "&";
        this.query += "offset=" + 0 + "&";
      }

      var result = await werkeService.getWerkeFiltered(this.query);

      if (result.length > 0) {
        this.totalAmount = result[0].total_count;
      } else {
        this.totalAmount = 0;
      }

      console.log(result);
      return result;
    },

    async handleScroll() {
      if (
        this.loading == false &&
        window.scrollY + window.innerHeight >=
          document.body.scrollHeight - window.innerHeight &&
        !(this.werkeToShow.length >= this.totalAmount)
      ) {
        this.loading = true;
        console.log("load new werke");
        this.werkeToShow = [
          ...this.werkeToShow,
          ...(await this.fetchWerkeToShow()),
        ];
      }
      this.loading = false;
    },
    werkeFiltered() {
      console.log("GETTING WERKE");
      var filteredWerke = [];

      // Checkboxes

      for (var w of this.$store.state.werke) {
        if (
          (this.$store.state.checkedAnwendungen.length > 0
            ? this.$store.state.checkedAnwendungen.some(
                (a) => a.id === w.anwendung
              )
            : true) &&
          (this.$store.state.checkedThemen.length > 0
            ? this.$store.state.checkedThemen.some((a) => a.id === w.thema)
            : true) &&
          (this.$store.state.checkedGenres.length > 0
            ? this.$store.state.checkedGenres.some((a) => a.id === w.genre)
            : true) &&
          (this.$store.state.stammAuswahl ? w.stamm : true)
        ) {
          filteredWerke.push(w);
        }
      }

      filteredWerke = filteredWerke.filter((post) => {
        var includes =
          post.titel.toLowerCase().includes(this.search.toLowerCase()) ||
          post.autor.toLowerCase().includes(this.search.toLowerCase()) ||
          post.inhalt.toLowerCase().includes(this.search.toLowerCase()) ||
          post.vorname.toLowerCase().includes(this.search.toLowerCase()) ||
          post.nachname.toLowerCase().includes(this.search.toLowerCase());
        return includes;
      });

      console.log(filteredWerke);

      if (
        this.$store.state.sortBy == "date" ||
        this.$store.state.sortBy == ""
      ) {
        filteredWerke = filteredWerke.sort((a, b) => {
          var dateA = new Date(a.datum);
          var dateB = new Date(b.datum);
          return this.$store.state.asc ? dateA - dateB : dateB - dateA;
        });
      }

      if (this.$store.state.sortBy == "random") {
        filteredWerke = filteredWerke.sort(() => {
          return Math.random() - 0.5;
        });
      }
      return filteredWerke;
    },
  },

  computed: {
    foundWerke() {
      return this.allFilteredWerke.length;
    },

    checkedAnwendungen: {
      get() {
        return this.$store.state.checkedAnwendungen;
      },
      set(payload) {
        this.$store.commit("checkAnwendungen", payload);
      },
    },
    checkedThemen: {
      get() {
        return this.$store.state.checkedThemen;
      },
      set(payload) {
        this.$store.commit("checkThemen", payload);
      },
    },
    checkedGenres: {
      get() {
        return this.$store.state.checkedGenres;
      },
      set(payload) {
        this.$store.commit("checkGenres", payload);
      },
    },
    stammAuswahl: {
      get() {
        return this.$store.state.stammAuswahl;
      },
      set(payload) {
        this.$store.commit("stammAuswahl", payload);
      },
    },
    asc: {
      get() {
        return this.$store.state.asc;
      },
      set(payload) {
        this.$store.commit("asc", payload);
      },
    },
    sortBy: {
      get() {
        return this.$store.state.sortBy;
      },
      set(payload) {
        this.$store.commit("sortBy", payload);
      },
    },
    profileAdmin() {
      return true; //this.$store.state.profileAdmin;
    },
  },
  beforeDestroy() {
    this.$store.commit("toggleEditWerk", false);
    window.removeEventListener("scroll", this.handleScroll);

    window.removeEventListener("click", this.outsideClick);
  },
};
</script>

<style lang="scss" scoped>

.intro{
  padding-left:10px;
}
.loading-werke {
  height: 1vh;
}
.found {
  text-align: center;
  font-size: 1.1em;
  padding-bottom: 20px;
}

.sort-search-wrapper {
  padding-left: 2vw;
  padding-right: 2vw;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-wrapper {
  display: inline-block;
  margin-right: 0;
  text-align: center;

  right: 0;
  padding: 10px;
  input {
    border-radius: 10px;

    max-width: 800px;
    padding: 10px;
    font-size: 18px;
  }
}
.filterBereich {
  padding-top: 20px;
  padding: 20px;
  //max-width: 1200px;
  height: 100%;
  display: flex;

  align-content: center;
  column-gap: 5vw;
  padding-bottom: 20px;
  font-size: calc(0.6em + 0.5vw);
  margin: 0 auto;
  flex-wrap: wrap;

  .filterspalte {
    width: calc(25% + 50px);
    overflow: visible;
    padding-top: 30px;

    span {
      font-size: calc(1em + 0.3vw);
      font-weight: bold;
      cursor: pointer;
      //   border-bottom: 1px solid gray;
    }
    label {
      white-space: nowrap;
      vertical-align: middle;
      position: inherit;
      cursor: pointer;
    }
    .content {
      width: calc(45%);
      overflow: visible;
      position: absolute;
      z-index: 999;
      background-color: white;
      padding: 20px;
      border: 1px solid gray;

      border-radius: 10px;

      box-shadow: 6px 5px 0px 0px rgba(0, 0, 0, 0.2);
    }
    input {
      cursor: pointer;
      transition: 750ms ease all;
      appearance: none;
      border: none;
      // background: #fff;
      outline: none;
      background-color: #fafafa;
      border: 1px solid #cacece;
      box-shadow: 0 0px 2px rgba(0, 0, 0, 0.5),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 10px;
      vertical-align: middle;
      // border-radius: 30px;
      display: inline-block;
      position: relative;
      //height: 30px;
      // border-radius: 100px;
    }

    input:checked {
      content: "\2714";
      padding-left: 25px;
      //position:absolute;
      color: #000000;
      background-color: #195073;
    }

    .field {
      white-space: nowrap;
      padding: 5px;
    }
    .field:hover {
      font-weight: bold;
    }
  }
}

@media (min-width: 1200px) {
  .filterBereich {
    justify-content: center;
  }
}

@media (max-width: 750px) {
  .filterBereich {
    //justify-content: space-between;
    padding-right: 10px;
  }
}

.sort-wrapper {
  // vertical-align: middle;
  // height: 100px;
  button {
    margin-left: 10px;
    margin-right: 10px;
    height: 30px;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;

    //vertical-align: middle;
  }
}
.blog-cards {
  padding: 50px;
  padding-bottom: 200px;
  position: relative;

  .toggle-edit {
    display: flex;
    align-items: center;
    position: absolute;
    top: -70px;
    right: 1;

    span {
      margin-right: 16px;
    }
    input[type="checkbox"] {
      position: relative;
      border: none;
      -webkit-appearance: none;
      background: #fff;
      outline: none;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    input[type="checkbox"]:before {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      top: 0;
      left: 0;
      background: #303030;
      transform: scale(1.1);
      transition: 750ms ease all;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    input:checked[type="checkbox"]:before {
      background: #fff;
      left: 52px;
    }
  }
}

.blog-cards {
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr;
  @media (min-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 950px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1400px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.filter-label {
  font-size: 18px;
}

.content-enter-active,
.content-leave-active {
  transition: all 0.5s ease;
  transform-origin: 0% 0%;
}

.content-enter {
  // transform: translateX(0%);
  opacity: 0;
  transform: scale(1, 0);
}

.content-enter-to {
  // transform: translateX(50%);
  opacity: 1;
  transform: scale(1, 1);
}

.content-leave-to {
  // transform: translateX(calc(100% + 550px));
  opacity: 0;
}
</style>
