var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h1',{},[_vm._v("BENUTZER")]),_c('div',{staticClass:"blog-card-wrap gray"},[_c('div',{staticClass:"sort-search-wrapper"},[_c('div',{staticClass:"sort-wrapper"},[_c('span',[_vm._v("Sortieren nach ")]),_c('button',{attrs:{"type":"button","name":"Datum","value":"datum"},on:{"click":function($event){return _vm.sortId()}}},[_vm._v(" id "),(this.asc)?_c('span',[_vm._v("▲")]):_c('span',[_vm._v("▼")])])]),_c('div',{staticClass:"search-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"search-input",attrs:{"type":"text","placeholder":"Suche.."},domProps:{"value":(_vm.search)},on:{"keyup":function($event){return _vm.resetList()},"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})])]),_c('div',{staticClass:"found"},[_vm._v(" Es wurden "),_c('strong',[_vm._v(_vm._s(this.totalAmount))]),_vm._v(" User gefunden ")]),_c('div',{staticClass:"blog-cards"},[_c('Slot',{attrs:{"header":true}}),_vm._l((this.userToShow),function(user){return _c('div',{key:user.BenutzerId},[(
            !_vm.gesperrt.some(function (w) {
              return w.benutzer_id == user.BenutzerId && w.hidden;
            })
          )?_c('div',{on:{"click":function($event){_vm.showIcons === user.BenutzerId
              ? (_vm.showIcons = -1)
              : (_vm.showIcons = user.BenutzerId)}}},[_c('Slot',{class:_vm.gesperrt.some(function (u) {
                return u.benutzer_id == user.BenutzerId;
              })
                ? 'bg-gesperrt'
                : _vm.markiert.some(function (u) {
                    return u.benutzer_id == user.BenutzerId;
                  })
                ? 'bg-markiert'
                : '',attrs:{"admin":true,"user":user}}),_c('div',{staticClass:"gesperrt"},[_vm._l((_vm.gesperrt.filter(function (s) {
                return s.benutzer_id == user.BenutzerId;
              })),function(sperrung){return _c('span',{key:sperrung.sperrung_id,staticClass:"bg-gesperrt p-1"},[_vm._v(" Gesperrt: "+_vm._s(sperrung.grund)+" ")])}),_vm._l((_vm.markiert.filter(function (s) {
                return s.benutzer_id == user.BenutzerId;
              })),function(markierung){return _c('span',{key:markierung.markierung_id,staticClass:"bg-markiert p-1"},[_vm._v(" Markiert: "+_vm._s(markierung.grund)+" ")])})],2)],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIcons === user.BenutzerId),expression:"showIcons === user.BenutzerId"}]},[_c('div',{staticClass:"icons"},[_c('SperrenBenutzer',{attrs:{"userid":user.BenutzerId},on:{"ok":function($event){return _vm.doSperren($event, user)}}}),_c('MarkierenBenutzer',{attrs:{"userid":user.BenutzerId},on:{"ok":function($event){return _vm.doMarkieren($event, user)}}}),_c('button',{on:{"click":function($event){return _vm.userBearbeiten(user.BenutzerId)}}},[_vm._v(" Bearbeiten ")])],1),_c('div',[_c('strong',[_vm._v("Ort:")]),_vm._v(" "+_vm._s(user.Ort))]),_c('div',[_c('strong',[_vm._v("Vita:")]),_vm._v(" "+_vm._s(user.Vita))]),_c('div',[_c('strong',[_vm._v("Newsletter:")]),_vm._v(" "+_vm._s(user.Newsletter ? "ja" : "nein")+" ")]),_c('br'),_c('br'),_c('br')])])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }