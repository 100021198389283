<template>
  <div class="welcome">
    <h1>Impressum</h1>

    <div class="container bg-light">
      <div class="row">
        <div class="col">
          <h4>Betreiber der Webseite</h4>
          Förderverein Forum Literaturbüro e.V. <br />
          Karthäuser-Str. 30 <br />
          31139 Hildesheim <br />
          Vorstand: Jo Köhler / Gabriel Muscu <br />
          Tel: 05121-263775 <br />
          Fax: 05121-263774 <br />
          E-Mail: info@forum-literatur.de <br />
          <a href="www.forum-literatur.de">www.forum-literatur.de</a>
          <br />
        </div>
        <div class="col">
          <h4>Betreiber der Webseite</h4>
          Registerblatt VR 2164 | Amtsgericht Hildesheim <br />
          Steuernummer 30/216/41964 | Finanzamt Hildesheim<br />
        </div>
        <br />
        <div class="w-100"></div>
        <div class="col">
          <h4>Realisierung der Website</h4>
          Julian Bauch<br />
          Design and Fullstack Development<br />

          <a target="_blank" href="https://www.iuilan.art/">iuilan.art</a><br>
        </div>
        <div class="col">
          <h4>Hosting</h4>
          Compra gmbH Hildesheim<br />

          <a target="_blank" href="https://www.iuilan.art/">compra.de</a><br>
        </div>
        <div class="col">
          <h4>Quellenangaben</h4>
          Fotos: Shutterstock<br />
          Grafiken: Norbert Jaekel Hildesheim
        </div>
      </div>
    </div>
    <br /><br />
    <h2>Rechtliche Hinweise</h2>
    <h4>1. Der Autor</h4>
    Forum-Literatur e.V. - im nachfolgenden der Autor genannt - übernimmt
    keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder
    Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den
    Autor, welche sich auf Schäden materieller oder ideeller Art beziehen, die
    durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw.
    durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht
    wurden sind grundsätzlich ausgeschlossen, sofern seitens des Autors kein
    nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt. Alle
    Angebote sind freibleibend und unverbindlich. Der Autor behält es sich
    ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte
    Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung
    zeitweise oder endgültig einzustellen.
    <br />
    <br />

    <h4>2. Verweise und Links</h4>
    Bei direkten oder indirekten Verweisen auf fremde Internetseiten ("Links"),
    die außerhalb des Verantwortungsbereiches des Autors liegen, würde eine
    Haftungsverpflichtung ausschließlich gemäß §5 Abs. 2 TDG ggf. in dem Fall in
    Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm
    technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger
    Inhalte zu verhindern. Der Autor erklärt daher ausdrücklich, dass zum
    Zeitpunkt der Linksetzung die entsprechenden verlinkten Seiten frei von
    illegalen Inhalten waren. Der Autor hat keinerlei Einfluss auf die aktuelle
    und zukünftige Gestaltung und auf die Inhalte der gelinkten/verknüpften
    Seiten. Deshalb distanziert er sich hiermit ausdrücklich von allen Inhalten
    aller gelinkten/verknüpften Seiten, die nach der Linksetzung verändert
    wurden. Diese Feststellung gilt für alle innerhalb des eigenen
    Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in
    vom Autor eingerichteten Gästebüchern, Diskussionsforen und Mailinglisten.
    Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für
    Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener
    Informationen entstehen, haftet allein der Anbieter der Seite, auf welche
    verwiesen wurde, nicht derjenige, der über Links auf die jeweilige
    Veröffentlichung lediglich verweist.
    <br />
    <br />

    <h4>3. Urheber-, Marken- und Kennzeichenrecht</h4>
    Der Autor ist bestrebt, in allen seinen Publikationen die Urheberrechte von
    verwendeten Grafiken, Tondokumenten, Videosequenzen und Texten zu beachten,
    bzw. nur solche Objekte zu verwenden, die von ihm selbst erstellt wurden,
    die lizenzfrei sind oder für die er Lizenzen besitzt. Alle innerhalb des
    Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und
    Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils
    gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen
    eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der
    Schluß zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt
    sind ! Das Urheberrecht für veröffentlichte und vom Autor selbst erstellte
    Objekte bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder
    Verwendung solcher Objekte in anderen elektronischen oder gedruckten
    Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht gestattet.
    Die Nennung von Firmennamen, Produktbezeichnungen, Markennamen oder
    Warenzeichen erfolgt in Anerkennung sämtlicher Rechte ihrer jeweiligen
    Eigentümer. Die Nennung dient lediglich als Hinweis auf die Bestimmung
    unserer Produkte und Dienstleistungen im Sinne von § 23 Nr. 3 Markengesetz.
    <br />
    <br />

    <h4>3. Urheber-, Marken- und Kennzeichenrecht</h4>
    Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten,
    von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne
    Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder
    nicht vollständig entsprechen sollten, bleiben die übrigen Teile des
    Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.
    <br />
    <br />
  </div>
</template>

<script>
export default {
  name: "Überuns",

  data() {
    return {};
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
h1 {
  //padding: 30px;
  text-align: left;
  font-size: 50px;
  font-weight: lighter;
}

.welcome {
  padding: 50px;
  max-width: 1200px;
}
.blog-card-wrap {
  padding: 50px;
  // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.stamm {
  width: 100%;
  padding: 20px;
  max-width: 600px;
}
.updates {
  .container {
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
    //align-items: center;
    @media (min-width: 800px) {
      padding: 15px 20px;
      flex-direction: row;
    }

    .router-button {
      display: flex;
      font-size: 14px;
      text-decoration: none;
      @media (min-width: 800px) {
        margin-left: auto;
      }
    }
  }
}
.blog-cards {
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr;
  @media (min-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>