<template>
  <div>
    <div class="">
      <div class="search-wrapper">
        <h1 v-if="!wb">Bestenliste</h1>

        <h1 v-if="wb">Publikumsvoting des Wettbewerbs</h1>

        <div class="container">
          <p v-if="!wb">
            Hier finden Sie die aktuell bestbewerteten Präparate aus der
            Literatur-Apotheke:
          </p>
        </div>
        <input
          type="text"
          v-model="search"
          @keyup="resetList()"
          placeholder="Suche.."
          class="search-input"
        />
      </div>

      <div v-if="!wb" class="found">
        Es wurden <strong>{{ this.foundAmount }}</strong> Präparate bewertet
   
      </div>

      <div v-else class="found">
        Es wurden <strong>{{ this.foundAmount }}</strong> Einreichungen bewertet
      </div>

      

      <!--div>
        <span v-for="(el, index) in this.checkedAnwendungen" :key="index">{{
          el.name
        }}</span>
      </div>
      <div>
        <span v-for="(el, index) in this.checkedThemen" :key="index">{{
          el.name
        }}</span>
      </div>
      <div>
        <span v-for="(el, index) in this.checkedGenres" :key="index">{{
          el.name
        }}</span>
      </div-->

      <!--  <WerkCard
          :werk="werk"
          v-for="(werk, index) in werkeFiltered_comp"
          :key="index"
        />
-->

      <div class="blog-cards">

        <div v-for="(werk) in this.werkeToShow" :key="werk.rank">
          <router-link
            class="link"
            :to="{
              name: wb ? 'WB_WerkView' : 'WerkView',
              params: { werkid: werk.WerkId },
            }"
            :title="werk.Titel"
          >
         
            <WerkPost :werk="werk" :nummer="werk.rank" />
          </router-link>
        </div>
        <div class="scrolling-component"></div>
      </div>
    </div>
  </div>
</template>

<script>
import WerkPost from "../components/WerkBestSlot";
//import { werkeFiltered } from "../services/werke.service";
import werkeService from "../services/werke.service";

export default {
  name: "werke",
  components: { WerkPost },
  data() {
    return {
      search: "",
      amount: 6,
      allFilteredWerke: [],
      werkeToShow: [],
      totalAmount: 0,
      foundAmount:0,
      bestList: [],
      query: "",
      wb: this.$route.path.includes("wettbewerb"),
      asc: false,
    };
  },
  async mounted() {
    window.addEventListener("scroll", this.handleScroll);



    
 

  

    this.bestList = await this.fetchBestWerkeToShow();


    this.resetList();
  },
  methods: {
 

    resetList() {
      console.log("RESET___________________________");
      this.werkeToShow = [];
      // console.log("Reset Werke List!", this.werkeToShow);
      this.werkeToShow = this.werkeFiltered();

    },


    async fetchBestWerkeToShow() {
      // console.log("all filtered werke", this.allFilteredWerke);

    

      if(this.wb){ this.query += "wb=1&"
      //this.query+="limit=100&"
     }      else {this.query += "wb=0&"}

      this.query += "order=3&";
      
      var searchWords = this.search.split(" ");
      for (var word of searchWords) {
        this.query += "search=" + word.toLowerCase();
        this.query += "&";
      }

      var result = await werkeService.getWerkeFiltered(this.query);
      var i = 1;
      result.forEach((element) => {
        element.rank = i;
        i++;
      });

      if (result.length > 0) {
        this.totalAmount = result[0].total_count;
      } else {
        this.totalAmount = 0;
      }





      return result;
    },

     handleScroll() {
      if (
        window.scrollY + window.innerHeight >=
          document.body.scrollHeight - window.innerHeight &&
        !(this.werkeToShow.length >= this.foundAmount)
      ) {
        console.log("load new werke");
        this.werkeToShow = [
          ...this.werkeToShow,
          ...( this.werkeFiltered()),
        ];
      }
    },

    werkeFiltered() {
      console.log("GETTING WERKE");
      var filteredWerke = [];

      // Checkboxes



      filteredWerke = this.bestList.filter((post) => {


       if(post.Titel== null)post.Titel=""

       if(post.Vorname== null)post.Vorname="Account gelöscht"
       
       if(post.Nachname== null)post.Nachname=""
       
       if(post.Quelle== null)post.Quelle=""

        console.log(this.search, post);
        var includes =
          post.Titel.toLowerCase().includes(this.search.toLowerCase()) ||
          post.Autor.toLowerCase().includes(this.search.toLowerCase()) ||
          post.Inhalt.toLowerCase().includes(this.search.toLowerCase()) ||
          post.Vorname.toLowerCase().includes(this.search.toLowerCase()) ||
          post.Nachname.toLowerCase().includes(this.search.toLowerCase()) ||
          post.Quelle.toLowerCase().includes(this.search.toLowerCase()) 
          ;
        return includes;
      });

      console.log("RETIURN",filteredWerke);

      

      this.foundAmount = filteredWerke.length;
      
      return filteredWerke;
    },
  },

  computed: {
    editWerk: {
      get() {
        return this.$store.state.editWerk;
      },
      set(payload) {
        this.$store.commit("toggleEditWerk", payload);
      },
    },
    profileAdmin() {
      return true; //this.$store.state.profileAdmin;
    },
  },
  beforeDestroy() {
    this.checkedAnwendungen = [];
    this.checkedThemen = [];
    this.checkedGenres = [];
    this.$store.commit("toggleEditWerk", false);
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.container {
  // padding:50px;
}
.found {
  text-align: center;
  font-size: 1.1em;
  padding-bottom: 20px;
}
.sort-search-wrapper {
  padding-top: 20px;
  padding-left: 2vw;
  padding-right: 2vw;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-wrapper {
  display: inline-block;
  margin-right: 0;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 50px;
  right: 0;
  width: 100%;
  input {
    border-radius: 10px;

    max-width: 800px;
    padding: 10px;
    font-size: 18px;
  }
}

.blog-cards {
  padding-top: 10px;
  padding-bottom: 200px;
  position: relative;

  .toggle-edit {
    display: flex;
    align-items: center;
    position: absolute;
    top: -70px;
    right: 1;

    span {
      // margin-right: 16px;
    }
    input[type="checkbox"] {
      position: relative;
      border: none;
      -webkit-appearance: none;
      background: #fff;
      outline: none;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    input[type="checkbox"]:before {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      top: 0;
      left: 0;
      background: #303030;
      transform: scale(1.1);
      transition: 750ms ease all;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    input:checked[type="checkbox"]:before {
      background: #fff;
      left: 52px;
    }
  }
}

.blog-cards {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.blog-cards div {
  //width: 500px;
  margin: 0 auto;
  width: 100%;
}
</style>
