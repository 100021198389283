
import WB_Layout from '../layouts/Wettbewerb.vue'
import WB_Home from '../views/wettbewerb/WB_Home.vue'
import WB_Werke from '../views/wettbewerb/WB_Werke.vue'

import WB_WerkCreate from '../views/wettbewerb/WB_WerkCreate.vue'
import WerkView from '../views/WerkView.vue'
import WB_BestenListe from '../views/BestenListe.vue'

import WB_Presse from '../views/wettbewerb/WB_Presse.vue'
import WerkPreview from '../views/WerkPreview.vue'
import TeilnahmebedingungenWB from '../views/TeilnahmebedingungenWB.vue'

import Teilnahmebedingungen from '../views/Teilnahmebedingungen.vue'

import Kontakt from '../views/Kontakt.vue'

export default [
  
  {
    path: "/wettbewerb/",
    name: "Wettbewerb",
    component: WB_Layout,
    children: [
      {
        path: "",
        name: "WB_Home",
        component: WB_Home,
        meta: {
          title: "Home",
        },

      }, 
      {
        path: "werke",
        name: "WB_Werke",
        component: WB_Werke,
        meta: {
          title: "werke",
        },
      },
      {
        path: "werk-einreichen",
        name: "WB_WerkCreate",
        component: WB_WerkCreate,
        meta: {
          title: "werke",
        },
      },
      {
        path: "voting",
        name: "WB_BestenListe",
        component: WB_BestenListe,
        meta: {
          title: "voting",
        },
      },
      {
        path: "presse",
        name: "WB_Presse",
        component: WB_Presse,
        meta: {
          title: "Presse",
        },
      },
      {
        path: "praeparat/:werkid",
        name: "WB_WerkView",
        component: WerkView,
      
        meta: {
          title: "voting",
        },
        
      },
      {
        path: "vorschau",
        name: "WB_Preview",
       component: WerkPreview,
        meta: {
          title: "voting",
        },
      },
      {
        path: "einreichungen",
        name: "WB_Einreichungen",
       // component: WB_Einreichungen,
        meta: {
          title: "voting",
        },},
        {
          path: "teilnahmebedingungen-wb",
          name: "TeilnahmebedingungenWB",
          component: TeilnahmebedingungenWB,
          meta: {
            title: "Teilnahme",
          },},
          {
            path: "teilnahmebedingungen",
            name: "Teilnahmebedingungen",
            component: Teilnahmebedingungen,
            meta: {
              title: "Teilnahme",
            },},
            {
              path: "kontakt",
              name: "Kontakt",
              component: Kontakt,
              meta: {
                title: "Kontakt",
              },}
    ]
  },

];



