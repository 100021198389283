export default  {

   

 plainText(html){
    var regX = /(<([^>]+)>)/ig;     
    var str = html+"";           
     return str.replace(regX, "");
  }

  
}