var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comment-wrapper"},[_c('div',{staticClass:"comment-content"},[_c('div',{},[_c('p',{staticClass:"non-serif"},[_c('router-link',{staticClass:"link",attrs:{"to":{
            name: 'BenutzerView',
            params: { benutzerid: _vm.kommentarToShow.benutzerid },
          }}},[_vm._v(" "+_vm._s(_vm.benutzerName)+" ")]),_vm._v(" schrieb: ")],1),_vm._v(" \""),_c('span',{staticClass:"text serif ",domProps:{"innerHTML":_vm._s(_vm.kommentarToShow.inhalt)}}),_vm._v("\" "),_c('span',{staticClass:"date"},[_vm._v("am "+_vm._s(new Date(_vm.kommentarToShow.timestamp).toLocaleString("de", { dateStyle: "long", }))+" ")]),(
          !this.showMessage &&
            !this.gemeldet &&
            this.$store.getters['auth/isLoggedIn']
        )?_c('KommentarMelden',{on:{"ok":function($event){return _vm.kommentarMelden($event)}}}):_vm._e(),(this.$store.getters['auth/admin'])?_c('span',{staticClass:"delete",on:{"click":function($event){_vm.showConfirm = true}}},[_vm._v("löschen ")]):_vm._e(),_c('Confirm',{directives:[{name:"show",rawName:"v-show",value:(_vm.showConfirm && this.$store.getters['auth/admin']),expression:"showConfirm && this.$store.getters['auth/admin']"}],attrs:{"ok":"ja","cancel":"nein","title":"Kommentar löschen?"},on:{"ok":function($event){_vm.kommentarLöschen($event)},"cancel":function($event){_vm.showConfirm = false}}}),(this.showMessage || this.gemeldet)?_c('span',{staticClass:"gemeldet",domProps:{"innerHTML":_vm._s(this.message)}}):_vm._e()],1),_c('div')])])}
var staticRenderFns = []

export { render, staticRenderFns }